import { distSq } from "vector";

export function nearestCornerReference(loopIndex, vertices, point) {
  const v = vertices[loopIndex];
  const distances = v.map((vertex) => distSq(vertex, point));
  const min = Math.min(...distances);
  const corner = distances.indexOf(min);

  return {
    type: "corner-position",
    loop: loopIndex,
    corner,
  };
}
