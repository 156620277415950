<script>
  import get from "lodash/get";
  import { Link } from "svelte-routing";
  import { Drawing } from "drawing";
  import { loadImage } from "isomorphs/canvas";

  import SidebarSection from "./SidebarSection.svelte";
  import ShowIcon from "src/assets/icons/show.svg";
  import CaretLeft from "src/assets/icons/caret-left.svg";
  import CaretRight from "src/assets/icons/caret-right.svg";
  import markColor from "@local/extensions/drawing/mark-color.js";
  import { selectedLocations, locationContext } from "src/stores/ui.js";
  import { TRIOPS_URL as triopsUrl } from "src/env";
  import { api } from "src/api";
  import nameMinusExtension from "@local/extensions/utilities/name-minus-extension.js";
  import decodeImage from "src/extensions/dom/image-decode.js";

  export let group;
  export let documents;
  export let recordid;
  export let record;
  export let selectable = false;
  export let disabled;

  const markShapes = {
    item: "hexagon",
    collection: "rectangle",
    type: "circle",
  };

  let selectedLocation = 0;
  let canvas;
  let img;
  let ctx;
  let imageData;

  $: {
    recordid;
    selectedLocation = 0;
  }
  $: pages = getDocPages(documents, recordid);
  $: page = pages[selectedLocation];
  $: fetchImageData(page);
  $: prevLocation = pages[selectedLocation - 1];
  $: nextLocation = pages[selectedLocation + 1];
  $: file = pages.length && page.document.files[page.file_index];
  $: src = imageSrc(file, imageData);
  $: {
    if (canvas) {
      ctx = canvas.getContext("2d");
    }
  }
  $: {
    recordid;
    selectedLocation;
    canvas && ctx && img && imageData && page && src && render();
  }

  function getDocPages(documents, recordid) {
    const pages = [];

    documents.order.forEach((id) => {
      const document = documents[id];

      let docIndices = {};
      let page = 0;
      document.files.forEach((file) => {
        if (file.pages) {
          const f = {};

          for (let i = 0; i < file.pages; i++) {
            f[i] = page;
            page++;
          }

          docIndices[file.object_id] = f;
        }
      });

      const fileIndices = document.files.reduce((fi, f, i) => {
        fi[f.object_id] = i;
        return fi;
      }, {});

      const filePaths = document.files.reduce((fp, f) => {
        fp[f.object_id] = f;
        return fp;
      }, {});

      const docPages = {};
      document.locations
        .filter((l) => l.record_id === recordid)
        .forEach((l) => {
          if (filePaths[l.position.file_path]) {
            if (!docPages[l.position.file_path]) {
              docPages[l.position.file_path] = {};
            }

            if (!docPages[l.position.file_path][l.position.file_page]) {
              docPages[l.position.file_path][l.position.file_page] = [];
            }

            docPages[l.position.file_path][l.position.file_page].push(l);
          }
        });

      const dp = [];
      Object.keys(docPages).forEach((fp) => {
        Object.keys(docPages[fp]).forEach((page) => {
          dp.push({
            document,
            page: docIndices[fp][page],
            file: filePaths[fp],
            file_index: fileIndices[fp],
            file_page: page,
            locations: docPages[fp][page],
          });
        });
      });

      pages.push(...dp);
    });

    return pages;
  }

  async function fetchImageData(page) {
    if (!page) return;

    if (Number.isFinite(page.file_index)) {
      const f = page.file;

      if (f.content_type === "application/pdf") {
        const path = encodeURIComponent(`${nameMinusExtension(f.object_id)}`);
        const res = await fetch(`${triopsUrl}/iiif/2/drawings/${path}.${page.file_page}.tiff/info.json`);
        const data = await res.json();
        imageData = data;
      } else {
        const { data, error } = await api.storage.from("documents").createSignedUrl(f.object_id, 60);
        if (error) return;

        const img = await decodeImage(data.signedUrl);
        imageData = {
          src: data.signedUrl,
          width: img.width,
          height: img.height,
        };
      }
    } else {
      imageData = null;
    }
  }

  function imageSrc(file, imageData) {
    if (file.content_type === "application/pdf") {
      const path = encodeURIComponent(`${nameMinusExtension(file.object_id)}`);
      return `${triopsUrl}/iiif/2/drawings/${path}.${page.file_page}.tiff/full/540,/0/default.jpg`;
    } else if (imageData) {
      return imageData.src;
    }

    return null;
  }

  function gotoPrev() {
    selectedLocation -= 1;
  }

  function gotoNext() {
    selectedLocation += 1;
  }

  function selectLocations(locations) {
    const ids = locations.map((l) => l.id);
    selectedLocations.selectOnly(...ids);
  }

  async function render() {
    const width = imageData.width;
    const height = imageData.height;
    const cw = canvas.clientWidth;
    const ch = canvas.clientHeight;
    if (!img) return;
    const iw = img.naturalWidth;
    const ih = img.naturalHeight;

    const car = cw / ch;
    const iar = iw / ih;

    let zoom;
    let translate;
    if (car > iar) {
      // canvas.width = iw * (ch / ih);
      // canvas.height = ch;
      zoom = ch / height;
      translate = { x: (cw - width * zoom) / 2, y: 0 };
    } else {
      // canvas.width = cw;
      // canvas.height = ih * (cw / iw);
      zoom = cw / width;
      translate = { x: 0, y: (ch - height * zoom) / 2 };
    }

    canvas.width = cw;
    canvas.height = ch;

    ctx.setTransform(zoom, 0, 0, zoom, translate.x, translate.y);

    page.locations.forEach((l) => {
      const pos = { x: l.position.x, y: -l.position.y };
      const labelBy = record.is_collection
        ? $group.data.settings.collections_label_by
        : $group.data.settings.label_by;
      const mark = labelBy ? get(record, labelBy) : record.mark;

      const dwg = new Drawing()
        .mark(pos, mark, {
          shape: markShapes[l.record_type],
        })
        .style(markColor(record, { emphasized: true }));

      dwg.render({ ctx, annoScale: 1 / zoom });
    });
  }

  async function showContext(page) {
    if ($locationContext?.file_id === page.file.id) {
      $locationContext = null;
    } else {
      const loc = page.locations.find((l) => l.transform);
      const path = encodeURIComponent(`${page.file.id}/${page.file.stored_name}`);

      let margin;
      let size;
      if (record.cache.width > record.cache.height) {
        margin = record.cache.height;
        size = record.cache.width + margin * 2;
      } else {
        margin = record.cache.width;
        size = record.cache.height + margin * 2;
      }

      const s = size * loc.transform.scale;
      const dx = (size - record.cache.width) / 2;
      const dy = (size - record.cache.height) / 2;
      const x = loc.transform.translate.x - dx * loc.transform.scale;
      const y = loc.transform.translate.y - s + dy * loc.transform.scale;

      const src = `${triopsUrl}/iiif/2/drawings/${path}.${page.file_page}.tiff/${x},${y},${s},${s}/full/0/default.jpg`;

      const contextImg = await loadImage(src);

      $locationContext = {
        record_id: record.id,
        file_id: page.file.id,
        file_page: page.file_page,
        img: contextImg,
        x: -dx,
        y: dy + record.cache.height,
        width: size,
        height: size,
        object_id: page.file.object_id,
        stored_name: page.file.stored_name,
        transform: loc.transform,
      };
    }
  }
</script>

{#key recordid}
  <SidebarSection>
    <div class="flex justify-between items-center px-2">
      <h3 class="mb-2">Locations</h3>
      <div>
        <button
          class="prev-next-button"
          on:click={gotoPrev}
          disabled={!prevLocation}
          class:active={prevLocation}>
          <CaretLeft />
        </button>
        <button
          class="prev-next-button"
          on:click={gotoNext}
          disabled={!nextLocation}
          class:active={nextLocation}>
          <CaretRight />
        </button>
      </div>
    </div>
    <div class="space-y-2 px-2">
      {#if pages.length === 0}
        <div class="flex justify-between items-center">
          <div class="text-gray-400">No locations.</div>
          {#if !disabled}
            <Link to={"documents"}>
              <div>+ Add markers</div>
            </Link>
          {/if}
        </div>
      {:else}
        <div>
          <div class="border mb-1">
            <Link
              to={`documents/${page.document.id}?doc-page=${page.page}`}
              on:click={() => selectLocations(page.locations)}>
              <div class="relative">
                {#if file}
                  <img
                    bind:this={img}
                    class="w-full h-36 object-contain text-gray-500 text-sm"
                    {src}
                    alt={page.document.name} />
                {:else}
                  <div class="text-gray-500 text-sm">Page not processed.</div>
                {/if}
                <div class="absolute top-0 left-0 w-full h-36">
                  <canvas bind:this={canvas} class="w-full h-full" />
                </div>
              </div>
            </Link>
          </div>
          <div class="flex justify-between items-center px-1">
            <div class="flex gap-1 grow overflow-hidden">
              {#if selectable && page.locations.some((l) => l.transform)}
                <button
                  class:text-gray-400={$locationContext?.file_id !== page.file.id}
                  on:click={() => showContext(page)}>
                  <ShowIcon />
                </button>
              {/if}
              <div class="truncate">{page.document.name}</div>
            </div>
            {#if pages.length > 0}
              <div class="flex-none">{selectedLocation + 1}/{pages.length}</div>
            {/if}
          </div>
        </div>
      {/if}
    </div>
  </SidebarSection>
{/key}

<style lang="scss">
  .prev-next-button {
    @apply rounded p-1 text-gray-400;

    &.active {
      @apply text-black;
      &:hover {
        @apply bg-gray-100;
      }
    }
  }
</style>
