<script>
  import upperFirst from "lodash/upperFirst";
  import { Link } from "svelte-routing";
  import formatHumanTime from "@local/extensions/formatters/format-human-time.js";

  export let datum;

  $: status = getStatus(datum);
  $: link = `/quotes/${datum.id}`;

  function getStatus(datum) {
    if (datum.status === "canceled") return "canceled";
    if (!datum.sent_at) return "draft";

    if (datum.quotes_count) {
      return "quoted";
    } else {
      return "sent";
    }
  }

  function items(count) {
    if (!count) return "No items";
    if (count === 0) return "No items";
    if (count === 1) return "1 item";
    return `${count} items`;
  }
</script>

<td>
  {datum.sent_at ? formatHumanTime(datum.sent_at) : ""}
</td>

<td>
  <Link to={link}>
    <div class="flex truncate items-center gap-2 text-blue-600 underline">
      <div class="truncate text-md">
        {datum.job_name}
      </div>
      <!-- <div class="text-gray-500 text-xs flex-none">
      {#if datum.group?.version}
        #{datum.group?.version}
      {/if}
    </div> -->
    </div>
  </Link>
</td>

<td>
  {datum.job_location || ""}
</td>

<td>
  {datum.seller_reference || ""}
</td>

<td>
  {items(datum.items_count)}
</td>

<td class="text-right">
  {datum.price ? (datum.price / 100).toFixed(2) : ""}
</td>

<td>
  <div class="italic">
    {upperFirst(status)}
  </div>
</td>

<style lang="scss">
  td {
    @apply text-sm px-2 py-2.5 truncate;
  }
</style>
