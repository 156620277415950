<script>
  import { Link, navigate } from "svelte-routing";
  import { fly } from "svelte/transition";
  import { linear } from "svelte/easing";
  import FabricationTypeProperties from "src/lib/sidebar/FabricationTypeProperties.svelte";
  import fabDrawing from "@local/extensions/drawing/fab-drawing";
  import Viewport from "../lib/drawing/Viewport.svelte";
  import CaretLeftIcon from "../assets/icons/caret-left.svg";
  import Sidebar from "../lib/sidebar/Sidebar.svelte";
  import SidebarTitle from "../lib/sidebar/SidebarTitle.svelte";
  import PrevNext from "../lib/sidebar/PrevNext.svelte";
  import { selectedFabs, showRightPanel } from "src/stores/ui.js";
  import { orderedList } from "@local/extensions/collections/sortable-list.js";
  import dimSettings from "@local/extensions/utilities/dim-settings.js";

  export let group;
  export let fabid;
  export let items;
  export let disabled = false;

  let slide = "none";
  let highlights = {};
  let focusedDim = null;

  $: fab = $group.data.fabrications[fabid];
  $: fabrications = orderedList($group.data.fabrications);
  $: setSelected(fabid);
  $: ds = dimSettings($group.data.settings);
  $: dwgOptions = {
    ...ds,
    extend: false,
    showDims: true,
    highlights,
  };
  $: drawing = fab && fabDrawing(fab, dwgOptions);
  $: redirect(fab);
  $: fabIndex = fabrications.findIndex((f) => f.id === fabid);
  $: nextFab = fabrications[fabIndex + 1];
  $: prevFab = fabrications[fabIndex - 1];
  $: assignments = getFabAssignments(items);

  function getFabAssignments(items) {
    const a = {};

    items.forEach((item) => {
      item.data.fabrications?.edge?.forEach((f) => {
        if (f.fab_id === fabid) a[item.id] = true;
      });

      item.data.fabrications?.corner?.forEach((f) => {
        if (f.fab_id === fabid) a[item.id] = true;
      });
    });

    return a;
  }

  function setSelected(typeid) {
    selectedFabs.selectOnly(typeid);
  }

  function redirect(fab) {
    if (!fab) navigate("../fabrications");
  }

  function updateFab(prop, value) {
    group.updateProp(`data.fabrications.${fab.id}.${prop}`, value);
  }

  function highlight(e) {
    const { param } = e.detail;
    if (param.id) {
      highlights[param.id] = true;
    }
  }

  function focusDim(e) {
    const { param } = e.detail;
    if (highlights[focusedDim]) {
      delete highlights[focusedDim];
      highlights = highlights;
    }

    if (param.id) {
      focusedDim = param.id;
      highlights[param.id] = true;
    } else {
      focusedDim = null;
    }
  }

  function unhighlight(e) {
    const { param } = e.detail;
    if (param.id && param.id !== focusedDim) {
      delete highlights[param.id];
      highlights = highlights;
    }
  }

  function blurDim(e) {
    const { param } = e.detail;
    focusedDim = null;
    if (highlights[param.id]) {
      delete highlights[param.id];
      highlights = highlights;
    }
  }

  function gotoNext() {
    slide = "left";
    navigate(`./${nextFab.id}`);
  }

  function gotoPrev() {
    slide = "right";
    navigate(`./${prevFab.id}`);
  }

  function slideAnimation(node, options) {
    if (slide === "none") return null;

    if (options.type === "in") {
      return fly(node, {
        x: slide === "right" ? -50 : 50,
        delay: 100,
        easing: linear,
        duration: 100,
      });
    }

    return fly(node, {
      x: slide === "right" ? 50 : -50,
      easing: linear,
      duration: 100,
    });
  }
</script>

<div class="p-4 absolute z-10 space-y-2">
  <div class="text-blue-500 text-sm">
    <Link to={"fabrications"} class="flex items-center space-x-1">
      <CaretLeftIcon />
      <div>All Fabrications</div>
    </Link>
  </div>
</div>

{#if fab}
  <div class="w-full h-full flex relative">
    <div class="flex-grow relative">
      <div class="absolute w-full h-full overflow-hidden">
        {#key fabid}
          <div
            class="absolute w-full h-full"
            in:slideAnimation={{ type: "in" }}
            out:slideAnimation={{ type: "out" }}>
            <Viewport padding={150} autofit={false} {drawing} pannable zoomable />
          </div>
        {/key}
      </div>
    </div>
    {#if $showRightPanel}
      <Sidebar>
        <svelte:fragment slot="header">
          <PrevNext
            prev={prevFab}
            next={nextFab}
            on:gotoprev={gotoPrev}
            on:gotonext={gotoNext}
            sticky
            title={fab.mark} />
        </svelte:fragment>
        <svelte:fragment slot="content">
          <SidebarTitle title={`${fab.mark}${fab.name && ` - ${fab.name}`}`} />
          <FabricationTypeProperties
            {group}
            {disabled}
            fabrication={fab}
            {assignments}
            on:highlight={highlight}
            on:focus={focusDim}
            on:unhighlight={unhighlight}
            on:blur={blurDim} />
        </svelte:fragment>
      </Sidebar>
    {/if}
  </div>
{/if}
