<script>
  import { DimText } from "dimtext";
  import cloneDeep from "lodash/cloneDeep";
  import SidebarSection from "./SidebarSection.svelte";
  import TextInput from "./TextInput.svelte";
  import Polyface from "@local/extensions/geometry/polyface.js";
  import parseLength from "@local/extensions/parsers/parse-length.js";
  import formatLength from "@local/extensions/formatters/format-length.js";
  import isRectangle from "@local/extensions/geometry/is-rectangle.js";
  import { findNestedIndex } from "@local/extensions/collections/find-flat-index.js";
  import { createFreeShape } from "@local/lamina-core";
  import dimSettings from "@local/extensions/utilities/dim-settings.js";

  export let job;
  export let item;
  export let feature;
  export let disabled;
  export let polyface;
  export let settings;

  const dt = new DimText();

  $: ds = dimSettings(settings);
  $: lengthFormatter = (v) => formatLength(v, ds.dimFormat, ds.dimPrecision, ds.displayUnit);
  $: canFillet = !hasAdjacentBulge(feature, item);

  function hasAdjacentBulge(feature, item) {
    const shape = createFreeShape(item);
    const edges = polyface.edges;

    if (Array.isArray(feature.index)) {
      return feature.index.some((i) => {
        const f = findNestedIndex(shape.vertices, i);
        const loop = edges[f.loop];
        const edge = loop[f.index];
        return edge.start.bulge || edge.end.bulge;
      });
    } else {
      const edge = edges[feature.loop][feature.index];
      return edge.start.bulge || edge.end.bulge;
    }
  }

  function updateSize(i) {
    const pf = new Polyface(i, $job.data.fabrications);
    i.width = dt.parse(pf.width.toString()).value;
    i.height = dt.parse(pf.height.toString()).value;

    if (isRectangle(i.shape)) {
      const { xmin, ymin } = pf.bbox;
      i.rectangle_offset = { x: xmin, y: ymin };
      i.shape = { type: "rect" };
    }
  }

  function updateFeature(prop, value) {
    const tempItem = cloneDeep(item);
    const shape = createFreeShape(tempItem);

    if (Array.isArray(feature.index)) {
      feature.index.forEach((i) => {
        const f = findNestedIndex(shape.vertices, i);
        shape.vertices[f.loop][f.index][prop] = value;
      });
    } else {
      shape.vertices[feature.loop][feature.index][prop] = value;
    }

    tempItem.shape = shape;
    updateSize(tempItem);
    job.updateItem(item.id, {
      rectangle_offset: tempItem.rectangle_offset,
      width: tempItem.width,
      height: tempItem.height,
      data: tempItem.data,
      shape: tempItem.shape,
    });
  }
</script>

<SidebarSection>
  <TextInput
    label="X"
    {disabled}
    labelWidth="5.5rem"
    value={feature.pt.x}
    parser={parseLength}
    formatter={lengthFormatter}
    on:input={(e) => updateFeature("x", e.detail.value)} />
  <TextInput
    label="Y"
    {disabled}
    labelWidth="5.5rem"
    value={feature.pt.y}
    parser={parseLength}
    formatter={lengthFormatter}
    on:input={(e) => updateFeature("y", e.detail.value)} />
  {#if canFillet}
    <TextInput
      label="Fillet radius"
      {disabled}
      labelWidth="5.5rem"
      value={feature.pt.fillet || 0}
      parser={parseLength}
      formatter={lengthFormatter}
      on:input={(e) => updateFeature("fillet", e.detail.value)} />
  {/if}
</SidebarSection>
