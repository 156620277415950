<!-- <svelte:options strictprops={false} /> -->

<script>
  import { onMount, tick } from "svelte";
  import { navigate } from "svelte-routing";
  import LoginForm from "src/lib/LoginForm.svelte";
  import NavbarUnauthenticated from "src/lib/NavbarUnauthenticated.svelte";
  import { user, profile, apiError, loading } from "src/stores/auth.js";

  export let path;

  let email = "";
  let password = "";
  let otp = "";
  let otpInput;

  let state = "magiclink"; // ["magiclink", "password", "reset", "otp"]
  let sent = false;

  $: otpValid = !!otp;

  async function login() {
    // If we've already sent the request, we allow logging in with the OTP
    if (sent) {
      const p = await user.verifyOtp(email, otp);
      if ($user && state === "reset") {
        if (p?.has_onboarded === false) {
          await profile.onboard();
        }
        navigate("/passreset");
      } else if (p?.has_onboarded === false) {
        await profile.onboard();
        navigate("/passreset");
      } else if ($user) {
        navigate(`/${path}`);
      }
    } else if (state === "otp") {
      await user.verifyOtp(email, otp);
    } else if (state === "password") {
      await user.login(email, password);
    } else if (state === "reset") {
      await user.resetPassword(email, "passreset");

      if (!$apiError) {
        sent = true;
        await tick();
        if (otpInput) {
          otpInput.focus();
        }
      }
    } else {
      await user.sendMagicLink(email, path);
      if (!$apiError) {
        sent = true;
        await tick();
        if (otpInput) {
          otpInput.focus();
        }
      }
    }
  }

  function chooseLoginFlow(type) {
    state = type;
    if (type === "magiclink") {
      localStorage.setItem("login-pref", "magiclink");
    } else if (type === "password") {
      localStorage.setItem("login-pref", "password");
    }
  }

  onMount(async () => {
    if ($user) {
      await tick();
      navigate(`/${path}`);
    } else {
      const hash = window.location.hash;
      if (hash === "#otp") {
        chooseLoginFlow("otp");
      } else if (hash === "#password") {
        chooseLoginFlow("password");
      } else if (hash === "#one-time-code") {
        chooseLoginFlow("magiclink");
      } else {
        const pref = localStorage.getItem("login-pref");
        if (pref === "password") {
          state = "password";
        } else if (pref === "magiclink") {
          state = "magiclink";
        } else {
          state = "magiclink";
        }
      }
    }
  });
</script>

<NavbarUnauthenticated />
<LoginForm action={login}>
  {#if !sent}
    <div>
      <input
        class="border rounded w-full p-2"
        class:loading={$loading}
        type="email"
        autocomplete="username"
        placeholder="name@email.com"
        disabled={$loading}
        bind:value={email} />
    </div>
    {#if state === "password"}
      <div>
        <input
          class="border rounded w-full p-2"
          class:loading={$loading}
          type="password"
          autocomplete="current-password"
          placeholder="password"
          disabled={$loading}
          bind:value={password} />
      </div>
    {:else if state === "otp"}
      <div>
        <input
          class="border rounded w-full p-2"
          class:loading={$loading}
          autocomplete="one-time-code"
          placeholder="one-time password"
          disabled={$loading}
          bind:value={otp} />
      </div>
    {/if}
    {#if $apiError}
      <div class="text-red-600 text-sm">{$apiError}</div>
    {/if}
    <button type="submit" class="btn btn-lg btn-primary w-full" class:loading={$loading} disabled={$loading}>
      {#if state === "magiclink"}
        Sign In with Email
      {:else if state === "otp"}
        Sign In with One-time Password
      {:else if state === "password"}
        Sign In
      {:else}
        Send Password Reset Link
      {/if}
    </button>
    <div class="rounded bg-gray-100 py-4 px-6 text-left text-sm">
      {#if state === "password"}
        <p>
          Forgot your password? <button
            class="text-blue-500 font-bold contents"
            on:click={() => chooseLoginFlow("reset")}>Reset password</button
          >,
        </p>
        <p>
          or sign in with a <button
            class="text-blue-500 font-bold contents"
            on:click={() => chooseLoginFlow("magiclink")}>one-time code</button> instead.
        </p>
      {:else if state === "reset"}
        <p>
          Enter email to receive password reset link, or <button
            class="text-blue-500 font-bold contents"
            on:click={() => chooseLoginFlow("magiclink")}>sign in with a one-time code instead.</button>
        </p>
      {:else if state === "otp"}
        <p>
          Provide your email address and the 6-digit code sent to your email to sign in. If you didn't receive
          a code, you can <button
            class="text-blue-500 font-bold contents"
            on:click={() => chooseLoginFlow("magiclink")}>request a new one.</button>
        </p>
      {:else if state === "magiclink"}
        <p>
          We'll email you a one-time code for a password-free sign-in. Or you can <button
            class="text-blue-500 font-bold contents"
            on:click={() => chooseLoginFlow("password")}>sign in with a password instead.</button>
        </p>
      {/if}
    </div>
  {:else}
    <div class="text-sm">
      <p class="mb-2">
        A 6-digit login code has been sent to <span class="font-bold">{email}</span>.
      </p>
      <p>Enter it below to log in:</p>
    </div>
    {#if $apiError}
      <div class="text-red-600 text-sm">{$apiError}</div>
    {/if}
    {#if state === "magiclink" || state === "reset"}
      <div>
        <input
          bind:this={otpInput}
          class="otp-input"
          class:loading={$loading}
          autocomplete="one-time-code"
          placeholder="123456"
          maxlength="6"
          size="6"
          disabled={$loading}
          bind:value={otp} />
      </div>
      <button
        type="submit"
        class="btn btn-lg btn-primary w-full"
        class:loading={$loading}
        disabled={$loading || !otpValid}>
        {#if state === "magiclink"}
          Sign in
        {:else}
          Go to Password Reset
        {/if}
      </button>
    {/if}
  {/if}
</LoginForm>

<style>
  .loading {
    opacity: 0.5;
  }

  .otp-input {
    @apply p-2 border rounded font-mono;
    font-size: 2.5rem;
    text-align: left;
    min-width: 0;
    max-width: 100%;
  }
</style>
