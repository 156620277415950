
import { Point } from 'openseadragon';
import RenderedTileSource from './RenderedTileSource.js';
import { getLogger } from "@diagraphics/logging";

const log = getLogger("PDFTileSource", "DEBUG");

/**
 *
 * @param {Array<number>} scales
 * @returns
 */
function validScales(scales) {
	return scales.length > 0 && scales.every((x, i, s) => i === 0 || x > s[i - 1]);
}

class PDFTileSource extends RenderedTileSource {
	constructor(options) {
		const { page, scales = [1], fingerprints = [], ...rest } = options;

		if (!validScales(scales)) {
			throw new Error('Option `scales` must be a monontonically increasing array of numbers');
		}

		/* So that we can use viewport scales directly as the output of getLevelScale()
		   we base the overall width and height on scale = 1 */
		const { width, height } = page.getViewport({ scale: 1 });

		super({
			...rest,
			width,
			height,
			tileWidth: width,
			tileHeight: height,
			tileOverlap: 0,
			minLevel: 0,
			maxLevel: scales.length - 1,
		});

		/** @type {import('pdfjs-dist').PDFPageProxy} */
		this.page = page;

		/** @type {Array<string>} */
		this.fingerprints = fingerprints;

		/** @type {Array<number>} */
		this.scales = scales;

		/** @type {import('pdfjs-dist').PageViewport[]} */
		this.viewports = new Array(scales.length);
	}

	getLevelViewport(level) {
		let viewport = this.viewports[level];

		if (!viewport) {
			const scale = this.getLevelScale(level);
			viewport = this.page.getViewport({ scale });
			this.viewports[level] = viewport;
		}

		return viewport;
	}

	getSourceId() {
		const { fingerprints, page } = this;
		return [...fingerprints, page._pageIndex].join('/');
	}

	getLevelScale(level) {
		if (this.scales.length > 0 && level >= this.minLevel && level <= this.maxLevel) {
			return this.scales[level];
		}
		return NaN;
	}

	getTileWidth(level) {
		const { width } = this.getLevelViewport(level);
		return width;
	}

	getTileHeight(level) {
		const { height } = this.getLevelViewport(level);
		return height;
	}

	/**
	 * A Rendered PDF source always has a single tile
	 *
	 * @param {number} level
	 * @returns {Point}
	 */
	getNumTiles(level) {
		const scale = this.getLevelScale(level);
		if (scale) {
			return new Point(1, 1);
		}
		return new Point(0, 0);
	}

	async renderTile(level /* x, y */) {
		const viewport = this.getLevelViewport(level);

		log.trace('[PDFTileSource] Rendering level %d with viewport %o', level, viewport);

		const { width, height } = viewport;
		const canvas = new OffscreenCanvas(width, height);
		const canvasContext = canvas.getContext('2d');
		const { promise: completed /* cancel */ } = this.page.render({ viewport, canvasContext });

		await completed;

		return canvasContext;
	}
}

export default PDFTileSource;
