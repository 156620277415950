import upperCase from "lodash/upperCase";
import getUserInitials from "@local/extensions/formatters/get-user-initials.js";

export default function getAssignToOptions(p, o) {
  if (!o) return [];

  const orgProfiles = o.profiles
    ? o.profiles.map((prof) => ({
        label: prof.username || prof.email,
        initials: upperCase(getUserInitials(prof)),
        color: prof.user_color,
        value: prof.id,
      }))
    : [];

  const present = orgProfiles.some((prof) => prof.value === p.id);

  if (present) {
    return [
      {
        label: "Unassigned",
        value: null,
      },
      ...orgProfiles,
    ];
  } else {
    return [
      {
        label: "Unassigned",
        value: null,
      },
      {
        label: p.username || p.email,
        initials: upperCase(getUserInitials(p)),
        color: p.user_color,
        value: p.id,
      },
      ...orgProfiles,
    ];
  }
}
