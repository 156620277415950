import { slope } from "vector";

function minimize(val, testvals) {
  let min = testvals[0];
  let d0 = Math.abs(val - min);

  testvals.slice(1).forEach((t) => {
    const d = Math.abs(val - t);
    if (d < d0) {
      min = t;
      d0 = d;
    }
  });

  return min;
}

function snap(
  pt,
  originalPt,
  prevPt,
  nextPt,
  threshold = 100,
  constraint,
  hplanes = [],
  vplanes = [],
) {
  const p = { ...pt };

  if (
    (originalPt.x - pt.x) ** 2 < threshold &&
    (originalPt.y - pt.y) ** 2 < threshold
  ) {
    return originalPt;
  }

  const nearestX = minimize(pt.x, [
    prevPt.x,
    nextPt.x,
    originalPt.x,
    ...vplanes,
  ]);
  const nearestY = minimize(pt.y, [
    prevPt.y,
    nextPt.y,
    originalPt.y,
    ...hplanes,
  ]);

  if ((nearestX - pt.x) ** 2 < threshold) p.x = nearestX;
  if ((nearestY - pt.y) ** 2 < threshold) p.y = nearestY;

  if (constraint === "horizontal") p.y = originalPt.y;
  if (constraint === "vertical") p.x = originalPt.x;
  if (constraint === "both") {
    p.x = originalPt.x;
    p.y = originalPt.y;
  }

  return p;
}

function snapEdge(
  to,
  original,
  threshold = 100,
  ca,
  cb,
  hplanes = [],
  vplanes = [],
) {
  let a = { ...to[0] };
  let b = { ...to[1] };

  const oa = { ...original[0] };
  const ob = { ...original[1] };

  if ((oa.x - a.x) ** 2 < threshold && (oa.y - a.y) ** 2 < threshold) {
    return original;
  }

  const s = slope(...original);
  const testx = s === Infinity || s === -Infinity ? [oa.x, ...vplanes] : [oa.x];
  const testy = s === 0 ? [oa.y, ...hplanes] : [oa.y];
  const nearestX = minimize(a.x, testx);
  const nearestY = minimize(a.y, testy);

  if ((nearestX - a.x) ** 2 < threshold) {
    const dx = nearestX - a.x;
    a.x = nearestX;
    b.x += dx;
  }
  if ((nearestY - a.y) ** 2 < threshold) {
    const dy = nearestY - a.y;
    a.y = nearestY;
    b.y += dy;
  }

  let constraint = null;
  if (!ca) {
    constraint = cb;
  } else if (!cb) {
    constraint = ca;
  } else if (ca === cb) {
    constraint = ca;
  } else {
    constraint = "both";
  }

  if (constraint === "horizontal") {
    a.y = oa.y;
    b.y = ob.y;
  }
  if (constraint === "vertical") {
    a.x = oa.x;
    b.x = ob.x;
  }
  if (constraint === "both") {
    a = oa;
    b = ob;
  }

  return [a, b];
}

function snapOrtho(
  pt,
  originalPt,
  threshold = 100,
  constraint,
  hplanes = [],
  vplanes = [],
) {
  const p = { ...pt };

  if (
    (originalPt.x - pt.x) ** 2 < threshold &&
    (originalPt.y - pt.y) ** 2 < threshold
  ) {
    return originalPt;
  }

  const nearestX = minimize(pt.x, [originalPt.x, ...vplanes]);
  const nearestY = minimize(pt.y, [originalPt.y, ...hplanes]);

  if ((nearestX - pt.x) ** 2 < threshold) p.x = nearestX;
  if ((nearestY - pt.y) ** 2 < threshold) p.y = nearestY;

  const x = originalPt.x - pt.x;
  const y = originalPt.y - pt.y;

  if (constraint === "horizontal") p.y = originalPt.y;
  if (constraint === "vertical") p.x = originalPt.x;
  if (constraint === "both") {
    p.x = originalPt.x;
    p.y = originalPt.y;
  }

  return Math.abs(y) > Math.abs(x)
    ? { x: originalPt.x, y: p.y }
    : { x: p.x, y: originalPt.y };
}

function snapEdgeOrtho(
  to,
  original,
  threshold = 100,
  ca,
  cb,
  hplanes = [],
  vplanes = [],
) {
  let a = { ...to[0] };
  let b = { ...to[1] };

  const oa = { ...original[0] };
  const ob = { ...original[1] };

  if ((oa.x - a.x) ** 2 < threshold && (oa.y - a.y) ** 2 < threshold) {
    return original;
  }

  const s = slope(...original);
  const testx = s === Infinity || s === -Infinity ? [oa.x, ...vplanes] : [oa.x];
  const testy = s === 0 ? [oa.y, ...hplanes] : [oa.y];
  const nearestX = minimize(a.x, testx);
  const nearestY = minimize(a.y, testy);

  if ((nearestX - a.x) ** 2 < threshold) {
    const dx = nearestX - a.x;
    a.x = nearestX;
    b.x += dx;
  }
  if ((nearestY - a.y) ** 2 < threshold) {
    const dy = nearestY - a.y;
    a.y = nearestY;
    b.y += dy;
  }

  const x = oa.x - a.x;
  const y = oa.y - a.y;

  let constraint = null;
  if (!ca) {
    constraint = cb;
  } else if (!cb) {
    constraint = ca;
  } else if (ca === cb) {
    constraint = ca;
  } else {
    constraint = "both";
  }

  if (constraint === "horizontal") {
    a.y = oa.y;
    b.y = ob.y;
  }
  if (constraint === "vertical") {
    a.x = oa.x;
    b.x = ob.x;
  }
  if (constraint === "both") {
    a = oa;
    b = ob;
  }

  return Math.abs(y) > Math.abs(x)
    ? [
        { x: oa.x, y: a.y },
        { x: ob.x, y: b.y },
      ]
    : [
        { x: a.x, y: oa.y },
        { x: b.x, y: ob.y },
      ];
}

export { snap, snapOrtho, snapEdge, snapEdgeOrtho };
