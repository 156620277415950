<script>
  import Navbar from "src/lib/Navbar.svelte";
  import XIcon from "src/assets/icons/x.svg";

  import { getSupportVideos } from "src/api";

  export let orgId = null;

  let currentVideo = null;
  let cvHeight;
  let cvWidth;
  let videos = [];
  $: categories = [
    ...videos
      .reduce((cats, vid) => {
        vid.category.forEach((cat) => {
          cats.set(cat, true);
        });
        return cats;
      }, new Map())
      .keys(),
  ];
  let selectedFilters = {};
  $: resetFilters(categories);
  $: videoAR = currentVideo ? currentVideo.width / currentVideo.height : 16 / 9;
  $: containerAR = cvWidth ? cvWidth / cvHeight : 16 / 9;
  $: videoBoxW = containerAR > videoAR ? cvHeight * videoAR : cvWidth;
  $: videoBoxH = containerAR > videoAR ? cvHeight : cvWidth / videoAR;
  $: filteredVideos = filterVideos(videos, categories, selectedFilters);

  async function fetchVideos() {
    const v = await getSupportVideos(orgId);
    if (Array.isArray(v)) {
      videos = v;
    }
  }

  function selectCategory(category) {
    selectedFilters[category] = !selectedFilters[category];
  }

  function resetFilters(categories) {
    selectedFilters = categories.reduce((s, cat) => {
      s[cat] = false;
      return s;
    }, {});
  }

  function filterVideos(videos, categories, selectedFilters) {
    if (categories.every((c) => !selectedFilters[c])) return videos;

    return videos.filter((video) => {
      return video.category.some((cat) => selectedFilters[cat]);
    });
  }

  function showVideo(video) {
    console.log(video);
    currentVideo = video;
  }
</script>

<Navbar context="home" />
<div class="grow flex flex-col min-h-0 relative">
  <div class="p-8">
    {#if categories.length > 1}
      <div class="flex gap-2 mb-3">
        {#each categories as category}
          <button
            class="filter-button"
            class:selected={selectedFilters[category]}
            on:click={() => selectCategory(category)}>
            {category}
          </button>
        {/each}
      </div>
    {/if}
    {#await fetchVideos() then _}
      <div class="flex flex-wrap gap-4">
        {#if !videos.length}
          <div class="w-80 text-sm text-gray-900 italic">No videos found</div>
        {/if}
        {#each filteredVideos as video}
          <button
            class="w-80 rounded-lg border border-gray-400 overflow-hidden text-left flex flex-col justify-start"
            on:click={() => showVideo(video)}>
            <div class="h-52 overflow-hidden flex-none border-b border-gray-400">
              <img src={video.thumbnail_url} alt={video.title} class="object-cover" />
            </div>
            <div class="p-2 space-y-2">
              <div class="text-sm text-gray-900 font-bold truncate">
                {video.title}
              </div>
              <div class="text-xs">
                {#if !video.description}
                  &nbsp;
                {/if}
                {video.description}
              </div>
            </div>
          </button>
        {/each}
      </div>
    {/await}
  </div>
  {#if currentVideo}
    <div class="absolute w-full h-full modal-container flex items-center justify-center bg-gray-900/25 p-4">
      <div
        class="rounded-lg overflow-hidden h-full w-full bg-white border border-gray-400 relative flex flex-col">
        <div class="flex justify-between px-4 py-2 border-b text-gray-500 flex-none items-center">
          <div class="grow truncate">
            {currentVideo.title}
          </div>
          <button class="flex-none p-1 rounded hover:bg-gray-200" on:click={() => (currentVideo = null)}>
            <XIcon />
          </button>
        </div>
        <div
          class="grow overflow-hidden flex items-center justify-center"
          bind:clientWidth={cvWidth}
          bind:clientHeight={cvHeight}>
          <div style="width:{videoBoxW}px;height:{videoBoxH}px;">
            {@html currentVideo.html}
          </div>
        </div>
      </div>
    </div>
  {/if}
</div>

<style lang="scss">
  .modal-container {
    z-index: 200;
  }

  .filter-button {
    @apply px-2 py-0.5 text-sm border border-gray-300 rounded-full;

    &.selected,
    &:hover {
      @apply bg-black text-white border-black;
    }
  }
</style>
