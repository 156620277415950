<script>
  // export let group;
</script>

<div
  class="h-10 w-full border-t border-gray-700 flex items-center justify-center px-4 gap-4 text-white text-sm truncate bg-teal-600">
  <!-- <div class="text-xs font-bold">
    RFQ #{$group.version}
  </div> -->
  <div>This is a read-only snapshot.</div>
</div>
