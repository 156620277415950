import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import get from "lodash/get";
import set from "lodash/set";
import merge from "lodash/merge";

import Polyface from "@local/extensions/geometry/polyface.js";

function makeMultiItem(grp, selectedItems, customColumns) {
  if (selectedItems.length < 2) return null;

  const items = selectedItems.slice();
  const last = cloneDeep(items.pop());
  const lastpf = new Polyface(last, grp.data.fabrications);

  // get normalized data shape for an item
  const ccd = customColumns.reduce((o, col) => {
    o[col.id] = undefined;
    return o;
  }, {});

  last.data = merge({ fabrications: {} }, last.data);
  last.custom_column_data = merge(ccd, last.custom_column_data);
  last.data.fabrications.edge_treatment = lastpf.edges[0].map((e, i) => {
    return get(last, `data.fabrications.edge_treatment.${i}`) || null;
  });

  return items.reduce((m, item) => {
    const pface = new Polyface(item, grp.data.fabrications);
    const perimeter = pface.edges[0];

    Object.entries(item).forEach(([key, value]) => {
      // Data/cache properties need to be merged to identify which properties are mixed
      if (key === "cache") {
        if (!m.cache) m.cache = {};
        const v = value || {};

        Object.entries(m.cache).forEach(([key, prop]) => {
          const vprop = v[key];
          if (!isEqual(prop, vprop)) {
            m.cache[key] = "Mixed";
          }
        });
      } else if (key === "custom_column_data") {
        const val = m[key] || {};
        const v = value || {};

        Object.entries(val).forEach(([colId, colVal]) => {
          const sVal = get(v, colId);
          if (sVal !== colVal) {
            val[colId] = "Mixed";
          } else {
            val[colId] = sVal;
          }
        });
      } else if (key === "data") {
        const val = m[key] || {};
        const v = value || {};

        const mEdgeTreatment = val.fabrications.edge_treatment || [];
        const sEdgeTreatment = perimeter.map(
          (e, i) => get(v, `fabrications.edge_treatment.${i}`) || null,
        );

        perimeter.forEach((e, i) => {
          const set = sEdgeTreatment[i];
          const met = mEdgeTreatment[i];
          if (met === "Mixed" || met === set) {
            return;
          }

          if (met === undefined) {
            mEdgeTreatment[i] = set;
          } else {
            mEdgeTreatment[i] = "Mixed";
          }
        });

        if (!isEqual(val.shape || {}, v.shape || {})) {
          val.shape = "Mixed";
        }
      } else if (typeof value === "object" && isEqual(m[key], value)) {
        m[key] = value;
      } else if (m[key] !== value) m[key] = "Mixed";
    });

    return m;
  }, last);
}

export default makeMultiItem;
