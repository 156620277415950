<script>
  import { navigate } from "svelte-routing";

  export let path;
  export let active;
</script>

<button class:active on:click={() => navigate(path)}><slot /></button>

<style lang="scss">
  button {
    @apply opacity-50 px-2 text-white border-b border-transparent;

    &:hover {
      @apply opacity-100;
    }

    &.active {
      @apply opacity-100 border-b-white;
    }
  }
</style>
