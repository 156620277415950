import { getPrice } from "./price-totals.js";
import type { ItemRecordCore } from "../entities/item.js";
import type { TablesGenerated } from "../database.js";

type PriceEntry = TablesGenerated<"price_entries">;

function itemFreightProperties(item: ItemRecordCore, priceEntry: PriceEntry | null, autoEntry: PriceEntry | null) {
  const width = item.cache?.width_in ?? 0;
  const height = item.cache?.height_in ?? 0;
  const weight = item.cache?.weight ?? 0;
  let price = 0;
  if (priceEntry) {
    if (autoEntry && (!priceEntry.unit || priceEntry.unit_price == null)) {
      price = getPrice(autoEntry, item);
    }

    price = getPrice(priceEntry, item);
    price = Math.round(price * 100);
  }

  return { id: item.id, width, height, weight, price };
}

export { itemFreightProperties };
