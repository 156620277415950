<script>
  import { Link } from "svelte-routing";
  import { createEventDispatcher, getContext } from "svelte";
  import { DimText, Dimension, Quantity } from "dimtext";
  import get from "lodash/get";
  import cloneDeep from "lodash/cloneDeep";

  import EllipsisIcon from "src/assets/icons/ellipsis.svg";
  import RefPlanesBoxIcon from "src/assets/icons/ref-planes-box.svg";
  import RefPlanesCrossIcon from "src/assets/icons/ref-planes-cross.svg";
  import CircleXIcon from "src/assets/icons/circle-x.svg";

  import SidebarSection from "./SidebarSection.svelte";
  import Viewport from "src/lib/drawing/ViewportFixed.svelte";
  import TextInput from "./TextInput.svelte";
  import FabricationsInput from "./FabricationsInput.svelte";
  import DimtextInput from "./DimtextInput.svelte";
  import BooleanInput from "./BooleanInput.svelte";
  import DimtextOffsetInput from "./DimtextOffsetInput.svelte";
  import SelectInput from "./SelectInput.svelte";
  import ContenteditableInput from "./ContenteditableInput.svelte";
  import FlagInput from "./FlagInput.svelte";
  import ButtonInput from "./ButtonInput.svelte";
  import Item2dEditor from "src/lib/Item2dEditor.svelte";
  import MiniToolbar from "src/lib/toolbar/MiniToolbar.svelte";

  import liteDrawing from "@local/extensions/drawing/lite-drawing.js";
  import parsePosInteger from "@local/extensions/parsers/parse-pos-integer.js";
  import Polyface from "@local/extensions/geometry/polyface.js";
  import { tooltip } from "svelte-utilities";
  import { drawingSrc } from "src/api";
  import { createBoxRefPlanes, createCrossRefPlanes } from "@local/lamina-core";

  export let types;
  export let group;
  export let item;
  export let collections = [];
  export let itemid = null;
  export let settings;
  export let standardColumns = {};
  export let customColumns = [];
  export let isCollection = false;
  export let disabled = false;
  export let multiple = false;
  export let showEditor = false;

  const dt = new DimText();
  const dispatch = createEventDispatcher();
  let showIndividualEdges = !treatmentsAreEqual(item);
  let refreshKey = crypto.randomUUID();
  const edgeTreatments = getContext("edgeTreatmentOptions");
  const typeColors = getContext("typeColors");

  const refPlaneButtons = [
    { component: RefPlanesBoxIcon, label: "Box", value: "box" },
    { component: RefPlanesCrossIcon, label: "Cross", value: "cross" },
  ];

  $: displayUnit = settings.display_unit;
  $: item && refresh();
  $: type = $group.types[item?.type_id];
  $: areaFormatter = makeAreaFormatter(displayUnit);
  $: mixedShape = item.shape === "Mixed";
  $: typeOptions = getTypeOptions($group, types);
  $: currentType = $group.types[item?.type_id];
  $: disabledProps = getDisabledProps($group, currentType, item);
  $: collectionOptions = [{ label: "None", value: null }].concat(
    collections.map((c) => ({ label: c.mark, value: c.id })),
  );
  $: shapeItem = item.shape.type !== "none";
  $: noShapePropUpdates = item.shape.type === "free" || mixedShape;
  $: noTypeUpdates = $group?.project_type === "product";
  $: polyface = new Polyface(item, $group.data.fabrications);
  $: edgeIndices = mixedShape
    ? item.data.fabrications.edge_treatment.map((t, i) => i)
    : polyface.shape.flat().map((v, i) => i);
  $: treatment = edgeIndices.map((i) => get(item.data, ["fabrications", "edge_treatment", i]) || null);
  $: allTreatments = treatment.reduce((a, t) => {
    if (t === a) return a;
    return "Mixed";
  }, treatment[0]);
  $: updateShowState(itemid);

  function getDisabledProps(grp, type, item) {
    if (!type) return {};
    if (!type.item_template_mask) return {};
    const props = {};

    if (grp.project_type === "product") {
      props.type_id = true;
    }

    return Object.entries(type.item_template_mask).reduce((o, [k, v]) => {
      if (v) o[k] = true;
      return o;
    }, props);
  }

  function getTypeOptions(grp, types) {
    if (grp.project_type === "product") {
      return types.map((t) => ({
        label: t.name,
        value: t.id,
      }));
    } else {
      return types.map((t) => ({
        label: `${t.mark}${t.name && ` - ${t.name}`}`,
        value: t.id,
      }));
    }
  }

  function makeAreaFormatter(displayUnit) {
    return (a) => {
      return displayUnit === "inches"
        ? typeof a === "number"
          ? a.toFixed(2)
          : ""
        : typeof a === "number"
          ? (a * 0.092903).toFixed(2)
          : "";
    };
  }

  function refresh() {
    refreshKey = crypto.randomUUID();
  }

  function treatmentsAreEqual(item) {
    const s = new Polyface(item, $group.data.fabrications);
    const edges = s.edges.flat();
    const t = edges.map((edge, i) => get(item.data, ["fabrications", "edge_treatment", i]));
    const first = t[0];
    if (first === "Mixed") return false;
    return t.every((t) => t === first);
  }

  function updateShowState(itemid) {
    showIndividualEdges = !treatmentsAreEqual(item);
  }

  function updateItem(prop, value) {
    dispatch("updateItem", { id: item.id, prop, value });
  }

  function removeDrawing() {
    dispatch("updateItem", { id: item.id, prop: "drawing", value: null });
  }

  function updateRefPlanes(value) {
    if (!value) {
      dispatch("updateItem", { id: item.id, prop: "data.reference_planes", value: null });
    } else if (value === "box") {
      const rp = createBoxRefPlanes();
      const bbox = item.cache.bbox;
      let top = bbox.ymax;
      let right = bbox.xmax;
      let bottom = bbox.ymin;
      let left = bbox.xmin;
      if (displayUnit === "millimeters") {
        top = top * 25.4;
        right = right * 25.4;
        bottom = bottom * 25.4;
        left = left * 25.4;
      }

      rp.top.value = new Dimension(new Quantity(top, displayUnit));
      rp.right.value = new Dimension(new Quantity(right, displayUnit));
      rp.bottom.value = new Dimension(new Quantity(bottom, displayUnit));
      rp.left.value = new Dimension(new Quantity(left, displayUnit));
      dispatch("updateItem", { id: item.id, prop: "data.reference_planes", value: rp });
    } else if (value === "cross") {
      const rp = createCrossRefPlanes();
      const bbox = item.cache.bbox;
      let ver = bbox.xmin + bbox.width / 2;
      let hor = bbox.ymin + bbox.height / 2;
      if (displayUnit === "millimeters") {
        ver = ver * 25.4;
        hor = hor * 25.4;
      }

      if (Number.isFinite(ver)) {
        rp.vertical.value = new Dimension(new Quantity(ver, displayUnit));
      }
      if (Number.isFinite(hor)) {
        rp.horizontal.value = new Dimension(new Quantity(hor, displayUnit));
      }
      dispatch("updateItem", { id: item.id, prop: "data.reference_planes", value: rp });
    }
  }

  function updatedWidth(item, offset) {
    const pf = new Polyface(item, $group.data.fabrications);
    const bbox = pf.bbox;
    const w = bbox.width - offset.toNumber("inches");
    return dt.parse(w.toString()).value;
  }

  function updatedHeight(item, offset) {
    const pf = new Polyface(item, $group.data.fabrications);
    const bbox = pf.bbox;
    const h = bbox.height - offset.toNumber("inches");
    return dt.parse(h.toString()).value;
  }

  function updateItemWidth(e) {
    const { field, value } = e.detail;
    if (field === "input") {
      dispatch("updateItem", { id: item.id, prop: "width", value });
    } else {
      if (item.shape.type === "free") {
        const width = updatedWidth(item, value);
        dispatch("updateItem", { id: item.id, diff: { width_offset: value, width } });
      } else {
        dispatch("updateItem", { id: item.id, prop: "width_offset", value });
      }
    }
  }

  function updateItemHeight(e) {
    const { field, value } = e.detail;
    if (field === "input") {
      dispatch("updateItem", { id: item.id, prop: "height", value });
    } else {
      if (item.shape?.type === "free") {
        const height = updatedHeight(item, value);
        dispatch("updateItem", { id: item.id, diff: { height_offset: value, height } });
      } else {
        dispatch("updateItem", { id: item.id, prop: "height_offset", value });
      }
    }
  }

  function updateData(path, value) {
    const prop = `data.${path.join(".")}`;
    dispatch("updateItem", { id: item.id, prop, value });
  }

  function updateAllEdges(value) {
    const treatments = edgeIndices.map((e) => value);
    dispatch("updateItem", {
      id: item.id,
      prop: "data.fabrications.edge_treatment",
      value: treatments,
    });
  }

  function updateCustomColData(id, val) {
    const prop = `custom_column_data.${id}`;
    dispatch("updateItem", { id: item.id, prop, value: val });
  }

  function getPropName(name, prop) {
    return standardColumns[prop]?.override ? standardColumns[prop].override : name;
  }

  function getDrawingSrc(item) {
    return item.drawing && drawingSrc(item.drawing);
  }

  function lb(item) {
    return item.is_collection ? $group.data.settings.collections_label_by : $group.data.settings.label_by;
  }

  function toggleBug() {
    const data = cloneDeep(item.data);
    if (!data.fabrications) {
      data.fabrications = {};
    }

    if (!data.fabrications.bug) {
      data.fabrications.bug = {
        type: "rectangle",
        width: dt.parse("1").value,
        height: dt.parse("1").value,
        alignment: "inside-edge",
        orientation: "orthogonal",
        reference: {
          type: "edge-offset-position",
          loop: 0,
          edge: 0,
          length: dt.parse("3").value,
          offset: dt.parse("3").value,
          start: "start",
        },
      };
    } else {
      delete data.fabrications.bug;
    }

    dispatch("updateItem", { id: item.id, prop: "data", value: data });
  }
</script>

{#if !multiple}
  <div class="thumbnail-image">
    {#if showEditor}
      <div class="border mx-4">
        <MiniToolbar {disabled} />
        <div class="mini-editor">
          <Item2dEditor
            paddingTop={80}
            paddingRight={80}
            paddingBottom={80}
            paddingLeft={80}
            {disabled}
            {isCollection}
            {group}
            {item}
            itemid={item?.id}
            {type}
            {types} />
        </div>
      </div>
      <SidebarSection>
        <Link to={`items/${item?.id}`}><div class="px-2 mt-2 text-blue-500">View Item</div></Link>
      </SidebarSection>
    {:else}
      <SidebarSection>
        <div class="relative">
          <Link to={`${isCollection ? "openings" : "items"}/${item.id}`}>
            <div
              class="flex items-center justify-center rounded border border-transparent hover:border-gray-300">
              {#key refreshKey}
                <div class="w-36 h-36 relative">
                  {#if item.drawing}
                    <img
                      class="w-full h-full border border-gray-400 object-contain"
                      src={getDrawingSrc(item)}
                      alt="" />
                  {:else}
                    <Viewport
                      paddingTop={4}
                      paddingRight={4}
                      paddingBottom={50}
                      paddingLeft={4}
                      drawing={liteDrawing(item, {
                        isCollection: item.is_collection,
                        labelBy: lb(item),
                        typeColor: $typeColors[item.type_id],
                      })}
                      width={136}
                      height={136} />
                  {/if}
                </div>
              {/key}
            </div>
          </Link>
          {#if item.drawing && !disabled}
            <button
              use:tooltip={{ text: "Remove Drawing" }}
              class="absolute top-0 right-0 text-gray-500 hover:text-black rounded p-1 hover:bg-gray-100"
              on:click|stopPropagation={removeDrawing}>
              <CircleXIcon />
            </button>
          {/if}
        </div>
      </SidebarSection>
    {/if}
  </div>
{/if}
<SidebarSection>
  <h3 class="px-2 mb-2">Properties</h3>
  <TextInput
    label={getPropName("Mark", "mark")}
    disabled={disabled || disabledProps.mark}
    labelWidth="5.5rem"
    value={item.mark}
    on:input={(e) => updateItem("mark", e.detail.value)} />
  {#if shapeItem}
    {#if settings.hide_width_offset}
      <DimtextInput
        label={getPropName("Width", "width")}
        disabled={disabled || noShapePropUpdates || disabledProps.width}
        labelWidth="5.5rem"
        value={item.width}
        {settings}
        allowNull
        on:input={(e) => updateItem("width", e.detail.value)} />
    {:else}
      <DimtextOffsetInput
        label={getPropName("Width", "width")}
        disabled={disabled || noShapePropUpdates || disabledProps.width}
        offsetDisabled={disabled}
        labelWidth="5.5rem"
        value={item.width}
        offsetValue={item.width_offset}
        totalValue={item.cache.width_prime}
        {settings}
        allowNull
        on:input={updateItemWidth} />
    {/if}
    {#if settings.hide_height_offset}
      <DimtextInput
        label={getPropName("Height", "height")}
        disabled={disabled || noShapePropUpdates || disabledProps.height}
        labelWidth="5.5rem"
        value={item.height}
        {settings}
        allowNull
        on:input={(e) => updateItem("height", e.detail.value)} />
    {:else}
      <DimtextOffsetInput
        label={getPropName("Height", "height")}
        disabled={disabled || noShapePropUpdates || disabledProps.height}
        offsetDisabled={disabled}
        labelWidth="5.5rem"
        value={item.height}
        offsetValue={item.height_offset}
        totalValue={item.cache.height_prime}
        {settings}
        allowNull
        on:input={updateItemHeight} />
    {/if}
  {/if}
  {#if !isCollection}
    <SelectInput
      label={getPropName("Type", "type_id")}
      disabled={disabled || disabledProps.type_id || noTypeUpdates}
      labelWidth="5.5rem"
      value={item.type_id}
      options={typeOptions}
      on:input={(e) => updateItem("type_id", e.detail.value)} />
    {#if $group.project_type !== "product"}
      <SelectInput
        label={getPropName("Opening", "collection_id")}
        disabled={disabled || disabledProps.collection_id}
        labelWidth="5.5rem"
        value={item.collection_id}
        options={collectionOptions}
        on:input={(e) => updateItem("collection_id", e.detail.value)} />
    {/if}
    <TextInput
      label={getPropName("Quantity", "quantity")}
      disabled={disabled || disabledProps.quantity}
      labelWidth="5.5rem"
      value={item.quantity}
      parser={parsePosInteger}
      on:input={(e) => updateItem("quantity", e.detail.value)} />
  {/if}
  <TextInput
    label={getPropName("Description", "description")}
    disabled={disabled || disabledProps.description}
    labelWidth="5.5rem"
    value={item.description}
    on:input={(e) => updateItem("description", e.detail.value)} />
  <TextInput
    label={getPropName(displayUnit === "inches" ? "Est. Area (sf)" : "Est. Area (m²)", "area")}
    readonly
    {disabled}
    labelWidth="5.5rem"
    value={item.cache.area}
    formatter={areaFormatter} />
  {#if !isCollection}
    <TextInput
      label={getPropName("Est. Wt. (lbs)", "weight")}
      readonly
      {disabled}
      labelWidth="5.5rem"
      value={item.cache.weight}
      formatter={(w) => (typeof w === "number" ? w.toFixed(0) : "")} />
    {#if $group.project_type !== "product"}
      <BooleanInput
        label="Stamp"
        {disabled}
        labelWidth="5.5rem"
        value={!!item.data.fabrications?.bug}
        on:input={toggleBug} />
    {/if}
    <FabricationsInput
      label="Fabrications"
      {disabled}
      labelWidth="5.5rem"
      value={item.data.fabrications}
      on:input={(e) => updateItem("data.fabrications", e.detail.value)} />
  {/if}
  <FlagInput
    label="Flag"
    {disabled}
    labelWidth="5.5rem"
    value={item.approval_status}
    on:input={(e) => updateItem("approval_status", e.detail.value)} />
  {#if !isCollection && $group.project_type !== "product"}
    <ButtonInput
      label="Ref. Planes"
      {disabled}
      labelWidth="5.5rem"
      value={item.data.reference_planes?.type}
      buttons={refPlaneButtons}
      on:input={(e) => updateRefPlanes(e.detail.value)} />
  {/if}
  <ContenteditableInput
    label={getPropName("Notes", "notes")}
    disabled={disabled || disabledProps.notes}
    labelWidth="5.5rem"
    value={item.notes}
    on:input={(e) => updateItem("notes", e.detail.value)} />
</SidebarSection>

{#if customColumns.length > 0}
  <SidebarSection>
    <h3 class="px-2 mb-2">Custom Properties</h3>
    {#each customColumns as col}
      <TextInput
        label={col.name}
        {disabled}
        labelWidth="5.5rem"
        value={get(item, `custom_column_data.${col.id}`)}
        on:input={(evt) => updateCustomColData(col.id, evt.detail.value)} />
    {/each}
  </SidebarSection>
{/if}

{#if !isCollection && $group.project_type !== "product"}
  <SidebarSection>
    <h3 class="px-2 mb-2">Edge Treatment</h3>
    <div class="flex items-center pr-1">
      <SelectInput
        label="All edges"
        labelWidth="5.5rem"
        value={allTreatments}
        disabled={showIndividualEdges || disabled}
        on:mouseover={() => dispatch("mouseoverEdges", edgeIndices)}
        on:mouseout={() => dispatch("mouseoutEdges")}
        options={$edgeTreatments}
        on:input={(evt) => updateAllEdges(evt.detail.value)} />
      <button
        class="p-1 flex-none rounded hover:bg-gray-100"
        class:bg-gray-100={showIndividualEdges}
        on:click={() => (showIndividualEdges = !showIndividualEdges)}>
        <EllipsisIcon />
      </button>
    </div>
    {#if showIndividualEdges}
      {#each edgeIndices as i (i)}
        <SelectInput
          label={`Edge ${i + 1}`}
          labelWidth="5.5rem"
          value={treatment?.[i]}
          {disabled}
          on:mouseover={() => dispatch("mouseoverEdges", [i])}
          on:mouseout={() => dispatch("mouseoutEdges")}
          options={$edgeTreatments}
          on:input={(evt) => updateData(["fabrications", "edge_treatment", i], evt.detail.value)} />
      {/each}
    {/if}
  </SidebarSection>
{/if}

<style lang="scss">
  .mini-editor {
    height: 30rem;
    position: relative;
  }
</style>
