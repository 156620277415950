<script>
  import { createEventDispatcher, getContext } from "svelte";
  import ExitIcon from "src/assets/icons/exit.svg";
  import TrashIcon from "src/assets/icons/trash.svg";
  import XIcon from "src/assets/icons/x.svg";
  import DuplicateIcon from "src/assets/icons/duplicate.svg";
  import ArchiveIcon from "src/assets/icons/archive.svg";

  export let datum;

  const productUser = getContext("productUser");
  const dispatch = createEventDispatcher();
</script>

{#if !$productUser}
  <button class="dropdown-button-item" on:click={() => dispatch("click", { value: "gotorow" })}>
    <ExitIcon />
    Go To
  </button>
{/if}
<button class="dropdown-button-item" on:click={() => dispatch("click", { value: "clonerow" })}>
  <DuplicateIcon />
  Clone
</button>
<button class="dropdown-button-item" on:click={() => dispatch("click", { value: "archiverow" })}>
  <ArchiveIcon />
  Archive
</button>
{#if !datum.sent_at}
  <button class="dropdown-button-item" on:click={() => dispatch("click", { value: "deleterow" })}>
    <TrashIcon />
    Delete
  </button>
  <!-- {:else if !(datum.status === "canceled")}
  <button class="dropdown-button-item" on:click={() => dispatch("click", { value: "cancelrow" })}>
    <XIcon />
    Cancel RFQ
  </button> -->
{/if}
