<script>
  import { dist } from "vector";

  export let group;
  export let items;
  export let types;

  $: displayUnit = $group?.data.settings.display_unit;
  $: squareFootage = totalSquareFootage(items, displayUnit);
  $: edgeTreatment = totalEdgeTreatment(items, displayUnit);
  $: perimeter = totalPerimeter(items, displayUnit);

  function totalSquareFootage(items, displayUnit) {
    const area = items.reduce((total, item) => {
      if (!item.width || !item.height) return total;
      return total + (item.cache.width / 12) * (item.cache.height / 12) * item.quantity;
    }, 0);

    if (displayUnit === "millimeters") {
      return (area * 0.092903).toFixed(2);
    } else {
      return area.toFixed(2);
    }
  }

  function totalEdgeTreatment(items, displayUnit) {
    const et = items.reduce((total, item) => {
      const t = item.data?.fabrications?.edge_treatment;

      if (!t) return total;

      let itemEt = 0;
      t.forEach((treatment, i) => {
        const edge = item.cache.edges[0][i];
        if (treatment && edge) {
          itemEt += dist(edge.start, edge.end);
        }
      });

      return total + itemEt * item.quantity;
    }, 0);

    if (displayUnit === "millimeters") {
      return (et * 25.4).toFixed(0);
    } else {
      return et.toFixed(2);
    }
  }

  function totalPerimeter(items, displayUnit) {
    const per = items.reduce((p, i) => {
      if (!i.width || !i.height) return p;
      return p + i.cache.perimeter_in * i.quantity;
    }, 0);

    if (displayUnit === "millimeters") {
      return (per * 25.4).toFixed(0);
    } else {
      return per.toFixed(2);
    }
  }
</script>

<div class="grow flex gap-2 px-2 flex-wrap">
  <div class="datum-block">
    <div class="info-text">{items.reduce((q, i) => q + i.quantity, 0)}</div>
    <div class="label-text">Units</div>
  </div>

  {#if $group?.project_type !== "product"}
    <div class="datum-block">
      <div class="info-text">{types.length}</div>
      <div class="label-text">Types</div>
    </div>
  {/if}

  <div class="datum-block">
    <div class="info-text">{squareFootage}</div>
    <div class="label-text">{displayUnit === "millimeters" ? "m²" : "SqFt"}</div>
  </div>

  {#if $group?.project_type !== "product"}
    <div class="datum-block">
      <div class="info-text">{edgeTreatment}</div>
      <div class="label-text">Edge Treatment ({displayUnit === "millimeters" ? "mm" : "in"})</div>
    </div>
  {/if}

  <div class="datum-block">
    <div class="info-text">{perimeter}</div>
    <div class="label-text">Total Perimeter ({displayUnit === "millimeters" ? "mm" : "in"})</div>
  </div>
</div>

<style lang="scss">
  .datum-block {
    @apply flex-1;
  }

  .info-text {
    @apply font-bold text-base;
  }

  .label-text {
    @apply text-xs text-gray-400 truncate;
  }
</style>
