<svelte:options strictprops={false} />

<script>
  import upperCase from "lodash/upperCase";
  import ProfileIcon from "src/assets/icons/profile.svg";
  import SpinnerIcon from "src/assets/icons/spinner-lg.svg";
  import getUserInitials from "@local/extensions/formatters/get-user-initials.js";
  import formatHumanTime from "@local/extensions/formatters/format-human-time.js";

  export let datum;

  $: spinning = datum.spinning === true;

  function items(count) {
    if (!count) return "No units";
    if (count === 0) return "No units";
    if (count === 1) return "1 unit";
    return `${count} units`;
  }
</script>

<td class="relative select-none">
  <div class="relative">
    {#if spinning}
      <div class="absolute w-full h-full flex items-center pl-4">
        <div class="absolute">
          <div class="animate-spin">
            <SpinnerIcon />
          </div>
        </div>
      </div>
    {/if}
    <div class="pl-4 py-2 flex gap-2 items-center" class:opacity-20={spinning}>
      <div class="grow overflow-x-hidden">
        <div class="flex items-center justify-between gap-2">
          <div class="font-bold text-md">
            {datum.name}
          </div>
          <div class="text-xs my-0.5">
            {items(datum.items_quantity || 0)}
          </div>
        </div>
        <div class="flex items-center justify-between gap-2">
          <div class="truncate text-xs">
            {#if datum.prepared_for_org_name}
              {datum.prepared_for_org_name}
            {:else if datum.location}
              {datum.location}
            {/if}
          </div>
          <div class="text-xs italic whitespace-nowrap">
            Updated {formatHumanTime(datum.updated_at)}
          </div>
        </div>
      </div>
      <div class="flex-none">
        {#if datum.assigned_to_username}
          <div
            class="profile-circle relative"
            style="background-color: {datum.assigned_to_user_color ||
              '#000'}; border-color: {datum.assigned_to_user_color || '#000'}">
            {upperCase(
              getUserInitials({ username: datum.assigned_to_username, email: datum.assigned_to_email }),
            )}
          </div>
        {:else if !datum.assigned_to}
          <div class="text-gray-400">
            <ProfileIcon />
          </div>
        {/if}
      </div>
    </div>
  </div>
</td>
