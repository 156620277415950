<script>
  export let value;
  export let max;

  $: left = `${(value / max) * 100 - 100}%`;
</script>

<div>
  <div class="w-12 h-1 bg-gray-300 rounded-full overflow-hidden relative">
    <div class="bg-blue-500 w-12 h-1 absolute top-0" style:left />
  </div>
  <div class="text-xs">{value} of {max}</div>
</div>
