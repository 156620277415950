<script>
  import { createEventDispatcher } from "svelte";
  import ArrowLeftIcon from "../../assets/icons/arrow-left.svg";
  import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
  import SidebarSection from "./SidebarSection.svelte";

  const dispatch = createEventDispatcher();

  export let prev;
  export let next;
  export let sticky = false;
  export let title = null;

  function gotoPrev() {
    dispatch("gotoprev");
  }

  function gotoNext() {
    dispatch("gotonext");
  }
</script>

<div class:sticky-container={sticky}>
  <SidebarSection>
    <div class="flex justify-between px-2 items-center text-sm gap-2">
      {#if prev}
        <button class="flex space-x-1 btn-minimal items-center" on:click={gotoPrev}>
          <ArrowLeftIcon />
          <div>Prev</div>
        </button>
      {:else}
        <div class="flex space-x-1 text-gray-400 items-center">
          <ArrowLeftIcon />
          <div>Prev</div>
        </div>
      {/if}
      {#if title}
        <div class="flex-grow text-center font-bold truncate">{title}</div>
      {/if}
      {#if next}
        <button class="flex space-x-1 btn-minimal items-center" on:click={gotoNext}>
          <div>Next</div>
          <ArrowRightIcon />
        </button>
      {:else}
        <div class="flex space-x-1 text-gray-400 items-center">
          <div>Next</div>
          <ArrowRightIcon />
        </div>
      {/if}
    </div>
  </SidebarSection>
</div>

<style lang="scss">
  .sticky-container {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
</style>
