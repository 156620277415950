<script>
  import { copyToClipboard } from "overline";

  export let label = "Label";
  export let labelWidth = null;
  export let value;
  export let border = false;
  export let outerBorder = true;

  $: lw = labelWidth ? `width:${labelWidth};` : "";
</script>

<div
  class="prop-container"
  class:has-outer-border={outerBorder}
  class:has-inner-border={!outerBorder}
  class:visible-border={border}>
  <div class="label" style={lw}>
    <slot name="label">
      {label}
    </slot>
  </div>
  <button class="input truncate cursor-copy" on:click={() => copyToClipboard(value)}>
    {value}
  </button>
</div>

<style lang="scss">
  .prop-container {
    @apply w-full flex items-center rounded bg-white;

    &.has-outer-border {
      @apply p-2;

      &:hover:not(.readonly),
      &.visible-border {
        @apply ring-1 ring-inset ring-gray-300;
      }
    }

    .label {
      @apply truncate text-gray-400 flex-none;
    }

    .input {
      @apply min-w-0 grow shrink bg-transparent ml-2;
    }

    &.has-inner-border {
      .input {
        @apply p-1 rounded;
      }
    }

    &.has-inner-border:not(.readonly) {
      .input:hover {
        @apply ring-1 ring-inset ring-gray-300;
      }
    }
  }
</style>
