<svelte:options strictprops={false} />

<script>
  import { createEventDispatcher } from "svelte";
  import TextInput from "./TextInput.svelte";
  import LibraryInput from "./LibraryInput.svelte";
  import Viewport from "src/lib/drawing/ViewportMinimal.svelte";
  import materialDrawing from "@local/extensions/drawing/material-drawing.js";
  import { createNullFields } from "@local/lamina-core";
  import imageUrl from "src/extensions/image-url.js";
  import { api } from "src/api";

  export let product;
  export let application;
  export let disabled;
  export let options = [];
  export let org;

  const dispatch = createEventDispatcher();

  let query;

  $: update(product);

  function update(product) {
    query = product.value.classification;
  }

  async function updateQuery() {
    const options = await api.rpc("product_query_options", {
      makeup: { type: "query", value: { classification: query, application }, vendor_id: $org.id },
    });

    if (options.error) return;

    let newDefault = null;

    const current = options.data.find((o) => o.id === product.default);
    if (current) {
      newDefault = current;
    } else if (options.data.length) {
      newDefault = options.data[0];
    }

    let newProduct;

    if (newDefault) {
      newProduct = {
        ...newDefault,
        application,
        type: "query",
        value: {
          ...product.value,
          classification: query,
        },
        default: newDefault.id,
      };
    } else {
      newProduct = {
        ...createNullFields(),
        application,
        data: application === "surface" ? null : { thickness: null },
        type: "query",
        value: {
          ...product.value,
          classification: query,
        },
        default: null,
      };
    }

    dispatch("update-product", newProduct);
  }

  function chooseDefault(record) {
    const newProduct = {
      ...product,
      ...record,
      default: record.id,
    };

    dispatch("update-product", newProduct);
  }
</script>

<div class="pl-4">
  <TextInput label="Query" labelWidth="4rem" {disabled} bind:value={query} on:input={updateQuery} />
  <LibraryInput
    label="Default"
    disabled={disabled || !options.length}
    labelWidth="4rem"
    value={product}
    nullText="None"
    library={[{ records: options }]}
    title="Materials"
    on:input={(e) => chooseDefault(e.detail.value)}>
    <svelte:fragment let:record slot="thumbnail">
      {#if application === "surface"}
        <div class="w-full h-full" style="background-color: {record?.color || '#FFFFFF'}">
          {#if record.image}
            <img src={imageUrl(record.image)} alt={record.name} class="text-xs w-full h-full object-cover" />
          {/if}
        </div>
      {:else}
        <Viewport padding={4} drawing={materialDrawing(record)} />
      {/if}
    </svelte:fragment>
    <div slot="caption" let:record>{record?.name}</div>
  </LibraryInput>
</div>
