<script>
  import SidebarSection from "./SidebarSection.svelte";
  import formatDateTime from "@local/extensions/formatters/format-date-time.js";

  const approvalStates = {
    approved: "Approved",
    approved_as_noted: "Approved as noted",
    revise_and_resubmit: "Revise",
    delete_requested: "Delete",
  }

  export let updatedBy = null;
  export let updatedOn = null;
  export let approvedBy = null;
  export let approvedOn = null;
  export let approvalStatus = null;
</script>

{#if updatedBy && updatedOn}
  <SidebarSection>
    <div class="text-xs text-gray-400 px-2 space-y-2">
      <p>
        Last updated by {updatedBy.username} on {formatDateTime(updatedOn)}.
      </p>
      {#if approvedBy && approvedOn}
        <p>
          Marked "{approvalStates[approvalStatus]}" by {approvedBy.username} on {formatDateTime(
            approvedOn
          )}.
        </p>
      {/if}
    </div>
  </SidebarSection>
{/if}
