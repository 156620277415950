<script>
  import { createEventDispatcher } from "svelte";

  export let action = "Action";

  const dispatch = createEventDispatcher();
</script>

<div class="flex justify-start px-2">
  <button class="btn" on:click={() => dispatch("click")}>{action}</button>
</div>
