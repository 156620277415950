import { orientation, angleBetween, subtract } from "vector";
import { almostEqual } from "overline";

export function isRightAngle(prev, pt, next) {
  const o = orientation(prev, pt, next);

  if (almostEqual(o, 0)) return false;

  const a = subtract(next, pt);
  const b = subtract(prev, pt);

  const w = angleBetween(a, b);

  return almostEqual(w, Math.PI / 2);
}

export function isPlumb(prev, pt, next) {
  const o = orientation(prev, pt, next);

  if (almostEqual(o, 0)) return false;

  return (
    (almostEqual(next.y, pt.y) && almostEqual(prev.x, pt.x)) ||
    (almostEqual(next.x, pt.x) && almostEqual(prev.y, pt.y))
  );
}
