<script>
  import { createEventDispatcher } from "svelte";

  export let value;
  export let rows = 5;
  export let disabled = false;
  export let placeholder = "Placeholder";
  export let fullwidth = false;
  let timer;

  const dispatch = createEventDispatcher();

  let localValue = value;

  $: refresh(value);

  function refresh(value) {
    localValue = value;
  }

  function update() {
    if (localValue !== value) {
      dispatch("input", { value: localValue });
    }
  }

  function debounce() {
    clearTimeout(timer);
    timer = setTimeout(() => {
      update();
    }, 1000);
  }
</script>

<textarea
  class="border border-gray-300 rounded text-sm p-2"
  class:w-72={!fullwidth}
  class:w-full={fullwidth}
  class:text-gray-500={disabled}
  {placeholder}
  {disabled}
  bind:value={localValue}
  on:blur={update}
  on:input={() => debounce()}
  {rows} />
