<script>
  import CircleXIcon from "src/assets/icons/circle-x.svg";
  import SearchIcon from "src/assets/icons/search.svg";
  import { rfqs, rfqFilter, selectedRfqIds } from "src/stores/rfqs";
  import { quotes, quoteFilter, selectedQuoteIds } from "src/stores/quotes";
  import { jobs, jobFilter, selectedJobIds } from "src/stores/jobs";
  import { filtered } from "@local/extensions/collections/sortable-list.js";

  export let filter;
  export let org;

  let focused;

  function handleFilter(e) {
    if (org?.org_type === "supplier") {
      const f = filtered($quotes, e.target.value, ["job.name", "job.location"]);
      const q = $selectedQuoteIds.filter((id) => f[id]);

      if (f.order.length === 0) {
        $selectedQuoteIds = [];
      } else if (q.length === 0) {
        $selectedQuoteIds = [f.order[0]];
      } else if (q.length !== $selectedQuoteIds.length) {
        $selectedQuoteIds = q;
      }

      $quoteFilter = e.target.value;

      const fj = filtered($jobs, e.target.value, ["name", "location"]);
      const j = $selectedJobIds.filter((id) => fj[id]);

      if (fj.order.length === 0) {
        $selectedJobIds = [];
      } else if (j.length === 0) {
        $selectedJobIds = [fj.order[0]];
      } else if (j.length !== $selectedJobIds.length) {
        $selectedJobIds = j;
      }

      $jobFilter = e.target.value;
    } else {
      const f = filtered($rfqs, e.target.value, ["job.name", "job.location"]);
      const r = $selectedRfqIds.filter((id) => f[id]);

      if (f.order.length === 0) {
        $selectedRfqIds = [];
      } else if (r.length === 0) {
        $selectedRfqIds = [f.order[0]];
      } else if (r.length !== $selectedRfqIds.length) {
        $selectedRfqIds = r;
      }

      $rfqFilter = e.target.value;
    }
  }

  function removeFilter() {
    filter = "";
    if (org?.org_type === "supplier") {
      $quoteFilter = "";
    } else {
      $rfqFilter = "";
    }
  }

  function handleFocus() {
    focused = true;
  }

  function handleBlur() {
    focused = false;
  }
</script>

<div class="relative text-sm">
  <div class="bg-white rounded p-1 flex items-center gap-2 border border-gray-300" class:focused>
    <div class="text-gray-400"><SearchIcon /></div>
    <input
      class="bg-transparent"
      on:focus={handleFocus}
      on:blur={handleBlur}
      bind:value={filter}
      on:input={handleFilter} />
  </div>
  {#if !!filter}
    <button class="absolute right-1 top-1.5 text-gray-400" on:click={removeFilter}>
      <CircleXIcon />
    </button>
  {/if}
</div>

<style lang="scss">
  input {
    border: none;
    outline: none;
    min-width: 0;
    max-width: 100%;
  }

  .focused {
    @apply ring-2 ring-inset ring-blue-500;
  }
</style>
