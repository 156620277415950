function removedIndices(arr1, arr2) {
	const indices = [];
	const arr2Obj = arr2.reduce((acc, item) => {
		acc[item] = true;
		return acc;
	}, {});
	arr1.forEach((item, index) => {
		if (!arr2Obj[item]) {
			indices.push(index);
		}
	});
	return indices;
}

export default removedIndices;
