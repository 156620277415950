<script>
  import { navigate } from "svelte-routing";

  import Navbar from "src/lib/Navbar.svelte";
  import NavbarUnauthenticated from "src/lib/NavbarUnauthenticated.svelte";
  import { Modal } from "svelte-utilities";
  import TextInput from "src/lib/sidebar/TextInput.svelte";
  import ContenteditableInput from "src/lib/sidebar/ContenteditableInput.svelte";

  import { api, addGuest } from "src/api";
  import { SUPABASE_URL as supabaseUrl } from "src/env";
  import isEmail from "@local/extensions/validators/is-email.js";
  import { profile } from "src/stores/auth.js";

  export let slug = null;

  let portal;
  let joinOrgErrorModal;
  let joinOrgModal;
  let guest = newGuest();
  let error = null;
  let submitted = false;

  function newGuest() {
    return {
      user_email: "",
      username: "",
      name: "",
    };
  }

  function bannerSrc(banner) {
    if (!banner) return null;
    if (banner.base64) return banner.base64;
    if (banner.object_id) {
      return `${supabaseUrl}/storage/v1/object/public/images/${banner.object_id}`;
    }
  }

  async function init() {
    if (!slug) {
      navigate("/");
      return;
    }

    const { data, error } = await api
      .from("public_organizations")
      .select("*")
      .eq("slug", slug.toLowerCase())
      .maybeSingle();

    if (error || !data) {
      if ($profile) {
        navigate("/not-found");
      } else {
        navigate(`/login/${slug}`);
      }
      return;
    }

    portal = data;
  }

  async function joinOrg() {
    // Is user logged in?
    if ($profile) {
      if ($profile.organization_id !== portal.id) {
        if ($profile.organization?.org_type === "recipient") {
          const { data } = await api
            .from("business_relations")
            .select("*")
            .eq("seller_id", portal.id)
            .eq("buyer_id", $profile.organization_id);

          // Already a guest of this organization
          if (data.length > 0) {
            navigate("/");
          } else {
            const { error: err } = await api
              .from("business_relations")
              .insert({ seller_id: portal.id, buyer_id: $profile.organization_id });

            if (!err) {
              navigate("/");
            } else {
              error = err.message;
              joinOrgErrorModal.open();
            }
          }
        } else {
          error = "not-recipient";
          joinOrgErrorModal.open();
        }
      } else {
        error = "member-of-org";
        joinOrgErrorModal.open();
      }
    } else {
      error = null;
      guest = newGuest();
      joinOrgModal.open();
    }
  }

  async function submitJoinRequest() {
    const result = await addGuest({
      ...guest,
      seller_id: portal.id,
    });

    if (result.error) {
      error = result.error.message;
    } else {
      submitted = true;
    }
  }

  function done() {
    joinOrgModal.close();
  }
</script>

{#if $profile}
  <Navbar />
{:else}
  <NavbarUnauthenticated />
{/if}
<div class="w-full h-full bg-gray-100 flex flex-col md:flex-row overflow-auto md:overflow-hidden">
  {#await init() then _}
    {#if portal.data?.banner}
      <div class="md:hidden w-full max-h-96">
        <img
          class="w-full h-full object-cover"
          src={bannerSrc(portal.data?.banner)}
          alt={portal.data?.banner?.filename} />
      </div>
    {/if}

    <div class="md:flex-none md:w-96 flex flex-col items-start justify-between">
      <div class="flex flex-col items-start">
        {#if portal.data?.logo}
          <div class="px-4 pt-8 w-full">
            <div class="w-full">
              <img
                class="block logo-image object-contain"
                src={portal.data.logo.base64}
                alt="Logo Thumbnail" />
            </div>
          </div>
        {/if}
        <h2 class="p-6 z-10">
          {portal?.name}
        </h2>

        <div class="text-left px-6 text-sm mb-8">
          {#if portal.address}
            <div class="whitespace-pre-line">
              {portal.address}
            </div>
          {/if}
          {#if portal.primary_contact_email}
            <div class="text-blue-500 underline">
              <a href="mailto:{portal.primary_contact_email}">
                {portal.primary_contact_email}
              </a>
            </div>
          {/if}
        </div>

        {#if portal.accepts_public_membership_requests}
          <div class="p-6 w-full">
            <div class="text-xs py-1">
              Get pricing, lead times, and check for errors<br /> with Shape Exchange.
            </div>
            <button class="btn btn-lg btn-primary-alt-2 w-full" on:click={joinOrg}> Get Started </button>
            <div class="text-xs italic text-gray-500 py-1">
              Upload glass sizes from Excel and PDF drawing sets,<br />
              or create shapes right here.
            </div>
          </div>
        {/if}

        {#if portal.description}
          <div class="py-8 px-6 whitespace-pre-line text-sm">{portal.description}</div>
        {/if}
      </div>
      {#if !profile}
        <div class="hidden md:block text-sm p-6">
          Existing Customer? <a href="/" class="text-blue-500 font-bold">Log in</a>
        </div>
      {/if}
    </div>

    {#if portal.data?.banner}
      <div class="hidden md:block grow overflow-hidden">
        <img
          class="w-full h-full object-cover"
          src={bannerSrc(portal.data?.banner)}
          alt={portal.data?.banner?.filename} />
      </div>
    {/if}
  {/await}
</div>

<Modal bind:this={joinOrgErrorModal} closeable buttons={[{ label: "OK", type: "confirm" }]}>
  <div slot="title">Submit a Request</div>
  <div slot="content">
    {#if error === "member-of-org"}
      You are a member of this organization.
    {:else if error === "not-recipient"}
      You belong to a supplier organization; you must belong to a recipient organization in order to submit a
      request.
    {:else if error}
      {error}
    {/if}
  </div>
</Modal>

<Modal bind:this={joinOrgModal} closeable>
  <div slot="title">Create a Customer Profile</div>
  <div slot="content" class="space-y-4">
    {#if error}
      <p class="text-red-500">{error}</p>
    {/if}
    {#if !submitted}
      <p>Customer Profile:</p>
      <TextInput labelWidth="6rem" label="Company" border inputOnKeydown bind:value={guest.name} />
      <TextInput labelWidth="6rem" label="Your Name" border inputOnKeydown bind:value={guest.username} />
      <TextInput labelWidth="6rem" label="Your Email" border inputOnKeydown bind:value={guest.user_email} />
      <button
        class="btn btn-lg w-full btn-primary"
        disabled={!isEmail(guest.user_email) || !guest.name}
        on:click={submitJoinRequest}>Create Account</button>
      <p class="text-center">
        Already have an account?
        <a href="/" class="text-blue-500 underline">Log in</a>
      </p>
    {:else}
      <div>Please check your email for a sign-in link.</div>
      <button class="btn btn-lg w-full btn-primary" on:click={done}>OK</button>
    {/if}
  </div>
</Modal>

<style lang="scss">
  .logo-image {
    max-width: 100%;
    max-height: 3rem;
  }
</style>
