<script>
  import { onMount, tick, getContext } from "svelte";
  import { nanoid } from "nanoid";
  import { navigate } from "svelte-routing";
  import uniqBy from "lodash/uniqBy";
  import debounce from "lodash/debounce";

  import ShapeIcon from "src/assets/icons/shape-lg.svg";
  import TypeIcon from "src/assets/icons/type-lg.svg";

  import { Modal } from "svelte-utilities";
  import TextInput from "src/lib/sidebar/TextInput.svelte";
  import SelectInput from "src/lib/sidebar/SelectInput.svelte";
  import SearchSelect from "src/lib/sidebar/SearchSelect.svelte";

  import eb from "src/extensions/event-bus.js";
  import sanitizePgString from "src/extensions/sanitize-postgrest-string.js";

  import { storeJob, jobs } from "src/stores/jobs.js";
  import { profile } from "src/stores/auth.js";
  import { selectedRfqIds } from "src/stores/rfqs.js";
  import { selectedQuoteIds } from "src/stores/quotes.js";
  import { selectedJobIds } from "src/stores/jobs.js";
  import { rfqs } from "src/stores/rfqs.js";

  import { api } from "src/api";

  export let org;
  export let isSupplier;
  export const open = () => openModal();

  const productLists = getContext("productLists");
  const supplier = getContext("supplier");

  let modal;
  let name;
  let request_for = "quote";
  let jobnameinput;

  let searchString = "";
  let selectedCustomer = null;
  let customers = [];

  $: allowCustomJobs = $profile?.user_role !== "product_user" && org?.org_type !== "product_rep";
  $: productOptions = $productLists.filter((pl) => !pl.parent_id);
  $: allowProductJobs = $supplier?.item_products?.length > 0 && productOptions?.length > 0;
  $: customerResults = customers.map((customer) => ({
    id: customer.id,
    name: customer.name,
    cta: customer.primary_contact_email,
  }));

  let action = allowCustomJobs ? "custom" : "product";

  async function fetchCustomers() {
    const s = sanitizePgString(searchString);

    const c = await api
      .from("business_relation_list_view")
      .select("*,buyer:buyer_id(*,profiles(*))")
      .eq("seller_id", org.id)
      .or(`recipient_name.ilike.%${s}%,recipient_email.ilike.%${s}%`)
      .range(0, 8);

    if (!c.error) {
      customers = uniqBy(c.data, "buyer_id").map((c) => c.buyer);
    }
  }

  async function openModal() {
    modal.open();

    await tick();
    if (jobnameinput) jobnameinput.focus();

    if (isSupplier) {
      fetchCustomers();
    }
  }

  async function createNewJob(project_type = "unconstrained") {
    let product_list_id = null;
    if (project_type === "product") {
      product_list_id = productOptions?.[0]?.id;
    }

    const job = await jobs.add(
      {
        id: crypto.randomUUID(),
        name: name || "Untitled",
        organization_id: org.id,
        assigned_to_id: $profile?.user_role === "developer" ? null : $profile?.id,
        prepared_for_org_id: selectedCustomer ? selectedCustomer.id : null,
        product_list_id,
      },
      project_type,
    );

    return job;
  }

  async function createNewRfq(project_type = "unconstrained") {
    let product_list_id = null;
    if (project_type === "product") {
      product_list_id = productOptions?.[0]?.id;
    }

    const job = await storeJob(
      {
        id: crypto.randomUUID(),
        name: name || "Untitled",
        organization_id: org.id,
        product_list_id,
      },
      project_type,
    );

    const rfq = await rfqs.add(
      {
        job_id: job.id,
        organization_id: org.id,
        link_nanoid: nanoid(12),
        request_for,
        assigned_to_id: $profile?.user_role === "developer" ? null : $profile.id,
      },
      0,
    );

    return rfq;
  }

  async function startCustomJob() {
    modal.close();
    if (isSupplier) {
      const job = await createNewJob();
      $selectedJobIds = [job.id];
      $selectedQuoteIds = [];
      navigate(`/jobs/${job.id}/current`);
    } else {
      const rfq = await createNewRfq();
      $selectedRfqIds = [rfq.id];
      navigate(`/jobs/${rfq.job_id}/current`);
    }
  }

  async function quoteStandardProducts() {
    modal.close();
    if (isSupplier) {
      const job = await createNewJob("product");
      $selectedJobIds = [job.id];
      $selectedQuoteIds = [];
    } else {
      const rfq = await createNewRfq("product");
      $selectedRfqIds = [rfq.id];
    }
  }

  function search() {
    debounce(fetchCustomers, 500)();
  }

  function select(e) {
    selectedCustomer = e.detail.result;
  }

  function deselect() {
    selectedCustomer = null;
    searchString = "";
    fetchCustomers();
  }

  function confirm() {
    if (!allowCustomJobs) {
      quoteStandardProducts();
    } else if (!allowProductJobs) {
      startCustomJob();
    } else {
      if (action === "custom") {
        startCustomJob();
      } else {
        quoteStandardProducts();
      }
    }
  }

  onMount(() => {
    eb.on("create-rfq", openModal);

    return () => {
      eb.unsubscribe("create-rfq", openModal);
    };
  });
</script>

<Modal
  bind:this={modal}
  closeable
  on:confirm={confirm}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Create", type: "confirm", style: "primary" },
  ]}>
  <div slot="title">Create Draft</div>
  <div slot="content" class="space-y-4">
    <div class="flex flex-col items-center justify-center gap-6">
      <div class="text-input-container space-y-4">
        <TextInput
          bind:this={jobnameinput}
          labelWidth="6rem"
          label="Name"
          bind:value={name}
          on:enter={confirm}
          placeholder="Untitled"
          border />
        {#if isSupplier && $profile?.user_role !== "product_user"}
          <SearchSelect
            placeholder="Customer"
            border
            bind:value={searchString}
            selection={selectedCustomer}
            results={customerResults}
            on:select={select}
            on:deselect={deselect}
            on:search={search} />
        {:else if allowCustomJobs}
          <SelectInput
            labelWidth="6rem"
            label="Exchange for"
            bind:value={request_for}
            border
            options={[
              { label: "Quote", value: "quote" },
              { label: "Order", value: "order" },
              { label: "Other (see notes)", value: "other" },
            ]} />
        {/if}
        {#if allowCustomJobs && allowProductJobs}
          <SelectInput
            labelWidth="6rem"
            label="Job Type"
            bind:value={action}
            border
            options={[
              { label: "Custom", value: "custom" },
              { label: "Product", value: "product" },
            ]} />
        {/if}
      </div>
    </div>
  </div>
</Modal>

<style lang="scss">
  .text-input-container {
    width: 21.5rem;
  }
  .create-button {
    @apply flex flex-col items-center justify-center gap-2 h-40 flex-1;
    @apply border border-gray-300 rounded-md shadow-lg;

    &:hover {
      @apply bg-gray-200 border-gray-400;
    }
  }
</style>
