import cloneDeep from "lodash/cloneDeep";

export function exportJson(group, job) {
  const { current_group, groups, organization, ...jobData } = job;

  const obj = cloneDeep(group);
  delete obj.organization;
  delete obj.updater;
  delete obj.approver;
  delete obj.job;

  obj.comments.forEach((c) => {
    delete c.commenter;
  });

  obj.items.order.forEach((id) => {
    const item = obj.items[id];
    delete item.approver;
    delete item.updater;
  });

  obj.types.order.forEach((id) => {
    const type = obj.types[id];
    delete type.approver;
    delete type.updater;
  });

  jobData.current_group = obj;
  jobData.current_group_id = group.id;

  return JSON.stringify(jobData);
}
