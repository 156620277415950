<script>
  import { setContext } from "svelte";
  import { Router, Route, Link } from "svelte-routing";

  import ProfileIcon from "src/assets/icons/profile.svg";
  import OrgIcon from "src/assets/icons/group.svg";
  import AddressCardIcon from "src/assets/icons/address-card.svg";
  import PeopleIcon from "src/assets/icons/people.svg";
  import MailIcon from "src/assets/icons/mail.svg";
  import GearIcon from "src/assets/icons/gear.svg";
  import InvitationsIcon from "src/assets/icons/invitations.svg";

  import Navbar from "src/lib/Navbar.svelte";
  import ProfileSettings from "src/lib/settings/ProfileSettings.svelte";
  import OrgSettings from "src/lib/settings/OrgSettings.svelte";
  import OrgUsers from "src/lib/settings/OrgUsers.svelte";
  import UserSettings from "src/lib/settings/UserSettings.svelte";
  import AddressBook from "src/lib/settings/AddressBook.svelte";
  import Customers from "src/lib/settings/Customers.svelte";
  import Reps from "src/lib/settings/Reps.svelte";
  import Customer from "src/lib/settings/CustomerSettings.svelte";
  import EmailPreferences from "src/lib/settings/EmailPreferences.svelte";
  import Invitations from "src/lib/settings/Invitations.svelte";
  import DeveloperSettings from "src/lib/settings/DeveloperSettings.svelte";

  import { orgs } from "src/stores/organizations.js";
  import { profile } from "src/stores/auth.js";
  import { createOrg } from "src/stores/org.js";

  export let path = undefined;

  const org = createOrg();
  setContext("org", org);

  $: orgid = $profile?.organization_id;
  $: $orgs[orgid] && org.init($orgs[orgid]);
</script>

<Navbar />
{#if $org}
  <div class="grow overflow-auto bg-gray-100 p-4">
    <h2 class="p-4">Settings</h2>
    <div class="flex flex-col sm:flex-row gap-2 text-sm">
      <div class="p-4 sm:w-64 nav-list">
        <Link to="/settings/profile">
          <button class:selected={path === "profile" || !path}>
            <ProfileIcon />
            <div class="hidden sm:block">Profile</div>
          </button>
        </Link>
        <Link to="/settings/org">
          <button class:selected={path === "org"}>
            <OrgIcon />
            <div class="hidden sm:block">{$org.name}</div>
          </button>
        </Link>
        <Link to="/settings/users">
          <button class:selected={path === "users"}>
            <PeopleIcon />
            <div class="hidden sm:block">Team</div>
          </button>
        </Link>
        {#if $org?.org_type === "recipient"}
          <Link to="/settings/contacts">
            <button class:selected={path === "contacts"}>
              <AddressCardIcon />
              <div class="hidden sm:block">Contacts</div>
            </button>
          </Link>
        {:else if $org?.org_type === "supplier"}
          <Link to="/settings/reps">
            <button class:selected={path === "reps"}>
              <PeopleIcon />
              <div class="hidden sm:block">Reps</div>
            </button>
          </Link>
          <Link to="/settings/customers">
            <button class:selected={path === "customers"}>
              <AddressCardIcon />
              <div class="hidden sm:block">Customers</div>
            </button>
          </Link>
        {/if}
        <Link to="/settings/email-preferences">
          <button class:selected={path === "email-preferences"}>
            <MailIcon />
            <div class="hidden sm:block">Email Preferences</div>
          </button>
        </Link>
        {#if $org?.org_type === "recipient"}
          <Link to="/settings/invitations">
            <button class:selected={path === "invitations"}>
              <InvitationsIcon />
              <div class="hidden sm:block">Invitations</div>
            </button>
          </Link>
        {/if}
        {#if $profile?.user_role === "developer"}
          <Link to="/settings/developer">
            <button class:selected={path === "developer"}>
              <GearIcon />
              <div class="hidden sm:block">Developer Settings</div>
            </button>
          </Link>
        {/if}
      </div>

      <div class="grow py-4 sm:px-4 overflow-hidden">
        <div class="max-w-4xl mx-auto">
          <Router>
            <Route path="org" component={OrgSettings} {org} manageable />
            <Route path="users" component={OrgUsers} />
            <Route path="users/:profileid" component={UserSettings} />
            <Route path="contacts" component={AddressBook} />
            <Route path="customers" component={Customers} />
            <Route path="reps" component={Reps} />
            <Route path="customers/:customerid" component={Customer} />
            <Route path="email-preferences" component={EmailPreferences} />
            <Route path="invitations" component={Invitations} />
            <Route path="developer" component={DeveloperSettings} />
            <Route path="/*" component={ProfileSettings} profile={$profile} isUser />
          </Router>
        </div>
      </div>
    </div>
  </div>
{/if}

<style lang="scss">
  .nav-list {
    @apply flex gap-2 items-stretch;
    flex-direction: row;

    @media (min-width: 640px) {
      flex-direction: column;
    }

    button {
      @apply flex gap-2 items-center p-2 rounded w-full;

      &.selected,
      &:hover {
        @apply bg-gray-200;
      }
    }
  }
</style>
