export function isValidFabrication(f) {
  return f.path || f.voids || ["function", "generic"].includes(f.type);
}

export function isEdgeFabrication(f) {
  return isValidFabrication(f) && f.position !== "corner";
}

export function isCornerFabrication(f) {
  return isValidFabrication(f) && f.position === "corner";
}
