<script>
  import { createEventDispatcher, getContext } from "svelte";
  import { navigate } from "svelte-routing";
  import cloneDeep from "lodash/cloneDeep";
  import isEmpty from "lodash/isEmpty";

  import { Modal } from "svelte-utilities";
  import { bucketArray } from "overline";

  import { selectedTypes as selectedTypesObj, showRightPanel, pathRoot } from "src/stores/ui.js";

  import Sidebar from "src/lib/sidebar/Sidebar.svelte";
  import JobProperties from "src/lib/sidebar/JobProperties.svelte";
  import Attachments from "src/lib/sidebar/Attachments.svelte";
  import PrevNext from "src/lib/sidebar/PrevNext.svelte";
  import TypeProperties from "src/lib/sidebar/TypeProperties.svelte";
  import LayerProperties from "src/lib/sidebar/LayerProperties.svelte";
  import LocationThumbnailViewer from "src/lib/sidebar/LocationThumbnailViewer.svelte";
  import SortableThumbnailList from "src/lib/SortableThumbnailList.svelte";
  import SidebarTitle from "src/lib/sidebar/SidebarTitle.svelte";
  import History from "src/lib/sidebar/History.svelte";
  import ListThumbnail from "src/lib/ListThumbnail.svelte";
  import FilterInput from "src/lib/products/FilterInput.svelte";
  import SelectInput from "src/lib/sidebar/SelectInput.svelte";
  import LibrarySection from "src/lib/sidebar/LibrarySection.svelte";
  import MakeupThumbnail from "src/lib/MakeupThumbnail.svelte";
  import TypeThumbnail from "src/lib/TypeThumbnail.svelte";

  import { createType as defaultType } from "@local/lamina-core";
  import { incrementMarkCopy } from "@local/extensions/identifiers/mark.js";
  import makeMultiType from "src/extensions/multi-type.js";
  import { genericLibrary, recordFilter } from "src/extensions/makeup-library.js";

  export let group;
  export let disabled = false;
  export let types;
  export let items;

  const dispatch = createEventDispatcher();
  const makeupLibrary = getContext("makeupLibrary");
  const suppliers = getContext("suppliers");
  const supplier = getContext("supplier");

  let confirmRemoveModal;
  let cannotRemoveModal;
  let makeupLibraryModal;
  let typesToDelete = [];
  let undeletableTypes = [];
  let filter = "";

  let width;
  let height;

  $: attachments = $group.attachments.filter((a) => !a.type_id && !a.item_id);
  $: typeAttachments = bucketArray($group.attachments, "type_id");
  $: typeComments = bucketArray($group.comments, "type_id");
  $: selectedTypes = types.filter((t) => $selectedTypesObj[t.id]);
  $: selectedType = findSelectedType(selectedTypes, typeAttachments, typeComments);
  $: selectedTypeIndex = types.indexOf(selectedTypes[0]);
  $: multiType = makeMultiType($group, selectedTypes);
  $: prevType = selectedTypes.length === 1 && types[selectedTypeIndex - 1];
  $: nextType = selectedTypes.length === 1 && types[selectedTypeIndex + 1];
  $: assignments = getTypeAssignments(types, items);
  $: typeSettings = {
    display_unit: $group.data.settings.display_unit,
    mm_precision: $group.data.settings.mm_precision,
    decimal_precision: 3,
    fractional_precision: 5,
    dimension_format: $group.data.settings.dimension_format,
  };
  $: generics = genericLibrary(filter);
  $: makeups = $makeupLibrary.map((m) => m.records).flat();
  $: filteredProducts = $makeupLibrary?.map((section) => ({
    ...section,
    records: section.records.filter(recordFilter(filter)),
  }));

  function getTypeAssignments(types, items) {
    const a = types.reduce((res, type) => {
      res[type.id] = {};
      return res;
    }, {});

    items.forEach((i) => {
      if (a[i.type_id]) a[i.type_id][i.id] = true;
    });

    return a;
  }

  function findSelectedType(s, typeAttachments, typeComments) {
    if (s.length !== 1) return null;

    const t = s[0];

    return {
      ...t,
      attachments: typeAttachments[t.id] || [],
      comments: typeComments[t.id] || [],
    };
  }

  function openMakeupModal() {
    makeupLibraryModal.open();
  }

  function addType(makeup) {
    const type = {
      ...defaultType($group.id, types),
      name: makeup.name,
      data: makeup.data,
      category: makeup.category,
      image: makeup.image,
      product_id: makeup.id,
    };

    group.addType(type);
    makeupLibraryModal.close();
  }

  async function cloneTypes(e) {
    const { selected } = e.detail;
    const typesToClone = selected.map((id) => $group.types[id]);
    const marks = types.map((t) => t.mark);

    const cloned = typesToClone.map((t) => {
      const { created_at, updated_at, ...type } = t;

      return {
        ...type,
        id: crypto.randomUUID(),
        created_at: new Date(),
        updated_at: new Date(),
        mark: incrementMarkCopy(t.mark, marks),
      };
    });

    await group.addType(...cloned);

    selectedTypesObj.selectOnly(...cloned.map((t) => t.id));
  }

  function updateType(evt) {
    const { id, prop, value, diff } = evt.detail;
    group.updateType(id, prop, value, diff);
  }

  function removeTypes() {
    $selectedTypesObj = {};
    const toDelete = [...typesToDelete];
    typesToDelete = [];
    group.removeType(...toDelete);
  }

  function confirmRemove(e) {
    const { selected } = e.detail;
    typesToDelete = selected.filter((id) => $group.types[id]);
    const ts = typesToDelete.map((id) => $group.types[id]);

    const usedTypes = items.reduce((u, i) => {
      u[i.type_id] = true;
      return u;
    }, {});

    undeletableTypes = ts.filter((t) => !!usedTypes[t.id]);

    if (undeletableTypes.length > 0) {
      cannotRemoveModal.open();
    } else {
      confirmRemoveModal.open();
    }
  }

  function selectType(e) {
    const { event, id } = e.detail;
    if (event.shiftKey && !isEmpty($selectedTypesObj)) {
      const allIds = types.map((t) => t.id);
      const indices = types
        .map((t, i) => i)
        .filter((i) => $selectedTypesObj[types[i].id] || types[i].id === id);

      const min = Math.min(...indices);
      const max = Math.max(...indices);

      const ids = allIds.slice(min, max + 1);
      selectedTypesObj.selectOnly(...ids);
    } else if (event.metaKey) {
      if (!$selectedTypesObj[id]) {
        selectedTypesObj.select(id);
      } else {
        selectedTypesObj.deselect(id);
      }
    } else {
      selectedTypesObj.selectOnly(id);
    }
  }

  function selectNone() {
    $selectedTypesObj = {};
  }

  function updateMultiType(evt) {
    const { prop, value } = evt.detail;
    const ids = selectedTypes.map((t) => t.id);
    group.updateMultipleTypes(ids, prop, value);
  }

  function updateTypeStatus(evt) {
    const val = evt.detail;
    const ids = selectedTypes.map((t) => t.id);
    group.updateMultipleTypes(ids, "approval_status", val);
  }

  function reorder(e) {
    const { insertion, dragging } = e.detail;
    const order = cloneDeep($group.types.order);
    const moved = order.splice(dragging, 1)[0];
    order.splice(insertion, 0, moved);
    group.updateTypeOrder(order);
  }

  function gotoPrev() {
    if (prevType) selectedTypesObj.selectOnly(prevType.id);
  }

  function gotoNext() {
    if (nextType) selectedTypesObj.selectOnly(nextType.id);
  }

  function updateSupplier(e) {
    dispatch("updateSupplier", e.detail);
  }

  function nav(e) {
    const { id } = e.detail;
    const newpath = `${$pathRoot}types/${id}`;
    navigate(newpath);
  }
</script>

<div class="bg-gray-100 h-full flex" bind:offsetWidth={width} bind:offsetHeight={height}>
  <SortableThumbnailList
    list={types}
    {disabled}
    selected={$selectedTypesObj}
    let:record
    on:add={openMakeupModal}
    on:select={selectType}
    on:remove-selected={confirmRemove}
    on:select-none={selectNone}
    on:clone-selected={cloneTypes}
    on:nav={nav}
    on:reorder={reorder}>
    <ListThumbnail {record} assignments={assignments[record.id]} {group}>
      <TypeThumbnail type={record} {group} />
    </ListThumbnail>
  </SortableThumbnailList>

  {#if $showRightPanel && width >= 640}
    <Sidebar>
      <svelte:fragment slot="header">
        {#if selectedTypes.length === 1 && selectedType}
          <PrevNext
            prev={prevType}
            next={nextType}
            on:gotoprev={gotoPrev}
            on:gotonext={gotoNext}
            sticky
            title={selectedType.mark} />
        {/if}
      </svelte:fragment>
      <svelte:fragment slot="content">
        {#if selectedTypes.length === 0}
          <SidebarTitle title="Job Properties" />
          <JobProperties {disabled} {group} />
          <Attachments {attachments} {group} {disabled} />
          <History updatedBy={$group.updater} updatedOn={$group.updated_at} />
        {:else if selectedTypes.length === 1}
          {#key selectedType.id}
            <SidebarTitle title={`${selectedType.mark}${selectedType.name && ` - ${selectedType.name}`}`} />
            <TypeProperties
              {disabled}
              type={selectedType}
              settings={typeSettings}
              on:updateType={updateType}
              on:updateSupplier />
            {#if selectedType?.data?.layers}
              <LayerProperties type={selectedType} {disabled} {group} />
            {/if}
            <LocationThumbnailViewer
              recordid={selectedType.id}
              {group}
              record={selectedType}
              {disabled}
              documents={$group.data.documents} />
            <Attachments attachments={selectedType.attachments} {group} {disabled} typeid={selectedType.id} />
            <History
              updatedBy={selectedType.updater}
              updatedOn={selectedType.updated_at}
              approvedBy={selectedType.approver}
              approvalStatus={selectedType.approval_status}
              approvedOn={selectedType.approval_status_updated_at} />
          {/key}
        {:else}
          <SidebarTitle title="Multiple Types Selected" />
          <TypeProperties
            {disabled}
            type={multiType}
            settings={typeSettings}
            on:updateType={updateMultiType}
            on:updateSupplier />
        {/if}
      </svelte:fragment>
    </Sidebar>
  {/if}
</div>

<Modal
  bind:this={confirmRemoveModal}
  on:confirm={removeTypes}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Delete", type: "confirm", style: "danger" },
  ]}
  closeOnOutclick>
  <div slot="title">Delete Types</div>
  <div slot="content">
    <div class="mb-2">Are you sure you want to delete these types?</div>
    {#each typesToDelete as id}
      {@const type = $group.types[id]}
      <div>{type.mark}{type.name && ` - ${type.name}`}</div>
    {/each}
  </div>
</Modal>

<Modal bind:this={cannotRemoveModal} buttons={[{ label: "Cancel", type: "cancel" }]}>
  <div slot="title">Delete Types</div>
  <div slot="content">
    <div class="mb-2">
      The following type(s) are currently in use. Please assign items a different type before deleting:
    </div>
    {#each undeletableTypes as type}
      <div>{type.mark}{type.name && ` - ${type.name}`}</div>
    {/each}
  </div>
</Modal>

<Modal width="36rem" bind:this={makeupLibraryModal} closeable fullframe>
  <div slot="title">Makeup Library</div>
  <div slot="content" class="overflow-y-auto">
    <div class="p-4 space-y-2 text-xs">
      <div class="mb-4">
        <FilterInput bind:filter />
      </div>
      <div>Choose a generic template to begin:</div>
      <div>
        {#each generics as section}
          <LibrarySection {section} on:select={(e) => addType(e.detail.record)}>
            <MakeupThumbnail slot="thumbnail" let:record {record} />
            <div slot="caption" let:record>{record?.name}</div>
          </LibrarySection>
        {/each}
      </div>
      {#if $suppliers?.length > 1}
        <div>Or select a supplier-defined product:</div>
        <SelectInput
          border
          label="Supplier"
          value={$supplier?.id}
          options={$suppliers.map((s) => ({ value: s.id, label: s.name }))}
          on:input={updateSupplier} />
      {:else if $suppliers?.length === 1 && makeups.length}
        <div>Or select a supplier-defined product:</div>
        <div class="font-bold mt-2">{$supplier?.name}</div>
      {/if}
      {#if makeups.length}
        <div>
          {#each filteredProducts as section}
            <LibrarySection {section} on:select={(e) => addType(e.detail.record)}>
              <MakeupThumbnail slot="thumbnail" let:record {record} />
              <div slot="caption" let:record>{record?.name}</div>
            </LibrarySection>
          {/each}
        </div>
      {/if}
    </div>
  </div>
</Modal>
