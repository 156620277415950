<script>
  import SidebarSection from "./SidebarSection.svelte";
  import DimtextInput from "./DimtextInput.svelte";
  import SelectInput from "./SelectInput.svelte";
  import { tempFeatureHole } from "src/stores/ui.js";
  import { createCircularHole, createRectangularHole } from "@local/lamina-core";

  export let disabled;
  export let settings;

  function updateFeature(prop, value) {
    $tempFeatureHole[prop] = value;
  }

  function updateFeatureType(value) {
    return {
      "circular-hole": () => {
        $tempFeatureHole = createCircularHole();
      },
      "rectangular-hole": () => {
        $tempFeatureHole = createRectangularHole();
      },
    }[value]();
  }
</script>

<SidebarSection>
  <SelectInput
    label="Hole Type"
    {disabled}
    labelWidth="5.5rem"
    value={$tempFeatureHole.type}
    options={[
      { label: "Circular", value: "circular-hole" },
      { label: "Rectangular", value: "rectangular-hole" },
    ]}
    on:input={(e) => updateFeatureType(e.detail.value)} />
  {#if $tempFeatureHole.type === "circular-hole"}
    <DimtextInput
      label="Diameter"
      {disabled}
      labelWidth="5.5rem"
      value={$tempFeatureHole.diameter}
      {settings}
      on:input={(e) => updateFeature("diameter", e.detail.value)} />
  {:else if $tempFeatureHole.type === "rectangular-hole"}
    <DimtextInput
      label="Width"
      {disabled}
      labelWidth="5.5rem"
      value={$tempFeatureHole.width}
      {settings}
      on:input={(e) => updateFeature("width", e.detail.value)} />
    <DimtextInput
      label="Height"
      {disabled}
      labelWidth="5.5rem"
      value={$tempFeatureHole.height}
      {settings}
      on:input={(e) => updateFeature("height", e.detail.value)} />
    <DimtextInput
      label="Radius"
      {disabled}
      labelWidth="5.5rem"
      value={$tempFeatureHole.radius}
      {settings}
      on:input={(e) => updateFeature("radius", e.detail.value)} />
    <SelectInput
      label="Dimension to"
      {disabled}
      labelWidth="5.5rem"
      value={$tempFeatureHole.alignment}
      options={[
        { label: "Center", value: "center" },
        { label: "Inside Edge", value: "inside-edge" },
      ]}
      on:input={(e) => updateFeature("alignment", e.detail.value)} />
    <SelectInput
      label="Orientation"
      {disabled}
      labelWidth="5.5rem"
      value={$tempFeatureHole.orientation}
      options={[
        { label: "Orthogonal", value: "orthogonal" },
        { label: "Edge-aligned", value: "edge-aligned" },
      ]}
      on:input={(e) => updateFeature("orientation", e.detail.value)} />
  {/if}
</SidebarSection>
