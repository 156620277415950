<script>
  import { api } from "src/api";
  import debounce from "lodash/debounce";
  import { Modal } from "svelte-utilities";
  import TextInput from "src/lib/sidebar/TextInput.svelte";
  import SelectInput from "./sidebar/SelectInput.svelte";
  import SearchSelect from "./sidebar/SearchSelect.svelte";
  import sanitizePgString from "src/extensions/sanitize-postgrest-string.js";
  import isEmail from "@local/extensions/validators/is-email.js";

  export let org;
  export const open = () => openModal();

  let modal;
  let searchString = "";
  let selectedOrg = null;
  let selectedResult = null;
  let searchResults = [];
  let orgResults = [];
  let orgContactResults = [];

  $: allResults = [...searchResults, ...orgResults];
  $: isValid = valid(selectedOrg);
  $: updateOrgContactResults(selectedOrg);

  async function addNewContact() {
    if (selectedOrg.type === "existing") {
      await org.addContact({
        contact_type: "supplier",
        contact_id: selectedOrg.id,
        primary_contact_id: selectedOrg.primary_contact_id,
      });
    } else if (selectedOrg.type === "new") {
      await org.addContact({
        contact_type: "supplier",
        email: selectedOrg.email,
        name: selectedOrg.name,
        company_name: selectedOrg.company_name,
      });
    }

    searchString = "";
    selectedOrg = null;
    selectedResult = null;
  }

  function openModal() {
    searchString = "";
    selectedOrg = null;
    selectedResult = null;
    modal.open();
  }

  function valid(selectedOrg) {
    if (!selectedOrg) return false;
    if (selectedOrg.type === "existing") {
      const existingPrimary = $org.contacts.filter(
        (c) => c.contact_id === selectedOrg.id && !c.primary_contact_id,
      );
      if (existingPrimary.length && !selectedOrg.primary_contact_id) return false;

      return true;
    }

    if (!isEmail(selectedOrg.email)) return false;
    if (!selectedOrg.company_name) return false;
    if (!selectedOrg.name) return false;
    return true;
  }

  function profileLabel(profile) {
    if (!profile.username || profile.username === profile.email) return profile.email;
    return `${profile.username} (${profile.email})`;
  }

  async function updateOrgContactResults(so) {
    if (!(so?.type === "existing")) {
      orgContactResults = [];
      return;
    }

    const { data: profiles, error } = await api
      .from("profiles")
      .select("*")
      .eq("organization_id", so.id)
      .is("is_public", true);

    const sameOrgContacts = $org.contacts.filter((c) => c.contact_id === so.id);
    const existingIds = sameOrgContacts.map((c) => c.primary_contact_id);
    const unique = profiles.filter((p) => !existingIds.includes(p.id));
    const primary = sameOrgContacts.find((c) => !c.primary_contact_id);

    if (error) {
      orgContactResults = [];
      return;
    }

    let r = [];

    if (so.primary_contact_email && !primary) {
      r.push({ label: so.primary_contact_email, value: null });
    }

    r.push(...unique.map((p) => ({ label: profileLabel(p), value: p.id })));
    orgContactResults = r;
  }

  async function searchOrgs(s) {
    if (!s) {
      orgResults = [];
      return;
    }

    const currentIds = $org.contacts.filter((c) => c.contact_id).map((c) => c.contact_id);

    const { data, error } = await api
      .from("organizations")
      .select("*")
      .or(`name.ilike.%${s}%,primary_contact_email.ilike.%${s}%`)
      .eq("org_type", "supplier")
      .is("is_public", true)
      // .not("id", "in", `(${currentIds.join(",")})`)
      .range(0, 5);

    if (error) {
      orgResults = [];
    } else {
      orgResults = data.map((o) => ({
        ...o,
        description: o.address,
        cta: "Add Shape Exchange Supplier",
        type: "existing",
        id: o.id,
      }));
    }
  }

  function search(e) {
    const name = sanitizePgString(e.detail.value);
    const existing = $org.contacts.some((c) => c.email === name);

    if (isEmail(name)) {
      if (existing) {
        searchResults = [
          {
            name,
            email: name,
            description: null,
            cta: "Email already in Address Book",
            type: "existing",
            invalid: true,
          },
        ];
      } else {
        searchResults = [
          {
            name: `+ Add New (${name})`,
            email: name,
            description: null,
            cta: "Add email to Address Book",
            type: "new",
          },
        ];
      }
    } else {
      searchResults = [
        {
          name: name ? `+ Add New (${name})` : `+ Add New`,
          company_name: name,
          email: null,
          description: null,
          cta: "Add new supplier to Address Book",
          type: "new",
        },
      ];
    }

    debounce(() => searchOrgs(name), 500)();
  }

  async function select(e) {
    const result = e.detail.result;
    selectedResult = result;
    searchString = result.name;

    if (result.type === "existing") {
      selectedOrg = {
        name: result.name,
        primary_contact_email: result.primary_contact_email,
        primary_contact_id: null,
        email: null,
        company_name: result.name,
        id: result.id,
        address: result.address,
        type: "existing",
      };
    } else if (result.type === "new") {
      selectedOrg = {
        email: result.email,
        name: null,
        company_name: result.company_name,
        address: null,
        type: "new",
      };
    }
  }

  function deselect() {
    selectedOrg = null;
    selectedResult = null;
  }

  function updateProp(prop, value) {
    if (prop === "company_name") {
      searchString = value;
    }

    // update the "selected" result to match
    if (prop === "email" && selectedResult?.email) {
      selectedResult.name = `+ Add New (${value})`;
    } else if (prop === "company_name" && selectedResult?.company_name) {
      selectedResult.name = `+ Add New (${value})`;
    }

    selectedOrg = {
      ...selectedOrg,
      [prop]: value,
    };
  }
</script>

<Modal
  width="36rem"
  bind:this={modal}
  closeable
  on:confirm={addNewContact}
  buttons={[
    { label: "Cancel", type: "cancel" },
    {
      label: selectedOrg ? "Add Supplier" : "Search for Supplier",
      type: "confirm",
      style: "primary",
      disabled: !selectedOrg || !isValid,
    },
  ]}>
  <div slot="title">Add New Supplier</div>
  <div slot="content" class="space-y-2">
    <SearchSelect
      placeholder="Enter Supplier Name or Email"
      border
      value={searchString}
      selection={selectedResult}
      on:focus={search}
      on:search={search}
      on:select={select}
      on:deselect={deselect}
      results={allResults} />
    {#if selectedOrg?.type === "existing"}
      <SelectInput
        label="Contact Email"
        labelWidth="6.5rem"
        value={selectedOrg.primary_contact_id}
        options={orgContactResults}
        on:input={(e) => {
          updateProp("primary_contact_id", e.detail.value);
        }} />
    {:else if selectedOrg?.type === "new"}
      <TextInput
        label="Company"
        inputOnKeydown
        labelWidth="6.5rem"
        value={selectedOrg.company_name}
        on:input={(e) => {
          updateProp("company_name", e.detail.value);
        }} />
      <TextInput
        inputOnKeydown
        label="Contact Name"
        labelWidth="6.5rem"
        value={selectedOrg.name}
        on:input={(e) => {
          updateProp("name", e.detail.value);
        }} />
      <TextInput
        label="Email"
        inputOnKeydown
        labelWidth="6.5rem"
        value={selectedOrg.email}
        on:input={(e) => {
          updateProp("email", e.detail.value);
        }} />
    {/if}
  </div>
</Modal>
