<script>
  import UndoIcon from "src/assets/icons/undo.svg";
  import RedoIcon from "src/assets/icons/redo.svg";
  import SelectIcon from "src/assets/icons/select.svg";
  import ZoomIcon from "src/assets/icons/zoom.svg";
  import ZoomOutIcon from "src/assets/icons/zoom-out.svg";
  import ZoomFitIcon from "src/assets/icons/zoom-fit.svg";
  import TableIcon from "src/assets/icons/table.svg";
  import PanelRightIcon from "src/assets/icons/panel-right.svg";
  import PanIcon from "src/assets/icons/pan.svg";
  import PenPlusIcon from "src/assets/icons/pen-plus.svg";
  import PenMinusIcon from "src/assets/icons/pen-minus.svg";
  import HoleIcon from "src/assets/icons/hole.svg";
  import NotchIcon from "src/assets/icons/notch.svg";
  import CaretDownIcon from "src/assets/icons/caret-down.svg";
  import CornerNotchIcon from "src/assets/icons/corner-notch.svg";
  import LocationIcon from "src/assets/icons/location.svg";
  import LocationItemIcon from "src/assets/icons/location-item.svg";
  import LocationTypeIcon from "src/assets/icons/location-type.svg";
  import LocationOpeningIcon from "src/assets/icons/location-opening.svg";
  import { clickOutside, Popover } from "svelte-utilities";

  import Tool from "./Tool.svelte";

  import { currentTool, showTable, showRightPanel } from "src/stores/ui.js";
  import eb from "src/extensions/event-bus.js";

  export let tab;
  export let org;
  export let path;
  export let canAdmin;
  export let disabled = false;

  let toolDropdown;
  let showToolPanel;

  const tools = {
    surfacesList: [["select"]],
    materialsList: [["select"]],
    makeupsList: [["select"]],
    makeupsEditor: [["select"]],
    itemsList: [["select"]],
    itemsEditor: [["select"]],
    edgesList: [["select"]],
    fabricationsList: [["select"]],
    typesList: [["select"]],
    typesEditor: [["select"]],
  };

  const toolIcons = {
    select: SelectIcon,
    pan: PanIcon,
    "zoom-to-fit": ZoomFitIcon,
    "zoom-in": ZoomIcon,
    "zoom-out": ZoomOutIcon,
    "add-point": PenPlusIcon,
    "remove-point": PenMinusIcon,
    "feature-hole": HoleIcon,
    "feature-edge-fabrication": NotchIcon,
    "feature-corner-fabrication": CornerNotchIcon,
    location: LocationIcon,
    "location-item": LocationItemIcon,
    "location-type": LocationTypeIcon,
    "location-collection": LocationOpeningIcon,
  };

  const toolIsStateful = {
    select: true,
    pan: true,
    location: true,
    "location-item": true,
    "location-type": true,
    "location-collection": true,
    "add-point": true,
    "remove-point": true,
    "feature-hole": true,
    "feature-edge-fabrication": true,
    "feature-corner-fabrication": true,
  };

  const toolEditsShape = {
    "add-point": false,
    "remove-point": false,
    "feature-hole": true,
    "feature-edge-fabrication": true,
    "feature-corner-fabrication": true,
  };

  $: undoable = $org && org.undoable();
  $: redoable = $org && org.redoable();
  $: pathSegments = (path && path.split("/")) || [];
  $: page = `${tab}${pathSegments[1] ? "Editor" : "List"}`;
  $: toolset = filterTools(page, disabled);
  $: statefulTools = toolset.flat().filter((tool) => toolIsStateful[tool]);
  $: statelessTools = toolset.flat().filter((tool) => !toolIsStateful[tool]);
  $: updateCurrentTool(toolset);

  function filterTools(page, disabled) {
    if (!page) return [];
    if (!disabled) return tools[page];

    return tools[page].map((t) => t.filter((tool) => !toolEditsShape[tool])).filter((t) => t.length > 0);
  }

  function updateCurrentTool(toolset) {
    const tools = toolset.flat();
    if (!tools.includes($currentTool)) {
      $currentTool = "select";
    }
  }

  function clickStatefulTool(tool) {
    $currentTool = tool;
    eb.dispatch(tool);
  }
</script>

<div class="w-full h-10 border-t border-gray-700 flex-none bg-black text-gray-200 z-10">
  <div class="flex items-center justify-between h-10 px-2">
    <div class="flex items-center flex-none gap-3">
      <div class="h-8 hidden sm:flex items-center">
        <Tool
          toolid="panel-left"
          type="toggle"
          state={$showTable}
          on:click={() => ($showTable = !$showTable)}>
          <TableIcon />
        </Tool>
      </div>

      <div class="h-8 flex items-center gap-1">
        <Tool toolid="undo" type="button" disabled={!undoable} on:click={org.undo()}>
          <UndoIcon />
        </Tool>
        <Tool toolid="redo" type="button" disabled={!redoable} on:click={org.redo()}>
          <RedoIcon />
        </Tool>
      </div>

      <!-- Narrow screens: collapse stateful tools into a dropdown -->
      <div class="flex sm:hidden" bind:this={toolDropdown}>
        <div class="relative mr-2">
          {#if statefulTools.length > 1}
            <div class="h-8 flex items-center">
              <Tool toolid={$currentTool} bind:state={$currentTool}>
                <svelte:component this={toolIcons[$currentTool]} />
              </Tool>
              <button class="text-gray-500" on:click={() => (showToolPanel = !showToolPanel)}>
                <CaretDownIcon />
              </button>
            </div>

            {#if showToolPanel}
              <Popover container={toolDropdown}>
                <div
                  class="tool-dropdown-container"
                  use:clickOutside={[toolDropdown]}
                  on:outclick={() => (showToolPanel = false)}>
                  {#each statefulTools as toolid}
                    <Tool
                      {toolid}
                      state={$currentTool}
                      on:click={() => clickStatefulTool(toolid)}
                      disabled={!canAdmin}>
                      <svelte:component this={toolIcons[toolid]} />
                    </Tool>
                  {/each}
                </div>
              </Popover>
            {/if}
          {:else}
            <div class="h-8 flex items-center">
              <Tool toolid={$currentTool} bind:state={$currentTool}>
                <svelte:component this={toolIcons[statefulTools[0]]} />
              </Tool>
            </div>
          {/if}
        </div>

        {#if statelessTools.length > 0}
          <div class="h-8 flex gap-1 items-center">
            {#each statelessTools as toolid}
              <Tool {toolid} type="button" on:click={() => eb.dispatch(toolid)} disabled={!canAdmin}>
                <svelte:component this={toolIcons[toolid]} />
              </Tool>
            {/each}
          </div>
        {/if}
      </div>

      <!-- Wider screens: display tools in order given -->
      <div class="hidden sm:flex gap-3">
        {#each toolset as tools}
          <div class="h-8 flex items-center gap-1">
            {#each tools as toolid}
              {@const isStateful = toolIsStateful[toolid]}
              <Tool
                {toolid}
                type={isStateful ? "enum" : "button"}
                state={$currentTool}
                on:click={() => (isStateful ? clickStatefulTool(toolid) : eb.dispatch(toolid))}
                disabled={!canAdmin}>
                <svelte:component this={toolIcons[toolid]} />
              </Tool>
            {/each}
          </div>
        {/each}
      </div>
    </div>

    <div class="flex items-center flex-none gap-3">
      <div class="flex items-center gap-1">
        <!-- <Tool toolid="settings" type="button" on:click={() => eb.dispatch("open-settings")}>
          <GearIcon />
        </Tool> -->
      </div>

      <div class="h-8 hidden sm:flex items-center">
        <Tool
          toolid="panel-right"
          type="toggle"
          state={$showRightPanel}
          on:click={() => ($showRightPanel = !$showRightPanel)}>
          <PanelRightIcon />
        </Tool>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  button {
    @apply opacity-50 px-2 text-white font-semibold;

    &:hover {
      @apply opacity-100;
    }
  }

  .tool-dropdown-container {
    @apply p-1 rounded drop-shadow-lg border border-gray-700;
    @apply bg-black text-xs text-gray-200 flex gap-1 z-50;
  }
</style>
