<script>
    import { createEventDispatcher } from "svelte";
    import { useLocation } from "svelte-routing";

    const location = useLocation();
    const dispatch = createEventDispatcher();

    $: if ($location) {
        dispatch("navigate", $location);
    }
</script>