import { writable } from "svelte/store";

function createNotifications() {
  const { set, update, ...rest } = writable([]);

  return {
    ...rest,
    set,
    update,
    add: (notification) => {
      const id = notification.id || crypto.randomUUID();
      notification.id = id;
      update((n) => [...n, notification]);
      if (notification.duration) {
        setTimeout(() => {
          update((n) => n.filter((n) => n?.id !== notification.id));
        }, notification.duration * 1000);
      }
    },
    remove: (notification) =>
      update((n) => n.filter((n) => n?.id !== notification.id)),
  };
}

export const notifications = createNotifications();
