import { api } from "src/api";
import { writable, derived } from "svelte/store";
import { sortableList } from "@local/extensions/collections/sortable-list.js";

function createEdgeTreatmentStore() {
  const { subscribe, set, update } = writable(sortableList([]));

  return {
    subscribe,
    set,
    update,
    async fetch() {
      try {
        const { data, error } = await api
          .from("standard_edge_treatments")
          .select("*");
        if (error) throw error;
        set(sortableList(data));
      } catch (error) {
        console.log(error);
      }
    },
  };
}

function createEdgeTreatmentOptions(edgeTreatments) {
  return derived(edgeTreatments, ($edgeTreatments) => {
    return [
      { label: "Unspecified", value: null },
      ...$edgeTreatments.order.map((i) => {
        const et = $edgeTreatments[i];
        return { label: et.name, value: et.id };
      }),
    ];
  });
}

export { createEdgeTreatmentStore, createEdgeTreatmentOptions };
