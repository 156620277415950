import DxfParser from "dxf-parser";
import convert from "convert";
import type { Length } from "convert";

const realThreshold = 1e-8;

const dxfUnits = {
  1: "inches",
  2: "feet",
  3: "miles",
  4: "millimeters",
  5: "centimeters",
  6: "meters",
  7: "kilometers",
  8: "microinches",
  9: "mils",
  10: "yards",
  11: "angstroms",
  12: "nanometers",
  13: "microns",
  14: "decimeters",
  15: "dekameters",
  16: "hectometers",
  17: "gigameters",
  18: "au",
  19: "lightyears",
  20: "parsecs",
};

function almostEqual(a:number, b:number): boolean {
  return a - b < realThreshold && a - b > -realThreshold;
}

interface Point {
  x: number,
  y: number,
  bulge?: number,
}

function midpoint(a: Point, b: Point): Point {
  return {
    x: (a.x + b.x) / 2,
    y: (a.y + b.y) / 2,
  }
}

function subtract(a: Point, b: Point): Point {
  return {
    x: a.x - b.x,
    y: a.y - b.y,
  }
}

function isOrigin(pt: Point) {
  return almostEqual(pt.x, 0) && almostEqual(pt.y, 0);
}

function isOpen(entity) {
  return ["POLYLINE", "LWPOLYLINE"].includes(entity.type) && !entity.shape;
}

function isClosed(entity) {
  if (entity.type === "CIRCLE") return true;
  if (["POLYLINE", "LWPOLYLINE"].includes(entity.type)) {
    if (entity.shape) return true;
    const first = entity.vertices[0];
    const last = entity.vertices[entity.vertices.length - 1];
    if (almostEqual(first.x, last.x) && almostEqual(first.y, last.y)) return true;
  }

  return false;
}

function moveBulge(vertices: Point[]): Point[] {
  const v = vertices.map((vertex) => {
    const p: Point = { x: vertex.x, y: vertex.y };
    return p;
  });

  vertices.forEach((vertex, i) => {
    if (vertex.bulge) {
      v[(i + 1 + vertices.length) % vertices.length].bulge = vertex.bulge;
    }
  });

  return v;
}

function firstOpenPolyline(dwg, position) {
  const pline = dwg.entities.find(isOpen);
  if (!pline) throw new Error("Drawing does not contain an open polyline");
  const vertices = pline.vertices;
  const first = vertices[0];
  const last = vertices[vertices.length - 1];

  if (position === "edge") {
    if (!almostEqual(first.y, last.y)) throw new Error("First and last vertices should share a y-coordinate");
  } else if (position === "corner") {
    const err = new Error("First vertex should lie on y-axis, last vertex should lie on x-axis.");
    if (almostEqual(first.x, 0)) {
      if (!(first.y > 0) || !almostEqual(last.y, 0) || !(last.x > 0)) throw err;
    } else if (almostEqual(last.x, 0)) {
      if (!(last.y > 0) || !almostEqual(first.y, 0) || !(first.x > 0)) throw err;
    } else {
      throw err;
    }
  }

  console.log('input vertices', vertices);

  // Ensure that polyline is drawn in clockwise direction
  let result;
  if (first.x <= last.x) {
    result = moveBulge(vertices);
  } else {
    result = [...vertices].reverse();
  }

  if (result[0]) delete result[0].bulge;

  return result;
}

function voids(dwg) {
  return dwg.entities
    .filter(isClosed)
    .map((entity) => {
      if (entity.type === "CIRCLE") {
        return [
          { x: entity.center.x - entity.radius, y: entity.center.y, bulge: -1 },
          { x: entity.center.x + entity.radius, y: entity.center.y, bulge: -1 },
        ]
      } else {
        return moveBulge(entity.vertices);
      }
    });
}

function rescale(vertices: Point[], sourceUnit: Length, outputUnit: Length) {
  return vertices.map((vertex) => ({
    ...vertex,
    x: convert(vertex.x, sourceUnit).to(outputUnit),
    y: convert(vertex.y, sourceUnit).to(outputUnit),
  }));
}

export default async function importFabrication(
  string: string,
  position: "corner" | "edge" = "edge",
  sourceUnit?: Length | null,
  outputUnit?: Length
): Promise<any> {
  const parser = new DxfParser();
  const dxf = await parser.parse(string);
  if (!dxf) throw new Error("Unable to parse DXF");

  const su = typeof dxf.header.$INSUNITS === "number" ? dxfUnits[dxf.header.$INSUNITS] : sourceUnit;
  const ou = outputUnit || "inches";

  const vertices = firstOpenPolyline(dxf, position);
  const v = voids(dxf);
  if (su && su !== ou) {
    return {
      path: rescale(vertices, su, ou),
      voids: v.map(voidPath => rescale(voidPath, su, ou)),
    };
  } else {
    return {
      path: vertices,
      voids: v,
    };
  }
}
