<script>
  import { createEventDispatcher, tick } from "svelte";
  import EditIcon from "src/assets/icons/edit.svg";
  import pasteAsPlaintext from "src/extensions/dom/paste-as-plaintext";
  import selectAllContenteditable from "src/extensions/dom/select-all-contenteditable";

  export let value;
  export let disabled = false;
  export let inheritsize = false;

  const dispatch = createEventDispatcher();

  let input;

  function handleKeydown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      e.target.blur();
    }
  }

  function handleInput(e) {
    const str = e.target.innerText.replace(/(\r\n|\n|\r)/gm, "");
    dispatch("input", { value: str });
  }

  function handleFocusout(e) {
    const str = e.target.innerText.replace(/(\r\n|\n|\r)/gm, "");
    dispatch("change", { value: str });
  }

  async function handleEditClick() {
    input.focus();
    await tick();
    selectAllContenteditable(input);
  }
</script>

<div class="flex items-center gap-2 editable-container">
  {#if disabled}
    <h2 bind:this={input}>{value}</h2>
    <div class="edit-icon text-gray-500" class:disabled>
      <EditIcon />
    </div>
  {:else}
    <h2
      class:inheritsize
      class="rounded"
      contenteditable
      bind:this={input}
      bind:innerHTML={value}
      on:paste={pasteAsPlaintext}
      on:keydown={handleKeydown}
      on:input={handleInput}
      on:focusout={handleFocusout} />
    <div class="edit-icon text-gray-500 cursor-pointer" on:click={handleEditClick}>
      <EditIcon />
    </div>
  {/if}
</div>

<style lang="scss">
  h2.inheritsize {
    font-size: inherit;
  }

  .editable-container {
    .edit-icon {
      visibility: hidden;
    }

    &:hover {
      .edit-icon:not(.disabled) {
        visibility: visible;
      }
    }
  }
</style>
