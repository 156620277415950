<script>
  import { createEventDispatcher } from "svelte";
  import { clickOutside } from "svelte-utilities";

  import EllipsisIcon from "src/assets/icons/ellipsis.svg";

  import { Modal } from "svelte-utilities";
  import LibrarySection from "./LibrarySection.svelte";
  import FilterInput from "src/lib/products/FilterInput.svelte";

  export let border = false;
  export let label = "Label";
  export let labelWidth = null;
  export let value;
  export let disabled = false;
  export let title = "Library";
  export let nullText = "Generic";
  export let preLibrary = [];
  export let library = [];

  let container;
  let modal;
  let content;
  let titleBar;
  let filter = "";

  export const focus = () => focusInput();

  const dispatch = createEventDispatcher();

  let focused = false;

  $: lw = labelWidth ? `width:${labelWidth};` : "";
  $: preLibItems = preLibrary.map((section) => section.records).flat();
  $: libItems = library.map((section) => section.records).flat();
  $: filteredLibrary = filterList(library, filter);
  $: filteredPreLibrary = filterList(preLibrary, filter);

  function focusInput(evt) {
    focused = true;
  }

  function filterList(library, filter) {
    const f = filter?.toLowerCase() || "";
    return library.map((section) => {
      return {
        ...section,
        records: section.records.filter((record) => {
          const name = record.name?.toLowerCase() || "";
          const category = record.category?.toLowerCase() || "";
          const classification = record.classification?.toLowerCase() || "";

          return name.includes(f) || category.includes(f) || classification.includes(f);
        }),
      };
    });
  }

  function isContainedBy(target, list = []) {
    return list.some((node) => {
      return node && node.contains(target);
    });
  }

  function handleClick() {
    focusInput();
    if (disabled) return;
    modal.open();
  }

  function blur(e) {
    if (e && e.type === "blur") {
      focused = false;
      return;
    }

    if (e && e.type === "outclick") return;

    modal.close();
    focused = false;
  }

  function selectRecord(record) {
    dispatch("input", { value: record });
    modal.close();
  }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<button
  class="prop-container"
  class:focused
  class:cursor-default={disabled}
  class:readonly={disabled}
  class:visible-border={border}
  bind:this={container}
  on:click|stopPropagation={handleClick}
  use:clickOutside
  on:outclick={blur}
  on:blur={blur}>
  <div class="label" style={lw}>
    <slot name="label">
      {label}
    </slot>
  </div>
  <div class="input">
    <div class="grow">{value?.name || nullText}</div>
    {#if !disabled}
      <div class="flex-none p-0.5 hover:bg-gray-200 rounded">
        <EllipsisIcon />
      </div>
    {/if}
  </div>
</button>

<Modal width="36rem" bind:this={modal} closeable fullframe>
  <div slot="title" bind:this={titleBar}>{title}</div>
  <div slot="content" bind:this={content} class="overflow-y-auto">
    <div class="p-4 space-y-2 text-xs">
      <div class="mb-4">
        <FilterInput bind:filter />
      </div>
      <slot name="prepend" />
      {#if preLibItems.length}
        <div>
          {#each filteredPreLibrary as section}
            <LibrarySection {section} on:select={(e) => selectRecord(e.detail.record)}>
              <svelte:fragment slot="thumbnail" let:record>
                <slot name="thumbnail" {record} />
              </svelte:fragment>
              <svelte:fragment slot="caption" let:record>
                <slot name="caption" {record} />
              </svelte:fragment>
            </LibrarySection>
          {/each}
        </div>
      {/if}
      {#if library && libItems.length}
        <slot name="pend" />
        <div>
          {#each filteredLibrary as section}
            <LibrarySection {section} on:select={(e) => selectRecord(e.detail.record)}>
              <svelte:fragment slot="thumbnail" let:record>
                <slot name="thumbnail" {record} />
              </svelte:fragment>
              <svelte:fragment slot="caption" let:record>
                <slot name="caption" {record} />
              </svelte:fragment>
            </LibrarySection>
          {/each}
        </div>
      {/if}
    </div>
  </div>
</Modal>

<style lang="scss">
  .prop-container {
    @apply px-2 py-2 w-full flex items-start rounded bg-white text-left;

    &:hover:not(.readonly):not(.focused),
    &.visible-border {
      @apply ring-1 ring-inset ring-gray-300;
    }

    &.focused {
      @apply ring-2 ring-inset ring-blue-500;
    }

    .label {
      @apply truncate text-gray-400 flex-none;
    }

    .input {
      @apply min-w-0 grow shrink bg-transparent ml-2 flex items-center;
    }
  }
</style>
