<script>
  import { createEventDispatcher, onMount } from "svelte";
  import cloneDeep from "lodash/cloneDeep";
  import { DimText } from "dimtext";

  import TextInput from "./TextInput.svelte";
  import SelectInput from "./SelectInput.svelte";
  import LibraryInput from "./LibraryInput.svelte";
  import Viewport from "src/lib/drawing/ViewportMinimal.svelte";

  import materialDrawing from "@local/extensions/drawing/material-drawing";
  import { hydrateMaterial } from "@local/lamina-core";
  import imageUrl from "src/extensions/image-url.js";
  import { api } from "src/api";

  export let layer;
  export let disabled;
  export let obSurface;

  const dt = new DimText();
  const dispatch = createEventDispatcher();

  const thicknesses = {
    glass: {
      "3/32in": { inches: "3/32", millimeters: "2.5" },
      "1/8in": { inches: "1/8", millimeters: "3.0" },
      "5/32in": { inches: "5/32", millimeters: "4.0" },
      "3/16in": { inches: "3/16", millimeters: "5.0" },
      "1/4in": { inches: "1/4", millimeters: "6.0" },
      "5/16in": { inches: "5/16", millimeters: "8.0" },
      "3/8in": { inches: "3/8", millimeters: "10.0" },
      "1/2in": { inches: "1/2", millimeters: "12.0" },
      "5/8in": { inches: "5/8", millimeters: "16.0" },
      "3/4in": { inches: "3/4", millimeters: "19.0" },
    },
    spacer: {
      "5/16in": { inches: "5/16", millimeters: "7.5" },
      "3/8in": { inches: "3/8", millimeters: "9" },
      "7/16in": { inches: "7/16", millimeters: "11" },
      "15/32in": { inches: "15/32", millimeters: "12" },
      "1/2in": { inches: "1/2", millimeters: "13.2" },
      "9/16in": { inches: "9/16", millimeters: "14" },
      "5/8in": { inches: "5/8", millimeters: "15.5" },
      "23/32in": { inches: "23/32", millimeters: "18" },
      "3/4in": { inches: "3/4", millimeters: "18.5" },
    },
    interlayer: {
      "0.015in": { inches: "0.015", millimeters: "0.38" },
      "0.025in": { inches: "0.025", millimeters: "0.64" },
      "0.030in": { inches: "0.030", millimeters: "0.76" },
      "0.040in": { inches: "0.040", millimeters: "1.02" },
      "0.050in": { inches: "0.050", millimeters: "1.27" },
      "0.060in": { inches: "0.060", millimeters: "1.52" },
      "0.090in": { inches: "0.090", millimeters: "2.29" },
      "0.100in": { inches: "0.100", millimeters: "2.54" },
      "0.120in": { inches: "0.120", millimeters: "3.05" },
    },
  };

  function transformThicknesses(t) {
    return Object.values(t).reduce((a, value) => {
      a[`${value.millimeters}mm`] = value;
      return a;
    }, {});
  }

  const mm = {
    glass: transformThicknesses(thicknesses.glass),
    spacer: transformThicknesses(thicknesses.spacer),
    interlayer: transformThicknesses(thicknesses.interlayer),
  };

  let thicknessUnit = setThicknessUnit(layer);
  let materialOptions = [];
  let ibSurfaceOptions = [];
  let obSurfaceOptions = [];

  $: setThicknessUnit(layer);
  $: thicknessValue = layer.material?.data?.thickness?.toString() || null;
  $: thicknessOptions = getThicknessOptions(layer, thicknessUnit);

  function setThicknessUnit(layer) {
    const quant = layer.material?.data?.thickness?.quants?.[0];
    if (quant?.unit === "inches") return "inches";
    if (quant?.unit === "millimeters") return "millimeters";
    return "inches";
  }

  function getThicknessOptions(layer, thicknessUnit) {
    let displaySuffix = thicknessUnit === "inches" ? '"' : "mm";
    let dtSuffix = thicknessUnit === "inches" ? "in" : "mm";

    return Object.values(thicknesses[layer.type]).map((value) => ({
      label: `${value[thicknessUnit]}${displaySuffix}`,
      value: `${value[thicknessUnit]}${dtSuffix}`,
    }));
  }

  function updateThickness(e) {
    const value = dt.parse(e.detail.value).value;
    dispatch("update", { prop: "material.data.thickness", value });
  }

  function changeUnit(unit) {
    if (unit === thicknessUnit) return;
    if (!thicknessValue) {
      thicknessUnit = unit;
      return;
    }

    let newThickness;
    if (unit === "inches") {
      newThickness = `${mm[layer.type][thicknessValue].inches}in`;
    } else {
      newThickness = `${thicknesses[layer.type][thicknessValue].millimeters}mm`;
    }

    dispatch("update", { prop: "material.data.thickness", value: dt.parse(newThickness).value });

    thicknessUnit = unit;
  }

  function chooseProduct(type, product) {
    const p = cloneDeep(product);
    p.type = layer[type].type;
    p.value = layer[type].value;

    dispatch("update", { prop: type, value: p });
  }

  function product(surface) {
    if (!surface?.type || surface.type === "none") {
      return null;
    }

    return { id: surface.id, name: surface.name };
  }

  onMount(async () => {
    if (layer.material.type === "query") {
      const materials = await api.rpc("product_query_options", { makeup: layer.material });
      materialOptions = materials.data.map((m) => hydrateMaterial(m));
    } else if (layer.material.type === "option") {
      materialOptions = layer.material.value.options;
    }

    if (layer.inboard_surface?.type === "query") {
      const ibSurfaces = await api.rpc("product_query_options", { makeup: layer.inboard_surface });
      ibSurfaceOptions = ibSurfaces.data;
    } else if (layer.inboard_surface?.type === "option") {
      ibSurfaceOptions = layer.inboard_surface.value.options;
    }

    if (layer.outboard_surface?.type === "query") {
      const obSurfaces = await api.rpc("product_query_options", { makeup: layer.outboard_surface });
      obSurfaceOptions = obSurfaces.data;
    } else if (layer.outboard_surface?.type === "option") {
      obSurfaceOptions = layer.outboard_surface.value.options;
    }
  });
</script>

{#if layer.material?.id}
  <LibraryInput
    label="Product"
    disabled={disabled || !materialOptions.length}
    labelWidth="5rem"
    value={layer.material}
    nullText="Undefined Product"
    library={[{ records: materialOptions }]}
    title="Materials"
    on:input={(e) => chooseProduct("material", e.detail.value)}>
    <Viewport slot="thumbnail" let:record padding={4} drawing={materialDrawing(record)} />
    <div slot="caption" let:record>{record?.name}</div>
  </LibraryInput>
{:else}
  <div class="flex items-center">
    <div class="grow">
      <SelectInput
        label="Thickness"
        {disabled}
        labelWidth="4.55rem"
        value={thicknessValue}
        options={thicknessOptions}
        on:input={updateThickness} />
    </div>
    {#if !disabled}
      <div class="flex-none flex items-center">
        <button
          class="unit-button"
          class:active={thicknessUnit === "inches"}
          on:click={() => changeUnit("inches")}>in</button>
        <button
          class="unit-button"
          class:active={thicknessUnit === "millimeters"}
          on:click={() => changeUnit("millimeters")}>mm</button>
      </div>
    {/if}
  </div>

  <TextInput
    label="Description"
    {disabled}
    labelWidth="4.55rem"
    value={layer.material?.name}
    on:input={(evt) => dispatch("update", { prop: "material.name", value: evt.detail.value })} />
{/if}

{#if layer.outboard_surface}
  {#if ["reference", "query"].includes(layer.outboard_surface?.type)}
    <LibraryInput
      label={`#${obSurface} Surface`}
      disabled={disabled || !obSurfaceOptions.length}
      labelWidth="5rem"
      value={product(layer.outboard_surface)}
      nullText="No coating"
      title="Surfaces"
      library={[{ records: obSurfaceOptions }]}
      on:input={(e) => chooseProduct("outboard_surface", e.detail.value)}>
      <div
        slot="thumbnail"
        let:record
        class="w-full h-full"
        style="background-color: {record?.color || '#FFFFFF'}">
        {#if record.image}
          <img src={imageUrl(record.image)} alt={record.name} class="text-xs w-full h-full object-cover" />
        {/if}
      </div>
      <div slot="caption" let:record>{record?.name}</div>
    </LibraryInput>
  {:else if layer.outboard_surface?.type === "text" || !layer.outboard_surface?.type}
    <TextInput
      label={`#${obSurface} Surface`}
      {disabled}
      labelWidth="4.55rem"
      value={layer.outboard_surface?.name}
      on:input={(evt) => dispatch("update", { prop: "outboard_surface.name", value: evt.detail.value })} />
  {/if}
{/if}

{#if layer.inboard_surface}
  {#if ["reference", "query"].includes(layer.inboard_surface?.type)}
    <LibraryInput
      label={`#${obSurface + 1} Surface`}
      disabled={disabled || !ibSurfaceOptions.length}
      labelWidth="5rem"
      value={product(layer.inboard_surface)}
      nullText="No coating"
      title="Surfaces"
      library={[{ records: ibSurfaceOptions }]}
      on:input={(e) => chooseProduct("inboard_surface", e.detail.value)}>
      <div
        slot="thumbnail"
        let:record
        class="w-full h-full"
        style="background-color: {record?.color || '#FFFFFF'}">
        {#if record.image}
          <img src={imageUrl(record.image)} alt={record.name} class="text-xs w-full h-full object-cover" />
        {/if}
      </div>
      <div slot="caption" let:record>{record?.name}</div>
    </LibraryInput>
  {:else if layer.inboard_surface?.type === "text" || !layer.inboard_surface?.type}
    <TextInput
      label={`#${obSurface + 1} Surface`}
      {disabled}
      labelWidth="4.55rem"
      value={layer.inboard_surface?.name}
      on:input={(evt) => dispatch("update", { prop: "inboard_surface.name", value: evt.detail.value })} />
  {/if}
{/if}

<style lang="scss">
  .unit-button {
    @apply p-1 rounded text-gray-500;

    &:hover,
    &.active {
      @apply text-black bg-gray-100;
    }
  }
</style>
