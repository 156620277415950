<script>
  import { tick } from "svelte";
  import cloneDeep from "lodash/cloneDeep";
  import isEmpty from "lodash/isEmpty";
  import { importFabrication } from "import-dxf";
  import { navigate } from "svelte-routing";

  import SortableThumbnailList from "src/lib/SortableThumbnailList.svelte";
  import { Modal } from "svelte-utilities";
  import Viewport from "src/lib/drawing/ViewportMinimal.svelte";
  import Sidebar from "src/lib/sidebar/Sidebar.svelte";
  import SidebarTitle from "src/lib/sidebar/SidebarTitle.svelte";
  import JobProperties from "src/lib/sidebar/JobProperties.svelte";
  import Attachments from "src/lib/sidebar/Attachments.svelte";
  import History from "src/lib/sidebar/History.svelte";
  import PrevNext from "src/lib/sidebar/PrevNext.svelte";
  import FabricationTypeProperties from "src/lib/sidebar/FabricationTypeProperties.svelte";
  import ListThumbnail from "src/lib/ListThumbnail.svelte";

  import TextInput from "src/lib/sidebar/TextInput.svelte";
  import RadioInput from "src/lib/sidebar/RadioInput.svelte";
  import Dropzone from "src/lib/dropzone/Dropzone.svelte";

  import { createFabrication } from "@local/lamina-core";
  import { fabFunctions, fabParams } from "@local/extensions/fabrications";
  import { objectify } from "overline";
  import fabDrawing from "@local/extensions/drawing/fab-drawing";
  import { drawingSrc } from "src/api";
  import { incrementMarkCopy } from "@local/extensions/identifiers/mark.js";

  import {
    selectedFabs as selectedFabsObj,
    showRightPanel,
    tempFeatureEdgeFabrication,
    tempFeatureCornerFabrication,
    pathRoot,
  } from "src/stores/ui.js";
  import { profile } from "src/stores/auth.js";
  import {
    orderedList,
    addToSortableList,
    removeFromSortableList,
  } from "@local/extensions/collections/sortable-list.js";

  export let group;
  export let disabled = false;
  export let items;

  let width;
  let height;

  let confirmAddModal;
  let newFab = createFabrication();
  let dropzone;
  let uploadErrorMessage = null;

  let confirmRemoveModal;
  let fabsToDelete = [];
  let undeletableFabs = [];

  $: comments = $group.comments.filter((c) => !c.type_id && !c.item_id);
  $: attachments = $group.attachments.filter((a) => !a.type_id && !a.item_id);
  $: fabrications = orderedList($group.data.fabrications);
  $: newFab = createFabrication(fabrications);
  $: selectedFabs = fabrications.filter((t) => $selectedFabsObj[t.id]);
  $: selectedFab = selectedFabs[0];
  $: selectedFabIndex = fabrications.indexOf(selectedFabs[0]);
  $: prevFab = selectedFabs.length === 1 && fabrications[selectedFabIndex - 1];
  $: nextFab = selectedFabs.length === 1 && fabrications[selectedFabIndex + 1];
  $: newFabDisabled = newFab.type === "static" && !newFab.path;
  $: assignments = getFabAssignments(items, fabrications);
  $: canImportDxf = $profile && $profile.user_role === "developer";

  function getFabAssignments(items, fabrications) {
    const a = fabrications.reduce((res, fab) => {
      res[fab.id] = {};
      return res;
    }, {});

    items.forEach((item) => {
      item.data.fabrications?.edge?.forEach((fab) => {
        if (a[fab.fab_id]) a[fab.fab_id][item.id] = true;
      });

      item.data.fabrications?.corner?.forEach((fab) => {
        if (a[fab.fab_id]) a[fab.fab_id][item.id] = true;
      });
    });

    return a;
  }

  function selectFab(e) {
    const { id, event } = e.detail;
    if (event.shiftKey && !isEmpty($selectedFabsObj)) {
      const allIds = fabrications.map((t) => t.id);
      const indices = fabrications
        .map((t, i) => i)
        .filter((i) => $selectedFabsObj[fabrications[i].id] || fabrications[i].id === id);

      const min = Math.min(...indices);
      const max = Math.max(...indices);

      const ids = allIds.slice(min, max + 1);
      selectedFabsObj.selectOnly(...ids);
    } else if (event.metaKey) {
      if (!$selectedFabsObj[id]) {
        selectedFabsObj.select(id);
      } else {
        selectedFabsObj.deselect(id);
      }
    } else {
      selectedFabsObj.selectOnly(id);
    }
  }

  function selectNone() {
    $selectedFabsObj = {};
  }

  function beginAddingFab() {
    uploadErrorMessage = null;
    confirmAddModal.open();
  }

  function addFab() {
    delete newFab.filename;
    if (newFab.type === "static") {
      delete newFab.func;
      delete newFab.params;
    } else if (newFab.type === "function") {
      if (newFab.position === "edge") {
        newFab.func = "geneva";
        newFab.params = fabParams.geneva.map((p) => p.default);
      } else {
        newFab.func = "patch";
        newFab.params = fabParams.patch.map((p) => p.default);
      }
      delete newFab.path;
    } else if (newFab.type === "generic") {
      newFab.drawing = null;
      delete newFab.func;
      delete newFab.params;
    }

    const fabs = cloneDeep($group.data.fabrications);
    addToSortableList(fabs, newFab);

    if (newFab.position === "edge") {
      $tempFeatureEdgeFabrication.fab_id = newFab.id;
    } else if (newFab.position === "corner") {
      $tempFeatureCornerFabrication.fab_id = newFab.id;
    }

    group.updateProp("data.fabrications", fabs);
  }

  function cloneFabs(e) {
    const { selected } = e.detail;
    const fabsToClone = selected.map((id) => $group.data.fabrications[id]);
    const fabMarks = fabrications.map((fab) => fab.mark);

    const newFabs = fabsToClone.map((fab) => {
      const newFab = cloneDeep(fab);
      newFab.mark = incrementMarkCopy(fab.mark, fabMarks);

      const regex = /\(([0-9]+)\)$/;
      const num = parseInt(newFab.mark.match(regex)[1], 10);

      if (fab.name.match(/^Fabrication\s\d+$/)) {
        newFab.name = `Fabrication ${num}`;
      }

      newFab.id = crypto.randomUUID();
      return newFab;
    });

    const fabs = cloneDeep($group.data.fabrications);
    addToSortableList(fabs, ...newFabs);

    group.updateProp("data.fabrications", fabs);
  }

  async function uploadStaticFab(evt) {
    uploadErrorMessage = null;
    const { file, data } = evt.detail;
    const enc = new TextDecoder("utf-8");
    const str = enc.decode(data);

    try {
      const result = await importFabrication(str, newFab.position, null, "inches");
      newFab.filename = file.name;
      newFab.path = result.path;
      newFab.voids = result.voids;
    } catch (err) {
      uploadErrorMessage = err.message || "Unable to import selected file.";
    }
    dropzone.stopLoading();
  }

  function confirmRemove(e) {
    const { selected } = e.detail;
    fabsToDelete = selected.filter((id) => $group.data.fabrications[id]);
    const fabs = fabsToDelete.map((id) => $group.data.fabrications[id]);

    const usedFabs = items.reduce((u, i) => {
      if (i.data.fabrications?.edge) {
        i.data.fabrications.edge.forEach((fab) => (u[fab.fab_id] = true));
      }
      return u;
    }, {});

    undeletableFabs = fabs.filter((f) => !!usedFabs[f.id]);

    confirmRemoveModal.open();
  }

  async function removeFabs() {
    const orphaned = objectify(undeletableFabs);

    const itemupdates = items.reduce((u, i) => {
      if (i.data.fabrications?.edge) {
        let shouldUpdate;
        const fabInstances = cloneDeep(i.data.fabrications.edge);

        fabInstances.forEach((f) => {
          if (orphaned[f.fab_id]) {
            shouldUpdate = true;
            f.orphaned = true;
            const fab = orphaned[f.fab_id];
            if (fab.type === "function") {
              const func = fabFunctions[fab.func];
              const res = func(...fab.params);
              f.path = res.path;
              f.voids = res.voids;
            } else {
              f.path = orphaned[f.fab_id].path;
            }
          }
        });

        if (shouldUpdate) {
          u.push({ type: "item", id: i.id, prop: "data.fabrications.edge", value: fabInstances });
        }
      }

      return u;
    }, []);

    const ftd = [...fabsToDelete];
    fabsToDelete = [];
    undeletableFabs = [];
    await tick();

    const fabs = cloneDeep($group.data.fabrications);
    removeFromSortableList(fabs, ...ftd);

    const groupupdate = { type: "group", id: $group.id, prop: "data.fabrications", value: fabs };

    group.update([...itemupdates, groupupdate]);
  }

  function reorder(e) {
    const { insertion, dragging } = e.detail;
    const order = cloneDeep($group.data.fabrications.order);
    const moved = order.splice(dragging, 1)[0];
    order.splice(insertion, 0, moved);
    group.updateProp("data.fabrications.order", order);
  }

  function gotoPrev() {
    if (prevFab) selectedFabsObj.selectOnly(prevFab.id);
  }

  function gotoNext() {
    if (nextFab) selectedFabsObj.selectOnly(nextFab.id);
  }

  function nav(e) {
    const { id } = e.detail;
    const newpath = `${$pathRoot}fabrications/${id}`;
    navigate(newpath);
  }
</script>

<div class="bg-gray-100 h-full flex" bind:offsetWidth={width} bind:offsetHeight={height}>
  <SortableThumbnailList
    list={fabrications}
    {disabled}
    selected={$selectedFabsObj}
    let:record
    on:add={beginAddingFab}
    on:select={selectFab}
    on:remove-selected={confirmRemove}
    on:select-none={selectNone}
    on:clone-selected={cloneFabs}
    on:nav={nav}
    on:reorder={reorder}>
    {#if record.drawing}
      <ListThumbnail {record} assignments={assignments[record.id]} {group}>
        <div class="w-full h-full flex items-center justify-center bg-white" style="padding:4px;">
          <img class="w-full h-full object-contain" src={drawingSrc(record.drawing)} alt="" />
        </div>
      </ListThumbnail>
    {:else}
      <ListThumbnail {record} assignments={assignments[record.id]} {group}>
        <Viewport padding={0} drawing={fabDrawing(record)} />
      </ListThumbnail>
    {/if}
  </SortableThumbnailList>

  {#if $showRightPanel && width >= 640}
    <Sidebar>
      <svelte:fragment slot="header">
        {#if selectedFabs.length === 1 && selectedFab}
          <PrevNext
            prev={prevFab}
            next={nextFab}
            on:gotoprev={gotoPrev}
            on:gotonext={gotoNext}
            sticky
            title={selectedFab.mark} />
        {/if}
      </svelte:fragment>
      <svelte:fragment slot="content">
        {#if selectedFabs.length === 0}
          <SidebarTitle title="Job Properties" />
          <JobProperties {disabled} {group} />
          <Attachments {attachments} {group} {disabled} />
          <History updatedBy={$group.updater} updatedOn={$group.updated_at} />
        {:else if selectedFabs.length === 1}
          {#key selectedFab.id}
            <SidebarTitle title={`${selectedFab.mark}${selectedFab.name && ` - ${selectedFab.name}`}`} />
            <FabricationTypeProperties
              {group}
              {disabled}
              fabrication={selectedFab}
              assignments={assignments[selectedFab.id]} />
          {/key}
        {:else}
          <SidebarTitle title="Multiple Selected" />
        {/if}
      </svelte:fragment>

      <svelte:fragment slot="footer" />
    </Sidebar>
  {/if}
</div>

<Modal
  bind:this={confirmRemoveModal}
  on:confirm={removeFabs}
  buttons={[
    { label: "Cancel", type: "cancel" },
    ...(!undeletableFabs.length ? [{ label: "Delete", type: "confirm", style: "danger" }] : []),
  ]}
  closeOnOutclick>
  <div slot="title">Delete Fabrications</div>
  <div slot="content" class="space-y-2">
    {#if undeletableFabs.length > 0}
      <div class="text-red-500 space-y-2">
        <p>The following fabrications are currently in use:</p>
        {#each undeletableFabs as fab}
          <div class="pl-4 text-red-500">{fab.name}</div>
        {/each}
        <p>You must remove the fabrication from any associated items before deleting</p>
      </div>
    {:else}
      <div class="space-y-2">
        <p>Are you sure you want to delete these fabrications?</p>
        {#each fabsToDelete as id}
          <div class="pl-4">{$group.data.fabrications[id].name}</div>
        {/each}
      </div>
    {/if}
  </div>
</Modal>

<Modal
  bind:this={confirmAddModal}
  on:confirm={addFab}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Add Fabrication", type: "confirm", style: "primary", disabled: newFabDisabled },
  ]}
  width="26rem"
  closeOnOutclick>
  <div slot="title">Add Fabrication</div>
  <div slot="content" class="space-y-2">
    <TextInput
      label="Mark"
      labelWidth="5.5rem"
      value={newFab.mark}
      on:input={(e) => (newFab.mark = e.detail.value)} />
    <TextInput
      label="Name"
      labelWidth="5.5rem"
      value={newFab.name}
      on:input={(e) => (newFab.name = e.detail.value)} />
    <RadioInput
      label="Position"
      labelWidth="5.5rem"
      value={newFab.position}
      on:input={(e) => (newFab.position = e.detail.value)}
      options={[
        { label: "Edge", value: "edge", name: "edge" },
        { label: "Corner", value: "corner", name: "corner" },
      ]} />
    <RadioInput
      label="Type"
      labelWidth="5.5rem"
      value={newFab.type}
      on:input={(e) => (newFab.type = e.detail.value)}
      options={[
        { label: "Flexible", value: "function", name: "flexible" },
        { label: "Generic", value: "generic", name: "generic" },
        ...(canImportDxf ? [{ label: "Static DXF", value: "static", name: "static" }] : []),
      ]} />
    {#if newFab.type === "static"}
      <div class="flex justify-between text-xs">
        <div>File: <span class="text-gray-500">{newFab.filename || "<no file>"}</span></div>
        <div class="relative tooltip-container">
          <div class="text-blue-500">Requirements</div>
          <div class="absolute tooltip top-0 right-0 mt-4 w-52 bg-white rounded p-2 shadow-lg">
            <p>Imported file should be a DXF containing a single open polyline.</p>
          </div>
        </div>
      </div>
      <div class="space-y-2">
        {#if uploadErrorMessage}
          <p class="text-red-500 pl-4 text-xs">Error: {uploadErrorMessage}</p>
        {/if}
        <Dropzone on:drop={uploadStaticFab} bind:this={dropzone} accept=".dxf" />
      </div>
    {/if}
  </div>
</Modal>
