import type { Json as AnyJson } from "@local/db";
import type {
  GroupRecord as Group,
  ItemRecord as Item,
  OrgRecord as Org,
} from "@local/lamina-core";
import { Dimension, type PlainDimension } from "dimtext";

type JsonObject = {
  [key: string]: AnyJson | undefined
}

type Dimensionable = string | PlainDimension | Dimension | Parameters<typeof Dimension.from>;

type MinMax<T> =
  | { min: T; max: T }
  | { min: T }
  | { max: T };

type Range<T> = {
  type: "range";
} & MinMax<T>;

type Choice<T> = {
  type: "choice";
  values: Array<{ value: T; name?: string }>;
};

type StringLength = {
  type: "length";
  length: number;
};
interface DTypeMap {
  dimtext: {
    inputType: string | Dimension | PlainDimension;
    outputType: Dimension;
    constraint: Range<Dimensionable> | Choice<Dimensionable>;
  };
  number: {
    inputType: number;
    outputType: number;
    constraint: Range<number> | Choice<number>;
  };
  string: {
    inputType: string;
    outputType: string;
    constraint: StringLength;
  };
  boolean: {
    inputType: boolean;
    outputType: boolean;
    constraint: never;
  };
  json: {
    inputType: JsonObject;
    outputType: JsonObject;
    constraint: Choice<JsonObject>;
  };
}

type BaseArgDef<DType extends keyof DTypeMap> = {
  type: DType;
  order?: number;
  label?: string;
  default?: DTypeMap[DType]["inputType"];
  constraint?: DTypeMap[DType]["constraint"];
};

type ArgDef = {
  [D in keyof DTypeMap]: BaseArgDef<D>;
}[keyof DTypeMap];

interface TypeDef {
  type: keyof DTypeMap;
}

interface ColumnDef extends TypeDef {
  id: string;
}

type TypeDefs = Record<string, TypeDef>;
type ArgDefs = Record<string, ArgDef>;
type ColumnDefs = Record<string, ColumnDef>;

type DefaultFor<DType extends keyof DTypeMap> = DTypeMap[DType]["inputType"];
type ConstraintFor<DType extends keyof DTypeMap> = DTypeMap[DType]["constraint"];

type TypeOf<D extends TypeDefs, K extends keyof D> =
  DTypeMap[D[K]["type"]]["outputType"];

type RecordOf<D extends TypeDefs> = {
  [K in keyof D]: TypeOf<D, K>;
};

type ConstraintsFor<A extends ArgDefs> = {
  [K in keyof A]: {
    default?: DefaultFor<A[K]["type"]>
    constraint?: ConstraintFor<A[K]["type"]>
  }
}

type RowComposer<R, Out extends ColumnDefs> = (
  row: R,
  customColumnData: RecordOf<Out>,
) => R;

type RowScript<
  R,
  In extends ArgDefs,
  Out extends ColumnDefs,
  Runtime = typeof defaultRuntime,
> = (
  row: R,
  args: RecordOf<In>,
  composer: RowComposer<R, Out>,
  runtime: Runtime,
) => R;

const defaultRuntime = { Dimension };

function itemComposer<C extends ColumnDefs>(cols: C): RowComposer<Item, C> {
  return function (row: Item, customColumnData: RecordOf<C>) {
    const {
      custom_column_data,
    } = row;

    row.custom_column_data ??= {};

    for (const [name, value] of Object.entries(customColumnData)) {
      const columnId = cols[name].id;
      // TODO validate type
      row.custom_column_data[columnId] = value;
    }

    return row;
  };
}

class ItemScript<In extends ArgDefs, Out extends ColumnDefs> {
  constructor(
    public readonly input: In,
    public readonly output: Out,
    protected readonly fn: RowScript<Item, In, Out>,
  ) { }

  validate<R extends Org, G extends Group>(org: R, group: G): boolean {
    const { custom_columns: orgCustomColumns } = org.data;
    const { custom_columns: groupCustomColumns } = group.data;

    const customColumns: Map<string, object> = new Map();

    if (orgCustomColumns) {
      for (const id of orgCustomColumns.order) {
        customColumns.set(id, orgCustomColumns[id]);
      }
    }

    if (groupCustomColumns) {
      for (const id of groupCustomColumns.order) {
        customColumns.set(id, groupCustomColumns[id]);
      }
    }

    const vals = Object.values(this.output);
    return vals.every(({ id }) => customColumns.has(id));
  }

  run(row: Readonly<Item>, args: RecordOf<In>): Item {
    const compose = itemComposer(this.output);
    return this.fn(row, args, compose, defaultRuntime);
  }
}

function defineItemScript<In extends ArgDefs, Out extends ColumnDefs>(
  declarations: {
    input: In;
    output: Out;
  },
  fn: RowScript<Item, In, Out>,
) {
  const { input, output } = declarations;
  return new ItemScript(input, output, fn);
}

function inlinejs(strs: TemplateStringsArray, ...exprs: unknown[]): string {
  let res = strs[0];
  const { length } = strs;

  for (let i = 1; i < length; i += 1) {
    res += exprs[i - 1];
    res += strs[i];
  }

  const encoded = new TextEncoder().encode(res);
  const base64 = btoa(String.fromCharCode(...encoded));

  return `data:text/javascript;base64,${base64}`;
}

interface ItemScriptModule {
  default: ConstructorParameters<typeof ItemScript>;
}

async function importItemScript(
  source: string,
) {
  const resolved = source.replace(
    /\#script/,
    inlinejs`export const defineItemScript = ({input, output}, run) => ([input, output, run]);`,
  );

  // const blob = new Blob([source], { type: "text/javascript" });
  // const url = URL.createObjectURL(blob);

  const url = inlinejs`${resolved}`;
  const { default: args } = await import(/* @vite-ignore */ url) as ItemScriptModule;

  return new ItemScript(...args);
}

export type { ArgDefs, ColumnDefs, DTypeMap as TypeMap, RowScript, ConstraintsFor };
export { defineItemScript, importItemScript };
