import { LinearDisplayFormat } from "dimtext";
import dimSettings from "@local/extensions/utilities/dim-settings.js";

function formatDims(item, settings) {
  const { displayUnit, dimFormat, dimPrecision } = dimSettings(settings);

  const fmt = {
    DECIMAL: LinearDisplayFormat.DECIMAL,
    FRACTIONAL: LinearDisplayFormat.FRACTIONAL,
  }[dimFormat];

  const widthTxt = item.cache.width_prime?.format(fmt, dimPrecision, {
    displayUnit,
    unicodeNumerals: true,
    unicodeSlash: true,
    fractionSeparator: false,
  });
  const heightTxt = item.cache.height_prime?.format(fmt, dimPrecision, {
    displayUnit,
    unicodeNumerals: true,
    unicodeSlash: true,
    fractionSeparator: false,
  });

  if (!widthTxt || !heightTxt) return "";
  return `${widthTxt} x ${heightTxt}`;
}

export default formatDims;
