<script>
  export let checked;
  export let disabled;

  let input;

  $: indeterminate = checked === "Mixed";
  $: checkIndeterminateStatus(input, indeterminate);

  function checkIndeterminateStatus(input, indeterminate) {
    if (!input) return;
    if (indeterminate) {
      input.indeterminate = true;
    } else {
      input.indeterminate = false;
    }
  }
</script>

<input type="checkbox" bind:this={input} {disabled} class="input" bind:checked on:input />
