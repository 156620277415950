export default function getUserInitials(p) {
  if (!p?.username && !p?.email) return "";
  const str = p.username || p.email;
  const names = str
    .trim()
    .split(" ")
    .filter((n) => n.length > 0);

  if (names.length > 1) {
    return names[0][0] + names[names.length - 1][0];
  }

  if (names.length === 1) return names[0][0];

  return "";
}
