<script>
  import { onMount } from "svelte";
  import upperFirst from "lodash/upperFirst";

  import { Modal } from "svelte-utilities";

  import eb from "src/extensions/event-bus.js";
  import { sortableList } from "@local/extensions/collections/sortable-list.js";
  import { currentSuppliers, currentSupplierid } from "src/stores/ui.js";
  import getUserInitials from "@local/extensions/formatters/get-user-initials";
  import { api } from "src/api";

  export let rfq;
  export let recipients;
  export let items;
  export let types;
  export let org;

  let modal;
  let suppliers = {};

  $: supplier = $currentSuppliers[$currentSupplierid];
  $: sendDisabled = !recipients.length;
  $: units = items.reduce((q, i) => q + i.quantity, 0);

  async function openModal() {
    modal.open();
    suppliers = $currentSuppliers;
    const { data, error } = await api
      .from("organizations")
      .select("*, profiles(*)")
      .in(
        "id",
        recipients.map((r) => r.contact_id),
      );

    if (error) {
      console.error(error);
      suppliers = sortableList([]);
    } else {
      suppliers = sortableList(data);
    }
  }

  function userInits(p) {
    return getUserInitials(p).toUpperCase();
  }

  function recipientEmail(recipient) {
    if (recipient.name) {
      return `${recipient.name} (${recipient.email})`;
    } else if (recipient.email) {
      return recipient.email;
    }

    return "";
  }

  onMount(() => {
    eb.on("open-rfq-modal", openModal);

    return () => {
      eb.unsubscribe("open-rfq-modal", openModal);
    };
  });
</script>

<Modal
  bind:this={modal}
  width="32rem"
  closeable
  on:confirm
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Send", type: "confirm", style: "primary", disabled: sendDisabled },
  ]}>
  <div slot="title">
    {#if org.account_type === "free"}
      {#if supplier}
        Send to {supplier.name}
      {:else}
        Unable to send
      {/if}
    {:else}
      Send to Fabricator
    {/if}
  </div>
  <div slot="content" class="space-y-4 text-sm">
    {#if org.account_type === "free" && !supplier}
      <div>
        Your free account is not sponsored by any suppliers. Upgrade to a paid account in order to send this
        RFQ.
      </div>
    {:else if sendDisabled}
      {#if !recipients.length}
        <div>You must add at least one recipient.</div>
      {/if}
    {:else}
      <div class="flex">
        <div class="flex-none w-20 text-gray-500">Sending:</div>
        <div class="text-black">
          <div class="font-bold">{rfq.job.name}</div>
          <div class="text-xs">{units} units, {types.length} types</div>
        </div>
      </div>
      <div class="flex">
        <div class="flex-none w-20 text-gray-500">For:</div>
        <div>
          <div class="font-bold">{upperFirst(rfq.request_for)}</div>
          {#if rfq.request_for === "order"}
            <div class="text-xs">PO No: {rfq.job.buyer_reference}</div>
          {/if}
        </div>
      </div>
      <div class="flex">
        <div class="flex-none w-20 text-gray-500">To:</div>
        <div class="space-y-3">
          {#each recipients as recipient}
            {@const rorg = suppliers[recipient.contact_id]}
            {@const prof = rorg?.profiles.find((p) => p.id === recipient.primary_contact_id)}
            <div>
              {#if rorg?.data.logo}
                <img
                  class="block logo-image object-contain h-6"
                  src={rorg.data.logo.base64}
                  alt="Logo Thumbnail" />
              {/if}
              <div class="font-bold text-sm">{recipient.company_name}</div>
              <div class="flex items-center text-xs gap-1">
                {#if prof}
                  <div
                    class="profile-circle relative"
                    style="background-color: {prof.user_color || '#000'}; border-color: {prof.user_color ||
                      '#000'}">
                    {userInits(prof)}
                  </div>
                {/if}
                <div>{recipientEmail(recipient)}</div>
              </div>
            </div>
          {/each}
        </div>
      </div>
    {/if}
  </div>
</Modal>
