<script>
  import { onMount } from "svelte";
  import { navigate } from "svelte-routing";

  export let to;
  export let path = "";

  onMount(() => {
    if (path) {
      navigate(`${to}/${path}`);
    } else {
      navigate(to);
    }
  });
</script>
