import uniqBy from "lodash/uniqBy";
import { api } from "src/api";
import { sortableList } from "@local/extensions/collections/sortable-list.js";
import {
  makePriceLibrary,
  orderProductLists,
} from "@local/lamina-core/reporters";

export async function getSuppliers(org) {
  if (!org) return [];

  if (org.org_type === "supplier") {
    const suppliers = await api
      .from("organizations")
      .select("*,profiles!profiles_organization_id_fkey(*))")
      .eq("id", org.id);

    if (suppliers.error) {
      return [];
    }

    return suppliers.data;
  }

  let br = api
    .from("business_relations")
    .select("*,seller: seller_id(*,profiles!profiles_organization_id_fkey(*))");

  if (org.org_type === "product_rep") {
    br = br.eq("rep_id", org.id);
  } else {
    br = br.eq("buyer_id", org.id);
  }

  const { data, error } = await br;

  if (error) {
    console.log("Error getting suppliers", error);
    return [];
  }

  const suppliers = uniqBy(data, "seller_id").map((s) => ({
    ...s.seller,
    business_relation: s,
  }));

  return suppliers;
}

export async function getSupplier(supplierId) {
  if (supplierId) {
    const s = await api
      .from("organizations")
      .select("*,products(*),makeups(*),item_products(*),product_categories(*)")
      .eq("id", supplierId)
      .is("products.published", true)
      .is("makeups.published", true)
      .is("item_products.published", true)
      .single();

    if (s.error) {
      return null;
    } else {
      return {
        ...s.data,
        product_categories: sortableList(s.data.product_categories),
      };
    }
  } else {
    return null;
  }
}

export async function getPriceEntries(supplierId) {
  if (!supplierId) {
    return {};
  }

  const pl = await api
    .from("product_lists")
    .select("*")
    .eq("vendor_id", supplierId);

  if (pl.error) {
    return {};
  }

  const plIds = pl.data.map((p) => p.id);

  const pe = await api.from("price_entries").select("*").in("list_id", plIds);

  if (pe.error) {
    return {};
  }

  return makePriceLibrary(pe.data);
}

export async function getProductLists(supplierId) {
  if (supplierId) {
    const pl = await api
      .from("product_lists")
      .select("*")
      .eq("vendor_id", supplierId)
      .order("sort_order");

    if (!pl.data) {
      return [];
    }

    return orderProductLists(pl.data);
  } else {
    return [];
  }
}
