import MultiSet from "mnemonist/multi-set.js";
// import onomat from "./onomat.js";

/**
 * Return a function that generates a unique tag for every item, as follows:
 *
 * Define "shortid" as the first 8 characters of the item's id.
 *
 * If the item has no mark, return the shortid.
 * If the item has a mark and it is unique, return the mark.
 * Otherwise, return the duplicate mark suffixed by the shortid.
 *
 * @param {Object} items
 * @returns {(id: string) => string}
 */
export default function itemTagGenerator(items) {
  const names = new MultiSet();

  for (const item of items) {
    names.add(item.mark);
  }

  return function itemTag(id) {
    const item = items[id];
    const prefix = item.mark;

    if (!prefix) {
      return id.slice(0, 8);
    } else if (names.count(prefix) > 1) {
      return prefix + "_" + id.slice(0, 8);
    } else {
      return prefix;
    }
  };
}
