<script>
  import { createEventDispatcher } from "svelte";

  export let border = false;
  export let label = "Label";
  export let labelWidth = null;
  export let value;
  export let disabled = false;
  export let outerBorder = true;
  export let min = undefined;
  export let max = undefined;
  export let inputOnKeydown = false;

  const dispatch = createEventDispatcher();

  let focused = false;
  let localValue = value;
  let input;

  $: lw = labelWidth ? `width:${labelWidth};` : "";
  $: refresh(value);

  function refresh(value) {
    localValue = value;
  }

  function decrement() {
    localValue = value - 1;
    value = localValue;
    dispatch("input", { value: localValue });
  }

  function increment() {
    localValue = value + 1;
    value = localValue;
    dispatch("input", { value: localValue });
  }

  function handleFocus(e) {
    focused = true;
    dispatch("focus", e);
    input.select();
  }

  function parseInput(val) {
    val = parseInt(val);
    if (isNaN(val)) {
      localValue = value;
    }

    if (min != null && val < min) {
      localValue = min;
    }

    if (max != null && val > max) {
      localValue = max;
    }

    localValue = val;
    value = val;
    dispatch("input", { value: localValue });
  }

  function handleInput(e) {
    if (inputOnKeydown) {
      parseInput(e.target.value);
    }
  }

  function handleBlur(e) {
    focused = false;
    dispatch("blur", e);
    parseInput(e.target.value);
  }
</script>

<div
  class="prop-container"
  class:has-outer-border={outerBorder}
  class:has-inner-border={!outerBorder}
  class:focused
  class:readonly={disabled}
  class:visible-border={border}>
  <div class="label" style={lw}>
    <slot name="label">
      {label}
    </slot>
  </div>
  <div class="input flex gap-2 items-center">
    {#if !disabled}
      <button disabled={min != null && value <= min} on:click={decrement}>-</button>
    {/if}
    <input
      bind:this={input}
      size="1"
      {disabled}
      bind:value={localValue}
      on:focus={handleFocus}
      on:input={handleInput}
      on:blur={handleBlur} />
    {#if !disabled}
      <button disabled={max != null && value >= max} on:click={increment}>+</button>
    {/if}
  </div>
</div>

<style lang="scss">
  input {
    border: none;
    background-color: none;
    outline: none;
    min-width: 0;
    max-width: 100%;
    text-align: center;
  }

  .prop-container {
    @apply w-full flex items-center rounded bg-white;

    &.has-outer-border {
      @apply p-2;

      &:hover:not(.readonly):not(.focused),
      &.visible-border {
        @apply ring-1 ring-inset ring-gray-300;
      }

      &.focused {
        @apply ring-2 ring-inset ring-blue-500;
      }
    }

    .label {
      @apply truncate text-gray-400 flex-none;
    }

    .input {
      @apply min-w-0 grow shrink bg-transparent ml-2;

      button {
        @apply px-1 rounded;

        &:hover {
          @apply bg-gray-200;
        }
      }
    }

    &.has-inner-border {
      .input {
        @apply p-1 rounded;
      }
    }

    &.has-inner-border:not(.readonly):not(.focused) {
      .input:hover {
        @apply ring-1 ring-inset ring-gray-300;
      }
    }

    &.has-inner-border.focused {
      .input {
        @apply ring-2 ring-inset ring-blue-500;
      }
    }
  }
</style>
