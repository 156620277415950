function formatDateTime(utc) {
  if (!utc) return "";
  const d = new Date(utc);
  return `${d.toLocaleDateString()} at ${d.toLocaleTimeString([], {
    hour: "numeric",
    minute: "2-digit",
  })}`;
}

export default formatDateTime;
