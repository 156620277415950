<script>
  import mime from "mime";
  import { createEventDispatcher } from "svelte";
  import { downloadBlob } from "downloaders";
  import CirclePlusIcon from "src/assets/icons/circle-plus.svg";
  import CircleXIcon from "src/assets/icons/circle-x-filled.svg";
  import PdfIcon from "src/assets/icons/file-pdf.svg";
  import Dropzone from "src/lib/dropzone/Dropzone.svelte";
  import { Modal } from "svelte-utilities";
  import ErrorMessage from "../standard-inputs/ErrorMessage.svelte";
  import fileExtension from "@local/extensions/utilities/extension";
  import { api } from "src/api";

  export let quote;
  export let editable = false;
  export let labelWidth = null;
  export let labelStyle = null;

  const dispatch = createEventDispatcher();

  let dropzone;
  let dropzoneModal;
  let errorMsg;

  $: lw = labelWidth ? `width:${labelWidth};` : "";
  $: lStyle = `${lw} ${labelStyle || ""}`;

  async function uploadFiles(evt) {
    const { file, data } = evt.detail;
    const extension = fileExtension(file.name);
    const content_type = mime.getType(extension);

    const attachment = {
      name: file.name,
      quote_id: quote?.id,
      id: crypto.randomUUID(),
      extension,
      uploaded_at: new Date(),
      content_type,
      data,
    };

    dispatch("add-attachment", { attachment });
    dropzone.stopLoading();
    dropzoneModal.close();
  }

  async function download(attachment) {
    try {
      const { data, error } = await api.storage.from("quotes").download(attachment.path);
      if (error) throw error;

      downloadBlob(data, attachment.name);
    } catch (error) {
      console.error(error);
    }
  }
</script>

<div class="flex gap-2 justify-between items-center">
  <div class="flex gap-2 items-center">
    <div style={lStyle}>Attachments</div>
    <div class="flex flex-wrap gap-2">
      {#if quote.data.pending_quote.attachments}
        {#each quote.data.pending_quote.attachments as attachment}
          <button class="item-list-button" on:click={() => download(attachment)}>
            <PdfIcon />
            <div style="max-width:6rem;" class="truncate">{attachment.name}</div>
            {#if editable}
              <button
                class="remove-button"
                on:click|stopPropagation={() => dispatch("remove-attachment", { attachment })}>
                <CircleXIcon />
              </button>
            {/if}
          </button>
        {/each}
      {/if}
    </div>
  </div>
  {#if editable}
    <button class="item-list-button" on:click={() => dropzoneModal.open()}>
      <CirclePlusIcon />
      <div>Attach PDF</div>
    </button>
  {/if}
</div>

<Modal bind:this={dropzoneModal} closeable>
  <div slot="title">Upload Quote</div>
  <div slot="content" class="space-y-2">
    {#if errorMsg}
      <ErrorMessage padding={false} message={errorMsg} on:close={() => (errorMsg = null)} />
    {/if}
    <p class="text-xs">Drag or drop or click to add your quote as a PDF attachment.</p>
    <Dropzone on:drop={uploadFiles} bind:this={dropzone} accept="application/pdf" />
  </div>
</Modal>
