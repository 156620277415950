<!-- <svelte:options strictprops={false} /> -->

<script>
  import { onMount } from "svelte";
  import { api } from "../api";

  import Navbar from "src/lib/Navbar.svelte";
  import ProfileSettings from "src/lib/settings/ProfileSettings.svelte";

  import { profile } from "../stores/auth.js";

  export let profileid;

  let p;

  $: isUser = $profile && profileid === $profile.id;

  onMount(() => {
    api
      .from("profiles")
      .select("*, organization: organization_id (name, org_type)")
      .eq("id", profileid)
      .single()
      .then(async ({ data, error }) => {
        if (error) {
          console.log(error);
          return;
        }

        p = data;
      });
  });
</script>

<Navbar />
<div class="grow overflow-auto bg-gray-100 p-4">
  {#if $profile && p}
    <ProfileSettings profile={p} {isUser} />
  {/if}
</div>
