<script>
  import { createEventDispatcher } from "svelte";
  import SidebarSection from "./SidebarSection.svelte";
  import TextInput from "./TextInput.svelte";
  import SelectInput from "./SelectInput.svelte";
  import MapboxInput from "./MapboxInput.svelte";
  import ContenteditableInput from "./ContenteditableInput.svelte";

  const dispatch = createEventDispatcher();

  export let disabled = false;
  export let group;

  function updateProp(prop, value) {
    group.updateJobProp(prop, value);
  }
</script>

<SidebarSection>
  <TextInput
    {disabled}
    label="Name"
    labelWidth="5rem"
    value={$group.job.name}
    on:input={(e) => updateProp("name", e.detail.value)} />
  <MapboxInput
    {disabled}
    label="Location"
    labelWidth="5rem"
    value={$group.job.location}
    coordinates={$group.job.location_coordinates}
    on:input={(e) =>
      updateProp({
        location: e.detail.value,
        location_coordinates: e.detail.coordinates,
        location_postal_code: e.detail.postal_code,
        location_country_code: e.detail.country_code,
      })} />
  <ContenteditableInput
    {disabled}
    label="Notes"
    labelWidth="5rem"
    value={$group.job.notes}
    on:input={(e) => updateProp("notes", e.detail.value)} />
</SidebarSection>
