<script lang="ts">
  export let href: string | null | undefined = null;
  export let delay = 0;
  export let auto = true;

  function validRedirect(href: string | null | undefined) {
    if (typeof href === "string" && href.startsWith("https://")) {
      // No insecure redirects
      try {
        const currentOrigin = new URL(window.location.href).origin;
        const redirectOrigin = new URL(href).origin;
        return currentOrigin !== redirectOrigin;
      } catch (err) {
        console.warn("[RootRedirect] Not redirecting due to error: %o", err);
      }
    }
    return false;
  }

  $: shouldRedirect = validRedirect(href);
</script>

<svelte:head>
  {#if auto && shouldRedirect}
    <meta http-equiv="refresh" content={`${delay}; url=${href}`} />
  {/if}
</svelte:head>

{#if shouldRedirect}
  <div class="absolute w-screen flex justify-center">
    <div class="m-5">
      If you are not redirected automatically, follow <a class="text-blue 500 underline" {href}>this link</a>.
    </div>
  </div>
{:else}
  <slot />
{/if}
