<script>
  import get from "lodash/get";
  import mime from "mime";
  import { loadImage } from "isomorphs/canvas";

  import Dropzone from "src/lib/dropzone/Dropzone.svelte";
  import { Modal } from "svelte-utilities";
  import ErrorMessage from "src/lib/standard-inputs/ErrorMessage.svelte";
  import DownloadIcon from "src/assets/icons/download.svg";
  import UploadIcon from "src/assets/icons/upload.svg";
  import XIcon from "src/assets/icons/x.svg";
  import { downloadBlob } from "downloaders";
  import { api, storeObject, drawingSrc } from "src/api";
  import sanitizePgString from "src/extensions/sanitize-postgrest-string";
  import fileExtension from "@local/extensions/utilities/extension";

  export let group;
  export let path;
  export let recordName = "item";

  let errorMsg = null;

  $: drawing = get($group, path);
  $: src = getImageThumbnail(drawing);
  $: imageHeight = getImageHeight(drawing);

  let dropzoneModal;
  let dropzone;
  let removeModal;

  function getImageThumbnail(drawing) {
    if (!drawing) return "";
    return drawingSrc(drawing, { width: 256, height: 256 });
  }

  function getImageHeight(drawing) {
    if (!drawing?.properties) return 15;
    const ar = drawing.properties.width / drawing.properties.height;
    return 18 / ar;
  }

  function upload() {
    dropzoneModal.open();
  }

  async function downloadDrawing() {
    try {
      const file = drawing.object_id;
      const { data, error } = await api.storage.from("drawings").download(file);
      if (error) throw error;

      downloadBlob(data, drawing.name);
    } catch (error) {
      console.log(error);
    }
  }

  function remove() {
    removeModal.open();
  }

  async function removeDrawing() {
    await group.updateProp(path, null);
  }

  async function uploadFiles(evt) {
    const { file, data } = evt.detail;
    const extension = fileExtension(file.name);
    const name = sanitizePgString(file.name);

    const id = crypto.randomUUID();
    const original = `${id}/doc.${extension}`;
    const contentType = mime.getType(extension);

    try {
      const {
        path: p,
        sidecarPath,
        sidecarProperties: sp,
        error,
      } = await storeObject($group.id, "drawings", original, contentType, data);

      if (error) throw error;

      if (sp) {
        await group.updateProp(path, {
          id,
          type: "image",
          preview_object_id: sidecarPath,
          object_id: p,
          name,
          properties: sp,
          extension,
        });
      } else {
        const blob = new Blob([data], { type: contentType });
        const url = URL.createObjectURL(blob);
        const img = await loadImage(url);
        const properties = {
          width: img.width,
          height: img.height,
        };

        await group.updateProp(path, {
          id,
          type: "image",
          preview_object_id: p,
          object_id: p,
          name,
          properties,
          extension,
        });
      }

      dropzone.stopLoading();
      dropzoneModal.close();
    } catch (e) {
      errorMsg = "There was an error uploading this drawing.";
      console.log(e);
      dropzone.stopLoading();
    }
  }
</script>

<div class="px-6 text-xs">
  <h3 class="mb-2">Upload Drawing</h3>
  {#if drawing}
    <div class="space-y-1">
      <div class="w-full border">
        <img class="object-contain w-full" {src} alt="Drawing Thumbnail" />
      </div>
      <div class="flex items-center justify-between my-2">
        <div class="flex items-center cursor-pointer gap-1 w-full" on:click={downloadDrawing}>
          <div class="text-blue-500 truncate">
            {drawing.name}
          </div>
          <div>
            <DownloadIcon />
          </div>
        </div>
        <button on:click={remove}>
          <XIcon />
        </button>
      </div>
    </div>
  {:else}
    <div class="flex items-center justify-between my-2">
      <div class="text-gray-400">Upload PDF or Image</div>
      <button on:click={upload}>
        <UploadIcon />
      </button>
    </div>
  {/if}
</div>

<Modal bind:this={dropzoneModal} closeable>
  <div slot="title">Upload Drawing</div>
  <div slot="content" class="space-y-2">
    {#if errorMsg}
      <ErrorMessage padding={false} message={errorMsg} on:close={() => (errorMsg = null)} />
    {/if}
    <p class="text-xs">
      Any of the following file types can be used to document this {recordName}'s shape: PDF, PNG, TIFF, GIF,
      or JPG.
    </p>
    <Dropzone
      on:drop={uploadFiles}
      bind:this={dropzone}
      accept="image/png, image/jpeg, image/tiff, image/gif, application/pdf" />
  </div>
</Modal>

<Modal
  bind:this={removeModal}
  on:confirm={removeDrawing}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Delete", type: "confirm", style: "danger" },
  ]}
  closeOnOutclick>
  <div slot="title">Delete Drawing</div>
  <div slot="content" class="space-y-2">
    <p>Are you sure you want to delete this drawing?</p>
    <p>
      {drawing?.name}
    </p>
  </div>
</Modal>
