export class PendingQueue {
  constructor(updateFunc) {
    this.updateFunc = updateFunc;
    this.pending = [];
    this.tempQueue = [];
    this.timer = null;
    this.flushing = false;
  }

  async flush() {
    this.flushing = true;

    const uList = await Promise.all(this.pending);

    // Only update if promise resolves to a function
    const updates = uList.filter((u) => u);

    // Run collected synchronous updates
    if (updates.length > 0) {
      this.updateFunc((n) => {
        updates.forEach((update) => {
          update(n);
        });
        return n;
      });
    }

    this.pending = [];
    this.flushing = false;
  }

  add(job) {
    this.pending.push(job);
    if (!this.flushing) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.flush();
      }, 1000);
    }
  }
}
