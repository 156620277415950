import saveFile from "./save-file.js";

/**
 *
 * @param {Blob | MediaSource} blob
 * @param {string} name
 */
function downloadBlob(blob, name = "file.txt") {
  const blobUrl = URL.createObjectURL(blob);
  saveFile(blobUrl, name);
}

export default downloadBlob;
