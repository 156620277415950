<script>
  import { createEventDispatcher } from "svelte";
  import EditIcon from "src/assets/icons/edit.svg";
  import { Modal } from "svelte-utilities";

  export let label;
  export let disabled = false;
  export let border = false;
  export let labelWidth = null;
  export let value;

  const dispatch = createEventDispatcher();

  let focused = false;
  let modal;
  let editedValue = JSON.stringify(value, null, 2);

  $: lw = labelWidth ? `width:${labelWidth};` : "";

  function focus() {
    if (disabled) return;
    focused = true;
  }

  function click() {
    focus();
    modal.open();
  }

  function blur() {
    focused = false;
  }

  function updateValue() {
    dispatch("input", { value: JSON.parse(editedValue) });
  }
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  class="prop-container"
  class:focused
  class:readonly={disabled}
  class:visible-border={border}
  on:click|stopPropagation={focus}>
  <div class="label" style={lw}>
    <slot>
      {label}
    </slot>
  </div>
  <button
    class="grow relative outline-none overflow-hidden"
    on:click={click}
    on:focus={focus}
    on:blur={blur}
    {disabled}>
    {#if !value}
      <div>&nbsp;</div>
    {:else}
      <div class="text-xs font-mono whitespace-pre text-left overflow-hidden">
        {JSON.stringify(value)}
      </div>
    {/if}

    {#if !disabled}
      <div class="absolute top-0 right-0 bg-white">
        <EditIcon />
      </div>
    {/if}
  </button>
</div>

<Modal
  bind:this={modal}
  closeable
  on:confirm={updateValue}
  width="32rem"
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Update", type: "confirm", style: "danger" },
  ]}>
  <div slot="title">Update {label}</div>
  <div slot="content">
    <textarea
      bind:value={editedValue}
      class="w-full border rounded p-2 font-mono"
      placeholder="Edit Template Data"
      rows="10" />
  </div>
</Modal>

<style lang="scss">
  .prop-container {
    @apply px-2 py-2 w-full flex items-center rounded bg-white overflow-hidden;

    &:hover:not(.readonly):not(.focused),
    &.visible-border {
      @apply ring-1 ring-inset ring-gray-300;
    }

    &.focused {
      @apply ring-2 ring-inset ring-blue-500;
    }

    .label {
      @apply truncate text-gray-400 flex-none;
    }
  }
</style>
