import { loadImage } from "isomorphs/canvas";

export default async function imgClipToBlob(url, clip) {
  try {
    const { x, y, width, height } = clip;
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
    const img = await loadImage(url);
    if (ctx === null) return null;
    ctx.drawImage(img, -x, -y, img.width, img.height);
    const blobPromise = new Promise((res) => {
      canvas.toBlob((blob) => res(blob));
    });

    const blob = await blobPromise;
    return blob;
  } catch (err) {
    console.log(err);
    return null;
  }
}
