<script>
  import cloneDeep from "lodash/cloneDeep";

  import LayerWidget from "./LayerWidget.svelte";

  import { draggable } from "svelte-utilities";
  import { clickOutside, Popover } from "svelte-utilities";

  import TrashIcon from "src/assets/icons/trash.svg";
  import GripIcon from "src/assets/icons/grip.svg";

  import {
    createGlass,
    createInterlayer,
    createSpacer,
    createGenericLayer,
    typeSurfaces,
  } from "@local/lamina-core";

  export let group;
  export let type = {};
  export let disabled = false;
  export let editMakeup = false;

  let dragging = null;
  let isDragging = false;
  let showNewLayerMenu = false;
  let newLayerButton;
  let popover;

  $: settings = $group.data.settings;
  $: surfaceNumbers = type?.data && typeSurfaces(type);
  $: recordName = editMakeup ? "makeup" : "type";

  function updateLayer(index, prop, value) {
    group.update([{ type: recordName, id: type.id, prop: `data.layers.${index}.${prop}`, value }]);
  }

  function deleteLayer(index) {
    const data = cloneDeep(type.data);
    data.layers.splice(index, 1);
    group.update([{ type: recordName, id: type.id, prop: "data", value: data }]);
  }

  function addLayer(layerType) {
    const data = cloneDeep(type.data);

    if (layerType === "glass") {
      data.layers.push(createGlass());
    } else if (layerType === "spacer") {
      data.layers.push(createSpacer());
    } else if (layerType === "interlayer") {
      data.layers.push(createInterlayer());
    } else if (layerType === "generic") {
      data.layers.push(createGenericLayer());
    }

    group.update([{ type: recordName, id: type.id, prop: "data", value: data }]);
    showNewLayerMenu = false;
  }

  function drag(index) {
    dragging = index;
    isDragging = true;
  }

  function dragend() {
    dragging = null;
    isDragging = false;
  }

  function drop(dropIndex) {
    if (!isDragging) return;
    if (dragging === dropIndex || dropIndex === dragging - 1) return;

    const insertion = dropIndex < dragging ? dropIndex + 1 : dropIndex;
    const data = cloneDeep(type.data);
    const moved = data.layers.splice(dragging, 1)[0];
    data.layers.splice(insertion, 0, moved);
    group.update([{ type: recordName, id: type.id, prop: "data", value: data }]);
  }
</script>

<div class="text-xs">
  <h3 class="px-6 mb-2">Makeup</h3>
  <div class="space-y-2">
    {#each type.data?.layers as layer, index}
      <div class="layer-container" class:dragging={dragging === index} class:is-dragging={isDragging}>
        {#if index === 0}
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <div class="drop-target top-target" class:visible={isDragging} on:mouseup={() => drop(-1)}>
            <div class="drop-target-line" />
          </div>
        {/if}
        <div class="layer-contents flex items-center">
          <button
            class="flex-none grip-icon p-1 h-8 w-8"
            class:disabled={disabled || type.product_id}
            use:draggable
            on:drag={() => !disabled && drag(index)}
            on:dragend={dragend}>
            <div class="button">
              <GripIcon />
            </div>
          </button>
          <div class="grow space-y-1 input-container overflow-hidden">
            <LayerWidget
              record={type}
              {index}
              {group}
              {layer}
              {disabled}
              {settings}
              {editMakeup}
              obSurface={surfaceNumbers[index]}
              dragging={dragging === index}
              on:update={(evt) => updateLayer(index, evt.detail.prop, evt.detail.value)} />
          </div>
          <button
            class="flex-none trash-icon p-1 h-8 w-8"
            class:disabled={disabled || type.product_id}
            on:click={() => !disabled && deleteLayer(index)}>
            <div class="button">
              <TrashIcon />
            </div>
          </button>
        </div>
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <div class="drop-target" class:visible={isDragging} on:mouseup={() => drop(index)}>
          <div class="drop-target-line" />
        </div>
      </div>
    {/each}
  </div>
  {#if !disabled && !type.product_id}
    <div class="text-right flex justify-end relative mx-8 mt-2">
      <button
        on:click={() => (showNewLayerMenu = !showNewLayerMenu)}
        bind:this={newLayerButton}
        class="py-1 px-2 flex items-center gap-1 cursor-pointer font-bold hover:bg-gray-200 rounded">
        <div>+</div>
        <div>New Element</div>
      </button>

      {#if showNewLayerMenu}
        <Popover container={newLayerButton} fit>
          <div
            class="p-2 text-xs text-black right-0"
            bind:this={popover}
            use:clickOutside={[newLayerButton]}
            on:outclick={() => (showNewLayerMenu = false)}>
            <button class="dropdown-button-item" on:click={() => addLayer("glass")}> Glass Lite </button>
            <button class="dropdown-button-item" on:click={() => addLayer("spacer")}> Spacer </button>
            <button class="dropdown-button-item" on:click={() => addLayer("interlayer")}> Interlayer </button>
            <button class="dropdown-button-item" on:click={() => addLayer("generic")}> Generic Layer </button>
          </div>
        </Popover>
      {/if}
    </div>
  {/if}
</div>

<style lang="scss">
  .button {
    @apply cursor-pointer rounded p-1;

    &:hover {
      @apply bg-gray-200;
    }
  }

  .grip-icon,
  .trash-icon {
    @apply text-gray-600 w-8 flex flex-col items-center;
    visibility: hidden;
  }

  .layer-container {
    @apply relative border border-transparent;
  }

  .layer-container:hover:not(.is-dragging) {
    .grip-icon:not(.disabled),
    .trash-icon:not(.disabled) {
      visibility: visible;
    }
  }

  .input-container {
    @apply bg-white shadow-lg rounded-md border border-gray-200;
  }
  .layer-container.dragging {
    .input-container {
      @apply bg-transparent border border-dashed border-gray-400;
    }
  }

  .drop-target {
    @apply absolute w-full h-full z-30 pointer-events-none;
    bottom: calc(-50% - 0.25rem);

    &.top-target {
      top: calc(-50% - 0.25rem);
    }

    &.visible {
      pointer-events: auto;

      &:hover {
        .drop-target-line {
          @apply w-full border-b border-gray-300 absolute;
          top: 50%;
        }
      }
    }
  }
</style>
