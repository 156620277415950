import { sortFunctions } from "../utilities/sort.js";

function orderCategory(
  groupBy,
  groupedItems,
  types,
  collections,
  product_categories,
  showUnused = false,
  direction = "asc",
) {
  const present = Object.keys(groupedItems).filter((key) => key !== "untyped");
  let sorted;

  if (groupBy === "category") {
    sorted = present.sort((a, b) => {
      const acat = product_categories?.[a];
      const bcat = product_categories?.[b];
      if (acat && bcat) {
        return sortFunctions.timestamp(acat.created_at, bcat.created_at);
      }
      return sortFunctions.alphabetic(a, b);
    });
  } else if (groupBy === "type_id") {
    const t = showUnused ? types : types.filter((t) => present.includes(t.id));
    sorted = t.map((t) => t.id);
  } else if (groupBy === "collection_id") {
    const c = showUnused
      ? collections
      : collections.filter((c) => present.includes(c.id));
    sorted = c.map((c) => c.id);
  } else if (["width", "height"].includes(groupBy)) {
    sorted = present.sort(sortFunctions.alphabetic);
  } else if (sortFunctions[groupBy]) {
    sorted = present.sort(sortFunctions[groupBy]);
  } else {
    // TODO: have typed custom columns that can sort differently depending on type
    sorted = present.sort(sortFunctions.alphabetic);
  }

  if (direction === "asc") {
    return sorted;
  } else {
    return sorted.reverse();
  }
}

export default orderCategory;
