<script>
  export let value;
  export let max;

  $: left = `${(value / max) * 100 - 100}%`;
</script>

<div class="w-full h-2 bg-gray-300 overflow-hidden relative">
  <div class="bg-blue-500 w-full h-2 absolute top-0" style:left />
</div>
