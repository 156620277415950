<script>
  import { getContext } from "svelte";
  import isEqual from "lodash/isEqual";
  import get from "lodash/get";
  import { draggable } from "svelte-utilities";
  import SummaryTableType from "./SummaryTableType.svelte";
  import { makeColumns } from "@local/lamina-core";
  import { clickOutside } from "svelte-utilities";
  import { orderedList } from "@local/extensions/collections/sortable-list.js";
  import { edgeTreatmentColumn } from "@local/extensions/formatters/edge-treatment.js";
  import { shapeIdentColumn } from "@local/extensions/formatters/shape-ident";
  import groupItems from "@local/extensions/collections/group-items.js";
  import copyToClipboard from "src/extensions/copy-to-clipboard.js";

  export let org;
  export let group;
  export let items;
  export let types;
  export let attachments;
  export let sent = false;
  export let center = true;
  export let paddingTop = null;

  const edgeTreatments = getContext("edgeTreatments");

  let table;
  let selection = [];
  let currentCell = null;
  let clickingTable = false;
  let left = false;

  function transform(coll, xf, defaultValue = []) {
    return coll ? xf(coll) : defaultValue;
  }

  const mod = /Mac|iPod|iPhone|iPad/.test(navigator.platform) ? "metaKey" : "ctrlKey";

  $: pt = paddingTop ? `padding-top: ${paddingTop};` : "";
  $: productGroup = $group?.project_type === "product";
  $: grouped = groupItems(items);
  $: ordered = getOrderedItems(grouped, types, productGroup);
  $: rowIndices = getRowIndices(ordered);
  $: orgColumns = transform($org?.data?.custom_columns, orderedList);
  $: jobColumns = transform($group?.data?.custom_columns, orderedList);
  $: standardColumns = $org?.data?.standard_columns || {};
  $: extraColumns = [shapeIdentColumn, edgeTreatmentColumn($edgeTreatments)].filter((c) =>
    c.displayFilter(items),
  );
  $: columns = formatColumns($group, types, orgColumns, jobColumns, standardColumns);
  $: selected = updateSelected(selection);
  $: linkPrefix = sent ? `/versions/${$group?.id}` : `/jobs/${$group?.job_id}/current`;

  function updateSelected(selection) {
    const sel = {};
    selection.forEach((s) => {
      for (let row = s[0]; row <= s[2]; row++) {
        for (let col = s[1]; col <= s[3]; col++) {
          sel[`${row}.${col}`] = true;
        }
      }
    });

    return sel;
  }

  function formatColumns(g, t, oc, jc, sc) {
    const cols = makeColumns(g, t, true, oc, jc, sc, extraColumns);
    return cols.map((col) => {
      if (col.type === "multi-column") {
        const { subcolumns, ...c } = col;

        return {
          ...c,
          ...subcolumns[c.result],
          label: c.label || subcolumns[c.result].label,
        };
      }

      return col;
    });
  }

  function getOrderedItems(grouped, types, productGroup) {
    const o = [];
    let index = 0;

    const t = productGroup ? types.filter((t) => grouped[t.id]?.length) : types;
    t.forEach((type) => {
      const items = grouped[type.id] || [];

      o.push({
        type,
        items,
        index,
      });

      index += items.length;
    });

    if (grouped.untyped.length) {
      o.push({
        type: null,
        items: grouped.untyped,
        index,
      });
    }

    return o;
  }

  function getRowIndices(ordered) {
    const indices = [];

    ordered.forEach((o, g) => {
      o.items.forEach((item, i) => {
        indices.push([g, i]);
      });
    });

    return indices;
  }

  function getEventTd(e) {
    try {
      const td = e.target.closest("td");
      if (!td) return null;
      if (!table.contains(td)) return null;

      const row = parseInt(td.getAttribute("data-row"));
      const col = parseInt(td.getAttribute("data-column"));
      const typeIndex = parseInt(td.getAttribute("data-type-index"));
      const typeRow = parseInt(td.getAttribute("data-type-row"));

      if (isNaN(row) || isNaN(col)) return null;

      return {
        el: td,
        row,
        col,
        typeIndex,
        typeRow,
      };
    } catch {
      return null;
    }
  }

  function select(td) {
    currentCell = { row: td.row, col: td.col };
    selection = [[td.row, td.col, td.row, td.col]];
  }

  function dragSelect(td) {
    if (currentCell) {
      const minR = Math.min(currentCell.row, td.row);
      const minC = Math.min(currentCell.col, td.col);
      const maxR = Math.max(currentCell.row, td.row);
      const maxC = Math.max(currentCell.col, td.col);
      selection[selection.length - 1] = [minR, minC, maxR, maxC];
    } else {
      select(td);
    }
  }

  function metaSelect(td) {
    currentCell = { row: td.row, col: td.col };
    const n = [td.row, td.col, td.row, td.col];

    if (selection.some((s) => isEqual(s, n))) {
      const index = selection.findIndex((s) => isEqual(s, n));
      selection.splice(index, 1);
    }

    selection.push(n);
  }

  function getEventTh(e) {
    try {
      const th = e.target.closest("th");
      if (!th) return null;
      if (!table.contains(th)) return null;

      const col = parseInt(th.getAttribute("data-column"));
      const row = parseInt(th.getAttribute("data-row"));
      const typeIndex = parseInt(th.getAttribute("data-type-index"));
      const typeRow = parseInt(th.getAttribute("data-type-row"));

      if (isNaN(col) && isNaN(row)) return null;

      // Row header
      if (isNaN(col)) {
        return {
          el: th,
          row,
          typeIndex,
          typeRow,
        };
      }

      // Column header
      return {
        el: th,
        col,
        typeIndex,
      };
    } catch {
      return null;
    }
  }

  function dragstart(e) {
    clickingTable = true;
    table.focus({ preventScroll: true });
    const td = getEventTd(e.detail.event);
    if (!td) return;

    if (e.detail.event.shiftKey) {
      dragSelect(td);
    } else if (e.detail.event.metaKey) {
      metaSelect(td);
    } else {
      select(td);
    }
  }

  function drag(e) {
    const td = getEventTd(e.detail.event);
    if (!td) return;

    dragSelect(td);
  }

  function dragend(e) {
    clickingTable = false;
    const td = getEventTd(e.detail.event);
    if (!td) return;

    dragSelect(td);
  }

  function deselect() {
    currentCell = null;
    selection = [];
  }

  function copy(e) {
    // Ignoring all but the last selection
    // TODO: support multiple selections
    const sel = selection[selection.length - 1];
    if (!sel) return;

    const result = [];

    for (let r = sel[0]; r <= sel[2]; r++) {
      const row = [];
      for (let c = sel[1]; c <= sel[3]; c++) {
        const [g, i] = rowIndices[r];
        const item = ordered[g].items[i];
        const col = columns[c];
        let value = get(item, col.prop);

        if (col.type === "select") {
          value = col.optionMap[value];
        } else if (col.formatter) {
          value = col.formatter(value);
        }

        row.push(value);
      }
      result.push(row);
    }

    const dest = result.map((row) => row.join("\t")).join("\n");
    copyToClipboard(dest);
  }

  function keydown(e) {
    if (e.key === "c" && e[mod]) {
      copy(e);
    }
  }

  function mouseleave(e) {
    if (clickingTable) {
      left = true;
    }
  }

  function mouseenter(e) {
    left = false;
  }

  function outclick(e) {
    if (!left) {
      deselect();
    }
    left = false;
  }
</script>

<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<div class="px-4 text-xs h-full overflow-x-auto overflow-y-auto" style={pt}>
  <table
    id="summary-table"
    class="outline-none"
    class:center
    bind:this={table}
    use:draggable
    use:clickOutside={[table]}
    tabindex="0"
    on:keydown={keydown}
    on:dragstart={dragstart}
    on:drag={drag}
    on:dragend={dragend}
    on:outclick={outclick}
    on:mouseleave={mouseleave}
    on:mouseenter={mouseenter}>
    {#each ordered as t, typeIndex}
      <SummaryTableType
        {group}
        type={t.type}
        items={t.items}
        {columns}
        {selected}
        {typeIndex}
        {linkPrefix}
        index={t.index}
        {attachments}
        {currentCell} />
    {/each}
  </table>
</div>

<style lang="scss">
  table {
    min-width: 36rem;
    &.center {
      margin: 0 auto;
    }
  }
</style>
