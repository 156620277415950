/**
 *
 * @param {string} href
 * @param {string} name
 */
function saveFile(href, name) {
  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  link.href = href;
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  // Remove link from body
  document.body.removeChild(link);
}

export default saveFile;
