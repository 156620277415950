import saveFile from "./save-file.js";

/**
 *
 * @param {string} str
 * @param {string} name
 */
function downloadString(str, name) {
  const nameComponents = name.split(".");
  const ext = nameComponents[nameComponents.length - 1];

  const type = {
    json: "text/json",
    txt: "text",
    csv: "text/csv",
  }[ext];

  const dataStr = `data:${type};charset=utf-8,` + encodeURIComponent(str);

  saveFile(dataStr, name);
}

export default downloadString;
