<script>
  import { createEventDispatcher, onMount } from "svelte";
  import isEmpty from "lodash/isEmpty";

  import { Modal } from "svelte-utilities";
  import TextInput from "../sidebar/TextInput.svelte";
  import ContenteditableInput from "src/lib/sidebar/ContenteditableInput.svelte";
  import AttachmentsInput from "./AttachmentsInput.svelte";
  import parseCurrency from "@local/extensions/parsers/parse-currency.js";
  import formatCurrency from "@local/extensions/formatters/format-currency.js";
  import eb from "src/extensions/event-bus.js";

  export let quote;
  export let items;
  export let types;
  export const open = () => openModal();

  const dispatch = createEventDispatcher();

  let modal;
  let showErrorMsg = false;

  $: sendDisabled = isSendDisabled(quote);
  $: units = items.reduce((q, i) => q + i.quantity, 0);

  function openModal() {
    modal.open();
  }

  function isSendDisabled(quote) {
    if (!quote) return true;
    if (isEmpty(quote.data.pending_quote)) return true;

    if (["quote", "order"].includes(quote.quote_request.request_for)) {
      return !quote.data.pending_quote.price && !quote.data.pending_quote.attachments.length;
    }

    return (
      !quote.data.pending_quote.price &&
      !quote.data.pending_quote.attachments.length &&
      !quote.data.pending_quote.notes
    );
  }

  function sendQuote() {
    showErrorMsg = false;
    if (sendDisabled) {
      showErrorMsg = true;
      return;
    } else {
      dispatch("send", quote);
      modal.close();
    }
  }

  onMount(() => {
    eb.on("open-quote-modal", openModal);

    return () => eb.unsubscribe("open-quote-modal", openModal);
  });
</script>

<Modal
  bind:this={modal}
  closeable
  width="36rem"
  on:confirm={sendQuote}
  buttons={[
    { label: "Cancel", type: "cancel" },
    {
      label: quote.sent_at ? "Send Revision" : "Send",
      type: "confirm",
      style: "primary",
      closeOnClick: false,
    },
  ]}>
  <div slot="title">
    {#if quote.sent_at}
      Revise Response
    {:else}
      Send Response
    {/if}
  </div>
  <div slot="content" class="space-y-4 text-sm">
    <div class="flex">
      <div class="flex-none w-24 text-gray-500">Request:</div>
      <div class="text-black">
        <div class="font-bold">{quote.job.name}</div>
        <div class="text-xs">{quote.quote_request.organization.name}</div>
        <div class="text-xs">{units} units, {types.length} types</div>
        <div class="text-xs">For {quote.quote_request.request_for}</div>
      </div>
    </div>
    {#if showErrorMsg}
      {#if ["order", "quote"].includes(quote.quote_request.request_for)}
        <div class="text-red-500">Response must include either a price or an attachment.</div>
      {:else}
        <div class="text-red-500">Response must include content.</div>
      {/if}
    {/if}
    <div class="flex">
      <div class="flex-none w-24 text-gray-500">Response:</div>
      <div class="grow space-y-2 text-xs">
        <div class="flex items-center gap-2">
          <div class="flex-1">
            <TextInput
              border
              label="Price"
              value={quote.data.pending_quote.price}
              formatter={formatCurrency}
              parser={parseCurrency}
              on:input={(e) => dispatch("update-pending", { price: e.detail.value })} />
          </div>
          <div class="flex-1">
            <TextInput
              border
              label="Days Valid"
              value={quote.data.pending_quote.days_valid || ""}
              on:input={(e) => dispatch("update-pending", { days_valid: e.detail.value })} />
          </div>
        </div>
        <ContenteditableInput
          border
          label="Notes"
          value={quote.data.pending_quote.notes}
          on:input={(e) => dispatch("update-pending", { notes: e.detail.value })} />
        <AttachmentsInput labelWidth="5rem" editable {quote} on:add-attachment on:remove-attachment />
      </div>
    </div>
  </div>
</Modal>
