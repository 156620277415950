import { writable } from "svelte/store";
import lset from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import { Revision } from "./revision.js";
import { PendingQueue } from "./pending-queue.js";
import { profile } from "./auth.js";
import {
  hydrateItem,
  hydrateType,
  hydrateGroup,
  hydrateGroupData,
} from "@local/lamina-core";
import {
  addToSortableList,
  removeFromSortableList,
  sortList,
} from "@local/extensions/collections/sortable-list.js";
import {
  api,
  updateProp,
  addRecords,
  removeRecords,
  removeItems,
  getGroupFromLink,
} from "src/api";
import { updateCommit } from "./update-commit.js";

let $profile;
profile.subscribe((value) => {
  $profile = value;
});

function removeNonDbProps(item) {
  const { approver, updater, cache, ...rest } = item;
  return rest;
}

async function fetchGroup(group_id) {
  const { data, error } = await api
    .from("groups")
    .select(
      `
        *,
        job: job_id(*,quote_requests!quote_requests_job_id_fkey(*),quotes!quotes_job_id_fkey(*)),
        organization: organization_id(*,profiles!profiles_organization_id_fkey(*)),
        items!items_group_id_fkey(*,approver: approval_status_updated_by(*), updater: updated_by_user(*)),
        types!types_group_id_fkey(*,approver: approval_status_updated_by(*), updater: updated_by_user(*)),
        comments!comments_group_id_fkey(*,commenter: user_id(*)),
        attachments!attachments_group_id_fkey(*),
        approver: approval_status_updated_by(*),
        updater: updated_by_user(*)
        `,
    )
    .eq("id", group_id)
    .order("created_at", { foreignTable: "items" })
    .order("created_at", { foreignTable: "types" })
    .order("created_at", { foreignTable: "comments" })
    .order("created_at", { foreignTable: "attachments" })
    .maybeSingle();
  if (error) throw error;

  return data;
}

async function fetchGroupFromLink(link_id) {
  return await getGroupFromLink({ link_id });
}

function createGroup(group) {
  const revision = new Revision();

  const g = hydrateGroup(group);

  const { subscribe, set, update } = writable(g);

  let groupValue;
  subscribe((value) => {
    groupValue = value;
  });

  const queue = new PendingQueue(update);

  const groupUpdate = async (payload) => {
    // We need related approver/updater records
    const { data: updated, error } = await api
      .from("groups")
      .select(
        "*,approver: approval_status_updated_by(*), updater: updated_by_user(*)",
      )
      .eq("id", payload.new.id)
      .single();

    if (error) return;
    if (!updated) return;

    const commitId = updated.commit_id;

    if (revision.isPending(commitId)) {
      // update timestamps
      update((n) => {
        n.updated_at = updated.updated_at;
        n.updater = updated.updater;
        n.approval_status_updated_at = updated.approval_status_updated_at;
        n.approver = updated.approver;
        return n;
      });

      revision.resolve(commitId, updated.id);
      return;
    } else {
      // is this an update due to deletion?
    }

    // Commit is not from this client, update should be merged
    update((n) => {
      Object.entries(updated).forEach(([prop, value]) => {
        if (prop === "data") {
          hydrateGroupData(value);
        }

        if (n[prop] !== value) n[prop] = value;
      });

      return n;
    });
  };

  // Subscribe to any updates to items that belong to this group
  const itemUpdate = async (payload) => {
    // We don't get a commitId to resolve for deletes, so in that case
    // we will have to search pending delete updates for the presence of
    // this id.
    if (payload.eventType === "DELETE") {
      const deletedId = payload.old.id;
      const commitId = revision.deleteCommitId(deletedId);

      if (commitId) {
        revision.resolve(commitId, deletedId);
        return;
      }

      return (n) => {
        removeFromSortableList(n.items, deletedId);
      };
    }

    // We need related approver/updater records
    const { data: updated, error } = await api
      .from("items")
      .select(
        "*, approver: approval_status_updated_by(*), updater: updated_by_user(*)",
      )
      .eq("id", payload.new.id)
      .single();

    if (error) return;

    const commitId = updated.commit_id;

    if (revision.isPending(commitId)) {
      revision.resolve(commitId, updated.id);
      return;
    }

    // Commit is not from this client, update should be merged
    return (n) => {
      if (payload.eventType === "INSERT") {
        addToSortableList(n.items, hydrateItem(updated, n));
      } else if (payload.eventType === "UPDATE") {
        n.items[updated.id] = hydrateItem(updated, n);
      }
    };
  };

  // Subscribe to any updates to items that belong to this group
  const typeUpdate = async (payload) => {
    // We don't get a commitId to resolve for deletes, so in that case
    // we will have to search pending delete updates for the presence of
    // this id.
    if (payload.eventType === "DELETE") {
      const deletedId = payload.old.id;
      const commitId = revision.deleteCommitId(deletedId);

      if (commitId) {
        revision.resolve(commitId, deletedId);
        return;
      }

      return (n) => {
        removeFromSortableList(n.types, deletedId);
      };
    }

    // We need related approver/updater records
    const { data: updated, error } = await api
      .from("types")
      .select(
        "*, approver: approval_status_updated_by(*), updater: updated_by_user(*)",
      )
      .eq("id", payload.new.id)
      .single();

    if (error) return;

    const commitId = updated.commit_id;

    if (revision.isPending(commitId)) {
      revision.resolve(commitId, updated.id);
      return;
    }

    // Commit is not from this client, update should be merged
    return (n) => {
      if (payload.eventType === "INSERT") {
        addToSortableList(n.types, hydrateType(updated));
      } else if (payload.eventType === "UPDATE") {
        n.types[updated.id] = hydrateType(updated);
      }
    };
  };

  const attachmentUpdate = async (payload) => {
    if (payload.eventType === "DELETE") {
      const deletedId = payload.old.id;
      const commitId = revision.deleteCommitId(deletedId);

      if (commitId) {
        revision.resolve(commitId, deletedId);
        return;
      }

      return (n) => {
        const index = n.attachments.findIndex((c) => c.id === deletedId);
        if (index !== -1) {
          n.attachments.splice(index, 1);
        }
        return n;
      };
    }

    const updated = payload.new;
    const commitId = updated.commit_id;

    if (revision.isPending(commitId)) {
      revision.resolve(commitId, updated.id);
      return;
    }

    // Commit is not from this client, update should be merged
    return (n) => {
      if (payload.eventType === "INSERT") {
        n.attachments.push(updated);
      } else if (payload.eventType === "UPDATE") {
        const index = n.attachments.findIndex((c) => c.id === updated.id);
        if (index !== -1) {
          n.attachments[index] = updated;
        }
      }

      return n;
    };
  };

  // Subscribe to any updates to this group record
  const groupSubscription = api
    .channel("group-changes")
    .on(
      "postgres_changes",
      {
        event: "UPDATE",
        schema: "public",
        table: "groups",
        filter: `id=eq.${group.id}`,
      },
      groupUpdate,
    )
    .on(
      "postgres_changes",
      {
        event: "DELETE",
        schema: "public",
        table: "items",
        filter: `group_id=eq.${group.id}`,
      },
      (payload) => {
        // TODO: replace this hack with a more robust solution.
        const id = payload.old.id;
        if (groupValue.items[id]) {
          queue.add(itemUpdate(payload));
        }
      },
    )
    .on(
      "postgres_changes",
      {
        event: "UPDATE",
        schema: "public",
        table: "items",
        filter: `group_id=eq.${group.id}`,
      },
      (payload) => {
        queue.add(itemUpdate(payload));
      },
    )
    .on(
      "postgres_changes",
      {
        event: "INSERT",
        schema: "public",
        table: "items",
        filter: `group_id=eq.${group.id}`,
      },
      (payload) => {
        queue.add(itemUpdate(payload));
      },
    )
    .on(
      "postgres_changes",
      {
        event: "DELETE",
        schema: "public",
        table: "types",
        filter: `group_id=eq.${group.id}`,
      },
      (payload) => {
        // TODO: replace this hack with a more robust solution.
        const id = payload.old.id;
        if (groupValue.items[id]) {
          queue.add(typeUpdate(payload));
        }
      },
    )
    .on(
      "postgres_changes",
      {
        event: "UPDATE",
        schema: "public",
        table: "types",
        filter: `group_id=eq.${group.id}`,
      },
      (payload) => {
        queue.add(typeUpdate(payload));
      },
    )
    .on(
      "postgres_changes",
      {
        event: "INSERT",
        schema: "public",
        table: "types",
        filter: `group_id=eq.${group.id}`,
      },
      (payload) => {
        queue.add(typeUpdate(payload));
      },
    )
    .on(
      "postgres_changes",
      {
        event: "UPDATE",
        schema: "public",
        table: "attachments",
        filter: `group_id=eq.${group.id}`,
      },
      (payload) => {
        queue.add(attachmentUpdate(payload));
      },
    )
    .on(
      "postgres_changes",
      {
        event: "INSERT",
        schema: "public",
        table: "attachments",
        filter: `group_id=eq.${group.id}`,
      },
      (payload) => {
        queue.add(attachmentUpdate(payload));
      },
    )
    .subscribe();

  return {
    subscribe,
    set,
    undoable() {
      return revision.undoable;
    },
    redoable() {
      return revision.redoable;
    },
    undo() {
      update((n) => {
        revision.undo(n);
        return n;
      });
    },
    redo() {
      update((n) => {
        revision.redo(n);
        return n;
      });
    },
    destroy() {
      api.channel("group-changes").unsubscribe();
    },
    react() {
      update((n) => n);
    },

    updateJobProp(prop, value) {
      let commit;
      if (typeof prop === "object") {
        commit = (n, commitId) => {
          const oldValue = Object.keys(prop).reduce((o, p) => {
            const path = p.split(".");
            const [col] = path;
            o[col] = n.job[col];
            return o;
          }, {});

          const newValue = Object.keys(prop).reduce((o, p) => {
            const path = p.split(".");
            const [col, ...rest] = path;

            if (col === "data" && rest.length > 0) {
              if (!o.data) o.data = cloneDeep(n.job.data);
              lset(o.data, rest, prop[p]);
            } else {
              o[p] = prop[p];
            }

            return o;
          }, {});

          Object.entries(newValue).forEach(([key, value]) => {
            n.job[key] = value;
          });

          updateProp("jobs", n.job.id, newValue, commitId);

          return (n) => {
            Object.entries(oldValue).forEach(([key, value]) => {
              n.job[key] = value;
            });

            updateProp("jobs", n.job.id, oldValue, commitId);
          };
        };
      } else {
        const path = prop.split(".");
        const [col, ...rest] = path;

        commit = (n, commitId) => {
          let oldValue;
          let newValue;

          if (col === "data" && rest.length > 0) {
            oldValue = n.job.data;
            const data = cloneDeep(n.job.data);
            lset(data, rest, value);
            newValue = data;
          } else {
            oldValue = n.job[prop];
            newValue = value;
          }

          n.job[col] = newValue;

          updateProp("jobs", n.job_id, col, newValue, commitId);

          return (n) => {
            n.job[col] = oldValue;
            updateProp("jobs", n.job_id, col, oldValue, commitId);
          };
        };
      }

      update((n) => {
        revision.commitTransaction(commit, n, "UPDATE", [n.job_id]);
        return n;
      });
    },

    updateProp(prop, value) {
      this.update([{ type: "group", prop, value }]);
    },

    addItem(...items) {
      const ids = items.map((i) => i.id);
      const commit = (n, commitId) => {
        const old_data = cloneDeep(n.data);
        old_data.item_order = [...n.items.order];

        addToSortableList(n.items, ...items.map((i) => hydrateItem(i, n)));
        n.data.item_order = [...n.items.order];
        addRecords(
          "items",
          items.map((i) => removeNonDbProps(i)),
          commitId,
        );
        updateProp("groups", n.id, "data", n.data, commitId);

        return (n) => {
          removeFromSortableList(n.items, ...ids);
          removeItems(ids);
          updateProp("groups", n.id, "data", old_data);
        };
      };

      update((n) => {
        revision.commitTransaction(commit, n, "INSERT", ids);
        return n;
      });
    },

    addItemsWithGroupUpdate(items, diff) {
      const ids = items.map((i) => i.id);
      const { data, ...otherProps } = diff;
      const commit = (n, commitId) => {
        const old_data = cloneDeep(n.data);
        old_data.item_order = [...n.items.order];
        const oldGroup = Object.keys(otherProps).reduce(
          (o, k) => {
            o[k] = n[k];
            return o;
          },
          { data: old_data },
        );

        addToSortableList(n.items, ...items.map((i) => hydrateItem(i, n)));
        n.data.item_order = [...n.items.order];
        if (data) {
          n.data = {
            ...n.data,
            ...data,
          };
        }

        const updatedGroup = {
          ...otherProps,
          data: n.data,
        };

        addRecords(
          "items",
          items.map((i) => removeNonDbProps(i)),
          commitId,
        );

        updateProp("groups", n.id, updatedGroup, commitId);

        return (n) => {
          removeFromSortableList(n.items, ...ids);
          removeItems(ids);
          updateProp("groups", n.id, oldGroup);
        };
      };

      update((n) => {
        revision.commitTransaction(commit, n, "INSERT", ids);
        return n;
      });
    },

    removeItem(...ids) {
      const commit = (n, commitId) => {
        const old_data = cloneDeep(n.data);
        old_data.item_order = [...n.items.order];

        removeFromSortableList(n.items, ...ids);
        n.data.item_order = [...n.items.order];

        const res = {};
        removeItems(ids).then((result) => (res.items = result));

        return (n) => {
          addToSortableList(
            n.items,
            ...res.items.map((i) => hydrateItem(i, n)),
          );
          addRecords(
            "items",
            res.items.map((i) => removeNonDbProps(i)),
            commitId,
          );
          updateProp("groups", n.id, "data", old_data);
        };
      };

      update((n) => {
        revision.commitTransaction(commit, n, "DELETE", ids);
        return n;
      });
    },

    updateItem(id, prop, value, diff) {
      if (prop && typeof prop === "object") {
        this.update([{ type: "item", id, diff: prop }]);
      } else {
        this.update([{ type: "item", id, prop, value, diff }]);
      }
    },

    updateItemOrder(order) {
      const commit = (n, commitId) => {
        const oldOrder = n.types.order;
        const oldData = n.data;

        n.items.order = order;
        const data = cloneDeep(n.data);
        lset(data, "item_order", order);

        updateProp("groups", n.id, "data", data, commitId);

        return (n) => {
          n.items.order = oldOrder;
          n.data = oldData;
          updateProp("groups", n.id, "data", oldData);
        };
      };

      update((n) => {
        revision.commitTransaction(commit, n, "UPDATE", [n.id]);
        return n;
      });
    },

    updateMultipleItems(ids, prop, value, diff) {
      const updates = ids.map((id) => ({
        type: "item",
        id,
        prop,
        value,
        diff,
      }));
      this.update(updates);
    },

    // In the event that we need to update multiple items/types/group in a single transaction
    update(updates) {
      const commit = updateCommit(updates, $profile);

      update((n) => {
        revision.commitTransaction(
          commit,
          n,
          "UPDATE",
          updates.map((u) => u.id),
        );
        return n;
      });
    },

    sortItems(property, direction, ignore) {
      const commit = (n, commitId) => {
        const old_data = cloneDeep(n.data);
        old_data.item_order = [...n.items.order];

        sortList(n.items, { property, direction, ignore });
        n.data.item_order = [...n.items.order];
        updateProp("groups", n.id, "data", n.data, commitId);

        return (n) => {
          n.items.order = [...old_data.item_order];
          updateProp("groups", n.id, "data", old_data);
        };
      };

      update((n) => {
        revision.commitTransaction(commit, n, "UPDATE", [n.id]);
        return n;
      });
    },

    addType(...types) {
      const ids = types.map((i) => i.id);
      const commit = async (n, commitId) => {
        const old_data = cloneDeep(n.data);
        old_data.type_order = [...n.types.order];

        addToSortableList(n.types, ...types.map((t) => hydrateType(t)));
        n.data.type_order = [...n.types.order];

        await addRecords(
          "types",
          types.map((i) => removeNonDbProps(i)),
          commitId,
        );
        updateProp("groups", n.id, "data", n.data, commitId);

        return async (n) => {
          removeFromSortableList(n.types, ...ids);
          await removeRecords("types", ids);
          updateProp("groups", n.id, "data", old_data);
        };
      };

      return new Promise((resolve) => {
        update((n) => {
          revision.commitTransaction(commit, n, "INSERT", ids).then(() => {
            resolve();
          });

          return n;
        });
      });
    },

    addTypesWithGroupUpdate(types, diff) {
      const ids = types.map((i) => i.id);
      const { data, ...otherProps } = diff;
      const commit = async (n, commitId) => {
        const old_data = cloneDeep(n.data);
        old_data.type_order = [...n.types.order];
        const oldGroup = Object.keys(otherProps).reduce(
          (o, k) => {
            o[k] = n[k];
            return o;
          },
          { data: old_data },
        );

        addToSortableList(n.types, ...types.map((t) => hydrateType(t)));
        n.data.type_order = [...n.types.order];
        if (data) {
          n.data = {
            ...n.data,
            ...data,
          };
        }

        const updatedGroup = {
          ...otherProps,
          data: n.data,
        };

        await addRecords(
          "types",
          types.map((i) => removeNonDbProps(i)),
          commitId,
        );
        updateProp("groups", n.id, updatedGroup, commitId);

        return async (n) => {
          removeFromSortableList(n.types, ...ids);
          await removeRecords("types", ids);
          updateProp("groups", n.id, oldGroup);
        };
      };

      return new Promise((resolve) => {
        update((n) => {
          revision.commitTransaction(commit, n, "INSERT", ids).then(() => {
            resolve();
          });

          return n;
        });
      });
    },

    removeType(...ids) {
      const commit = (n, commitId) => {
        const old_data = cloneDeep(n.data);
        old_data.type_order = [...n.types.order];

        removeFromSortableList(n.types, ...ids);
        n.data.type_order = [...n.types.order];

        const res = {};
        removeRecords("types", ids).then((result) => (res.types = result));
        updateProp("groups", n.id, "data", n.data, commitId);

        return (n) => {
          addToSortableList(n.types, ...res.types.map((t) => hydrateType(t)));
          addRecords(
            "types",
            res.types.map((t) => removeNonDbProps(t)),
            commitId,
          );
          updateProp("groups", n.id, "data", old_data);
        };
      };

      update((n) => {
        revision.commitTransaction(commit, n, "DELETE", ids);
        return n;
      });
    },

    updateType(id, prop, value, diff) {
      this.update([{ type: "type", id, prop, value, diff }]);
    },

    updateTypeOrder(order) {
      const commit = (n, commitId) => {
        const oldOrder = n.types.order;
        const oldData = n.data;

        n.types.order = order;
        const data = cloneDeep(n.data);
        lset(data, "type_order", order);

        updateProp("groups", n.id, "data", data, commitId);

        return (n) => {
          n.types.order = oldOrder;
          n.data = oldData;
          updateProp("groups", n.id, "data", oldData);
        };
      };

      update((n) => {
        revision.commitTransaction(commit, n, "UPDATE", [n.id]);
        return n;
      });
    },

    updateMultipleTypes(ids, prop, value, diff) {
      const updates = ids.map((id) => ({
        type: "type",
        id,
        prop,
        value,
        diff,
      }));
      this.update(updates);
    },

    addComment(...comments) {
      const ids = comments.map((c) => c.id);
      const commit = (n, commitId) => {
        n.comments.push(...comments);

        const records = comments.map((c) => {
          const { commenter, ...rest } = c;
          return rest;
        });

        addRecords("comments", records, commitId);
      };

      update((n) => {
        revision.commitTransactionNotUndoable(commit, n, "INSERT", ids);
        return n;
      });
    },

    removeComment(...ids) {
      const commit = (n, commitId) => {
        const indexes = ids.map((id) =>
          n.comments.findIndex((i) => i.id === id),
        );
        indexes.sort((a, b) => b - a);
        indexes.forEach((index) => {
          n.comments.splice(index, 1);
        });

        removeRecords("comments", ids);
      };

      update((n) => {
        revision.commitTransactionNotUndoable(commit, n, "DELETE", ids);
        return n;
      });
    },

    updateComment(id, prop, value) {
      const commit = (n, commitId) => {
        const comment = n.comments.find((i) => i.id === id);
        comment[prop] = value;

        updateProp("comments", comment.id, prop, value, commitId);
      };

      update((n) => {
        revision.commitTransactionNotUndoable(commit, n, "UPDATE", [id]);
      });
    },

    addAttachment(...attachments) {
      const ids = attachments.map((a) => a.id);
      const commit = async (n, commitId) => {
        n.attachments.push(...attachments);

        await addRecords("attachments", attachments, commitId);
      };

      return new Promise((resolve) => {
        update((n) => {
          revision
            .commitTransactionNotUndoable(commit, n, "INSERT", ids)
            .then(() => {
              resolve();
            });
          return n;
        });
      });
    },

    removeAttachment(...ids) {
      const commit = (n, commitId) => {
        const indexes = ids.map((id) =>
          n.attachments.findIndex((i) => i.id === id),
        );
        indexes.sort((a, b) => b - a);
        indexes.forEach((index) => {
          n.attachments.splice(index, 1);
        });

        removeRecords("attachments", ids);
      };

      update((n) => {
        revision.commitTransactionNotUndoable(commit, n, "DELETE", ids);
        return n;
      });
    },
  };
}

export { createGroup, fetchGroup, fetchGroupFromLink };
