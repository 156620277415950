export function findFlatIndex(arr, loop, index) {
  let i = 0;
  for (let l = 0; l < loop; l++) {
    i += arr[l].length;
  }
  return i + index;
}

export function findNestedIndex(arr, flatIndex) {
  let loop = 0;
  let index = flatIndex;

  while (index > arr[loop].length - 1) {
    index -= arr[loop].length;
    loop += 1;
  }

  return { loop, index };
}
