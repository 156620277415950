<script>
  import { getContext } from "svelte";
  import { Link, navigate } from "svelte-routing";
  import Navbar from "src/lib/Navbar.svelte";
  import JobDetail from "src/lib/home/JobDetail.svelte";
  import { fetchJob, jobs, selectedJobIds } from "src/stores/jobs.js";
  import CaretIcon from "src/assets/icons/caret-left.svg";
  import { api } from "src/api";
  import ActionButtons from "src/lib/home/ActionButtons.svelte";

  export let jobid;

  const org = getContext("org");

  let job;
  let listRecord;

  async function archive() {
    await jobs.archive(jobid);
    getJob();
  }

  async function unarchive() {
    await jobs.unarchive(jobid);
    getJob();
  }

  async function getJob() {
    const data = await fetchJob(jobid);
    const lr = await api.from("job_list_view").select("*").eq("id", jobid).single();

    if (!data || lr.error) {
      navigate("/not-found");
      return;
    } else {
      if (!data.archived_at) {
        $selectedJobIds = [data.id];
      }

      job = data;
      listRecord = lr.data;
    }
  }
</script>

<Navbar />

<div class="grow bg-gray-100 flex flex-col min-h-0">
  {#key jobid}
    {#if $org}
      {#await getJob() then _}
        <div class="flex justify-between items-end px-8">
          <div>
            <h2 class="py-4">Job Details</h2>
            <div class="flex gap-4 items-center">
              <div class="text-blue-500 text-sm flex gap-2 w-48 flex-none">
                {#if job?.archived_at}
                  <Link to="/archive/">
                    <div class="flex items-center gap-1">
                      <CaretIcon />
                      <div>Archive</div>
                    </div>
                  </Link>
                {:else}
                  <Link to="/jobs">
                    <div class="flex items-center gap-1">
                      <CaretIcon />
                      <div>Jobs List</div>
                    </div>
                  </Link>
                {/if}
              </div>
              <h3>
                {job.name}
              </h3>
            </div>
          </div>
          <ActionButtons
            page={$org?.org_type === "supplier" ? "quotes" : "rfqs"}
            selected={listRecord}
            selectedRecordType="job" />
        </div>
        <div class="p-4 grow overflow-hidden">
          <div class="content-container h-full flex flex-col border bg-white rounded-lg overflow-hidden">
            {#if job}
              <div class="overflow-y-auto py-2">
                <JobDetail {jobid} on:archive-job={archive} on:unarchive-job={unarchive} />
              </div>
            {/if}
          </div>
        </div>
      {/await}
    {/if}
  {/key}
</div>

<style lang="scss">
  .content-container {
    min-width: 32rem;
  }
</style>
