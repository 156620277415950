<script>
  import { createEventDispatcher } from "svelte";
  import CaretDownIcon from "src/assets/icons/caret-down.svg";
  import CaretRightIcon from "src/assets/icons/caret-right.svg";
  import CircleXIcon from "src/assets/icons/circle-x.svg";

  export let section;
  export let addable = false;
  export let deletable = false;
  export let selected = null;
  export let collapsible = true;

  const dispatch = createEventDispatcher();

  let expanded = true;
</script>

<div class="flex mt-2">
  {#if section.title && collapsible}
    <div class="flex-none">
      <button on:click={() => (expanded = !expanded)}>
        {#if expanded}
          <CaretDownIcon />
        {:else}
          <CaretRightIcon />
        {/if}
      </button>
    </div>
  {/if}
  <div class="grow">
    {#if section.title}
      <div class="font-bold text-xs mb-2">{section.title}</div>
    {/if}
    {#if expanded}
      <div class="flex flex-wrap gap-3 text-xs mb-2">
        {#if section.records.length === 0 && !addable}
          <div class="italic px-4 text-gray-500">No items</div>
        {/if}
        {#each section.records as record, index}
          <button
            class="tile"
            class:selected={selected && selected === record.id}
            on:click={() => dispatch("select", { record, index })}>
            <div class="h-full">
              <div class="w-full h-20 border relative">
                <slot name="thumbnail" {record}>Missing thumbnail template</slot>
              </div>
              <div class="text-xxs">
                <slot name="caption" {record}>Missing label template</slot>
              </div>
            </div>
            {#if deletable}
              <button
                class="delete-tile-button"
                on:click|stopPropagation={() => dispatch("delete", { record })}>
                <CircleXIcon />
              </button>
            {/if}
          </button>
        {/each}
        {#if addable}
          <button class="tile" on:click={() => dispatch("add")}>
            <div class="text-center w-full">+</div>
          </button>
        {/if}
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  .tile {
    flex: 1;
    max-width: 8rem;
    min-width: 7.25rem;
    position: relative;
    @apply shrink grow rounded border border-gray-300 bg-white p-2 shadow text-left;

    &:hover,
    &.selected {
      @apply ring-2;
    }

    .delete-tile-button {
      display: none;
    }

    &:hover .delete-tile-button {
      display: block;
    }

    .delete-tile-button {
      @apply absolute top-0 left-0 bg-white shadow-lg rounded-full p-1 -ml-3 -mt-3;
      @apply border border-transparent;

      &:hover {
        @apply border-gray-300;
      }
    }
  }
</style>
