export default function parseCurrency(v) {
  if (typeof v !== "string") throw new Error("Invalid currency value");

  const num = v.match(/\d+(\.?\d+)?/);
  if (!num) throw new Error("Invalid currency value");

  const d = parseFloat(num[0]);
  if (!Number.isFinite(d) || !v) {
    throw new Error("Invalid currency value");
  }

  return Math.round(d * 100);
}
