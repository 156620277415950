<script>
  import { tick, createEventDispatcher, onMount } from "svelte";
  import debounce from "lodash/debounce";
  import { Modal } from "svelte-utilities";
  import SearchSelect from "src/lib/sidebar/SearchSelect.svelte";
  import XIcon from "src/assets/icons/x.svg";

  import isEmail from "@local/extensions/validators/is-email.js";
  import sanitizePgString from "src/extensions/sanitize-postgrest-string.js";
  import { api, checkContact } from "src/api";
  import eb from "src/extensions/event-bus.js";

  export let sent = false;
  export let org;
  export let rfq;
  export const open = () => openModal();

  const dispatch = createEventDispatcher();

  let modal;
  let recipients = [];
  let sentQr = [];
  let newResult = [];
  let contactResults = [];
  let searchResults = [];
  let focused = null;

  function resultSort(a, b) {
    if (a.name && b.name) return a.name.localeCompare(b.name);
    return 0;
  }

  $: results = newResult.concat([...contactResults, ...searchResults].sort(resultSort)).slice(0, 8);
  $: usedIds = getUsedIds(recipients, sentQr);
  $: contacts = getContacts(org);

  async function openModal() {
    await tick();

    if (sent) {
      console.log("quotes?", rfq.quotes);

      sentQr = rfq.quotes.map((q) => ({
        name: q.organization ? q.organization.name : q.email,
        type: q.organization ? "existing" : "email",
        id: q.sender_id || q.organization_id,
      }));
    } else {
      sentQr = rfq.data.saved_recipients
        ? rfq.data.saved_recipients.map((r) => ({
            id: r.primary_contact_id || r.contact_id,
          }))
        : [];
    }

    recipients = [
      {
        searchString: "",
        result: null,
        id: crypto.randomUUID(),
      },
    ];

    modal.open();
  }

  function getUsedIds(recipients, sentQr) {
    const all = {};

    recipients.forEach((r) => {
      if (r.result) {
        all[r.result.id] = true;
      }
    });

    sentQr.forEach((r) => {
      all[r.id] = true;
    });

    return all;
  }

  function contactLabel(contact) {
    if (contact.primary_contact) {
      const p = contact.primary_contact;
      if (!p.username || p.username === p.email) return p.email;
      return `${p.username} (${p.email})`;
    }

    if (contact.contact) {
      const org = contact.contact;
      if (org.primary_contact_email) {
        return org.primary_contact_email;
      }
    }

    return "Shape Exchange Org.";
  }

  function getContacts(org) {
    if (!org?.contacts) return [];

    return org.contacts
      .filter((c) => c.contact)
      .map((c) => {
        return {
          name: c.contact.name,
          description: c.contact.address?.split("\n").slice(0, 1).join(" "),
          cta: contactLabel(c),
          type: "existing",
          values: {
            primary_contact_id: c.primary_contact_id,
            company_name: c.contact.name,
            contact_name: c.primary_contact?.username,
            email: c.primary_contact?.email || c.contact.primary_contact_email,
          },
          quoter_organization_id: c.contact_id,
          id: c.primary_contact_id || c.contact_id,
        };
      });
  }

  function confirm(e) {
    const { value } = e.detail;

    const r = recipients
      .filter((r) => r.result && !r.result.pending)
      .map((r) => {
        // Quote recipients who are already org contacts
        if (r.result.type === "existing") {
          return {
            id: r.id,
            type: "existing",
            contact_id: r.result.quoter_organization_id,
            primary_contact_id: r.result.values.primary_contact_id,
            company_name: r.result.values.company_name,
            name: r.result.values.contact_name,
            email: r.result.values.email,
          };

          // Quote recipients who are existing orgs found via search
        } else if (r.result.type === "org") {
          const contact = r.result.contactOptions.find((c) => c.value === r.result.contact_id);
          return {
            id: r.id,
            type: "org",
            contact_id: r.result.quoter_organization_id,
            primary_contact_id: r.result.values.primary_contact_id,
            company_name: r.result.values.company_name,
            name: r.result.values.contact_name,
            email: r.result.values.email,
          };

          // Quote recipients who will be stubbed out if necessary
        } else if (r.result.type === "new") {
          return {
            id: r.id,
            type: "new",
            company_name: r.result.values.company_name,
            name: r.result.values.contact_name,
            email: r.result.values.email,
          };
        }
      })
      .filter((r) => r);

    dispatch(value, { recipients: r });
  }

  function contactDescription(c) {
    if (c.username && c.username !== c.email) {
      return `${c.username} (${c.email})`;
    }

    return c.email;
  }

  async function searchExistingOrgs(s) {
    if (!s) return;

    const cIds = contacts.filter((c) => c.quoter_organization_id).map((c) => c.id);

    const orgResults = await api
      .from("organizations")
      .select("*,profiles(*)")
      .or(`name.ilike.%${s}%,primary_contact_email.ilike.%${s}%,address.ilike.%${s}%`)
      .eq("org_type", "supplier")
      .is("profiles.is_public", true)
      .is("is_public", true)
      .not("id", "in", `(${cIds.join(",")})`)
      .range(0, 8);
    if (orgResults.error) return;

    const r = orgResults.data
      .map((org) => ({
        name: org.name,
        description: org.address?.split("\n").slice(0, 1).join(" "),
        cta: org.primary_contact_email || "Shape Exchange Org.",
        values: {
          primary_contact_id: null,
          company_name: org.name,
          contact_name: null,
          email: org.primary_contact_email,
        },
        type: "org",
        contactOptions: [
          ...(org.primary_contact_email
            ? [{ label: org.primary_contact_email, value: null, email: org.primary_contact_email }]
            : []),
          ...(org.profiles || []).map((u) => ({
            label: contactDescription(u),
            value: u.id,
            username: u.username,
            email: u.email,
          })),
        ],
        pending: true,
        quoter_organization_id: org.id,
        id: org.id,
      }))
      .sort(resultSort);

    searchResults = r.slice(0, 8);
  }

  function search(v) {
    const s = sanitizePgString(v);

    let list = contacts;
    if (s) {
      list = list.filter((c) =>
        [c.name?.toLowerCase(), c.description?.toLowerCase()].some((val) => val?.includes(s)),
      );

      const listIds = list.map((l) => l.id.toLowerCase());
      if (isEmail(s) && !listIds.includes(s.toLowerCase())) {
        newResult = [
          {
            name: s,
            values: {
              company_name: null,
              contact_name: null,
              email: s,
            },
            description: null,
            cta: "Send to new supplier",
            type: "new",
            updateValue: "email",
            pending: true,
            id: s,
          },
        ];
      } else {
        newResult = [
          {
            name: s,
            values: {
              company_name: s,
              contact_name: null,
              email: null,
            },
            description: null,
            cta: "Send to new supplier",
            type: "new",
            updateValue: "company_name",
            pending: true,
          },
        ];
      }
    } else {
      newResult = [];
    }

    contactResults = list.filter((c) => !usedIds[c.id]);
    debounce(() => searchExistingOrgs(s), 500)();
  }

  function focus(index) {
    const recipient = recipients[index];
    search(recipient.searchString);
    focused = index;
  }

  function addRecipient() {
    recipients = [
      ...recipients,
      {
        searchString: "",
        result: null,
        id: crypto.randomUUID(),
      },
    ];
  }

  function selectRecipient(index, result) {
    recipients[index].result = result;
    recipients[index].searchString = result.name;

    if (recipients.every((r) => r.result)) addRecipient();
  }

  function deselectRecipient(index) {
    recipients[index].result = null;
    recipients[index].searchString = "";
  }

  function removeRecipient(index) {
    if (recipients.length === 1) return;
    recipients = recipients.filter((q, i) => i !== index);
  }

  function validatePending(s) {
    if (s.type === "new") {
      if (!s.values) return false;
      if (!s.values.company_name) return false;
      if (!s.values.contact_name) return false;
      if (!isEmail(s.values.email)) return false;
    }
    return true;
  }

  function updatePending(s, prop, value) {
    if (s.type === "new") {
      if (s.updateValue === prop) {
        s.name = value;
      }
    }
  }

  function confirmPending(s) {
    if (s.type === "org") {
      const contact = s.contactOptions.find((c) => c.value === s.values.primary_contact_id);
      if (contact) {
        s.cta = contact.label;
        s.values.contact_name = contact.username;
        s.values.email = contact.email;
      }
    } else if (s.type === "new") {
      s.name = s.values.company_name;
      s.cta = `${s.values.contact_name} (${s.values.email})`;
    }
  }

  async function checkRecipient(selection) {
    if (selection.type === "org") return { success: true, message: null };

    const res = await checkContact({
      organization_id: rfq.organization_id,
      email: selection.values.email,
    });

    if (res.error) return { success: false, message: res.error.message };

    if (res.exists) {
      return { success: false, message: "This contact already exists in your organization." };
    }

    if (!selection.values?.contact_name || selection.values?.contact_name.length < 3) {
      return { success: false, message: "Contact name must be at least 3 characters." };
    }

    return { success: true, message: null };
  }

  onMount(() => {
    eb.on("open-recipient-modal", openModal);

    return () => eb.unsubscribe("open-recipient-modal", openModal);
  });
</script>

<Modal
  bind:this={modal}
  closeable
  on:confirm={confirm}
  width="36rem"
  buttons={[
    { label: "Cancel", type: "cancel" },
    {
      label: sent ? "Send to Recipients" : "Add Recipients",
      type: "confirm",
      style: "primary",
      value: sent ? "send-to-recipients" : "add-recipients",
    },
  ]}>
  <div slot="title">
    {#if sent}
      Send Quote to Recipients
    {:else}
      Add Recipients
    {/if}
  </div>
  <div slot="content" class="space-y-2">
    {#if sent}
      <div>Add new recipients to this quote request:</div>
    {:else}
      <div>Choose recipients for this quote request:</div>
    {/if}
    {#each recipients as recipient, index (recipient.id)}
      <div
        class="flex items-center gap-2"
        class:opacity-50={index === recipients.length - 1 && !recipient.searchString && focused !== index}>
        {#if recipients.length !== 1 && index !== recipients.length - 1}
          <div class="p-1 rounded hover:bg-gray-200 cursor-pointer" on:click={() => removeRecipient(index)}>
            <XIcon />
          </div>
        {:else}
          <div class="p-1 opacity-0">
            <XIcon />
          </div>
        {/if}
        <SearchSelect
          placeholder={recipients.length > 1 && index === recipients.length - 1
            ? ""
            : "Choose Supplier Name or Email"}
          border
          bind:value={recipient.searchString}
          bind:selection={recipient.result}
          {results}
          pendingData={recipient.result?.type === "org"
            ? [
                {
                  label: "Contact",
                  prop: "primary_contact_id",
                  type: "select",
                  options: recipient.result.contactOptions,
                },
              ]
            : [
                { label: "Company", prop: "company_name" },
                { label: "Contact name", prop: "contact_name" },
                { label: "Email", prop: "email" },
              ]}
          update={updatePending}
          confirm={confirmPending}
          validate={validatePending}
          confirmPending={checkRecipient}
          showSelectedDetails
          on:focus={() => focus(index)}
          on:search={(e) => search(e.detail.value)}
          on:select={(e) => selectRecipient(index, e.detail.result)}
          on:deselect={() => deselectRecipient(index)} />
      </div>
    {/each}
  </div>
</Modal>
