<script>
  import { onMount } from "svelte";
  import { nanoid } from "nanoid";
  import { Modal } from "svelte-utilities";
  import ErrorMessage from "src/lib/standard-inputs/ErrorMessage.svelte";
  import Dropzone from "src/lib/dropzone/Dropzone.svelte";
  import { profile } from "src/stores/auth.js";
  import { rfqs } from "src/stores/rfqs.js";
  import { jobs } from "src/stores/jobs.js";
  import eb from "src/extensions/event-bus.js";
  import importJob from "src/extensions/import-job.js";

  export let org;
  export let isSupplier;

  export const open = () => openModal();

  let modal;
  let errorMsg;
  let dropzone;

  function openModal() {
    modal.open();
  }

  async function createNewRfq(job_id) {
    const rfq = await rfqs.add(
      {
        job_id,
        organization_id: org.id,
        link_nanoid: nanoid(12),
        assigned_to_id: $profile?.user_role === "developer" ? null : $profile.id,
      },
      0,
    );

    return rfq;
  }

  async function uploadJson(evt) {
    const { file, data: rawData } = evt.detail;

    const enc = new TextDecoder("utf-8");
    const str = enc.decode(rawData);
    const jobdata = JSON.parse(str);
    try {
      const job = await importJob(jobdata, org.id, $profile.id);
      if (!isSupplier) {
        await createNewRfq(job.id);
      } else {
        await jobs.fetchOne(job.id);
      }

      dropzone.stopLoading();
      modal.close();
    } catch (e) {
      dropzone.stopLoading();
      errorMsg = "An error occurred importing this file";
    }
  }

  onMount(() => {
    eb.on("upload-job", openModal);

    return () => {
      eb.unsubscribe("upload-job", openModal);
    };
  });
</script>

<Modal bind:this={modal} closeable width="36rem">
  <div slot="title">Upload Job</div>
  <div slot="content" class="flex flex-col gap-2">
    {#if errorMsg}
      <ErrorMessage padding={false} message={errorMsg} on:close={() => (errorMsg = null)} />
    {/if}
    <Dropzone
      text="Drag and drop or click to upload a job JSON file"
      on:drop={uploadJson}
      bind:this={dropzone}
      accept=".json" />
  </div>
</Modal>
