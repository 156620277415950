<script>
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let left;
  export let right;
  export let selected = "left";

  function toggle(value) {
    selected = value;
    dispatch("toggle", { value });
  }
</script>

<div>
  <button class:selected={selected === "left"} on:click={() => toggle("left")}>{left}</button>
  <button class:selected={selected === "right"} on:click={() => toggle("right")}>{right}</button>
</div>

<style lang="scss">
  div {
    @apply grow flex justify-between border border-gray-200 bg-gray-200;
    @apply rounded text-xs overflow-hidden text-gray-600;
    padding: 1.5px;
  }

  button {
    @apply grow rounded border border-transparent;
    padding: 0.25rem;
  }

  .selected {
    @apply bg-white  text-black;
  }
</style>
