<script>
  import { getContext } from "svelte";
  import { DimText } from "dimtext";
  import set from "lodash/set";
  import cloneDeep from "lodash/cloneDeep";
  import SidebarSection from "./SidebarSection.svelte";
  import SelectInput from "./SelectInput.svelte";
  import TextInput from "./TextInput.svelte";
  import ActionButton from "./ActionButton.svelte";
  import parseLength from "@local/extensions/parsers/parse-length.js";
  import { bulgeSagitta, sagittaBulge } from "@local/extensions/geometry/bulge-sagitta.js";
  import formatLength from "@local/extensions/formatters/format-length.js";
  import { findFlatIndex } from "@local/extensions/collections/find-flat-index.js";
  import { createFreeShape } from "@local/lamina-core";
  import isRectangle from "@local/extensions/geometry/is-rectangle.js";
  import Polyface from "@local/extensions/geometry/polyface.js";
  import dimSettings from "@local/extensions/utilities/dim-settings.js";

  export let job;
  export let item;
  export let feature;
  export let disabled;
  export let polyface;
  export let settings;

  const dt = new DimText();
  const edgeTreatments = getContext("edgeTreatmentOptions");

  $: ds = dimSettings(settings);
  $: lengthFormatter = (v) => formatLength(v, ds.dimFormat, ds.dimPrecision, ds.displayUnit);
  $: multiple = Array.isArray(feature.index);
  $: canBulge =
    !disabled && !multiple && !hasAdjacentFillet(feature, item) && !hasEdgeFabrications(feature, item);
  $: edge = !multiple && polyface.edges[feature.loop][feature.index];
  $: sagitta = !multiple && bulgeSagitta(edge.start, edge.end);
  $: edgeTreatmentValue = getEdgeTreatment(item, feature);

  function getEdgeTreatment(item, feature) {
    const treatment = item.data?.fabrications?.edge_treatment || [];

    if (Array.isArray(feature.index)) {
      const [first, ...rest] = feature.index;
      if (first === undefined) return null;
      if (rest.every((i) => treatment[i] === treatment[first])) return treatment[first];
      return undefined;
    }

    return treatment[feature.index];
  }

  function updateEdges(value) {
    const data = item.data ? cloneDeep(item.data) : {};
    if (multiple) {
      feature.index.forEach((i) => {
        set(data, ["fabrications", "edge_treatment", i], value);
      });
    } else {
      set(
        data,
        ["fabrications", "edge_treatment", findFlatIndex(polyface.shape, feature.loop, feature.index)],
        value,
      );
    }

    job.updateItem(item.id, "data", data);
  }

  function hasAdjacentFillet(feature, item) {
    const shape = createFreeShape(item);
    const loop = shape.vertices[feature.loop];
    const v = loop[(feature.index + 1) % loop.length];
    const prev = loop[feature.index];
    if (v?.fillet || prev?.fillet) return true;
  }

  function hasEdgeFabrications(feature, item) {
    return item.data.fabrications?.edge?.some(
      (f) => f.reference.loop === feature.loop && f.reference.edge === feature.index,
    );
  }

  function freeClone(item) {
    const data = cloneDeep(item.data);
    const rectangle_offset = cloneDeep(item.rectangle_offset);
    const shape = createFreeShape(item);
    return { data, shape, cache: item.cache, rectangle_offset };
  }

  function updateSizes(item) {
    const pf = new Polyface(item);
    const bbox = pf.bbox;
    const w = bbox.width - item.cache.width_offset_in;
    const h = bbox.height - item.cache.height_offset_in;

    item.width = dt.parse(w.toString()).value;
    item.height = dt.parse(h.toString()).value;
  }

  function convertToArc() {
    const clone = freeClone(item);

    const loop = clone.shape.vertices[feature.loop];
    const v = loop[(feature.index + 1) % loop.length];
    v.bulge = 1;

    updateSizes(clone);

    job.updateItem(item.id, {
      data: clone.data,
      shape: clone.shape,
      width: clone.width,
      height: clone.height,
    });
  }

  function convertToLine() {
    const clone = freeClone(item);

    const loop = clone.shape.vertices[feature.loop];
    const v = loop[(feature.index + 1) % loop.length];
    delete v.bulge;

    updateSizes(clone);

    const pf = new Polyface(clone);

    if (isRectangle(clone.shape)) {
      const { xmin, ymin } = pf.bbox;
      clone.rectangle_offset = { x: xmin, y: ymin };
      clone.shape = { type: "rect" };
    }

    job.updateItem(item.id, {
      rectangle_offset: clone.rectangle_offset,
      shape: clone.shape,
      data: clone.data,
      width: clone.width,
      height: clone.height,
    });
  }

  function updateBulge(s) {
    const b = sagittaBulge(edge.start, edge.end, s);

    const clone = freeClone(item);
    const loop = clone.shape.vertices[feature.loop];
    const v = loop[(feature.index + 1) % loop.length];
    v.bulge = b;

    updateSizes(clone);

    job.updateItem(item.id, {
      rectangle_offset: clone.rectangle_offset,
      data: clone.data,
      shape: clone.shape,
      width: clone.width,
      height: clone.height,
    });
  }
</script>

<SidebarSection>
  <SelectInput
    label="Treatment"
    {disabled}
    labelWidth="5.5rem"
    value={edgeTreatmentValue}
    options={$edgeTreatments}
    on:input={(evt) => updateEdges(evt.detail.value)} />

  {#if !multiple && edge.end.bulge}
    <TextInput
      label="Arc Height"
      {disabled}
      labelWidth="5.5rem"
      value={sagitta}
      parser={parseLength}
      formatter={lengthFormatter}
      on:input={(e) => updateBulge(e.detail.value)} />
  {/if}
</SidebarSection>

{#if canBulge}
  <SidebarSection>
    {#if edge.end.bulge}
      <ActionButton action="Convert to Line" on:click={convertToLine} />
    {:else}
      <ActionButton action="Convert to Arc" on:click={convertToArc} />
    {/if}
  </SidebarSection>
{/if}
