import { createTRPCProxyClient, httpBatchLink } from '@trpc/client';
import type { SupabaseClient } from "@supabase/supabase-js";
import type { SupabaseRouter } from "../router/supabase.ts"

const DEFAULT_URL = "http://localhost:8000/trpc";

type Client = ReturnType<typeof createTRPCProxyClient<SupabaseRouter>>;

async function getAccessToken(api: SupabaseClient): Promise<string | null> {
    const { data: { session }, error } = await api.auth.getSession();

    if (error) {
        console.warn(error);
    }

    if (session) {
        return session.access_token;
    }

    return null;
}

function createClient(url?: string): Client
function createClient(url: string, accessToken: string): Client;
function createClient(url: string, supabaseClient: SupabaseClient): Client;
function createClient(url: string = DEFAULT_URL, accessTokenSource?: string | SupabaseClient): Client {
    return createTRPCProxyClient<SupabaseRouter>({
        links: [
            httpBatchLink({
                url,
                async headers() {
                    if (accessTokenSource) {
                        const accessToken = typeof accessTokenSource === "string"
                            ? accessTokenSource
                            : await getAccessToken(accessTokenSource);

                        if (accessToken) {
                            return {
                                Authorization: `Bearer ${accessToken}`,
                            };
                        }
                    }
                    console.warn('No access token could be retrieved from the provided source');
                    return {};
                }
            })
        ],
    });
}

const testEndpointId = '418f0f57-babd-5bef-a3a2-c2bc30f8e0be';


export type { Client }
export { createClient, testEndpointId }
