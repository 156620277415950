<script>
  export let title = "";
</script>

<h3 class="px-6 text-sm">
  <slot>
    {title}
  </slot>
</h3>

<style>
  h3 {
    column-span: all;
  }
</style>
