<script>
  import { createEventDispatcher } from "svelte";
  import UploadIcon from "src/assets/icons/upload.svg";
  import CircleXIcon from "src/assets/icons/circle-x.svg";

  export let label = "Image";
  export let labelWidth = null;
  export let value;
  export let accept = "image/png, image/jpeg, image/svg+xml";
  export let disabled = false;
  export let uploadType = "base64"; // base64 | arraybuffer

  const dispatch = createEventDispatcher();

  let reader;

  $: lw = labelWidth ? `width:${labelWidth};` : "";

  function uploadImage() {
    reader.click();
  }

  function handleImage(e) {
    const selectedImage = e.target.files[0];
    const r = new FileReader();

    r.onload = (e) => {
      if (e.target.result) {
        const type = {
          "image/png": "png",
          "image/svg+xml": "svg",
          "image/jpeg": "jpg",
          "image/jpg": "jpg",
        }[selectedImage.type];

        if (uploadType === "base64") {
          dispatch("upload", {
            type,
            base64: e.target.result,
            filename: selectedImage.name,
          });
        } else if (uploadType === "arraybuffer") {
          dispatch("upload", {
            type,
            contentType: selectedImage.type,
            data: e.target.result,
            filename: selectedImage.name,
          });
        }
      }
    };

    if (selectedImage) {
      if (uploadType === "base64") {
        r.readAsDataURL(selectedImage);
      } else if (uploadType === "arraybuffer") {
        r.readAsArrayBuffer(selectedImage);
      }
    }
  }

  function deleteImage() {
    dispatch("delete");
  }
</script>

<div class="px-2 my-2">
  {#if value}
    <div class="image-container relative mb-2">
      {#if !disabled}
        <button
          class="delete-button absolute top-0 left-0 -ml-3 -mt-3 p-1 cursor-pointer bg-white rounded-xl"
          on:click={deleteImage}>
          <CircleXIcon />
        </button>
      {/if}
      <img class="block w-full border border-gray-400" src={value} alt="Logo Thumbnail" />
    </div>
  {/if}
  <div class="flex items-center gap-2">
    <div class="text-gray-400" style={lw}>{label}</div>
    {#if !disabled}
      <button class="flex items-center space-x-1" on:click={uploadImage}>
        <UploadIcon />
        <div>Upload</div>
      </button>
    {/if}
    <input bind:this={reader} class="hidden" type="file" {accept} on:change={handleImage} />
  </div>
</div>

<style lang="scss">
  .image-container {
    .delete-button {
      display: none;
    }

    &:hover {
      .delete-button {
        display: block;
      }
    }
  }
</style>
