import { DimText, LinearDisplayFormat } from "dimtext";
import { almostEqual } from "overline";

const dt = new DimText();

function formatLength(v, format, precision = 2, unit = "inches") {
  const fmt = {
    DECIMAL: LinearDisplayFormat.DECIMAL,
    FRACTIONAL: LinearDisplayFormat.FRACTIONAL,
  }[format];

  if (almostEqual(v, 0)) v = 0;
  const dim = dt.parse(v.toString());
  return dim.value.format(fmt, precision, { displayUnit: unit });
}

export default formatLength;
