<script>
  import { getContext } from "svelte";
  import { Link, navigate } from "svelte-routing";
  import Navbar from "src/lib/Navbar.svelte";
  import QuoteDetail from "src/lib/home/QuoteDetail.svelte";
  import RfqDetail from "src/lib/home/RfqDetail.svelte";
  import ProductRfqDetail from "src/lib/home/ProductRfqDetail.svelte";
  import ActionButtons from "src/lib/home/ActionButtons.svelte";
  import CaretIcon from "src/assets/icons/caret-left.svg";
  import { profile } from "src/stores/auth.js";
  import { api } from "src/api";
  import { selectedQuoteIds } from "src/stores/quotes.js";
  import { rfqStr, selectedRfqIds } from "src/stores/rfqs.js";
  import { fullQuoteStr } from "src/stores/quotes.js";

  export let quoteid;

  const org = getContext("org");

  let quote;
  let listRecord;

  $: selectedRecordType = getSelectedRecordType($org);

  function getSelectedRecordType(o) {
    return o?.org_type === "supplier" ? "response" : "request";
  }

  async function getRfq() {
    const { data, error } = await api
      .from("quote_requests")
      .select(rfqStr)
      .eq("id", quoteid)
      .eq("organization_id", $profile?.organization_id)
      .maybeSingle();

    if (error) return null;
    return data;
  }

  async function getQuote() {
    const { data, error } = await api
      .from("quotes")
      .select(fullQuoteStr)
      .eq("id", quoteid)
      .eq("organization_id", $profile?.organization_id)
      .maybeSingle();

    if (error) return null;
    return data;
  }

  async function getData() {
    let data;
    let lr;
    if ($profile.organization?.org_type === "supplier") {
      data = await getQuote();
      lr = await api.from("response_list_view").select("*").eq("id", quoteid).single();
    } else {
      data = await getRfq();
      lr = await api.from("request_list_view").select("*").eq("id", quoteid).single();
    }

    if (!data || lr.error) {
      navigate("/not-found");
      return;
    } else {
      if (!data.archived_at && $org?.org_type === "supplier") {
        $selectedQuoteIds = [data.id];
      } else if (!data.archived_at) {
        $selectedRfqIds = [data.id];
      }

      quote = data;
      listRecord = lr.data;
    }
  }
</script>

<Navbar />

<div class="grow bg-gray-100 flex flex-col min-h-0">
  {#key quoteid}
    {#if $org}
      {#await getData() then _}
        <div class="flex justify-between items-end px-8">
          <div>
            <h2 class="py-4">Quote Details</h2>
            <div class="flex gap-4 items-center">
              <div class="text-blue-500 text-sm flex gap-2 w-48 flex-none">
                {#if quote?.archived_at}
                  <Link to="/archive">
                    <div class="flex items-center gap-1">
                      <CaretIcon />
                      <div>Archive</div>
                    </div>
                  </Link>
                {:else}
                  <Link to="/">
                    <div class="flex items-center gap-1">
                      <CaretIcon />
                      <div>Home</div>
                    </div>
                  </Link>
                {/if}
              </div>
              <h3>
                {quote?.job?.name}
              </h3>
            </div>
          </div>
          <ActionButtons
            page={$org?.org_type === "supplier" ? "quotes" : "rfqs"}
            selected={listRecord}
            {selectedRecordType} />
        </div>
        <div class="p-4 grow overflow-hidden">
          <div class="content-container h-full flex flex-col border bg-white rounded-lg overflow-hidden">
            {#if $org}
              <div class="overflow-y-auto py-2">
                {#if $org.org_type === "supplier"}
                  <QuoteDetail {quoteid} on:archive={getData} on:unarchive={getData} />
                {:else if listRecord?.project_type === "product"}
                  <ProductRfqDetail rfqid={quoteid} />
                {:else}
                  <RfqDetail rfqid={quoteid} />
                {/if}
              </div>
            {/if}
          </div>
        </div>
      {/await}
    {/if}
  {/key}
</div>

<style lang="scss">
  .content-container {
    min-width: 32rem;
  }
</style>
