<script>
  import { Link } from "svelte-routing";
  import { getContext } from "svelte";
  import get from "lodash/get";

  import CircleCheckIcon from "src/assets/icons/circle-check-filled.svg";
  import CircleWarningIcon from "src/assets/icons/circle-warning-filled.svg";

  import SidebarSection from "./SidebarSection.svelte";
  import NewLocationButtons from "./NewLocationButtons.svelte";
  import formatDims from "@local/extensions/formatters/format-dims.js";
  import Viewport from "src/lib/drawing/ViewportFixed.svelte";
  import liteDrawingMini from "@local/extensions/drawing/lite-drawing-mini.js";

  export let group;
  export let itemsWithLocs;
  export let locationCounts;
  export let collectionsWithLocs;
  export let typesWithLocs;
  export let single = false;

  const typeColors = getContext("typeColors");

  function description(item) {
    const mark = item.mark || "";
    const labelBy = item.is_collection
      ? $group.data.settings.collections_label_by
      : $group.data.settings.label_by;
    const label = (labelBy && get(item, labelBy)) || "";

    let textLbl = label && label !== mark ? `${mark} - ${label}` : mark;

    if (item.width && item.height) {
      return `${textLbl} - ${formatDims(item, $group.data.settings)}`;
    } else {
      return textLbl;
    }
  }
</script>

<SidebarSection>
  <div class="px-2 space-y-2">
    {#if itemsWithLocs.length}
      {#if !single}
        <div class="font-bold">Items</div>
      {/if}
      <div class="space-y-1">
        {#each itemsWithLocs as item (item.id)}
          {@const typeColor = $typeColors[item.type_id]}
          <div class="flex gap-3 items-center">
            <NewLocationButtons record_type="item" record_id={item.id} on:add-location on:clone-location />
            <Link to={`items/${item.id}`} class="truncate">
              <div class="flex gap-2">
                <div class="w-4 h-4 relative flex-none">
                  {#key item}
                    <Viewport
                      padding={1}
                      drawing={liteDrawingMini(item, { typeColor })}
                      width={16}
                      height={16} />
                  {/key}
                </div>
                <div class="truncate">
                  {description(item)}
                </div>
              </div>
            </Link>
            {#if locationCounts[item.id] > 1}
              <div>({locationCounts[item.id]})</div>
            {/if}
          </div>
        {/each}
      </div>
    {/if}
    {#if collectionsWithLocs.length}
      {#if !single}
        <div class="font-bold">Openings</div>
      {/if}
      <div class="space-y-1">
        {#each collectionsWithLocs as coll (coll.id)}
          <div class="flex gap-3 items-center">
            <NewLocationButtons
              record_type="collection"
              record_id={coll.id}
              on:add-location
              on:clone-location />
            <Link to={`openings/${coll.id}`} class="truncate">
              <div class="flex gap-2">
                <div class="w-4 h-4 relative flex-none">
                  {#key coll}
                    <Viewport padding={1} drawing={liteDrawingMini(coll)} width={16} height={16} />
                  {/key}
                </div>
                <div class="truncate">
                  {description(coll)}
                </div>
              </div>
            </Link>
            {#if locationCounts[coll.id] > 1}
              <div>({locationCounts[coll.id]})</div>
            {/if}
          </div>
        {/each}
      </div>
    {/if}
    {#if typesWithLocs.length}
      {#if !single}
        <div class="font-bold">Types</div>
      {/if}
      <div class="space-y-1">
        {#each typesWithLocs as type (type.id)}
          <div class="flex gap-3 items-center">
            <NewLocationButtons record_type="type" record_id={type.id} on:add-location on:clone-location />
            <div class="flex gap-2 items-center overflow-hidden">
              <Link to={`types/${type.id}`} class="truncate">{type.mark} - {type.name}</Link>
              {#if locationCounts[type.id] > 1}
                <div>({locationCounts[type.id]})</div>
              {/if}
            </div>
            {#if type.approval_status !== "none"}
              <div class="grow flex justify-end items-center gap-1">
                {#if type.approval_status === "approved"}
                  <div class="text-green-500">
                    <CircleCheckIcon />
                  </div>
                {:else if type.approval_status === "flagged"}
                  <div class="text-red-500">
                    <CircleWarningIcon />
                  </div>
                {/if}
              </div>
            {/if}
          </div>
        {/each}
      </div>
    {/if}
  </div>
</SidebarSection>
