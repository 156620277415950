function isDelete(transaction) {
  if (transaction.isUndo) return transaction.type === "INSERT";
  return transaction.type === "DELETE";
}
export class Revision {
  constructor(max) {
    this.max = (max || 15) + 1;
    this.undos = [];
    this.redos = [];
    this.pending = {};
  }

  get undoable() {
    return this.undos.length > 0;
  }

  get redoable() {
    return this.redos.length > 0;
  }

  async commit(transaction, n) {
    this.undos.push(transaction);
    if (transaction.pending.length > 0) {
      this.pending[transaction.commitId] = transaction;
    }

    if (this.undos.length > this.max) {
      this.undos.shift();
    }

    this.redos = [];

    await transaction.commit(n);
  }

  async commitNotUndoable(transaction, n) {
    this.pending[transaction.commitId] = transaction;
    await transaction.commit(n);
  }

  isPending(commitId) {
    return commitId && this.pending[commitId];
  }

  deleteCommitId(id) {
    const transaction = Object.values(this.pending).find((t) => {
      return isDelete(t) && t.pending.includes(id);
    });

    return transaction && transaction.commitId;
  }

  resolve(commitId, recordId) {
    const transaction = this.pending[commitId];
    const index = transaction.pending.indexOf(recordId);
    if (index !== -1) {
      transaction.pending.splice(index, 1);
    }

    if (transaction.pending.length <= 0) {
      delete this.pending[commitId];
    }
  }

  undo(n) {
    if (!this.undoable) return;
    const transaction = this.undos.pop();
    transaction.pending = [...transaction.ids];
    this.pending[transaction.commitId] = transaction;
    transaction.isUndo = true;
    transaction.undo(n);
    this.redos.push(transaction);
  }

  redo(n) {
    if (!this.redoable) return;
    const transaction = this.redos.pop();
    transaction.pending = [...transaction.ids];
    this.pending[transaction.commitId] = transaction;
    delete transaction.isUndo;
    transaction.commit(n);
    this.undos.push(transaction);
  }

  async commitTransaction(commit, n, type, ids) {
    const commitId = crypto.randomUUID();

    const transaction = {
      commitId,
      type,
      ids,
      pending: [...ids],
      async commit(n) {
        this.undo = await commit(n, commitId);
      },
    };

    await this.commit(transaction, n);
  }

  async commitTransactionNotUndoable(commit, n, type, ids) {
    const commitId = crypto.randomUUID();

    const transaction = {
      commitId,
      type,
      ids,
      pending: [...ids],
      async commit(n) {
        await commit(n, commitId);
      },
    };

    await this.commitNotUndoable(transaction, n);
  }
}
