<script>
  import cloneDeep from "lodash/cloneDeep";
  import { createEventDispatcher, getContext } from "svelte";
  import { downloadBlob } from "downloaders";

  import FileIcon from "src/assets/icons/file.svg";
  import CaretRightIcon from "src/assets/icons/caret-right.svg";
  import CaretDownIcon from "src/assets/icons/caret-down.svg";
  import TrashIcon from "src/assets/icons/trash.svg";
  import BlankIcon from "src/assets/icons/blank.svg";
  import FilePdfIcon from "src/assets/icons/file-pdf.svg";
  import FileImgIcon from "src/assets/icons/file-img.svg";
  import DownloadIcon from "src/assets/icons/download.svg";

  import { Modal } from "svelte-utilities";
  import UppyDropzone from "src/lib/dropzone/UppyDropzone.svelte";

  import Viewport from "src/lib/drawing/ViewportFixed.svelte";
  import liteDrawingMini from "@local/extensions/drawing/lite-drawing-mini.js";
  import SidebarSection from "./SidebarSection.svelte";
  import TextInput from "./TextInput.svelte";
  import extension from "@local/extensions/utilities/extension.js";
  import nameMinusExtension from "@local/extensions/utilities/name-minus-extension.js";
  import {
    transformSheetPoint,
    sheetScaleFactor,
  } from "@local/extensions/geometry/transform-sheet-points.js";
  import { createItem as defaultItem } from "@local/lamina-core";
  import maxima from "@local/extensions/utilities/maxima.js";
  import { showDocFiles, showDocAnnotations, selectedAnnotations } from "src/stores/ui.js";
  import { profile } from "src/stores/auth.js";
  import { api } from "src/api";
  import { SUPABASE_URL as supabaseUrl } from "src/env";

  export let document;
  export let group;
  export let items = [];
  export let disabled;
  export let annotations = [];
  export let hoveredFile = null;
  export let hoveredAnnotation = null;
  export let viewer = null;

  const dispatch = createEventDispatcher();
  const typeColors = getContext("typeColors");

  let importModal;
  let confirmRemoveModal;
  let fileToDelete = null;
  let convertModal;
  let annoSelector;
  let overallAnnoSelection;

  const fileIcons = {
    file: FileIcon,
    pdf: FilePdfIcon,
    jpg: FileImgIcon,
    jpeg: FileImgIcon,
    png: FileImgIcon,
    svg: FileImgIcon,
  };

  $: checkSelectionState($selectedAnnotations, annoSelector, annotations);
  $: selectedAnnos = annotations.filter((a) => $selectedAnnotations[a.id]);
  $: pageIndices = getPageIndices(document);

  function getPageIndices(document) {
    const indices = {};
    let page = 0;
    document.files.forEach((file) => {
      if (file.pages) {
        const pages = [];

        for (let i = 0; i < file.pages; i++) {
          pages.push(page);
          page++;
        }

        indices[file.id] = pages;
      }
    });
    return indices;
  }

  function checkSelectionState(selected, annoSelector, annotations) {
    if (!annotations) return;
    const ids = annotations.map((a) => a.id);
    if (ids.every((id) => selected[id])) {
      overallAnnoSelection = true;
    } else if (ids.every((id) => !selected[id])) {
      overallAnnoSelection = false;
    } else {
      overallAnnoSelection = "mixed";
    }

    if (annoSelector) {
      if (overallAnnoSelection === "mixed") {
        annoSelector.indeterminate = true;
      } else {
        annoSelector.indeterminate = false;
      }
    }
  }

  function updateDoc(prop, value) {
    if (typeof document.id === "string") {
      group.updateProp(`data.documents.${document.id}.${prop}`, value);
    }
  }

  async function downloadFile(file) {
    try {
      const { data, error } = await api.storage.from("documents").download(file.object_id);
      if (error) throw error;

      downloadBlob(data, file.name);
    } catch (error) {
      console.log(error);
    }
  }

  function confirmRemoveFile(id) {
    fileToDelete = id;
    confirmRemoveModal.open();
  }

  function beginConverting() {
    convertModal.open();
  }

  function convertAnnotations() {
    const converted = annotations.filter((a) => $selectedAnnotations[a.id]);

    // Create items
    const newItems = [];
    converted.forEach((a, i) => {
      const item = {
        ...defaultItem($group.id, $profile.id, [...items, ...newItems]),
        ...a.item,
        is_imported: true,
      };

      newItems.push(item);
    });

    // Add location markers for each new item
    const data = cloneDeep($group.data);
    const doc = data.documents[document.id];
    newItems.forEach((item, i) => {
      const annotation = converted[i];
      const pt = annotation.own_label_point;

      const tile = viewer.tile(annotation.page);
      const size = tile.getContentSize();

      const initPt = { x: pt[0] - annotation.sheet.limits[0][0], y: pt[1] - annotation.sheet.limits[0][1] };

      const scale = sheetScaleFactor(annotation.sheet.limits, annotation.sheet.rotation, size);
      const tpt = transformSheetPoint(initPt, annotation.sheet.rotation, scale, size);

      let rot = annotation.sheet.rotation % 360 || 0;
      const m = maxima(annotation.geom.coordinates[0]);

      let bb;
      let w;
      let h;
      if (rot === 0) {
        bb = { x: m.minX, y: m.minY };
        w = m.maxX - m.minX;
        h = m.maxY - m.minY;
      } else if (rot === 90) {
        bb = { x: m.maxX, y: m.minY };
        w = m.maxY - m.minY;
        h = m.maxX - m.minX;
      } else if (rot === 180) {
        bb = { x: m.maxX, y: m.maxY };
        w = m.maxX - m.minX;
        h = m.maxY - m.minY;
      } else if (rot === 270) {
        bb = { x: m.minX, y: m.maxY };
        w = m.maxY - m.minY;
        h = m.maxX - m.minX;
      }

      const bboxPt = { x: bb.x - annotation.sheet.limits[0][0], y: bb.y - annotation.sheet.limits[0][1] };
      const bboxTpt = transformSheetPoint(bboxPt, annotation.sheet.rotation, scale, size);
      const diag = Math.sqrt(w * w + h * h);
      const itemDiag = Math.sqrt(item.cache.width * item.cache.width + item.cache.height * item.cache.height);
      const sc = diag / itemDiag;

      const position = {
        file_path: annotation.document_name,
        file_page: annotation.sheet_idx,
        x: tpt.x,
        y: tpt.y,
      };

      const location = {
        id: crypto.randomUUID(),
        type: "pin",
        position,
        transform: {
          scale: scale * sc,
          translate: bboxTpt,
        },
        record_type: "item",
        record_id: item.id,
      };

      doc.locations.push(location);
    });

    group.addItemsWithGroupUpdate(newItems, { data });

    $selectedAnnotations = {};
  }

  function deleteFile(id) {
    const documents = cloneDeep($group.data.documents);
    const doc = documents[document.id];

    const file = doc.files.find((f) => f.id === id);
    if (file) {
      const locations = doc.locations.filter((l) => l.position.file_path !== file.object_id);
      doc.locations = locations;
    }

    const files = doc.files.filter((f) => f.id !== id);
    doc.files = files;
    group.updateProp("data.documents", documents);
  }

  function beginAddingFile() {
    importModal.open();
  }

  function selectAllAnnotations() {
    const ids = annotations.map((a) => a.id);
    if (ids.some((id) => $selectedAnnotations[id])) {
      selectedAnnotations.deselect(...ids);
    } else {
      selectedAnnotations.select(...ids);
    }
  }

  async function addFiles(e) {
    const { results } = e.detail;
    const imageTypes = ["image/jpeg", "image/png", "image/gif"];

    if (results.length) {
      const files = results.map((r) => ({
        id: r.meta.uuid,
        name: r.meta.name,
        stored_name: nameMinusExtension(r.meta.name),
        object_id: r.meta.objectName,
        extension: r.extension,
        content_type: r.meta.type,
        pages: imageTypes.includes(r.meta.type) ? 1 : null,
        status: {},
        ready: imageTypes.includes(r.meta.type),
      }));

      const doc = cloneDeep(document);
      doc.files.push(...files);
      if (files.some((file) => !file.ready)) {
        doc.ready = false;
      }

      const docs = cloneDeep($group.data.documents);
      docs[document.id] = doc;
      group.updateProp("data.documents", docs);
    }

    importModal.close();
  }
</script>

<SidebarSection>
  <TextInput
    label="Name"
    {disabled}
    labelWidth="5rem"
    value={document.name}
    on:input={(e) => updateDoc("name", e.detail.value)} />
</SidebarSection>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<SidebarSection>
  <div class="relative">
    <h3 class="mb-2 px-2">Files</h3>
    <button class="absolute top-0 p-1 left-0 -ml-3 -mt-1" on:click={() => ($showDocFiles = !$showDocFiles)}>
      {#if $showDocFiles}
        <CaretDownIcon />
      {:else}
        <CaretRightIcon />
      {/if}
    </button>
  </div>
  {#if $showDocFiles}
    <div class="px-2">
      {#each document.files as file}
        <div
          class="flex justify-between items-center rounded border border-transparent file-button-container hover:border-gray-400"
          on:mouseover={() => (hoveredFile = file.id)}
          on:mouseout={() => (hoveredFile = null)}>
          <button
            class="flex gap-1 overflow-hidden"
            on:click={() => dispatch("zoom-to-page", { page: pageIndices[file.id] })}
            on:dblclick={() => downloadFile(file)}>
            <button
              class="flex-none text-gray-400 file-button"
              on:click|stopPropagation={() => downloadFile(file)}>
              <div class="file-button-icon">
                <svelte:component this={fileIcons[extension(file.name)] || FileIcon} />
              </div>
              <div class="file-download-icon">
                <DownloadIcon />
              </div>
            </button>
            <div class="grow overflow-hidden">
              <div class="truncate">
                {file.name}
              </div>
            </div>
          </button>
          {#if !disabled && document.files.length > 1}
            <button on:click={() => confirmRemoveFile(file.id)} class="p-1">
              <TrashIcon />
            </button>
          {:else}
            <div class="p-1">
              <BlankIcon />
            </div>
          {/if}
        </div>
      {/each}
    </div>
    {#if !disabled}
      <div class="flex justify-end py-2">
        <button class="font-bold" on:click={beginAddingFile}> + New File </button>
      </div>
    {/if}
  {/if}
</SidebarSection>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->
{#if annotations?.length}
  <SidebarSection>
    <div class="relative">
      <div class="flex justify-between items-start">
        <h3 class="mb-2 px-2">Importable Shapes</h3>
        <div class="flex gap-2 pr-3.5 border border-transparent">
          {#if $showDocAnnotations && annotations.length}
            <!-- <div>Page</div> -->
            <div>
              <input
                bind:this={annoSelector}
                type="checkbox"
                bind:checked={overallAnnoSelection}
                on:input={selectAllAnnotations} />
            </div>
          {:else if annotations.length}
            <div>
              ({annotations.length})
            </div>
          {/if}
        </div>
      </div>
      <button
        class="absolute top-0 p-1 left-0 -ml-3 -mt-1"
        on:click={() => ($showDocAnnotations = !$showDocAnnotations)}>
        {#if $showDocAnnotations}
          <CaretDownIcon />
        {:else}
          <CaretRightIcon />
        {/if}
      </button>
    </div>
    {#if $showDocAnnotations}
      <div class="px-2">
        {#each annotations as annotation (annotation.id)}
          <button
            class="text-left w-full flex justify-between items-center rounded border hover:border-gray-400"
            class:border-transparent={parseInt(hoveredAnnotation) !== annotation.id}
            class:border-gray-400={parseInt(hoveredAnnotation) === annotation.id}
            on:mouseover={() => (hoveredAnnotation = annotation.id)}
            on:mouseout={() => (hoveredAnnotation = null)}
            on:click={() => dispatch("zoom-to-page", { page: annotation.page })}>
            <div
              class="p-1 flex gap-2 grow overflow-hidden annotation-item"
              class:selected={$selectedAnnotations[annotation.id]}>
              <div class="w-4 h-4 relative flex-none">
                <Viewport
                  padding={1}
                  drawing={liteDrawingMini(annotation.item, {
                    typeColor: $typeColors[annotation.item?.type_id],
                  })}
                  width={16}
                  height={16} />
              </div>
              <div class="grow flex gap-2 overflow-hidden">
                <div class="grow truncate">
                  {annotation.own_label}
                </div>
                <!-- <div class="text-gray-500">
                  {annotation.page}
                </div> -->
              </div>
              <div class="flex-none w-4">
                <input
                  type="checkbox"
                  checked={$selectedAnnotations[annotation.id]}
                  on:input={() => selectedAnnotations.toggle(annotation.id)}
                  on:click|stopPropagation />
              </div>
            </div>
          </button>
        {/each}
      </div>
      {#if !disabled}
        <div class="flex justify-end py-2">
          <button class="font-bold" on:click={beginConverting}>
            + Import Selected ({selectedAnnos.length})
          </button>
        </div>
      {/if}
    {/if}
  </SidebarSection>
{/if}

<Modal bind:this={importModal} closeable width="36rem">
  <div slot="title">Add File to Document</div>
  <div slot="content">
    <UppyDropzone
      uploadUrl={`${supabaseUrl}/storage/v1/upload/resumable`}
      bucket="documents"
      accept="application/pdf,image/jpeg,image/gif,image/png"
      on:complete={addFiles} />
  </div>
</Modal>

<Modal
  bind:this={convertModal}
  closeable
  width="36rem"
  on:confirm={convertAnnotations}
  buttons={selectedAnnos.length
    ? [
        { label: "Cancel", type: "cancel" },
        { label: "Create Items", type: "confirm", style: "primary" },
      ]
    : [{ label: "Cancel", type: "cancel" }]}>
  <div slot="title">Import Shapes</div>
  <div slot="content" class="space-y-2">
    {#if selectedAnnos.length}
      <div>Import the following shapes:</div>
      <div class="space-y-1">
        <div class="flex gap-2 px-4 justify-end text-xs text-gray-500">Page</div>
        {#each selectedAnnos as annotation}
          <div class="flex gap-2 px-4">
            <div class="w-4 h-4 relative">
              <Viewport padding={1} drawing={liteDrawingMini(annotation.item)} width={16} height={16} />
            </div>
            <div class="grow flex gap-2 items-center text-xs overflow-hidden">
              <div class="grow truncate">
                {annotation.own_label}
              </div>
              <div class="text-gray-500">
                {annotation.page + 1}
              </div>
            </div>
          </div>
        {/each}
      </div>
    {:else}
      <div>Select shapes to import as items.</div>
    {/if}
  </div>
</Modal>

<Modal
  bind:this={confirmRemoveModal}
  on:confirm={() => deleteFile(fileToDelete)}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Delete", type: "confirm", style: "danger" },
  ]}
  closeOnOutclick>
  <div slot="title">Delete File</div>
  <div slot="content" class="space-y-2">
    <div class="space-y-2">
      <p>Are you sure you want to delete this file?</p>
      <div class="pl-4">{document.files.find((f) => f.id === fileToDelete)?.name}</div>
    </div>
  </div>
</Modal>

<style lang="scss">
  .file-button-container {
    .file-button-icon {
      display: block;
    }

    .file-download-icon {
      display: none;
    }

    &:hover {
      .file-button-icon {
        display: none;
      }

      .file-download-icon {
        display: block;
        color: black;
      }
    }
  }
</style>
