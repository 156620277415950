import { writable, derived } from "svelte/store";
import { api } from "src/api";

import { storedWritable } from "./ui.js";
import createRecordList from "./record-list.js";
import {
  selected,
  sortList,
} from "@local/extensions/collections/sortable-list.js";
import { myRfqsOnly } from "./ui.js";
import { jobs } from "./jobs.js";
import { profile } from "./auth.js";

let $profile;
profile.subscribe((value) => {
  $profile = value;
});

let $jobs;
jobs.subscribe((value) => {
  $jobs = value;
});

const ls = JSON.parse(localStorage.getItem("quotes")) || {};
const stored = {
  selected_quote_ids: [],
  ...ls,
};
localStorage.setItem("quotes", JSON.stringify(stored));

export const quoteStr = `
*,
job: job_id(*),
sender: sender_id(*),
assigned_to: assigned_to_id(*),
notifications(*),
superseded_quotes(*,sender:sender_id(*)),
quote_request:quote_request_id(
  *,sender:sender_id(*),organization:organization_id(*),group:group_id(*,items!items_group_id_fkey(count),types!types_group_id_fkey(count))
)`;

export const fullQuoteStr = `
*,
job:job_id(*),
sender: sender_id(*),
organization: organization_id(*),
notifications(*),
superseded_quotes(*,sender:sender_id(*)),
quote_request:quote_request_id(
  *,
  sender:sender_id(*),
  organization:organization_id(*),
  quotes:quotes_quote_request_id_fkey(*,organization:organization_id(*)),
  group:group_id(*,items!items_group_id_fkey(*),types!types_group_id_fkey(*))
)
`;

function createQuotes() {
  const { subscribe, load, loadOne, unload, insert, unloadOne, sort } =
    createRecordList();

  let $quotes;
  subscribe((value) => {
    $quotes = value;
  });

  const fetchCount = async () => {
    try {
      let query = api
        .from("quotes")
        .select("id", { count: "exact", head: true })
        .eq("organization_id", $profile.organization_id);

      const { count, error } = await query;
      if (error) throw error;

      return { count };
    } catch (error) {
      console.log(error);
    }
  };

  const quoteUpdate = async (payload) => {
    if (payload.eventType === "DELETE") {
      if ($quotes[payload.old.id]) unload(payload.old.id);
    } else if (payload.eventType === "UPDATE") {
      if ($quotes[payload.new.id]) {
        if (payload.new.archived_at) {
          unload(payload.new.id);
        } else {
          const { data } = await api
            .from("response_list_view")
            .select("*")
            .eq("id", payload.new.id)
            .eq("organization_id", $profile.organization_id)
            .single();

          if (data) loadOne(data);
        }
      }
    } else if (payload.eventType === "INSERT") {
      const { data } = await api
        .from("response_list_view")
        .select("*")
        .eq("id", payload.new.id)
        .eq("organization_id", $profile.organization_id)
        .single();

      if (data && !$jobs?.[data.job_id]) {
        loadOne(data);
      }
    }
  };

  let rfqSubscription;

  const notificationUpdate = async (payload) => {
    if ($quotes[payload.new.quote_id]) {
      const { data } = await api
        .from("response_list_view")
        .select("*")
        .eq("id", payload.new.quote_id)
        .eq("organization_id", $profile.organization_id)
        .single();

      if (data) loadOne(data);
    }
  };

  let notificationSubscription;

  return {
    subscribe,
    load,
    loadOne,
    unloadOne,
    unload,
    sort,
    fetchCount,

    // Subscribe to DB changes
    async subscribeToDbUpdates() {
      await this.unsubscribe();

      rfqSubscription = api
        .channel("quote-changes")
        .on(
          "postgres_changes",
          {
            event: "DELETE",
            schema: "public",
            table: "quotes",
            filter: `organization_id=eq.${$profile.organization_id}`,
          },
          quoteUpdate,
        )
        .on(
          "postgres_changes",
          {
            event: "INSERT",
            schema: "public",
            table: "quotes",
            filter: `organization_id=eq.${$profile.organization_id}`,
          },
          quoteUpdate,
        )
        .on(
          "postgres_changes",
          {
            event: "UPDATE",
            schema: "public",
            table: "quotes",
            filter: `organization_id=eq.${$profile.organization_id}`,
          },
          quoteUpdate,
        )
        .subscribe();

      notificationSubscription = api
        .channel("notification-changes")
        .on(
          "postgres_changes",
          {
            event: "INSERT",
            schema: "public",
            table: "notifications",
            filter: `organization_id=eq.${$profile.organization_id}`,
          },
          notificationUpdate,
        )
        .subscribe();
    },

    async unsubscribe() {
      if (rfqSubscription) {
        await rfqSubscription.unsubscribe();
      }

      if (notificationSubscription) {
        await notificationSubscription.unsubscribe();
      }
    },

    // Retrieves records from DB
    async fetch({ limit = null } = {}) {
      try {
        let query = api
          .from("response_list_view")
          .select("*")
          .eq("organization_id", $profile.organization_id)
          .is("archived_at", null);

        if ($profile?.user_role === "product_user") {
          query = query.eq("project_type", "product");
        }

        if (limit) {
          query = query.limit(limit);
        }

        query = query
          .order("sent_at", { ascending: false, nullsFirst: true })
          .order("request_created_at", { ascending: false });

        const { data, error } = await query;

        if (error) throw error;
        load(data);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchById(...ids) {
      try {
        const { data, error } = await api
          .from("response_list_view")
          .select("*")
          .in("id", ids);

        if (error) throw error;
        insert(data);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchOne(quoteid, sortPosition) {
      try {
        const { data, error } = await api
          .from("response_list_view")
          .select("*")
          .eq("id", quoteid)
          .single();
        if (error) throw error;

        loadOne(data, sortPosition);
      } catch (error) {
        console.log(error);
      }
    },

    async archive(...ids) {
      try {
        const { error } = await api
          .from("quotes")
          .update({ archived_at: new Date() })
          .in("id", ids);

        if (error) throw error;

        unload(...ids);
      } catch (error) {
        console.log(error);
      }
    },

    async unarchive(quoteid) {
      try {
        const { error } = await api
          .from("quotes")
          .update({ archived_at: null })
          .eq("id", quoteid);

        if (error) throw error;

        const { data, error: re } = await api
          .from("response_list_view")
          .select("*")
          .eq("id", quoteid)
          .single();

        if (re) throw re;

        loadOne(data);
      } catch (error) {
        console.log(error);
      }
    },
  };
}

export const quotes = createQuotes();

export async function fetchQuote(quoteid) {
  const { data: quote, error } = await api
    .from("quotes")
    .select(fullQuoteStr)
    .eq("id", quoteid)
    .single();

  if (error) throw error;

  return quote;
}

export const quoteFilter = writable("");
export const quoteSortBy = writable("sent_at");
export const quoteSortDirection = writable("desc");

function sortCategory(quote) {
  const canceled =
    quote.status === "canceled" || quote.request_status === "canceled";
  if (canceled) return 2;
  if (quote.sent_at) return 3;
  return 1;
}

export const filteredQuotes = derived(
  [quotes, quoteFilter, quoteSortBy, quoteSortDirection, myRfqsOnly, profile],
  ([$list, $filter, $sortBy, $sortDirection, $mineOnly, $profile]) => {
    const ids = $list?.order.filter((id) => {
      const quote = $list[id];

      if (
        $mineOnly &&
        quote.assigned_to_id &&
        quote.assigned_to_id !== $profile.id
      ) {
        return false;
      }

      const str = $filter.toLowerCase();
      const orgName = (quote.recipient_organization_name || "").toLowerCase();
      const name = (quote.job_name || "").toLowerCase();
      const location = (quote.job_location || "").toLowerCase();
      const seller_reference = (quote.seller_reference || "").toLowerCase();
      if (orgName.includes(str)) return true;
      if (name.includes(str)) return true;
      if (location.includes(str)) return true;
      if (seller_reference.includes(str)) return true;
      return false;
    });

    const f = selected($list, ids);

    sortList(f, {
      sortFunction: (a, b) => {
        const aCat = sortCategory(a);
        const bCat = sortCategory(b);

        if (aCat !== bCat) return aCat - bCat;

        if (aCat === 1 && bCat === 1) {
          return new Date(b.request_sent_at) - new Date(a.request_sent_at);
        }

        return new Date(b.sent_at) - new Date(a.sent_at);
      },
    });
    return f;
  },
);

export function unsentList(filteredQuotes) {
  return derived(filteredQuotes, ($list) =>
    $list.order.filter((id) => !$list[id].sent_at).map((id) => $list[id]),
  );
}

export function sentList(filteredQuotes) {
  return derived(filteredQuotes, ($list) =>
    $list.order.filter((id) => $list[id].sent_at).map((id) => $list[id]),
  );
}

export function createQuoteArray(filteredQuotes) {
  return derived(filteredQuotes, ($list) => $list.order.map((id) => $list[id]));
}

export const selectedQuoteIds = storedWritable(
  "selected_quote_ids",
  stored.selected_quote_ids,
  "quotes",
);
