<script>
  import mime from "mime";
  import { downloadBlob } from "downloaders";

  import Bugsnag from "src/bugsnag.js";

  import UploadIcon from "src/assets/icons/upload.svg";
  import BlankIcon from "src/assets/icons/blank.svg";
  import TrashIcon from "src/assets/icons/trash.svg";
  import CaretLeft from "src/assets/icons/caret-left.svg";
  import CaretRight from "src/assets/icons/caret-right.svg";
  import FileIcon from "src/assets/icons/file.svg";
  import FilePdfIcon from "src/assets/icons/file-pdf.svg";
  import FileImgIcon from "src/assets/icons/file-img.svg";
  import DownloadIcon from "src/assets/icons/download.svg";
  import ImageIcon from "src/assets/icons/image.svg";
  import SpinnerIcon from "src/assets/icons/spinner-lg.svg";
  import LocationItemIcon from "src/assets/icons/location-item-empty.svg";

  import { Modal } from "svelte-utilities";
  import { api, storeObject } from "src/api";
  import { profile } from "src/stores/auth.js";
  import Dropzone from "src/lib/dropzone/Dropzone.svelte";
  import decodeImage from "src/extensions/dom/image-decode.js";
  import imgClipToBase64 from "src/extensions/dom/img-clip-to-base64.js";
  import imgClipToBlob from "src/extensions/dom/img-clip-to-blob.js";
  import extension from "@local/extensions/utilities/extension.js";
  import nameMinusExtension from "@local/extensions/utilities/name-minus-extension.js";
  import { objectify } from "overline";
  import { tooltip } from "svelte-utilities";
  import { SUPABASE_URL as supabaseUrl, TRIOPS_URL as triopsUrl } from "src/env";

  export let attachments = [];
  export let group;
  export let itemid = null;
  export let typeid = null;
  export let disabled = false;

  const fileIcons = {
    file: BlankIcon,
    pdf: FilePdfIcon,
    jpg: FileImgIcon,
    jpeg: FileImgIcon,
    png: FileImgIcon,
    svg: FileImgIcon,
  };

  const previewable = ["image/jpeg", "image/png", "image/tiff", "image/gif", "application/pdf"];

  let dropzoneModal;
  let dropzone;
  let attachmentToRemove;
  let removeModal;

  let currentImageIndex = 0;
  let thumbnail;

  $: clips = getClips($group.data.documents);
  $: imageAttachments = attachments.filter((a) => previewable.includes(a.metadata.content_type));
  $: viewableImages = [...clips, ...imageAttachments];
  $: attachmentList = [...clips, ...attachments];
  $: currentImage = viewableImages[currentImageIndex];
  $: getImageThumbnail(currentImage);
  $: prevImage = viewableImages[currentImageIndex - 1];
  $: nextImage = viewableImages[currentImageIndex + 1];

  function upload() {
    dropzoneModal.open();
  }

  function name(filename) {
    const segs = filename.split(".");
    if (segs.length > 1) return segs.slice(0, -1).join(".");
    return segs[0];
  }

  function filename(path) {
    return path.split("/").pop();
  }

  function iiifName(attachment) {
    return `${nameMinusExtension(attachment.object_id)}.0.tiff`;
  }

  function getClips(docs) {
    const recordid = itemid || typeid;
    if (!recordid) return [];

    return docs.order.reduce((locs, id) => {
      const doc = docs[id];
      const files = objectify(doc.files, "object_id");

      const c = doc.locations
        .filter((l) => l.record_id === recordid && l.clip)
        .map((location) => {
          const file = files[location.position.file_path];
          return {
            type: "clip",
            document_id: doc.id,
            id: location.id,
            name: file?.name,
            file,
            location,
          };
        });

      locs.push(...c);
      return locs;
    }, []);
  }

  async function uploadFiles(evt) {
    const { file, data } = evt.detail;
    const id = crypto.randomUUID();
    const ext = extension(file.name);
    let fname = file.name;
    while (attachments.some((a) => filename(a.name) === fname)) {
      const n = name(fname);
      fname = n + "-copy." + ext;
    }

    const object_id = `${id}/doc.${ext}`;
    const contentType = mime.getType(ext);

    try {
      const s = await storeObject($group.id, "attachments", object_id, contentType, data);

      await group.addAttachment({
        id,
        user_id: $profile.id,
        group_id: $group.id,
        item_id: itemid,
        type_id: typeid,
        object_id,
        preview_object_id: s.sidecarPath || object_id,
        name: fname,
        metadata: {
          id,
          extension: ext,
          content_type: contentType,
        },
      });
    } catch (err) {
      Bugsnag.notify(err);
      console.log("112", err);
    }

    dropzone.stopLoading();
    dropzoneModal.close();
  }

  async function waitUntil(condition) {
    return await new Promise((resolve) => {
      const interval = setInterval(async () => {
        const ready = await condition();
        if (ready) {
          resolve(ready);
          clearInterval(interval);
        }
      }, 1000);
    });
  }

  async function pdfThumbnail(attachment) {
    if (attachment.preview_object_id) {
      const url = await api.storage.from("attachments").createSignedUrl(attachment.preview_object_id, 60, {
        transform: { width: 540, height: 320, resize: "contain" },
      });

      if (!url.error) {
        return url.data.signedUrl;
      }
    }

    const iiif0name = iiifName(attachment);
    const { data: exists, error } = await api.rpc("storage_object_exists", {
      bucket_id: "drawings",
      object_id: iiif0name,
    });

    if (error) throw error;

    if (!exists) {
      const condition = async () => {
        const { data: exists } = await api.rpc("storage_object_exists", {
          bucket_id: "drawings",
          object_id: iiif0name,
        });
        if (exists) return true;
        return false;
      };

      await waitUntil(condition);
    }

    const path = encodeURIComponent(iiif0name);
    return `${triopsUrl}/iiif/2/drawings/${path}/full/540,/0/default.jpg`;
  }

  async function ensureDrawingExists(attachment) {
    if (attachment.type === "clip") {
      if (attachment.file?.extension === "pdf") {
        if (!attachment.file?.object_id) throw new Error("No object_id for clip.");
        if (!attachment.location.position) throw new Error("No position for clip.");
        const path = `${nameMinusExtension(attachment.file.object_id)}.${attachment.location.position.file_page}.tiff`;

        return {
          attachment_id: attachment.id,
          id: attachment.file.object_id.split("/")[0],
          name: attachment.file.name,
          type: "iiif",
          path,
          clip: attachment.location.clip,
          extension: "pdf",
          properties: {
            width: attachment.location.clip.width,
            height: attachment.location.clip.height,
          },
        };
      } else {
        const file = await api.storage.from("documents").download(attachment.file.object_id);
        if (file.error) throw file.error;

        const url = URL.createObjectURL(file.data);
        const blob = await imgClipToBlob(url, attachment.location.clip);
        const clipId = crypto.randomUUID();
        const object_id = `${attachment.file.object_id}/${clipId}.png`;

        const stored = await api.storage.from("drawings").upload(object_id, blob, {
          contentType: "image/png",
        });
        if (stored.error) throw stored.error;

        return {
          attachment_id: attachment.id,
          id: clipId,
          name: attachment.file.name,
          type: "image",
          extension: "png",
          object_id,
          preview_object_id: object_id,
          properties: {
            width: attachment.location.clip.width,
            height: attachment.location.clip.height,
          },
        };
      }
    } else if (attachment.metadata.extension === "pdf") {
      const id = attachment.metadata.id;

      // First, check if the attachment already has a viewable image in the "drawings" bucket.
      const { data: exists } = await api.rpc("storage_object_exists", {
        bucket_id: "drawings",
        object_id: `${id}/doc.pdf.tiff`,
      });

      if (exists) {
        const path = encodeURIComponent(`${id}/doc.pdf.tiff`);
        const dwgurl = `${supabaseUrl}/storage/v1/render/image/public/drawings/${path}`;
        const dwgimg = await decodeImage(dwgurl);

        return {
          attachment_id: attachment.id,
          id,
          name: attachment.name,
          type: "image",
          extension: "jpg",
          object_id: `${id}/doc.pdf`,
          preview_object_id: `${id}/doc.pdf.tiff`,
          properties: {
            width: dwgimg.width,
            height: dwgimg.height,
          },
        };
      }

      // If not, we will store the object in the "drawings" bucket and create a thumbnail.
      const original = `${id}/doc.pdf`;
      const contentType = "application/pdf";
      const { data, error } = await api.storage.from("attachments").download(attachment.object_id);
      if (error) throw error;
      const s = await storeObject($group.id, "drawings", original, contentType, data);

      return {
        attachment_id: attachment.id,
        id,
        name: attachment.name,
        type: "image",
        extension: "jpg",
        object_id: original,
        preview_object_id: s.sidecarPath,
        properties: s.sidecarProperties,
      };
    } else {
      // If the attachment is an image, we can first check if it is present in the "drawings"
      // bucket. If not, we can store it there.

      const { data: exists } = await api.rpc("storage_object_exists", {
        bucket_id: "drawings",
        object_id: attachment.object_id,
      });

      if (!exists) {
        const a = await api.storage.from("attachments").download(attachment.object_id);
        if (a.error) throw a.error;
        await api.storage.from("drawings").upload(attachment.object_id, a.data, {
          contentType: attachment.metadata.content_type,
        });
      }

      const url = `${supabaseUrl}/storage/v1/render/image/public/drawings/${encodeURIComponent(
        attachment.object_id,
      )}`;

      const img = await decodeImage(url);

      return {
        attachment_id: attachment.id,
        id: attachment.object_id.split("/")[0],
        name: attachment.name,
        extension: attachment.metadata.extension,
        object_id: attachment.object_id,
        preview_object_id: attachment.object_id,
        properties: {
          width: img.width,
          height: img.height,
        },
      };
    }
  }

  async function iiifThumbnail(image) {
    if (!image.file?.object_id || !image.location.position) return null;
    const slug = `${nameMinusExtension(image.file.object_id)}.${image.location.position.file_page}.tiff`;
    const path = encodeURIComponent(slug);
    const { x, y, width, height } = image.location.clip;
    return `${triopsUrl}/iiif/2/drawings/${path}/${x},${y},${width},${height}/full/0/default.jpg`;
  }

  async function imgThumbnail(image) {
    const { data, error } = await api.storage.from("documents").download(image.file.object_id);
    if (error) return null;

    const url = URL.createObjectURL(data);
    const clipped = await imgClipToBase64(url, image.location.clip);
    return clipped;
  }

  async function getImageThumbnail(image) {
    if (!image) {
      thumbnail = null;
      return;
    }

    if (image.type === "clip") {
      if (image.file?.extension === "pdf") {
        thumbnail = await iiifThumbnail(image);
      } else {
        thumbnail = await imgThumbnail(image);
      }
      return;
    }

    if (!previewable.includes(image.metadata.content_type)) {
      thumbnail = null;
      return;
    }
    const att = await api.storage.from("attachments").list("", { limit: 1, search: image.metadata.id });

    if (!att.error && att.data.length) {
      if (image.metadata.content_type === "application/pdf") {
        try {
          thumbnail = await pdfThumbnail(image);
        } catch (error) {
          console.log(error);
          thumbnail = null;
          return;
        }
        return;
      }
      const { data, error } = await api.storage.from("attachments").createSignedUrl(image.object_id, 60, {
        transform: { width: 512, height: 320, resize: "contain" },
      });

      if (error) {
        thumbnail = null;
        return;
      }

      thumbnail = data.signedUrl;
    }
  }

  async function makePreview() {
    const attachment = currentImage;
    try {
      // Check whether attachment preview has already been created
      const drawing = await ensureDrawingExists(attachment);

      if (itemid) {
        await group.updateItem(itemid, "drawing", drawing);
      } else if (typeid) {
        await group.updateType(typeid, "drawing", drawing);
      }
    } catch (error) {
      console.log("223", error);
    }
  }

  function removePreview() {
    if (itemid) {
      group.updateItem(itemid, "drawing", null);
    } else if (typeid) {
      group.updateType(typeid, "drawing", null);
    }
  }

  async function openAttachment(attachment) {
    try {
      const { data, error } = await api.storage.from("attachments").download(attachment.object_id);
      if (error) throw error;

      downloadBlob(data, attachment.name);
    } catch (error) {
      console.log("242", error);
    }
  }

  function confirmRemoveAttachment(attachment) {
    attachmentToRemove = attachment;
    removeModal.open();
  }

  async function removeAttachment(attachment) {
    if (itemid) {
      const item = $group.items[itemid];
      if (attachment.id === item?.drawing?.attachment_id) {
        await group.updateItem(itemid, "drawing", null);
      }
    } else if (typeid) {
      const type = $group.types[typeid];
      if (attachment.id === type?.drawing?.attachment_id) {
        await group.updateType(typeid, "drawing", null);
      }
    }

    await group.removeAttachment(attachment.id);
  }

  function gotoPrev() {
    currentImageIndex -= 1;
  }

  function gotoNext() {
    currentImageIndex += 1;
  }

  function setCurrentImage(attachment) {
    currentImageIndex = viewableImages.findIndex((a) => a.id === attachment.id);
  }
</script>

<div class="px-6 text-xs">
  <div class="flex justify-between items-center">
    <h3 class="mb-2">Attachments</h3>
    <div>
      <button class="prev-next-button" on:click={gotoPrev} disabled={!prevImage} class:active={prevImage}>
        <CaretLeft />
      </button>
      <button class="prev-next-button" on:click={gotoNext} disabled={!nextImage} class:active={nextImage}>
        <CaretRight />
      </button>
    </div>
  </div>
  {#if viewableImages.length > 0}
    <div class="border mb-2">
      <div class="relative">
        {#if thumbnail}
          <div class="w-full h-36 relative">
            <img class="w-full h-full object-contain text-gray-500" src={thumbnail} alt={currentImage.name} />
            {#if itemid}
              {@const item = $group.items[itemid]}
              {@const isDrawing = item?.drawing?.attachment_id === currentImage.id}
              {#if isDrawing}
                <button
                  class="absolute bottom-0 right-0 px-2 py-1 flex gap-1 items-center text-xs rounded bg-gray-100/75"
                  on:click={removePreview}>
                  <div>Remove Drawing</div>
                  <ImageIcon />
                </button>
              {:else}
                <button
                  class="absolute bottom-0 right-0 px-2 py-1 flex gap-1 items-center text-xs rounded bg-gray-100/75"
                  on:click={makePreview}>
                  <div>Set Drawing</div>
                  <ImageIcon />
                </button>
              {/if}
            {/if}
          </div>
        {:else}
          <div class="w-full h-36 flex items-center justify-center">
            <div class="animate-spin">
              <SpinnerIcon />
            </div>
          </div>
        {/if}
      </div>
    </div>
  {/if}
  {#if attachmentList.length > 0}
    <div class="space-y-2 mb-2">
      {#each attachmentList as attachment}
        {@const isPreview = attachment.id === currentImage?.id}
        <div class="attachment flex items-center gap-2 justify-between overflow-hidden">
          <div class="flex gap-1 overflow-hidden">
            {#if attachment.type === "clip" || previewable.includes(attachment.metadata.content_type)}
              <button
                class="flex-none"
                class:text-black={isPreview}
                class:text-gray-400={!isPreview}
                on:click={() => setCurrentImage(attachment)}
                use:tooltip={{ text: "Show Image" }}>
                {#if attachment.type === "clip"}
                  <LocationItemIcon />
                {:else}
                  <svelte:component this={fileIcons[extension(attachment.name)] || FileIcon} />
                {/if}
              </button>
              <button class="grow overflow-hidden" on:click={() => setCurrentImage(attachment)}>
                <div class="truncate">
                  {attachment.name}
                </div>
              </button>
            {:else}
              <div class="flex-none text-gray-400">
                <FileIcon />
              </div>
              <div class="grow truncate">
                {attachment.name}
              </div>
            {/if}
          </div>
          {#if attachment.type !== "clip"}
            <div class="flex gap-1">
              <button
                class="flex-none text-gray-400 hover:text-black"
                on:click={() => openAttachment(attachment)}
                use:tooltip={{ text: "Download Attachment" }}>
                <DownloadIcon />
              </button>
              <button
                class="flex-none text-gray-400 hover:text-black"
                use:tooltip={{ text: "Delete Attachment" }}
                on:click={() => confirmRemoveAttachment(attachment)}>
                <TrashIcon />
              </button>
            </div>
          {/if}
        </div>
      {/each}
    </div>
  {/if}
  <div class="flex items-center justify-end">
    {#if attachmentList.length === 0}
      <div class="grow text-gray-400">No attachments.</div>
    {/if}
    {#if !disabled}
      <button on:click={upload} class="flex gap-2 items-center">
        <UploadIcon />
        <div>Upload</div>
      </button>
    {/if}
  </div>
</div>

<Modal bind:this={dropzoneModal} closeable>
  <div slot="title">Upload</div>
  <div slot="content">
    <Dropzone on:drop={uploadFiles} bind:this={dropzone} />
  </div>
</Modal>

<Modal
  closeable
  on:confirm={() => removeAttachment(attachmentToRemove)}
  bind:this={removeModal}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Delete", type: "confirm", style: "danger" },
  ]}>
  <div slot="title">Delete Attachment</div>
  <div slot="content" class="space-y-4 text-sm">
    <div>Are you sure you want to delete this attachment?</div>
    <div class="font-bold">
      {attachmentToRemove.name}
    </div>
  </div>
</Modal>

<style lang="scss">
  .prev-next-button {
    @apply rounded p-1 text-gray-400;

    &.active {
      @apply text-black;
      &:hover {
        @apply bg-gray-100;
      }
    }
  }
</style>
