import { roundValue } from "@local/extensions/parsers/round-value.js";

function intersects({ x: testX, y: testY }, seg) {
  const x = roundValue(testX);
  const y = roundValue(testY);
  const ax = roundValue(seg.start.x);
  const ay = roundValue(seg.start.y);
  const bx = roundValue(seg.end.x);
  const by = roundValue(seg.end.y);

  let r = false;

  if ((ay < y && by >= y) || (by < y && ay >= y)) {
    if (ax + ((y - ay) / (by - ay)) * (bx - ax) < x) {
      r = true;
    }
  }

  return r;
}

// TODO: handle the case where the enclosing path is not composed
// of all straight segments.
export default function polyfaceContainsPoint(polyface, point) {
  const perimeter = polyface.edges[0];

  const nodes = perimeter.reduce((n, segment) => {
    let ints = 0;

    if (intersects(point, segment)) {
      ints += 1;
    }

    return n + ints;
  }, 0);

  if (nodes % 2 === 1) {
    return true;
  }
  return false;
}
