import { PDFWorker, getDocument as pdfGetDocument } from 'pdfjs-dist';

const workers = [];
const url = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url);

function getWorker(idx = 0) {
	let worker = workers[idx];

	if (!worker) {
		const port = new Worker(url);
		worker = new PDFWorker({ port });
	}
	return worker;
}

/**
 *
 * @param { string | URL | ArrayBuffer } src
 * @param {import('pdfjs-dist').PDFWorker} worker
 * @returns
 */
function getDocument(src, worker = getWorker()) {
	const initParams =
		typeof src === 'string' || (typeof src === 'object' && src instanceof URL)
			? { url: src }
			: { data: src };

	const { promise: doc } = pdfGetDocument({ ...initParams, worker });
	return doc;
}

export { getWorker, getDocument };
