<!-- <svelte:options strictprops={false} /> -->

<script>
  import { navigate } from "svelte-routing";

  import Navbar from "src/lib/Navbar.svelte";
  import Pager from "src/lib/Pager.svelte";
  import FilterInput from "src/lib/standard-inputs/FilterInput.svelte";
  import ErrorMessage from "src/lib/standard-inputs/ErrorMessage.svelte";
  import Datatable from "src/lib/datatable/Datatable.svelte";

  import { createOrg as defaultOrg } from "@local/lamina-core";
  import { api } from "src/api";
  import { orgs, orderedOrgs, orgListError } from "../stores/organizations.js";
  import sanitizePgString from "src/extensions/sanitize-postgrest-string.js";

  export let queryParams;

  const maxRows = 20;

  let filter = "";
  let sortBy = "name";
  let sortAsc = true;
  let filterTimer;
  let newOrg = null;
  let count;

  $: inputPage = parseInt(queryParams?.page) || 1;
  $: page = Math.max(inputPage - 1, 0);
  $: {
    page;
    fetchOrgs();
  }

  const columns = [
    {
      label: "Name",
      prop: "name",
      type: "text",
      required: true,
    },
    {
      label: "Slug",
      prop: "slug",
      type: "text",
      editable: false,
    },
    {
      label: "Org Type",
      prop: "org_type",
      type: "select",
      options: [
        { label: "supplier", value: "supplier" },
        { label: "recipient", value: "recipient" },
        { label: "product rep", value: "product_rep" },
      ],
    },
    {
      label: "Demo",
      prop: "is_demo",
      type: "boolean",
      editable: false,
    },
    {
      label: "Account Type",
      prop: "account_type",
      type: "text",
      editable: false,
    },
    // {
    //   label: "Stub",
    //   prop: "is_stub",
    //   type: "boolean",
    //   editable: false,
    // },
    {
      label: "Confirmed",
      prop: "confirmed_at",
      type: "boolean",
      editable: false,
    },
  ];

  function beginAddingOrg() {
    newOrg = defaultOrg();
  }

  function clickRow(evt) {
    const { id } = evt.detail;
    navigate(`/orgs/${id}`);
  }

  function addNewOrg(evt) {
    const { row } = evt.detail;
    orgs.add(row);
    newOrg = null;
  }

  function stopAddingOrg() {
    newOrg = null;
  }

  function removeRow(evt) {
    const { id } = evt.detail;
    orgs.remove(id);
  }

  function gotoPage(e) {
    const p = e.detail;
    navigate(`/orgs?page=${p + 1}`);
  }

  function debounce() {
    clearTimeout(filterTimer);
    filterTimer = setTimeout(() => {
      fetchOrgs();
    }, 500);
  }

  async function fetchOrgs() {
    const min = page * maxRows;
    const max = min + maxRows - 1;

    orgs.fetch({ limit: maxRows, min, max, filter, sortBy, sortAsc });

    let cquery = api.from("organizations").select("*", { count: "exact", head: true });

    if (filter) {
      cquery = cquery.or(`name.ilike.%${sanitizePgString(filter)}%`);
    }

    const { count: c, error: countError } = await cquery;

    if (countError) return;

    count = c;
  }

  async function sortTable(e) {
    const { column } = e.detail;
    if (sortBy === column.prop) {
      sortAsc = !sortAsc;
    } else {
      sortAsc = true;
      sortBy = column.prop;
    }
    await fetchOrgs();
  }
</script>

<Navbar />
<div class="grow overflow-auto bg-gray-100 p-4 flex flex-col min-h-0">
  <div class="flex justify-between">
    <h2 class="p-4">Organizations</h2>
    <div class="flex items-center px-4">
      <div class="text-sm p-4 flex items-center gap-2">
        <div>Filter list:</div>
        <FilterInput bind:value={filter} on:input={() => debounce()} />
      </div>
      <button class="btn btn-primary" on:click={beginAddingOrg}>+ New Org</button>
    </div>
  </div>
  <ErrorMessage message={$orgListError} on:close={() => ($orgListError = null)} />
  <div class="grow overflow-hidden">
    <div class="h-full flex flex-col justify-between w-full bg-white border rounded-lg mb-4 overflow-hidden">
      <div class="overflow-y-auto">
        <Datatable
          data={$orderedOrgs}
          {columns}
          newRow={newOrg}
          deletable
          clickable
          sortable
          {sortBy}
          sortDirection={sortAsc ? "asc" : "desc"}
          on:clickrow={clickRow}
          on:addrow={addNewOrg}
          on:sort={sortTable}
          on:stopaddingrow={stopAddingOrg}
          on:deleterow={removeRow} />
      </div>
      <Pager {page} {count} on:gotopage={gotoPage} />
    </div>
  </div>
</div>
