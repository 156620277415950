<script>
  import { createEventDispatcher, tick } from "svelte";
  import { DimText, LinearDisplayFormat } from "dimtext";
  import dimSettings from "@local/extensions/utilities/dim-settings.js";

  export let border = false;
  export let label = "Label";
  export let labelWidth = null;
  export let value;
  export let disabled = false;
  export let readonly = false;
  export let validator = (v) => true;
  export let coercer = (v) => v;
  export let settings;
  export let allowNull = false;
  export let highlightNull = true;
  export let max = undefined;
  export let min = undefined;

  const dispatch = createEventDispatcher();

  let input;
  let focused = false;
  let inputContents;

  $: ds = dimSettings(settings);
  $: dt = new DimText({ defaultUnit: ds.displayUnit });
  $: unit = ds.displayUnit === "inches" ? '"' : "mm";
  $: lw = labelWidth ? `width:${labelWidth};` : "";
  $: inputValue = value === "Mixed" ? value : value?.toString();
  $: noinput = !value && highlightNull;
  $: displayValue = value === "Mixed" ? value : formatDisplayValue(value, unit);
  $: update(value, ds.dimFormat);
  $: valid = validate(inputContents);

  function formatDisplayValue(value, unit) {
    const v = value?.format(LinearDisplayFormat[ds.dimFormat], ds.dimPrecision, {
      displayUnit: ds.displayUnit,
    });
    if (v) return `${v}${unit}`;
    return null;
  }

  function update(value, sdf) {
    inputContents = focused ? inputValue : displayValue;
  }

  function validate(inputContents) {
    const result = inputContents && inputContents !== "Mixed" && dt.parse(inputContents);

    if (!result || !result.ok) return false;

    try {
      const dim = result.value;
      if (!validator(dim)) return false;
      if (min) {
        const mincompare = min.compare(dim);
        if (mincompare > 0) return false;
      }

      if (max) {
        const maxcompare = max.compare(dim);
        if (maxcompare < 0) return false;
      }

      return true;
    } catch (err) {
      return false;
    }
  }

  function parseInput(val) {
    try {
      if (val === "" && allowNull && value !== null) {
        value = null;
        dispatch("input", { value: null });
        return;
      }

      let result = dt.parse(val);
      if (!result.ok) throw result.err;
      if (value?.toString() !== result.value.toString()) {
        const coerced = coercer(result.value);
        if (valid) {
          value = coerced;
          dispatch("input", { value: coerced });
        }
      }
    } catch (err) {
      dispatch("error", err);
    }
  }

  function focus() {
    dispatch("focus");
    input.focus();
  }

  async function handleFocus(evt) {
    focused = true;
    inputContents = inputValue;
    await tick();
    input.select();
  }

  function handleKeydown(evt) {
    if (evt.key === "Enter") {
      evt.stopPropagation();
      input.blur();
    }
  }

  async function handleBlur(evt) {
    focused = false;
    parseInput(evt.target.value);
    await tick();
    inputContents = displayValue;
    dispatch("blur");
  }
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  class="prop-container"
  class:focused
  class:readonly={disabled || readonly}
  class:visible-border={border}
  class:noinput
  on:mouseover
  on:mouseout
  on:focus
  on:blur
  on:click|stopPropagation={focus}>
  <div class="label" style={lw}>
    <slot name="label">
      {label}
    </slot>
  </div>
  {#if readonly}
    <div bind:this={input} class="input">{displayValue}</div>
  {:else}
    <input
      bind:this={input}
      class="input"
      {disabled}
      bind:value={inputContents}
      on:focus={handleFocus}
      on:keydown={handleKeydown}
      on:blur={handleBlur} />
  {/if}
</div>

<style lang="scss">
  input {
    border: none;
    background-color: none;
    outline: none;
    min-width: 0;
    max-width: 100%;
  }

  .prop-container {
    @apply px-2 py-2 w-full flex items-center rounded;

    .label {
      @apply truncate text-gray-400 flex-none;
    }

    &.noinput:not(.focused) {
      input {
        @apply bg-red-200;
      }

      .label {
        @apply text-black;
      }
    }

    &:hover:not(.readonly),
    &.visible-border {
      @apply ring-1 ring-inset ring-gray-300;
    }

    &.focused {
      @apply ring-2 ring-inset ring-blue-500;
    }

    .input {
      @apply min-w-0 grow shrink bg-transparent ml-2;
    }
  }
</style>
