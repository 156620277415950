<script>
  import { createEventDispatcher } from "svelte";
  import { Dashboard } from "@uppy/svelte";
  import Uppy from "@uppy/core";
  import Tus from "@uppy/tus";
  import { api } from "src/api";

  import "@uppy/core/dist/style.css";
  import "@uppy/dashboard/dist/style.css";

  export let uploadUrl;
  export let bucket;
  export let accept = null;

  const dispatch = createEventDispatcher();

  let uppy;

  async function initUppy() {
    const { data } = await api.auth.getSession();
    const { access_token } = data.session;

    uppy = new Uppy({
      restrictions: {
        ...(accept && { allowedFileTypes: accept.split(",") }),
      },
    }).use(Tus, {
      endpoint: uploadUrl,
      headers: {
        authorization: `Bearer ${access_token}`,
      },
      chunkSize: 6 * 1024 * 1024,
      removeFingerprintOnSuccess: true,
      storeFingerprintForResuming: false,
      allowedMetaFields: ["uuid", "bucketName", "objectName", "contentType", "cacheControl"],
    });

    uppy.on("file-added", (file) => {
      const uuid = crypto.randomUUID();

      file.meta = {
        ...file.meta,
        name: file.name,
        uuid,
        bucketName: bucket,
        objectName: `${uuid}/doc.${file.extension}`,
        contentType: file.type,
      };

      dispatch("file-added", { file });
    });

    uppy.on("complete", (result) => {
      dispatch("complete", { results: result.successful });
    });
  }
</script>

{#await initUppy() then _}
  <Dashboard {uppy} props={{ proudlyDisplayPoweredByUppy: false }} />
{/await}
