import { writable, derived } from "svelte/store";
import creatRecordList from "./record-list";
import { api } from "src/api";
import { createSampleRfq } from "src/api/requests.js";
import { alphabeticMultiSort } from "@local/extensions/utilities/sort.js";
import sanitizePgString from "src/extensions/sanitize-postgrest-string";

export const orgListError = writable(null);

function createOrgs() {
  const { subscribe, load, loadOne, unloadOne } = creatRecordList();

  return {
    subscribe,
    load,
    loadOne,
    unloadOne,

    // Retrieves records from DB
    async fetch({
      limit = null,
      min = 0,
      max = 20,
      filter = null,
      sortBy = "name",
      sortAsc = true,
    } = {}) {
      try {
        let query = api
          .from("organizations")
          .select("*,product_lists!product_lists_vendor_id_fkey(*)");
        if (limit) query = query.limit(limit).range(min, max);
        if (filter)
          query = query.or(`name.ilike."%${sanitizePgString(filter)}%"`);

        if (sortBy) {
          query = query.order(sortBy, { ascending: sortAsc });
        }

        const { data, error } = await query;
        if (error) throw error;

        load(data);
      } catch (error) {
        console.log(error);
      }
    },

    // Retrieves a single record from DB
    async fetchOne(orgid) {
      try {
        const { data, error } = await api
          .from("organizations")
          .select(
            `
            *,
            product_lists!product_lists_vendor_id_fkey(*),
            profiles!profiles_organization_id_fkey(*),
            contacts!contacts_organization_id_fkey(*,contact:contact_id(*),primary_contact:primary_contact_id(*))
          `,
          )
          .eq("id", orgid)
          .single();
        if (error) throw error;

        data.contacts.sort(alphabeticMultiSort);
        loadOne(data);
      } catch (error) {
        console.log(error);
      }
    },

    // Persists a new org in DB
    async add(org) {
      orgListError.set(null);

      try {
        const { created_at, ...o } = org;

        const { data, error } = await api
          .from("organizations")
          .insert([o])
          .select("*");

        if (error) throw error;

        const [newOrg] = data;

        loadOne(newOrg);

        // Create a sample RFQ for the new org
        if (org.org_type === "recipient") {
          await createSampleRfq({ organization_id: newOrg.id });
        }
      } catch (error) {
        orgListError.set(error.message);
      }
    },

    // Remove an org from DB
    async remove(id) {
      orgListError.set(null);

      try {
        const { data, error } = await api
          .from("organizations")
          .delete()
          .eq("id", id);

        if (error) throw error;

        unloadOne(id);
      } catch (error) {
        console.log(error);
        if (error.code === "23503") {
          console.log(error);
          const match = error.message.match(/on table "(\w+)"/g);
          const r = match[1].match(/"(\w+)"/)[1];
          orgListError.set(
            `You must delete any related ${r} before this organization can be deleted.`,
          );
        } else {
          orgListError.set(error.message);
        }
      }
    },
  };
}

export const orgs = createOrgs();

export const orderedOrgs = derived(orgs, ($orgs) =>
  $orgs.order.map((id) => $orgs[id]),
);
