<script>
  import { Link } from "svelte-routing";
  import { getContext } from "svelte";
  import get from "lodash/get";
  import Viewport from "src/lib/drawing/ViewportFixed.svelte";
  import liteDrawingMini from "@local/extensions/drawing/lite-drawing-mini.js";

  import CircleCheckIcon from "src/assets/icons/circle-check-filled.svg";
  import CircleWarningIcon from "src/assets/icons/circle-warning-filled.svg";
  import formatDims from "@local/extensions/formatters/format-dims.js";

  export let group;
  export let item;

  const typeColors = getContext("typeColors");

  $: itemLabel = label(item);
  $: settings = $group.data.settings;
  $: typeColor = $typeColors[item.type_id];

  function lb(item) {
    return item.is_collection ? $group.data.settings.collections_label_by : $group.data.settings.label_by;
  }

  function label(item) {
    const labelBy = lb(item);
    return labelBy && get(item, labelBy);
  }
</script>

<div class="flex justify-between items-center gap-2">
  <div class="flex gap-2 items-center grow overflow-hidden">
    <div class="overflow-hidden">
      <Link to={`${item.is_collection ? "openings" : "items"}/${item.id}`}>
        <div class="flex gap-2">
          <div class="w-4 h-4 relative">
            <Viewport padding={1} drawing={liteDrawingMini(item, { typeColor })} width={16} height={16} />
          </div>
          <div class="truncate">
            <span class="text-blue-500">{item.mark}</span>
            {#if itemLabel && itemLabel !== item.mark}
              <span> - </span>
              <span>{label(item)}</span>
            {/if}
          </div>
        </div>
      </Link>
    </div>
    <div class="whitespace-nowrap">{formatDims(item, settings)}</div>
    <slot />
  </div>
  {#if item.approval_status !== "none"}
    <div>
      {#if item.approval_status === "approved"}
        <div class="text-green-500">
          <CircleCheckIcon />
        </div>
      {:else if item.approval_status === "flagged"}
        <div class="text-red-500">
          <CircleWarningIcon />
        </div>
      {/if}
    </div>
  {/if}
</div>
