import cloneDeep from "lodash/cloneDeep";

function makeMultiType(grp, s) {
  if (s.length < 2) return null;

  const ts = [...s];
  const last = cloneDeep(ts.pop());

  return ts.reduce((m, type) => {
    Object.entries(type).forEach(([key, value]) => {
      if (m[key] !== value) m[key] = "Mixed";
    });

    return m;
  }, last);
}

export default makeMultiType;
