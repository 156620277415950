<svelte:options strictprops={false} />

<script>
  import upperCase from "lodash/upperCase";
  import ProfileIcon from "src/assets/icons/profile.svg";
  import SpinnerIcon from "src/assets/icons/spinner-lg.svg";
  import ProgressBar from "./ProgressBar.svelte";
  import formatHumanTime from "@local/extensions/formatters/format-human-time.js";
  import getUserInitials from "@local/extensions/formatters/get-user-initials.js";
  import { profile } from "src/stores/auth.js";

  export let datum;

  $: hasNotifications = datum.notifications_count > 0;
  $: completedQuotes = datum.submitted_quotes_count;
  $: status = getStatus(datum);
  $: draft = status === "draft";
  $: complete = status === "completed";
  $: spinning = datum.spinning === true;

  function getStatus(datum) {
    if (datum.status === "canceled") return "canceled";
    if (!datum.sent_at) return "draft";
    if (completedQuotes < datum.quotes_count) return "in-progress";
    if (completedQuotes === datum.quotes_count) return "completed";

    return null;
  }

  function items(count) {
    if (!count) return "No units";
    if (count === 0) return "No units";
    if (count === 1) return "1 unit";
    return `${count} units`;
  }
</script>

<td class="relative select-none">
  <div class="absolute top-4 left-4">
    <div class="rounded-full w-2 h-2" class:bg-blue-500={hasNotifications} />
  </div>
  <div
    class="border-l-4 overflow-x-hidden relative"
    class:border-none={draft}
    class:border-blue-300={complete}
    class:border-transparent={!draft && !complete}>
    {#if spinning}
      <div class="absolute w-full h-full flex items-center pl-6">
        <div class="absolute">
          <div class="animate-spin">
            <SpinnerIcon />
          </div>
        </div>
      </div>
    {/if}
    <div
      class="pl-6 py-2 flex gap-2 justify-between overflow-x-hidden"
      class:opacity-40={status === "canceled"}
      class:opacity-20={spinning}>
      <div class="overflow-x-hidden grow min-w-0">
        <div class="truncate font-bold text-md">
          {datum.job_name}
        </div>
        <div class="truncate text-xs">
          {#if datum.location}
            {datum.location}&nbsp;
          {:else if datum.seller_reference}
            {datum.seller_reference}&nbsp;
          {:else}
            &nbsp;
          {/if}
        </div>
      </div>
      <div class="flex gap-2 items-center flex-none">
        <div class="flex gap-2 flex-none">
          {#if status !== "canceled" && status !== "draft" && $profile?.organization?.account_type !== "free"}
            <div class="mt-2">
              <ProgressBar value={completedQuotes} max={datum.quotes_count} />
            </div>
          {/if}

          <div class="text-right">
            <div class="text-xs my-0.5">
              {items(datum.items_quantity || 0)}
            </div>
            <div class="text-xs italic whitespace-nowrap">
              {#if status === "canceled"}
                Canceled
              {:else if datum.sent_at}
                Sent {formatHumanTime(datum.sent_at)}
              {:else}
                Draft
              {/if}
            </div>
          </div>
        </div>
        <div class="flex-none">
          {#if datum.assigned_to_username}
            <div
              class="profile-circle relative"
              style="background-color: {datum.assigned_to_user_color ||
                '#000'}; border-color: {datum.assigned_to_user_color || '#000'}">
              {upperCase(
                getUserInitials({ username: datum.assigned_to_username, email: datum.assigned_to_email }),
              )}
            </div>
          {:else if !datum.assigned_to}
            <div class="text-gray-400">
              <ProfileIcon />
            </div>
          {/if}
        </div>
      </div>
    </div>
  </div>
</td>
