<svelte:options strictprops={false} />

<script>
  import { Link, navigate } from "svelte-routing";
  import { tick, onMount, getContext } from "svelte";
  import get from "lodash/get";
  import merge from "lodash/merge";
  import cloneDeep from "lodash/cloneDeep";
  import isEqual from "lodash/isEqual";
  import isEmpty from "lodash/isEmpty";
  import Mousetrap from "mousetrap";

  import Item2dEditor from "src/lib/Item2dEditor.svelte";
  import Attachments from "src/lib/sidebar/Attachments.svelte";
  import LocationThumbnailViewer from "src/lib/sidebar/LocationThumbnailViewer.svelte";
  import CaretLeftIcon from "src/assets/icons/caret-left.svg";
  import Sidebar from "src/lib/sidebar/Sidebar.svelte";
  import SidebarTitle from "src/lib/sidebar/SidebarTitle.svelte";
  import EdgeProperties from "src/lib/sidebar/EdgeProperties.svelte";
  import VertexProperties from "src/lib/sidebar/VertexProperties.svelte";
  import HoleProperties from "src/lib/sidebar/HoleProperties.svelte";
  import BugProperties from "src/lib/sidebar/BugProperties.svelte";
  import RefPlaneProperties from "src/lib/sidebar/RefPlaneProperties.svelte";
  import FabricationInstanceProperties from "src/lib/sidebar/FabricationInstanceProperties.svelte";
  import NewHoleProperties from "src/lib/sidebar/NewHoleProperties.svelte";
  import CollectionItemList from "src/lib/sidebar/CollectionItemList.svelte";
  import NewFabricationInstanceProperties from "src/lib/sidebar/NewFabricationInstanceProperties.svelte";
  import GenericFeatureProperties from "src/lib/sidebar/GenericFeatureProperties.svelte";
  import History from "src/lib/sidebar/History.svelte";
  import ItemProperties from "src/lib/sidebar/ItemProperties.svelte";
  import PrevNext from "src/lib/sidebar/PrevNext.svelte";

  import {
    groupBy,
    groupCollectionsBy,
    currentTool,
    selectedItems,
    tempFeatureHole,
    tempFeatureEdgeFabrication,
    tempFeatureCornerFabrication,
    showRightPanel,
    locationContext,
  } from "src/stores/ui.js";

  import contiguousRanges from "@local/extensions/ranges/contiguous-ranges.js";
  import { findFlatIndex, findNestedIndex } from "@local/extensions/collections/find-flat-index.js";
  import orderCategory from "@local/extensions/collections/order-category.js";
  import groupItems from "@local/extensions/collections/group-items.js";
  import flattenGroups from "@local/extensions/collections/flatten-groups.js";
  import { createFreeShape as freeShape } from "@local/lamina-core";
  import Polyface from "@local/extensions/geometry/polyface.js";

  export let group;
  export let types;
  export let items;
  export let collections;
  export let itemid;

  export let customColumns;
  export let customColColumns;
  export let standardColumns;
  export let isCollection = false;

  export let disabled = false;

  const supplier = getContext("supplier");

  const featureProps = {
    vertex: VertexProperties,
    edge: EdgeProperties,
    void: HoleProperties,
    efab: FabricationInstanceProperties,
    cfab: FabricationInstanceProperties,
    bug: BugProperties,
    refplane: RefPlaneProperties,
    mixed: GenericFeatureProperties,
  };

  const newFeatureProps = {
    efab: NewFabricationInstanceProperties,
    cfab: NewFabricationInstanceProperties,
    void: NewHoleProperties,
  };

  const featureNames = {
    "feature-hole": "Hole",
    "feature-edge-fabrication": "Edge Fabrication",
  };

  let width;
  let height;
  let selectedFeatures = [];
  let hoveredFeatures = [];
  let activeInput = null;
  let slide = "left";

  $: customCols = isCollection ? customColColumns : customColumns;
  $: gb = item?.is_collection ? groupCollectionsBy : groupBy;
  $: paddingX = width >= 640 ? 180 : 160;
  $: paddingY = width >= 640 ? 150 : 120;
  $: showingActiveTool = ["feature-hole", "feature-edge-fabrication", "feature-corner-fabrication"].includes(
    $currentTool,
  );
  $: sidebarPosition = getSidebarPosition(width, height);
  $: item = $group.items[itemid];
  $: productProject = $group.project_type === "product";
  $: groupedItems = groupItems(item?.is_collection ? collections : items, $gb, $group);
  $: orderedCategory = orderCategory($gb, groupedItems, types, collections, $supplier?.product_categories);
  $: itemList = flattenGroups(groupedItems, orderedCategory);
  $: setSelected(itemid);
  $: redirect(item);
  $: polyface = item && new Polyface(item, $group.data.fabrications);
  $: type = item && $group.types[item.type_id];
  $: attachments = $group.attachments.filter((a) => a.item_id === itemid);
  $: itemIndex = itemList.findIndex((i) => i.id === itemid);
  $: nextItem = itemList[itemIndex + 1];
  $: prevItem = itemList[itemIndex - 1];
  $: selectedFeature = makeSelectedFeature(item, selectedFeatures);
  $: tempFeature = setTempFeature($currentTool);
  $: childItems = items.filter((i) => i.collection_id === itemid);

  // TODO: support import of DXF corner fabrications

  function getSidebarPosition(width, height) {
    if (width >= 640) {
      return "right";
    }

    return height > width ? "bottom" : "right";
  }

  function setSelected(itemid) {
    selectedItems.selectOnly(itemid);

    if ($locationContext?.record_id && $locationContext.record_id !== itemid) {
      locationContext.set(null);
    }
  }

  function setTempFeature(ct) {
    if (ct === "feature-hole") {
      return {
        type: "void",
        props: $tempFeatureHole,
      };
    } else if (ct === "feature-edge-fabrication") {
      return {
        type: "efab",
        props: $tempFeatureEdgeFabrication,
      };
    } else if (ct === "feature-corner-fabrication") {
      return {
        type: "cfab",
        props: $tempFeatureCornerFabrication,
      };
    }
  }

  function redirect(item) {
    if (!item) navigate(`../items`);
  }

  function makeSelectedFeature(item, selectedFeatures) {
    if (selectedFeatures.length === 0) return null;

    if (selectedFeatures.length === 1) {
      const feat = selectedFeatures[0];

      if (feat.type === "edge") {
        return {
          ...feat,
          title: `Edge ${feat.index + 1}`,
          treatment: item.data.fabrications?.edge_treatment?.[feat.id] || null,
        };
      } else if (feat.type === "vertex") {
        return {
          ...feat,
          title: `Vertex ${feat.index + 1}`,
          pt: polyface.shape[feat.loop][feat.index],
        };
      } else if (feat.type === "void") {
        return {
          ...feat,
          title: `Hole ${feat.index + 1}`,
          props: item.data.fabrications?.voids?.[feat.id] || null,
        };
      } else if (feat.type === "bug") {
        return {
          ...feat,
          title: "Tempered Stamp",
          props: item.data.fabrications?.bug || null,
        };
      } else if (feat.type === "efab") {
        return {
          ...feat,
          title: `Edge Fabrication ${feat.index + 1}`,
          props: item.data.fabrications?.edge?.[feat.id] || null,
        };
      } else if (feat.type === "cfab") {
        return {
          ...feat,
          title: `Corner Fabrication ${feat.index + 1}`,
          props: item.data.fabrications?.corner?.[feat.id] || null,
        };
      } else if (feat.type === "refplane") {
        return {
          ...feat,
          title: "Reference Plane",
          props: item.data.reference_planes[feat.id],
        };
      }
    }

    const indices = selectedFeatures.map((f) => findFlatIndex(polyface.shape, f.loop, f.index));
    const r = contiguousRanges(indices)
      .map((range) => {
        if (range[0] === range[1]) return `${range[0] + 1}`;
        return `${range[0] + 1}-${range[1] + 1}`;
      })
      .join(", ");

    const type = selectedFeatures[0].type;
    const similar = selectedFeatures.every((feat) => feat.type === type);
    if (similar) {
      if (type === "edge") {
        const first = indices[0];
        const rest = indices.slice(1);

        const treatment = rest.reduce(
          (t, edge) => {
            const e = get(item.data, ["fabrications", "edge_treatment", edge]) || null;
            if (e !== t) return "Mixed";
            return t;
          },
          get(item.data, ["fabrications", "edge_treatment", first]) || null,
        );

        return {
          type: "edge",
          title: `Edges ${r}`,
          index: indices,
          treatment,
        };
      } else if (type === "vertex") {
        const first = indices[0];
        const rest = indices.slice(1);
        const feat = findNestedIndex(polyface.shape, first);
        const pt = cloneDeep(polyface.shape[feat.loop][feat.index]);

        rest.forEach((i) => {
          const f = findNestedIndex(polyface.shape, i);
          const fpt = polyface.shape[f.loop][f.index];
          if (pt.x !== fpt.x) pt.x = "Mixed";
          if (pt.y !== fpt.y) pt.y = "Mixed";
          if (pt.fillet !== fpt.fillet) pt.fillet = "Mixed";
        });

        return {
          type: "vertex",
          title: `Vertices ${r}`,
          index: indices,
          pt,
        };
      } else if (type === "void") {
        const first = indices[0];
        const rest = indices.slice(1);
        const p = cloneDeep(item.data.fabrications.voids[first]);

        const props = rest.reduce((p, index) => {
          const f = item.data.fabrications.voids[index];
          if (f.type !== p.type) {
            p.type = "Mixed";
          }

          if (!isEqual(p.diameter, f.diameter)) p.diameter = "Mixed";
          if (!isEqual(p.width, f.width)) p.width = "Mixed";
          if (!isEqual(p.height, f.height)) p.height = "Mixed";
          if (!isEqual(p.radius, f.radius)) p.radius = "Mixed";
          if (!isEqual(p.reference.length, f.reference.length)) p.reference.length = "Mixed";
          if (!isEqual(p.reference.offset, f.reference.offset)) p.reference.offset = "Mixed";

          return p;
        }, p);

        return {
          type: "void",
          title: `Holes ${r}`,
          index: indices,
          props,
        };
      } else if (type === "efab") {
        const first = indices[0];
        const rest = indices.slice(1);
        const p = cloneDeep(item.data.fabrications.edge[first]);

        const props = rest.reduce((p, index) => {
          const f = item.data.fabrications.edge[index];

          if (p.fab_id !== f.fab_id) p.fab_id = "Mixed";
          if (!isEqual(p.reference.length, f.reference.length)) p.reference.length = "Mixed";

          return p;
        }, p);

        return {
          type: "efab",
          title: `Edge Fabrications ${r}`,
          index: indices,
          props,
        };
      } else if (type === "cfab") {
        const first = indices[0];
        const rest = indices.slice(1);
        const p = cloneDeep(item.data.fabrications.corner[first]);

        const props = rest.reduce((p, index) => {
          const f = item.data.fabrications.corner[index];

          if (p.fab_id !== f.type_id) p.fab_id = "Mixed";

          return p;
        }, p);

        return {
          type: "cfab",
          title: `Corner Fabrications ${r}`,
          index: indices,
          props,
        };
      } else if (type === "bug") {
        return {
          type: "bug",
          title: "Tempered Stamp",
          index: indices,
          props: {},
        };
      } else if (type === "refplane") {
        const ids = selectedFeatures.map((f) => f.id);

        return {
          type: "refplane",
          title: "Reference Planes",
          id: selectedFeatures.map((f) => f.id),
          props: {},
        };
      }
    } else {
      return {
        type: "mixed",
        title: "Mixed Features",
        index: indices,
      };
    }
  }

  function updateItem(prop, value, diff) {
    const id = item.id;
    group.updateItem(id, prop, value, diff);
  }

  function gotoNext() {
    slide = "left";
    navigate(`./${nextItem.id}`);
  }

  function gotoPrev() {
    slide = "right";
    navigate(`./${prevItem.id}`);
  }

  function mouseoverEdges(e) {
    const edges = e.detail;
    const indices = polyface.shape.map((loop, l) => loop.map((edge, e) => ({ l, e }))).flat();
    hoveredFeatures = edges.map((i) => ({ type: "edge", index: indices[i].e, loop: indices[i].l }));
  }

  function mouseoutEdges(e) {
    hoveredFeatures = [];
  }

  function deselectFeatures() {
    selectedFeatures = [];
    hoveredFeatures = [];
    activeInput = null;
  }

  async function deleteFeature(e) {
    const { features } = e.detail;

    const voids = features.filter((f) => f.type === "void").map((f) => f.index);
    const edge = features.filter((f) => f.type === "efab").map((f) => f.index);
    const corner = features.filter((f) => f.type === "cfab").map((f) => f.index);
    const bug = features.find((f) => f.type === "bug");

    deselectFeatures();
    await tick();

    const data = cloneDeep(item.data);

    if (voids.length > 0) {
      data.fabrications.voids = data.fabrications.voids.filter((v, i) => !voids.includes(i));
    }

    if (edge.length > 0) {
      data.fabrications.edge = data.fabrications.edge.filter((e, i) => !edge.includes(i));
    }

    if (corner.length > 0) {
      data.fabrications.corner = data.fabrications.corner.filter((c, i) => !corner.includes(i));
    }

    if (bug) {
      delete data.fabrications.bug;
    }

    group.updateItem(itemid, { data });
  }

  function deleteSelected() {
    if (activeInput) return;
    const features = selectedFeatures
      .filter((f) => ["void", "efab", "cfab", "bug"].includes(f.type))
      .map((f) => ({ type: f.type, index: f.index }));
    deleteFeature({ detail: { features } });
  }

  onMount(() => {
    Mousetrap.bind(["del", "backspace"], deleteSelected);

    return () => {
      Mousetrap.unbind(["del", "backspace"]);
    };
  });
</script>

<div class="p-4 absolute z-10 space-y-2">
  <div class="text-blue-500 text-sm">
    {#if isCollection}
      <Link to={"openings"} class="flex items-center space-x-1">
        <CaretLeftIcon />
        <div>All Openings</div>
      </Link>
    {:else}
      <Link to={"items"} class="flex items-center space-x-1">
        <CaretLeftIcon />
        <div>All Items</div>
      </Link>
    {/if}
  </div>
</div>

{#if item}
  <div class="w-full h-full relative" bind:offsetWidth={width} bind:offsetHeight={height}>
    <div class="absolute w-full h-full overflow-hidden">
      {#key itemid}
        <Item2dEditor
          paddingTop={paddingY}
          paddingRight={paddingX + (sidebarPosition === "bottom" ? 0 : 320)}
          paddingBottom={paddingY + (sidebarPosition === "bottom" ? 224 : 0)}
          paddingLeft={paddingX}
          {disabled}
          {isCollection}
          {group}
          {itemid}
          {item}
          {type}
          {types}
          {slide}
          bind:activeInput
          bind:hoveredFeatures
          bind:selectedFeatures />
      {/key}
    </div>
    {#if $showRightPanel}
      <Sidebar type="absolute" position={sidebarPosition}>
        <svelte:fragment slot="header">
          {#if !showingActiveTool && !selectedFeatures.length}
            <PrevNext
              prev={prevItem}
              next={nextItem}
              on:gotoprev={gotoPrev}
              on:gotonext={gotoNext}
              sticky
              title={item?.mark} />
          {/if}
        </svelte:fragment>

        <svelte:fragment slot="content">
          {#if showingActiveTool}
            <SidebarTitle title={"New " + featureNames[$currentTool]} />
            <svelte:component
              this={newFeatureProps[tempFeature.type]}
              job={group}
              {item}
              disabled={disabled || productProject}
              {polyface}
              settings={$group.data.settings}
              feature={tempFeature} />
          {:else if selectedFeatures.length > 0}
            <SidebarTitle title={selectedFeature.title} />
            <svelte:component
              this={featureProps[selectedFeature.type]}
              job={group}
              {item}
              disabled={disabled || productProject}
              {polyface}
              settings={$group.data.settings}
              feature={selectedFeature}
              on:deleteFeature={deleteFeature} />
          {:else}
            <ItemProperties
              {disabled}
              {group}
              {item}
              {itemid}
              {types}
              {collections}
              customColumns={customCols}
              {standardColumns}
              {isCollection}
              settings={$group.data.settings}
              on:updateItem={(e) => updateItem(e.detail.prop, e.detail.value, e.detail.diff)}
              on:mouseoverEdges={mouseoverEdges}
              on:mouseoutEdges={mouseoutEdges} />
            {#if item.is_collection}
              <CollectionItemList
                {group}
                {items}
                collectionItems={childItems}
                collectionId={item.id}
                {disabled} />
            {/if}
            {#if !productProject}
              <LocationThumbnailViewer
                recordid={itemid}
                {group}
                record={item}
                {disabled}
                documents={$group.data.documents}
                selectable />
            {/if}
            <Attachments {attachments} {group} {itemid} {disabled} />
            <History
              updatedBy={item.updater}
              updatedOn={item.updated_at}
              approvedBy={item.approver}
              approvalStatus={item.approval_status}
              approvedOn={item.approval_status_updated_at} />
          {/if}
        </svelte:fragment>
      </Sidebar>
    {/if}
  </div>
{/if}
