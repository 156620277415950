import { DimText } from "dimtext";

const dt = new DimText();

function parseLength(v) {
  const result = dt.parse(v.toString());
  if (!result.ok) throw result.err;
  return result.value.toNumber("inches");
}

export default parseLength;
