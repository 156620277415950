<script>
  import { onMount } from "svelte";
  import { orgs, orderedOrgs } from "../stores/organizations.js";
  import { profile } from "../stores/auth.js";
  import { api } from "src/api";

  $: orgOptions = $orderedOrgs.map(o => ({ label: o.name, value: o.id }));

  let selectedOrg;

  // Allow developers to impersonate an org user
  async function selectOrg() {
    if (selectedOrg) {
      const { data, error } = await api.from("organizations")
        .select("*")
        .eq("id", selectedOrg)
        .single();

      if (error) {
        console.error(error);
        return;
      }

      $profile.organization_id = selectedOrg;
      $profile.organization = data;
      localStorage.setItem("currentOrg", selectedOrg);
    }
  }

  onMount(() => {
    orgs.fetch();
  });
</script>

<div class="w-full h-full absolute flex flex-col justify-center">
  <div class="mx-auto w-96 border rounded bg-white drop-shadow-md text-center">
    <div class="w-full text-left text-sm">
      <div class="w-full px-4 py-2 border-b text-gray-500">
        Choose Organization
      </div>
      <div class="p-4">
        <div class="mb-2">Please choose an organization to impersonate:</div>
        <select
          class="border bg-transparent rounded w-full p-2"
          bind:value={selectedOrg}
        >
          <option value="" disabled selected class="text-gray-500"
            >Select an option</option
          >
          {#each orgOptions as option}
            <option value={option.value}>{option.label}</option>
          {/each}
        </select>
      </div>
    </div>

    <div class="w-full px-4 mb-4 flex space-x-2 items-center justify-end">
      <button class="btn btn-primary" on:click={selectOrg}>OK</button>
    </div>
  </div>
</div>
