<svelte:options strictprops={false} />

<script>
  import { createEventDispatcher } from "svelte";
  import { DimText } from "dimtext";
  import set from "lodash/set";
  import cloneDeep from "lodash/cloneDeep";
  import SidebarSection from "./SidebarSection.svelte";
  import TextInput from "./TextInput.svelte";
  import DimtextInput from "./DimtextInput.svelte";
  import { createFreeShape } from "@local/lamina-core";
  import Polyface from "@local/extensions/geometry/polyface.js";
  import isRectangle from "@local/extensions/geometry/is-rectangle.js";

  export let job;
  export let item;
  export let feature;
  export let disabled;
  export let settings;

  const dispatch = createEventDispatcher();

  $: displayUnit = settings.display_unit;

  function updateFeature(prop, value) {
    const data = cloneDeep(item.data);

    const f = data.reference_planes;
    set(f, prop, value);

    job.updateItem(item.id, { data });
  }

  function updateVertices(item, func) {
    item.shape.vertices.forEach((loop, li) => {
      loop.forEach((v, vi) => {
        func(item.shape.vertices[li][vi]);
      });
    });
  }

  function updateSize(item, pf) {
    const bbox = pf.bbox;
    const w = bbox.width - item.cache.width_offset_in;
    const h = bbox.height - item.cache.height_offset_in;

    const wu = displayUnit === "millimeters" ? w * 25.4 : w;
    const hu = displayUnit === "millimeters" ? h * 25.4 : h;

    const dt = new DimText({ defaultUnit: displayUnit });
    item.width = dt.parse(wu.toString()).value;
    item.height = dt.parse(hu.toString()).value;
  }

  function moveRefPlane(id, value) {
    if (item.data.reference_planes?.type === "box") {
      const x1 = item.data.reference_planes.left.value.toNumber("inches");
      const x2 = item.data.reference_planes.right.value.toNumber("inches");
      const y1 = item.data.reference_planes.bottom.value.toNumber("inches");
      const y2 = item.data.reference_planes.top.value.toNumber("inches");

      const mx = x1 + (x2 - x1) / 2;
      const my = y1 + (y2 - y1) / 2;

      const tempItem = cloneDeep(item);
      tempItem.shape = createFreeShape(tempItem);
      const v = value.toNumber("inches");

      if (id === "top") {
        const dv = v - y2;
        updateVertices(tempItem, (v) => {
          if (v.y >= my) v.y += dv;
        });
      } else if (id === "right") {
        const dv = v - x2;
        updateVertices(tempItem, (v) => {
          if (v.x >= mx) v.x += dv;
        });
      } else if (id === "bottom") {
        const dv = v - y1;
        updateVertices(tempItem, (v) => {
          if (v.y < my) v.y += dv;
        });
      } else if (id === "left") {
        const dv = v - x1;
        updateVertices(tempItem, (v) => {
          if (v.x < mx) v.x += dv;
        });
      }

      tempItem.data.reference_planes[id].value = value;
      const pf = new Polyface(tempItem, $job.data.fabrications);
      updateSize(tempItem, pf);
      if (isRectangle(tempItem.shape)) {
        const { xmin, ymin } = pf.bbox;
        tempItem.rectangle_offset = { x: xmin, y: ymin };
        tempItem.shape = { type: "rect" };
      }

      job.updateItem(item.id, {
        rectangle_offset: tempItem.rectangle_offset,
        width: tempItem.width,
        height: tempItem.height,
        data: tempItem.data,
        shape: tempItem.shape,
      });
    } else {
      const data = cloneDeep(item.data);
      const f = data.reference_planes;
      set(f, `${id}.value`, value);
      job.updateItem(item.id, { data });
    }
  }
</script>

<SidebarSection>
  {#if Array.isArray(feature.id)}
    <div class="text-xs italic px-2">Multiple selected.</div>
  {:else}
    <TextInput
      label="Name"
      {disabled}
      value={feature.props.name}
      labelWidth="5.5rem"
      on:input={(e) => updateFeature(`${feature.id}.name`, e.detail.value)} />
    <DimtextInput
      label="Position"
      {disabled}
      labelWidth="5.5rem"
      value={feature.props.value}
      {settings}
      on:input={(e) => moveRefPlane(feature.id, e.detail.value)} />
  {/if}
</SidebarSection>
