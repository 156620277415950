import { Dimension, Quantity } from "dimtext";
import {
  nearestPointOnLine,
  nearestPointOnSegment,
} from "./nearest-point-on-line.js";
import {
  distSq,
  rotate,
  subtract,
  normalize,
  dot,
  length,
  scale,
} from "vector";
import { roundValue } from "../parsers/round-value.js";
import { findNestedIndex } from "../collections/find-flat-index.js";

export function nearestEdgeOffsetReference(
  loopIndex,
  edges,
  point,
  precision,
  unit = "inches",
) {
  const loop = edges[loopIndex];

  const pts = loop.map((edge) =>
    nearestPointOnLine(edge.start, edge.end, point),
  );
  const distances = pts.map((p) => distSq(p, point));
  const min = Math.min(...distances);
  const edgeIndex = distances.indexOf(min);
  const edge = loop[edgeIndex];

  const start =
    distSq(edge.end, point) < distSq(edge.start, point) ? "end" : "start";
  const end = start === "end" ? "start" : "end";

  const evec = subtract(edge[end], edge[start]);
  const lvec = subtract(pts[edgeIndex], edge[start]);

  let l = dot(lvec, evec) / length(evec); // length along edge vector

  const n = normalize(evec);
  const pa = subtract(point, edge[start]);
  const or = rotate(n, Math.PI / 2);
  const ovec = subtract(pa, scale(n, dot(pa, n)));
  let o = dot(ovec, or) / length(or); // length along offset vector

  if (end === "start") o = -o; // Positive offset points to inside of shape

  if (unit === "millimeters") {
    l *= 25.4;
    o *= 25.4;
  }

  if (precision !== undefined) {
    l = roundValue(l, precision);
    o = roundValue(o, precision);
  }

  return {
    type: "edge-offset-position",
    loop: loopIndex,
    edge: edgeIndex,
    start,
    length: new Dimension(new Quantity(l, unit)),
    offset: new Dimension(new Quantity(o, unit)),
  };
}

export function edgeReference(
  loopIndex,
  edgeIndex,
  edges,
  point,
  precision,
  unit = "inches",
) {
  const edge = edges[loopIndex][edgeIndex];

  const start =
    distSq(edge.end, point) < distSq(edge.start, point) ? "end" : "start";
  const end = start === "end" ? "start" : "end";

  const pt = nearestPointOnSegment(edge.start, edge.end, point);

  const evec = subtract(edge[end], edge[start]);
  const lvec = subtract(pt, edge[start]);
  let l = dot(lvec, evec) / length(evec);

  if (unit === "millimeters") {
    l *= 25.4;
  }

  if (precision !== undefined) {
    l = roundValue(l, precision);
  }

  return {
    type: "edge-position",
    loop: loopIndex,
    edge: edgeIndex,
    start,
    length: new Dimension(new Quantity(l, unit)),
  };
}

export function nearestEdgeReference(edges, point, precision, unit = "inches") {
  const pts = edges
    .map((loop) => {
      return loop.map((edge) =>
        nearestPointOnSegment(edge.start, edge.end, point),
      );
    })
    .flat();

  const distances = pts.map((p) => distSq(p, point));
  const min = Math.min(...distances);
  const edgeIndex = distances.indexOf(min);

  const { loop, index } = findNestedIndex(edges, edgeIndex);

  return edgeReference(loop, index, edges, point, precision, unit);
}
