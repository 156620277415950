<script>
  import { getContext } from "svelte";
  import cloneDeep from "lodash/cloneDeep";

  import SimpleCheckbox from "./SimpleCheckbox.svelte";
  import EditIcon from "src/assets/icons/edit.svg";

  import { Modal } from "svelte-utilities";
  import TextInput from "src/lib/sidebar/TextInput.svelte";
  import SelectInput from "src/lib/sidebar/SelectInput.svelte";
  import BooleanInput from "src/lib/sidebar/BooleanInput.svelte";
  import ToggleInput from "src/lib/sidebar/ToggleInput.svelte";

  const org = getContext("org");
  const edgeTreatments = getContext("edgeTreatments");
  const fabrications = getContext("fabrications");

  export let selected;
  export let disabled = false;

  const newEntry = () => ({
    list_id: null,
    product_id: null,
    unit_price: null,
    unit: null,
    formula: null,
  });

  let editModal;
  let entry = newEntry();
  let list = null;
  let overrideChildList = false;
  let defineCosts = "define";

  $: cp = sumChildPrice(list, entry, selected);
  $: ordered = orderLists($org.product_lists);
  $: assigned = ordered.reduce((map, pl) => {
    if (pl.parent_id) {
      map[pl.parent_id] = true;
    }
    return map;
  }, {});

  function orderLists(lists) {
    const o = lists.toSorted((a, b) => a.sort_order - b.sort_order);
    const assignments = o.reduce((map, pl) => {
      if (pl.parent_id) {
        if (!map[pl.parent_id]) map[pl.parent_id] = [];
        map[pl.parent_id].push(pl);
      }
      return map;
    }, {});
    const unParented = o.filter((list) => !list.parent_id);
    return unParented.reduce((ordered, list) => {
      ordered.push(list);

      if (assignments[list.id]) {
        ordered.push(...assignments[list.id]);
      }

      return ordered;
    }, []);
  }

  function parser(v) {
    if (v === "" || v === undefined || v === null) return null;
    if (typeof v !== "string") throw new Error("Invalid currency value");

    const num = v.match(/\d+(\.?\d+)?/);
    if (!num) throw new Error("Invalid currency value");

    const d = parseFloat(num[0]);
    if (!Number.isFinite(d)) {
      throw new Error("Invalid currency value");
    }

    return d;
  }

  function formatter(v) {
    if (!v) return "";
    if (v === "Mixed") return "Mixed";
    return v.toFixed(4);
  }

  function getEntry(list) {
    if (Array.isArray(selected.id)) {
      const entries = selected.id.map(
        (id) => list.price_entries[id] || { ...newEntry(), list_id: list.id, product_id: id },
      );
      const last = cloneDeep(entries.pop());
      delete last.formula_compiled;
      last.product_id = [last.product_id];

      return entries.reduce((e, entry) => {
        e.product_id.push(entry.product_id);
        if (e.unit_price !== entry.unit_price) e.unit_price = "Mixed";
        if (e.unit !== entry.unit) e.unit = "Mixed";
        if (e.formula !== entry.formula) e.formula = "Mixed";
        return e;
      }, last);
    } else {
      const e = cloneDeep(
        list.price_entries[selected.id] || { ...newEntry(), list_id: list.id, product_id: selected.id },
      );
      delete e.formula_compiled;
      return e;
    }
  }

  function product(id) {
    return (
      $org.makeups[id] ||
      $org.materials[id] ||
      $org.surfaces[id] ||
      $org.item_products[id] ||
      $edgeTreatments[id] ||
      $fabrications[id]
    );
  }

  function getChecked(list) {
    if (Array.isArray(selected.id)) {
      const checked = selected.id.map((id) => list.price_entries[id]);
      if (checked.every((c) => !!c)) return true;
      if (checked.every((c) => !c)) return false;
      return "Mixed";
    } else {
      return list.price_entries[selected.id];
    }
  }

  function toggleProductList(list, e) {
    const ids = Array.isArray(selected.id) ? selected.id : [selected.id];
    const value = e.target.checked;

    if (value) {
      const unchecked = ids.filter((id) => !list.price_entries[id]);
      org.addPriceEntry(list.id, unchecked);
    } else {
      const checked = ids.filter((id) => list.price_entries[id]);
      org.removePriceEntry(list.id, checked);
    }
  }

  function isUndefinedOrNull(v) {
    return v === undefined || v === null;
  }

  function editEntry(l, e) {
    list = l;
    entry = { ...e };
    if (assigned[list.id]) {
      if (entry.unit_price) {
        overrideChildList = true;
      } else {
        overrideChildList = false;
      }
    }
    if (selected.application === "makeup" && isUndefinedOrNull(entry.unit_price)) {
      defineCosts = "defer";
    } else {
      defineCosts = "define";
    }
    editModal.open();
  }

  function getPrice(list, entry) {
    if (isUndefinedOrNull(entry.unit_price) || isUndefinedOrNull(entry.unit)) return null;
    if (entry.unit_price === "Mixed" || entry.unit === "Mixed") return "Mixed";
    const currency = list.currency === "USD" ? "$" : "C$";
    return `${currency}${entry.unit_price}/${entry.unit}`;
  }

  function prodPrice(prod, list) {
    if (!prod || !list) return {};
    const entry = list.price_entries[prod.id];
    if (!entry) return {};
    if (entry.unit_price === null || entry.unit === null) return {};
    if (entry.unit_price === "Mixed" || entry.unit === "Mixed") return { Mixed: true };
    return { [entry.unit]: entry.unit_price };
  }

  function addByKey(a, b) {
    Object.keys(b).forEach((key) => {
      if (a[key] === "Mixed" || b[key] === "Mixed") {
        a[key] = "Mixed";
      } else if (a[key] !== undefined) {
        a[key] += b[key];
      } else {
        a[key] = b[key];
      }
    });
  }

  function sumChildPrice(list, entry, product) {
    if (!list || !entry || !product) return null;
    if (product.application !== "makeup") return null;
    if (Array.isArray(product.id)) return "Mixed";
    if (!isUndefinedOrNull(entry.unit_price) || !isUndefinedOrNull(entry.unit)) return null;
    const currency = list.currency === "USD" ? "$" : "C$";
    const s = product.data.layers.reduce((sum, layer) => {
      const mat = prodPrice(layer.material, list);
      const ibsurf = prodPrice(layer.inboard_surface, list);
      const obsurf = prodPrice(layer.outboard_surface, list);

      addByKey(sum, mat);
      addByKey(sum, ibsurf);
      addByKey(sum, obsurf);

      return sum;
    }, {});

    if (s.Mixed) return "Mixed";

    return Object.keys(s).reduce((str, unit) => {
      if (str) str += ", ";
      str += `${currency}${s[unit]}/${unit}`;
      return str;
    }, "");
  }

  function updatePriceEntry() {
    const { product_id } = entry;
    const ids = Array.isArray(product_id) ? product_id : [product_id];
    delete entry.product_id;
    delete entry.list_id;
    if (defineCosts === "defer") {
      entry.unit_price = null;
      entry.unit = null;
      entry.formula = null;
    }
    org.updatePriceEntry(list.id, ids, entry);
  }
</script>

{#if $org.product_lists.length}
  <div class="px-6 text-xs">
    <h3 class="mb-2">Product Lists</h3>
    <div class="space-y-2 mb-2">
      {#each ordered as list}
        {@const entry = getEntry(list)}
        {@const price = getPrice(list, entry)}
        {@const childPrice = sumChildPrice(list, entry, selected)}
        {@const checked = getChecked(list)}
        <div class="w-full flex h-6 items-center justify-between pl-item">
          <button
            class="flex items-center gap-2 py-0.5"
            class:pl-4={list.parent_id}
            {disabled}
            on:click={() => editEntry(list, entry)}>
            <div>
              {list.name}
            </div>
            {#if price}
              <div class="text-gray-500">
                {price}
              </div>
            {:else if childPrice}
              <div class="text-gray-500">
                {childPrice}
              </div>
            {/if}
            <div class="p-0.5 rounded hover:bg-gray-200 ps-button">
              <EditIcon />
            </div>
          </button>
          {#if !disabled}
            <div class="flex gap-1 items-center">
              <SimpleCheckbox {disabled} {checked} on:input={(e) => toggleProductList(list, e)} />
            </div>
          {/if}
        </div>
      {/each}
    </div>
  </div>
{/if}

<Modal
  closeable
  on:confirm={() => updatePriceEntry()}
  bind:this={editModal}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Update", type: "confirm", style: "primary" },
  ]}>
  <div slot="title">Edit Product List Entry</div>
  <div slot="content" class="space-y-4 text-sm">
    <div class="text-xs space-y-2">
      {#if list}
        <div>Product list: <span class="font-bold">{list.name}</span></div>
      {/if}
      <div>
        <div class="mb-1">Editing price entry for:</div>
        {#if Array.isArray(entry.product_id)}
          {#each entry.product_id as id}
            <div class="px-4">
              {product(id)?.name}
            </div>
          {/each}
        {:else}
          <div class="px-4">
            {product(entry.product_id)?.name}
          </div>
        {/if}
      </div>
    </div>
    {#if assigned[list?.id]}
      <BooleanInput label="Override Child Price" border bind:value={overrideChildList} />
    {/if}
    {#if overrideChildList || !assigned[list?.id]}
      <div class="text-xs">
        Currency: <span class="font-bold">{list.currency}</span>
      </div>
      {#if selected.application === "makeup"}
        <SelectInput
          label="Price Computation"
          border
          bind:value={defineCosts}
          options={[
            { label: "Use price of components", value: "defer" },
            { label: "Define makeup price", value: "define" },
          ]} />
      {/if}
      {#if defineCosts === "define"}
        <TextInput label="Unit Price" border {formatter} {parser} bind:value={entry.unit_price} />
        <SelectInput
          label="Price Unit"
          border
          bind:value={entry.unit}
          options={[
            { label: "Sq. Ft.", value: "sqft" },
            { label: "Sq. In.", value: "sqin" },
            { label: "Sq. Meters", value: "m2" },
            { label: "Item", value: "item" },
          ]} />
      {:else if cp}
        <div class="text-xs">
          Price of child products: <span class="font-bold">{cp}</span>
        </div>
      {/if}
    {/if}
  </div>
</Modal>

<style lang="scss">
  .pl-item {
    .ps-button {
      display: none;
    }

    &:hover .ps-button {
      display: block;
    }
  }
</style>
