<svelte:options strictprops={false} />

<script>
  import cloneDeep from "lodash/cloneDeep";
  import set from "lodash/set";
  import { createEventDispatcher } from "svelte";
  import MaterialProperties from "./MaterialProperties.svelte";
  import SurfaceProperties from "./SurfaceProperties.svelte";

  export let product;
  export let application;
  export let disabled;
  export let settings;

  const dispatch = createEventDispatcher();

  function update(update) {
    const { prop, value } = update.detail;
    const newProduct = cloneDeep(product);
    set(newProduct, prop, value);
    set(newProduct.value, prop, value);
    dispatch("update-product", newProduct);
  }
</script>

{#if application === "material"}
  <MaterialProperties material={product} {disabled} {settings} on:update={update} inline />
{:else if application === "surface"}
  <SurfaceProperties surface={product} {disabled} on:update={update} inline />
{/if}
