import get from "lodash/get.js";

/**
 * @template {Record<"type_id", string | null>} T
 * @overload
 * @param {Array<T>} items
 * @returns {import('./types').Grouped<"type_id",T>}
 */

/**
 * @template {Record<string, string | null>} T
 * @overload
 * @param {Array<T>} items
 * @param {"none"} field
 * @returns {import('./types').Ungrouped<T>}
 */

/**
 * @template {Record<F, string | null>} T
 * @template {string} F
 * @overload
 * @param {Array<T>} items
 * @param {F} field
 * @returns {import('./types').Grouped<F,T>}
 */

/**
 * @param {any} items
 * @param {string} [field]
 */
function groupItems(items, field = "type_id", group = {}) {
  if (field === "none") {
    return {
      untyped: [...items],
    };
  }

  const [f, ...rest] = field.split(".");
  const path = rest.join(".");
  const grouped = items.reduce(
    (all, item) => {
      let v;
      if (f === "type") {
        const type = group?.types?.[item.type_id];
        v = get(type, path);
      } else if (f === "category") {
        const type = group?.types?.[item.type_id];
        if (type) {
          v = type.category_id || type.category;
        } else {
          v = null;
        }
      } else {
        v = get(item, field);
      }

      if (v) {
        if (!all[v]) all[v] = [];
        all[v].push(item);
      } else {
        all.untyped.push(item);
      }

      return all;
    },
    { untyped: [] },
  );

  return grouped;
}

export default groupItems;
