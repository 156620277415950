<script>
  import { tick, createEventDispatcher } from "svelte";

  import EllipsisIcon from "src/assets/icons/ellipsis.svg";
  import CheckIcon from "src/assets/icons/check.svg";
  import CircleXIcon from "src/assets/icons/circle-x.svg";

  import { Modal } from "svelte-utilities";
  import LibrarySection from "src/lib/sidebar/LibrarySection.svelte";
  import TextInput from "src/lib/sidebar/TextInput.svelte";
  import ImageInput from "src/lib/sidebar/ImageInput.svelte";

  import { api } from "src/api";
  import imageUrl from "src/extensions/image-url.js";

  export let border = false;
  export let label = "Label";
  export let labelWidth = null;
  export let product;
  export let disabled = false;
  export let readonly = false;
  export let placeholder = "";
  export let outerBorder = true;
  export let orgid;

  const dispatch = createEventDispatcher();

  let input;
  let focused = false;

  let modal;
  let categories = [];
  let addingCategory = false;
  let name;
  let image;
  let selected = null;
  let textValue = product.category;
  let categoryName = "";

  $: lw = labelWidth ? `width:${labelWidth};` : "";
  $: fetchCategory(product);
  $: displayValue =
    product.category_id === "Mixed" ? "Mixed" : product.category_id ? categoryName : textValue;

  async function fetchCategory(product) {
    if (!product.category_id) return;
    if (product.category_id === "Mixed") return;
    const r = await api.from("product_categories").select("*").eq("id", product.category_id).single();

    if (r.data) {
      categoryName = r.data.name;
    }
  }

  async function fetchCategories() {
    const r = await api
      .from("product_categories")
      .select("*")
      .order("created_at")
      .eq("organization_id", orgid);

    if (r.data) {
      categories = r.data;
    }
  }

  function openModal() {
    selected = null;
    addingCategory = false;
    modal.open();
    fetchCategories();
  }

  function parseInput(val) {
    textValue = val;
    if (textValue !== product.category) {
      dispatch("input", { prop: "category", value: textValue });
    }
  }

  function focusInput() {
    input.focus();
  }

  function handleFocus(evt) {
    focused = true;
    input.select();
  }

  function handleKeydown(evt) {
    if (evt.key === "Enter") {
      evt.stopPropagation();
      parseInput(evt.target.value);
    }
  }

  function handleBlur(evt) {
    focused = false;
    parseInput(evt.target.value);
  }

  function selectCategory(e) {
    const { record } = e.detail;
    name = record.name;
    image = record.image;
    selected = record.id;
  }

  function chooseCategory() {
    dispatch("input", { prop: "category_id", value: selected });
  }

  async function deleteCategory(e) {
    const r = await api.from("product_categories").delete().eq("id", e.detail.record.id);
    fetchCategories();
  }

  async function startAddingCategory() {
    addingCategory = true;
    await tick();
    name = "";
    image = null;
  }

  function deselectCategory() {
    dispatch("input", { prop: "category_id", value: null });
  }

  async function addCategory() {
    await api.from("product_categories").insert({ organization_id: orgid, name, image });
    openModal();
  }

  async function updateCategory() {
    await api.from("product_categories").update({ name, image }).eq("id", selected);
    openModal();
  }

  async function uploadImage(e) {
    if (e.detail.type && e.detail.data) {
      const uuid = crypto.randomUUID();
      const filename = `${uuid}/${e.detail.filename}`;
      const { data: result, error } = await api.storage.from("images").upload(filename, e.detail.data, {
        contentType: e.detail.contentType,
      });

      if (!error) {
        image = {
          id: uuid,
          bucket: "images",
          object_id: result.path,
          content_type: e.detail.contentType,
          filename: e.detail.filename,
        };
      }
    }
  }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  class="prop-container"
  class:has-outer-border={outerBorder}
  class:has-inner-border={!outerBorder}
  class:focused
  class:readonly={readonly || disabled}
  class:visible-border={border}
  on:click|stopPropagation={focusInput}>
  <div class="label" style={lw}>
    <slot name="label">
      {label}
    </slot>
  </div>
  <div class="grow">
    {#if readonly || product.category_id}
      <div class="flex items-center justify-start">
        <div bind:this={input} class="mx-2">{displayValue}</div>
        {#if !disabled && product.category_id && product.category_id !== "Mixed"}
          <button class="text-gray-400" on:click|stopPropagation={deselectCategory}>
            <CircleXIcon />
          </button>
        {/if}
      </div>
    {:else}
      <input
        size="1"
        bind:this={input}
        class="input"
        {placeholder}
        {disabled}
        bind:value={textValue}
        on:focus={handleFocus}
        on:keydown={handleKeydown}
        on:blur={handleBlur} />
    {/if}
  </div>
  {#if !disabled}
    <button on:click|stopPropagation={openModal}>
      <EllipsisIcon />
    </button>
  {/if}
</div>

<Modal
  bind:this={modal}
  width="36rem"
  closeable
  fullframe
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Choose Category", type: "confirm", disabled: !selected },
  ]}
  on:confirm={chooseCategory}>
  <div slot="title">Choose Category</div>
  <div slot="content" class="overflow-y-auto">
    <div class="p-4 space-y-2 text-xs">
      <LibrarySection
        section={{ records: categories }}
        addable
        deletable
        {selected}
        on:select={selectCategory}
        on:add={startAddingCategory}
        on:delete={deleteCategory}>
        <div slot="thumbnail" let:record class="w-full h-full">
          {#if record.image}
            <img src={imageUrl(record.image)} alt={record.name} class="w-full h-full object-cover" />
          {/if}
        </div>
        <div slot="caption" let:record>
          {record.name}
        </div>
      </LibrarySection>
      {#if addingCategory}
        <div class="border rounded flex items-stretch">
          <div class="grow p-4">
            <h3 class="px-2 mb-1">New Category</h3>
            <TextInput label="Name" bind:value={name} inputOnKeydown />
            <div class="image-container">
              <ImageInput
                label="Image"
                labelWidth="5rem"
                value={imageUrl(image)}
                accept="image/png, image/jpeg"
                uploadType="arraybuffer"
                on:upload={uploadImage}
                on:delete={() => (image = null)} />
            </div>
          </div>
          <button
            class="confirm-button"
            class:disabled={!name}
            class:text-gray-300={!name}
            disabled={!name}
            on:click={addCategory}>
            <CheckIcon />
          </button>
        </div>
      {:else if selected}
        <div class="border rounded flex items-stretch">
          <div class="grow p-4">
            <h3 class="px-2 mb-1">Update Category</h3>
            <TextInput label="Name" bind:value={name} inputOnKeydown />
            <div class="image-container">
              <ImageInput
                label="Image"
                labelWidth="5rem"
                value={imageUrl(image)}
                accept="image/png, image/jpeg"
                uploadType="arraybuffer"
                on:upload={uploadImage}
                on:delete={() => (image = null)} />
            </div>
          </div>
          <button
            class="confirm-button"
            class:disabled={!name}
            class:text-gray-300={!name}
            disabled={!name}
            on:click={updateCategory}>
            <CheckIcon />
          </button>
        </div>
      {/if}
    </div>
  </div>
</Modal>

<style lang="scss">
  input {
    border: none;
    background-color: none;
    outline: none;
    min-width: 0;
    max-width: 100%;
    width: 100%;
  }

  .prop-container {
    @apply w-full flex items-center rounded bg-white;

    &.has-outer-border {
      @apply p-2;

      &:hover:not(.readonly):not(.focused),
      &.visible-border {
        @apply ring-1 ring-inset ring-gray-300;
      }

      &.focused {
        @apply ring-2 ring-inset ring-blue-500;
      }
    }

    .label {
      @apply truncate text-gray-400 flex-none;
    }

    .input {
      @apply min-w-0 grow shrink ml-2;
    }

    &.has-inner-border {
      .input {
        @apply p-1 rounded;
      }
    }

    &.has-inner-border:not(.readonly):not(.focused) {
      .input:hover {
        @apply ring-1 ring-inset ring-gray-300;
      }
    }

    &.has-inner-border.focused {
      .input {
        @apply ring-2 ring-inset ring-blue-500;
      }
    }
  }

  .image-container {
    width: 150px;
  }

  .confirm-button {
    @apply flex-none border-l p-4;

    &:hover:not(.disabled) {
      @apply bg-gray-100;
    }
  }
</style>
