<script>
  import { tick } from "svelte";
  import cloneDeep from "lodash/cloneDeep";
  import isEmpty from "lodash/isEmpty";
  import { navigate } from "svelte-routing";

  import { Modal } from "svelte-utilities";
  import UppyDropzone from "src/lib/dropzone/UppyDropzone.svelte";
  import ListThumbnail from "src/lib/ListThumbnail.svelte";
  import DocumentThumbnail from "src/lib/DocumentThumbnail.svelte";
  import SortableThumbnailList from "src/lib/SortableThumbnailList.svelte";
  import Sidebar from "src/lib/sidebar/Sidebar.svelte";
  import JobProperties from "src/lib/sidebar/JobProperties.svelte";
  import DocumentProperties from "src/lib/sidebar/DocumentProperties.svelte";
  import Attachments from "src/lib/sidebar/Attachments.svelte";
  import History from "src/lib/sidebar/History.svelte";
  import PrevNext from "src/lib/sidebar/PrevNext.svelte";
  import SidebarTitle from "src/lib/sidebar/SidebarTitle.svelte";

  import { selectedDocuments as selectedDocumentsObj, pathRoot, showRightPanel } from "src/stores/ui.js";
  import { orderedList, addToSortableList, removeFromSortableList } from "@local/extensions/collections/sortable-list.js";
  import nameMinusExtension from "@local/extensions/utilities/name-minus-extension.js";
  import { SUPABASE_URL as supabaseUrl } from "src/env";

  export let group;
  export let disabled;

  let width;
  let height;

  let importModal;

  let confirmRemoveModal;
  let docsToDelete = [];

  $: attachments = $group.attachments.filter((a) => !a.type_id && !a.item_id);
  $: documents = orderedList($group.data.documents);
  $: selectedDocs = documents.filter((d) => $selectedDocumentsObj[d.id]);
  $: selectedDoc = selectedDocs[0];
  $: selectedDocIndex = documents.indexOf(selectedDocs[0]);
  $: prevDoc = selectedDocs.length === 1 && documents[selectedDocIndex - 1];
  $: nextDoc = selectedDocs.length === 1 && documents[selectedDocIndex + 1];

  function selectNone() {
    $selectedDocumentsObj = {};
  }

  function selectDocument(e) {
    const { id, event } = e.detail;
    if (event.shiftKey && !isEmpty($selectedDocumentsObj)) {
      const allIds = documents.map((t) => t.id);
      const indices = documents
        .map((t, i) => i)
        .filter((i) => $selectedDocumentsObj[documents[i].id] || documents[i].id === id);

      const min = Math.min(...indices);
      const max = Math.max(...indices);

      const ids = allIds.slice(min, max + 1);
      selectedDocumentsObj.selectOnly(...ids);
    } else if (event.metaKey) {
      if (!$selectedDocumentsObj[id]) {
        selectedDocumentsObj.select(id);
      } else {
        selectedDocumentsObj.deselect(id);
      }
    } else {
      selectedDocumentsObj.selectOnly(id);
    }
  }

  function confirmRemove(e) {
    const { selected } = e.detail;
    docsToDelete = selected.filter((id) => $group.data.documents[id]);
    confirmRemoveModal.open();
  }

  async function removeDocuments() {
    const dtd = [...docsToDelete];
    docsToDelete = [];
    await tick();

    const docs = cloneDeep($group.data.documents);
    removeFromSortableList(docs, ...dtd);

    group.updateProp("data.documents", docs);
  }

  function cloneDocuments(d) {
    const { selected } = d.detail;

    const docs = cloneDeep($group.data.documents);

    const cloned = selected.map((id) => {
      const doc = cloneDeep(docs[id]);
      doc.id = crypto.randomUUID();
      doc.locations = [];
      return doc;
    });
    addToSortableList(docs, ...cloned);
    group.updateProp("data.documents", docs);
  }

  function beginAddingDocument() {
    importModal.open();
  }

  async function addDocument(e) {
    const { results } = e.detail;
    const imageTypes = ["image/jpeg", "image/png", "image/gif"];

    if (results.length) {
      const ready = results.every((f) => imageTypes.includes(f.meta.type));
      const t = results[0];
      const thumbnail = imageTypes.includes(t.meta.type)
        ? {
            path: t.meta.objectName,
            bucket: "documents",
            name: t.meta.name,
            type: "image",
          }
        : null;

      const files = results.map((r) => ({
        id: r.meta.uuid,
        name: r.meta.name,
        stored_name: nameMinusExtension(r.meta.name),
        object_id: r.meta.objectName,
        extension: r.extension,
        content_type: r.meta.type,
        pages: imageTypes.includes(r.meta.type) ? 1 : null,
        status: {},
        ready: imageTypes.includes(r.meta.type),
      }));

      const doc = {
        id: crypto.randomUUID(),
        name: t.meta.name,
        thumbnail,
        files,
        ready,
        locations: [],
      };

      const docs = cloneDeep($group.data.documents);
      addToSortableList(docs, doc);

      group.updateProp("data.documents", docs);
    }

    importModal.close();
  }

  function updateDocument(e) {
    const { document } = e.detail;
    const docs = cloneDeep($group.data.documents);
    docs[document.id] = document;
    group.updateProp("data.documents", docs);
  }

  function reorder(e) {
    const { insertion, dragging } = e.detail;
    const order = cloneDeep($group.data.documents.order);
    const moved = order.splice(dragging, 1)[0];
    order.splice(insertion, 0, moved);
    group.updateProp("data.documents.order", order);
  }

  function gotoPrev() {
    if (prevDoc) selectedDocumentsObj.selectOnly(prevDoc.id);
  }

  function gotoNext() {
    if (nextDoc) selectedDocumentsObj.selectOnly(nextDoc.id);
  }

  function nav(e) {
    const { id } = e.detail;
    const newpath = `${$pathRoot}documents/${id}`;
    navigate(newpath);
  }
</script>

<div class="bg-gray-100 h-full flex" bind:offsetWidth={width} bind:offsetHeight={height}>
  <SortableThumbnailList
    list={documents}
    {disabled}
    selected={$selectedDocumentsObj}
    on:add={beginAddingDocument}
    on:select={selectDocument}
    on:remove-selected={confirmRemove}
    on:select-none={selectNone}
    on:clone-selected={cloneDocuments}
    on:nav={nav}
    on:reorder={reorder}
    let:record>
    <ListThumbnail {record} {group}>
      <DocumentThumbnail document={record} on:update={updateDocument} />
    </ListThumbnail>
  </SortableThumbnailList>

  {#if $showRightPanel && width >= 640}
    <Sidebar>
      <svelte:fragment slot="header">
        {#if selectedDocs.length === 1 && selectedDoc}
          <PrevNext
            prev={prevDoc}
            next={nextDoc}
            title={selectedDoc.name}
            on:gotoprev={gotoPrev}
            on:gotonext={gotoNext}
            sticky />
        {/if}
      </svelte:fragment>
      <svelte:fragment slot="content">
        {#if selectedDocs.length === 0}
          <SidebarTitle title="Job Properties" />
          <JobProperties {disabled} {group} />
          <Attachments {attachments} {group} {disabled} />
          <History updatedBy={$group.updater} updatedOn={$group.updated_at} />
        {:else if selectedDocs.length === 1}
          {#key selectedDoc.id}
            <DocumentProperties document={selectedDoc} {group} {disabled} />
          {/key}
        {:else}
          <SidebarTitle title="Multiple Selected" />
        {/if}
      </svelte:fragment>

      <svelte:fragment slot="footer" />
    </Sidebar>
  {/if}
</div>

<Modal bind:this={importModal} closeable width="36rem">
  <div slot="title">Create New Document</div>
  <div slot="content">
    <UppyDropzone
      uploadUrl={`${supabaseUrl}/storage/v1/upload/resumable`}
      bucket="documents"
      accept="application/pdf,image/jpeg,image/gif,image/png"
      on:complete={addDocument} />
  </div>
</Modal>

<Modal
  bind:this={confirmRemoveModal}
  on:confirm={removeDocuments}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Delete", type: "confirm", style: "danger" },
  ]}
  closeOnOutclick>
  <div slot="title">Delete Documents</div>
  <div slot="content" class="space-y-2">
    <div class="space-y-2">
      <p>Are you sure you want to delete these documents?</p>
      {#each docsToDelete as id}
        <div class="pl-4 truncate">{$group.data.documents[id].name}</div>
      {/each}
    </div>
  </div>
</Modal>
