import { api, headers, jsonResult } from "./index.js";
import { BACKEND_PREFIX as backendPrefix } from "src/env.js";
import { profile } from "src/stores/auth.js";

let $profile;
profile.subscribe((value) => {
  $profile = value;
});

async function searchCrmContacts({ search_string }) {
  const { data } = await api.auth.getSession();
  const { access_token } = data.session;
  const orgid = $profile.organization_id;

  const res = await fetch(`${backendPrefix}/crm/${orgid}/search-contacts/`, {
    method: "POST",
    headers: headers(access_token),
    body: JSON.stringify({ search_string }),
  });

  return jsonResult(res);
}

async function addCrmContact(contact) {
  const { data } = await api.auth.getSession();
  const { access_token } = data.session;
  const orgid = $profile.organization_id;

  const res = await fetch(`${backendPrefix}/crm/${orgid}/contacts`, {
    method: "POST",
    headers: headers(access_token),
    body: JSON.stringify({ contact }),
  });

  return jsonResult(res);
}

export { searchCrmContacts, addCrmContact };
