<svelte:options strictprops={false} />

<script>
  import { createEventDispatcher } from "svelte";
  import set from "lodash/set";
  import cloneDeep from "lodash/cloneDeep";
  import SidebarSection from "./SidebarSection.svelte";
  import DimtextInput from "./DimtextInput.svelte";
  import SelectInput from "./SelectInput.svelte";
  import DeleteButton from "./DeleteButton.svelte";

  export let job;
  export let item;
  export let feature;
  export let disabled;
  export let settings;

  const dispatch = createEventDispatcher();

  function updateFeature(prop, value) {
    const data = cloneDeep(item.data);

    const f = data.fabrications.bug;
    set(f, prop, value);

    job.updateItem(item.id, { data });
  }

  async function removeFeature() {
    const indices = Array.isArray(feature.index) ? feature.index : [feature.index];
    const features = indices.map((index) => ({ type: "bug", index }));

    dispatch("deleteFeature", { features });
  }
</script>

<SidebarSection>
  <DimtextInput
    label="Edge distance"
    {disabled}
    labelWidth="5.5rem"
    value={feature.props.reference.length}
    {settings}
    on:input={(e) => updateFeature("reference.length", e.detail.value)} />
  <DimtextInput
    label="Offset distance"
    {disabled}
    labelWidth="5.5rem"
    value={feature.props.reference.offset}
    {settings}
    on:input={(e) => updateFeature("reference.offset", e.detail.value)} />
  <SelectInput
    label="Dimension to"
    {disabled}
    labelWidth="5.5rem"
    value={feature.props.alignment}
    options={[
      { label: "Center", value: "center" },
      { label: "Inside Edge", value: "inside-edge" },
    ]}
    on:input={(e) => updateFeature("alignment", e.detail.value)} />
  <SelectInput
    label="Orientation"
    {disabled}
    labelWidth="5.5rem"
    value={feature.props.orientation}
    options={[
      { label: "Orthogonal", value: "orthogonal" },
      { label: "Edge-aligned", value: "edge-aligned" },
    ]}
    on:input={(e) => updateFeature("orientation", e.detail.value)} />
</SidebarSection>
<SidebarSection>
  <DeleteButton on:delete={removeFeature} />
</SidebarSection>
