<script>
  import { tooltip } from "svelte-utilities";

  import { createEventDispatcher } from "svelte";
  import DuplicateIcon from "src/assets/icons/duplicate.svg";
  import DuplicateSendIcon from "src/assets/icons/duplicate-send.svg";
  import TrashIcon from "src/assets/icons/trash.svg";

  const dispatch = createEventDispatcher();

  export let cloneable = false;
  export let deletable = false;
  export let shareable = false;
  export let selected = 1;
</script>

{#if cloneable || deletable || shareable}
  <div class="w-full flex justify-center p-4 absolute z-30 pointer-events-none">
    <div class="flex items-center bg-white p-2 rounded border shadow-lg pointer-events-auto">
      <div class="text-xs px-2 mr-1">{selected} selected</div>
      {#if cloneable}
        <button
          class="mini-button"
          on:click|stopPropagation={(e) => dispatch("clone")}
          use:tooltip={{ text: "Clone" }}>
          <DuplicateIcon />
        </button>
      {/if}
      {#if shareable}
        <button
          class="mini-button"
          on:click|stopPropagation={(e) => dispatch("share")}
          use:tooltip={{ text: "Send" }}>
          <DuplicateSendIcon />
        </button>
      {/if}
      {#if deletable}
        <button
          class="mini-button"
          on:click|stopPropagation={(e) => dispatch("delete")}
          use:tooltip={{ text: "Delete" }}>
          <TrashIcon />
        </button>
      {/if}
    </div>
  </div>
{/if}

<style lang="scss">
  .mini-button {
    @apply p-2 rounded;

    &:hover {
      @apply bg-gray-200;
    }
  }
</style>
