<script>
  import { distSq } from "vector";
  import { createEventDispatcher } from "svelte";
  import SelectedActions from "./SelectedActions.svelte";
  import FilterInput from "src/lib/products/FilterInput.svelte";
  import { draggable } from "svelte-utilities";
  import { copyToClipboard } from "overline";

  import { profile } from "src/stores/auth.js";

  export let list;
  export let disabled;
  export let selected;
  export let reorderable = true;
  export let filterable = false;
  export let shareable = false;
  export let filter = "";

  const dispatch = createEventDispatcher();

  // variables to manage type drag/drop
  let dragStartPt = null;
  let dragging = null;
  let isDragging = false;

  $: selectedList = list.filter((record) => selected[record.id]);
  $: idCopyable = $profile?.user_role === "developer";

  function dragstart(e, id, index) {
    if (!reorderable) return;
    const { x, y } = e;
    dragStartPt = { x, y };
    dragging = index;
  }

  function drag(e, id, index) {
    if (dragging === null) return;

    const d = dragStartPt ? distSq(e, dragStartPt) : 0;

    if (d > 100) {
      isDragging = true;
    } else {
      isDragging = false;
    }
  }

  function drop(dropIndex) {
    if (!isDragging) return;
    if (dragging === dropIndex || dropIndex === dragging - 1) return;
    const insertion = dropIndex < dragging ? dropIndex + 1 : dropIndex;
    dispatch("reorder", { insertion, dragging, dropIndex });
  }

  function dragend() {
    dragging = null;
    isDragging = false;
  }

  function keydownContainer() {}

  function keydownRecord() {}

  function copyIdToClipboard() {
    if (selectedList.length === 1) {
      const [{ id }] = selectedList;
      if (id) {
        copyToClipboard(id);
      }
    }
  }
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<div class="flex-grow relative" on:click={() => dispatch("select-none")} on:keydown={keydownContainer}>
  {#if selectedList.length > 0}
    <SelectedActions
      cloneable={!disabled}
      deletable={!disabled}
      {shareable}
      selected={selectedList.length}
      on:share={() => dispatch("share-selected", { selected: selectedList.map((s) => s.id) })}
      on:delete={() => dispatch("remove-selected", { selected: selectedList.map((s) => s.id) })}
      on:clone={() => dispatch("clone-selected", { selected: selectedList.map((s) => s.id) })}
      on:copy-id={copyIdToClipboard} />
  {/if}
  <div class="h-full w-full overflow-y-auto overflow-x-hidden pt-4">
    {#if filterable}
      <div class="px-6 pt-4 md:w-96 z-10">
        <FilterInput bind:filter />
      </div>
    {/if}
    <div class="flex p-4 flex-wrap gap-4 items-stretch">
      {#each list as record, index (record.id)}
        <div
          class="relative w-40 sm:w-52 p-2"
          on:mousedown|stopPropagation={(event) => dispatch("select", { event, id: record.id })}
          on:click|stopPropagation
          on:keydown={keydownRecord}
          on:dblclick={() => dispatch("nav", { id: record.id })}
          use:draggable
          on:dragstart={(e) => !disabled && dragstart(e.detail, record.id, index)}
          on:drag={(e) => drag(e.detail, record.id, index)}
          on:dragend={dragend}>
          {#if index === 0}
            <div class="drop-target left-target" class:visible={isDragging} on:mouseup={() => drop(-1)}>
              <div class="drop-target-line" />
            </div>
          {/if}
          <div
            class="relative h-full w-full border border-gray-300 bg-white shadow rounded-lg p-4 space-y-2"
            class:dragging={isDragging && dragging === index}
            class:ring-4={selected[record.id]}>
            <slot {record}>Missing template</slot>
          </div>
          <div class="drop-target" class:visible={isDragging} on:mouseup={() => drop(index)}>
            <div class="drop-target-line" />
          </div>
        </div>
      {/each}
      {#if !disabled}
        <div class="w-40 h-40 sm:h-56 sm:w-52 p-2">
          <button
            on:click={() => dispatch("add")}
            class="hover:bg-gray-200 cursor-pointer rounded-md border border-gray-400 border-dashed flex flex-col justify-center items-center w-full h-full text-gray-500 text-xl">
            <div class="text-center">+</div>
          </button>
        </div>
      {/if}
    </div>
  </div>
</div>

<style lang="scss">
  .dragging {
    @apply border border-dashed bg-gray-200 border-gray-400;
  }

  .drop-target {
    @apply absolute w-full h-full z-30 pointer-events-none opacity-50;
    right: calc(-50% - 0.5rem);
    top: 0;

    &.left-target {
      left: calc(-50% - 0.25rem);
    }

    &.visible {
      pointer-events: auto;

      &:hover {
        .drop-target-line {
          @apply h-full border-l border-gray-300 absolute;
          left: 50%;
        }
      }
    }
  }
</style>
