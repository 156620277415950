<script>
  import SortInput from "./sidebar/SortInput.svelte";
  import { profile } from "src/stores/auth.js";

  export let group;
  export let customColumns;
  export let standardColumns;
  export let groupBy;
  export let groupByDirection;
  export let sortBy;
  export let sortByDirection;
  export let isCollections = false;

  $: groupOptions = makeGroupOptions(customColumns);
  $: sortOptions = makeSortOptions(customColumns);
  $: checkGroupBy(groupOptions);
  $: checkSortBy(sortOptions);

  function makeGroupOptions(customColumns) {
    const sc = [
      { label: "None", value: "none" },
      ...(isCollections
        ? []
        : [
            { label: "Type", value: "type_id" },
            { label: "Product", value: "type.product_id" },
            { label: "Product Category", value: "category" },
            { label: "Opening", value: "collection_id" },
          ]),
      { label: "Mark", value: "mark" },
      { label: "Width", value: "width" },
      { label: "Height", value: "height" },
      { label: "Quantity", value: "quantity" },
      { label: "Description", value: "description" },
    ].map((col) => {
      if (standardColumns[col.value]?.override) {
        col.label = standardColumns[col.value].override;
      }
      return col;
    });

    return sc.concat(
      customColumns.map((c) => ({
        label: c.name,
        value: `custom_column_data.${c.id}`,
      })),
    );
  }

  function checkGroupBy(groupOptions) {
    const current = groupOptions.find((c) => c.value === $groupBy);

    if (!current) {
      $groupBy = "type_id";
    }
  }

  function checkSortBy(sortOptions) {
    const current = sortOptions.find((c) => c.value === $sortBy);

    if (!current) {
      $sortBy = "created_at";
    }
  }

  function makeSortOptions(customColumns) {
    const sc = [
      { label: "Created at", value: "created_at" },
      { label: "Mark", value: "mark" },
      { label: "Width", value: "width" },
      { label: "Height", value: "height" },
      { label: "Quantity", value: "quantity" },
      { label: "Description", value: "description" },
    ].map((col) => {
      if (standardColumns[col.value]?.override) {
        col.label = standardColumns[col.value].override;
      }
      return col;
    });

    return sc.concat(
      customColumns.map((c) => ({
        label: c.name,
        value: `custom_column_data.${c.id}`,
      })),
    );
  }

  function reGroup(evt) {
    $groupBy = evt.detail.sortBy;
    $groupByDirection = evt.detail.sortDirection;
  }

  function reSort(evt) {
    $sortBy = evt.detail.sortBy;
    $sortByDirection = evt.detail.sortDirection;
    group.sortItems($sortBy, $sortByDirection, { prop: "is_collection", value: !isCollections });
  }
</script>

<div class="text-xs flex flex-wrap items-center gap-2 justify-end text-gray-500">
  {#if $group?.project_type !== "product"}
    <div class="w-36">
      <SortInput
        label="Group by"
        sortBy={$groupBy}
        sortDirection={$groupByDirection}
        on:input={reGroup}
        options={groupOptions} />
    </div>
  {/if}
  <div class="w-36">
    <SortInput
      label="Sort by"
      sortBy={$sortBy}
      sortDirection={$sortByDirection}
      on:input={reSort}
      options={sortOptions} />
  </div>
</div>
