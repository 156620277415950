<script>
  import { onMount, createEventDispatcher, getContext, setContext } from "svelte";
  import { writable } from "svelte/store";
  import { downloadString } from "downloaders";
  import { navigate } from "svelte-routing";
  import isEqual from "lodash/isEqual";
  import upperCase from "lodash/upperCase";

  import CirclePlusIcon from "src/assets/icons/circle-plus.svg";
  import TagIcon from "src/assets/icons/tag.svg";
  import DownloadIcon from "src/assets/icons/download.svg";
  import ProfileIcon from "src/assets/icons/profile.svg";
  import LocationIcon from "src/assets/icons/location.svg";
  import NotesIcon from "src/assets/icons/notes.svg";
  import NumberIcon from "src/assets/icons/number.svg";
  import GroupAttachments from "./GroupAttachments.svelte";
  import ContenteditableInput from "src/lib/sidebar/ContenteditableInput.svelte";
  import TextInput from "src/lib/sidebar/TextInput.svelte";
  import SelectInput from "src/lib/sidebar/SelectInput.svelte";
  import RecipientsInput from "./RecipientsInput.svelte";
  import MapboxInput from "src/lib/sidebar/MapboxInput.svelte";

  import HamburgerMenu from "src/lib/HamburgerMenu.svelte";
  import HamburgerMenuItem from "src/lib/HamburgerMenuItem.svelte";
  import { Modal } from "svelte-utilities";
  import Quote from "./Quote.svelte";
  import GroupSummary from "./GroupSummary.svelte";
  import QuoteRecipientModal from "./QuoteRecipientModal.svelte";
  import SendModal from "./SendModal.svelte";
  import DownloadModal from "./DownloadModal.svelte";
  import SummaryTable from "./SummaryTable.svelte";
  import Notifications from "./Notifications.svelte";

  import { orderedList } from "@local/extensions/collections/sortable-list.js";
  import { rfqs, fetchRfq } from "src/stores/rfqs.js";
  import { orgs } from "src/stores/organizations.js";
  import { fetchGroup, createGroup } from "src/stores/group.js";
  import { profile } from "src/stores/auth.js";
  import formatDateTime from "@local/extensions/formatters/format-date-time.js";
  import copyToClipboard from "src/extensions/copy-to-clipboard.js";
  import typeColor from "@local/extensions/drawing/type-color.js";
  import eb from "src/extensions/event-bus.js";
  import { api } from "src/api";
  import { sendQuoteRequest, addQuoteRequestRecipients, cancelQuoteRequest } from "src/api/requests.js";
  import { SHORTENER_URL } from "src/env";
  import { currentSupplierid } from "src/stores/ui.js";
  import { bucketArrayMultiple } from "overline";
  import getUserInitials from "@local/extensions/formatters/get-user-initials.js";
  import { exportJson } from "src/extensions/export-job/json.js";

  export let rfqid;

  const dispatch = createEventDispatcher();
  const typeColors = writable({});

  setContext("typeColors", typeColors);

  let rfq;
  let qrModal;
  let cancelRfqModal;
  let group;

  function transform(coll, xf, defaultValue = []) {
    return coll ? xf(coll) : defaultValue;
  }

  const suppliers = getContext("suppliers");
  const org = getContext("org");

  $: recipients = rfq?.data.saved_recipients || [];
  $: items = transform($group?.items, orderedList).filter((i) => !i.is_collection);
  $: collections = transform($group?.items, orderedList).filter((i) => i.is_collection);
  $: types = transform($group?.types, orderedList);
  $: $typeColors = types.reduce((a, t) => {
    a[t.id] = typeColor(t);
    return a;
  }, {});
  $: archived = !!rfq?.archived_at;
  $: locationZipcode = findZipcode(rfq?.job.location);
  $: status = getStatus(rfq);
  $: assignToOptions = getAssignToOptions($profile, $org);
  $: allAttachments = $group && bucketArrayMultiple($group.attachments, "item_id", "type_id");

  function confirmAddRecipients() {
    qrModal.open();
  }

  function findZipcode(location) {
    if (!location) return null;
    const match = location.match(/\d{5}(?:[-\s]\d{4})?/);
    if (!match) return null;
    return match[0];
  }

  function getStatus(rfq) {
    if (!rfq) return null;
    if (rfq.status === "canceled") return "canceled";
    if (!rfq.sent_at) return "draft";
    return "sent";
  }

  function getAssignToOptions(p, o) {
    if (!o) return [];

    const orgProfiles =
      o.profiles?.map((prof) => ({
        label: prof.username || prof.email,
        initials: upperCase(getUserInitials(prof)),
        color: prof.user_color,
        value: prof.id,
      })) || [];

    const present = orgProfiles.some((prof) => prof.value === p.id);

    if (present) {
      return [
        {
          label: "Unassigned",
          value: null,
        },
        ...orgProfiles,
      ];
    } else {
      return [
        {
          label: "Unassigned",
          value: null,
        },
        {
          label: p.username || p.email,
          initials: upperCase(getUserInitials(p)),
          color: p.user_color,
          value: p.id,
        },
        ...orgProfiles,
      ];
    }
  }

  async function addRecipients(e) {
    const { recipients } = e.detail;

    const existingRecipients = rfq.data.saved_recipients || [];
    const saved_recipients = [...existingRecipients, ...recipients];

    await api
      .from("quote_requests")
      .update({
        data: { saved_recipients },
      })
      .eq("id", rfqid);

    rfq = await fetchRfq(rfqid);
    rfqs.fetchOne(rfqid);
  }

  async function setRecipient(e) {
    const { recipient } = e.detail;
    const saved_recipients = [recipient];
    await api
      .from("quote_requests")
      .update({
        data: { saved_recipients },
      })
      .eq("id", rfqid);

    rfq = await fetchRfq(rfqid);
    rfqs.fetchOne(rfqid);
  }

  async function removeReipient(e) {
    const { id } = e.detail;
    const saved_recipients = rfq.data.saved_recipients.filter((r) => r.id !== id);

    await api.from("quote_requests").update({ data: { saved_recipients } }).eq("id", rfqid);

    rfq = await fetchRfq(rfqid);
    rfqs.fetchOne(rfqid);
  }

  async function sendRecipients(e) {
    const { recipients } = e.detail;

    await addQuoteRequestRecipients({
      id: rfqid,
      recipients,
      sender_id: $profile.id,
    });

    rfq = await fetchRfq(rfqid);
    rfqs.fetchOne(rfqid);
    orgs.fetchOne($org.id);
  }

  async function updateRfq(update) {
    await api.from("quote_requests").update(update).eq("id", rfqid).select("*").single();

    rfq = await fetchRfq(rfqid);
    rfqs.fetchOne(rfqid);
  }

  async function updateJob(update) {
    await api.from("jobs").update(update).eq("id", rfq.job_id).select("*").single();

    rfq = await fetchRfq(rfqid);
    rfqs.fetchOne(rfqid);
  }

  async function updateGroup(prop, value) {
    group.updateProp(prop, value);
  }

  async function sendRfq(e) {
    rfqs.spin(rfqid);

    await sendQuoteRequest({
      id: rfqid,
      recipients: rfq.data.saved_recipients,
      sender_id: $profile.id,
      job_id: rfq.job_id,
      organization_id: $org.id,
    });

    rfq = await fetchRfq(rfqid);
    rfqs.fetchOne(rfqid);
    orgs.fetchOne($org.id);
  }

  async function cancelQuote(e) {
    const { id } = e.detail;
    await api.from("quotes").delete().eq("id", id);

    rfq = await fetchRfq(rfqid);
    rfqs.fetchOne(rfqid);
  }

  async function copyLink() {
    copyToClipboard(`${SHORTENER_URL}/rfq/${rfq.link_nanoid}`);
  }

  async function cloneRfq() {
    dispatch("clone-rfq", { id: rfqid });
  }

  async function cancelRfq() {
    await cancelQuoteRequest({ id: rfqid });
    rfq = await fetchRfq(rfqid);
    rfqs.fetchOne(rfqid);
  }

  async function deleteRequest() {
    dispatch("delete-rfq", { id: rfqid });
  }

  async function update() {
    rfq = await fetchRfq(rfqid);
    rfqs.fetchOne(rfqid);
  }

  async function archiveRfq() {
    await rfqs.archive(rfqid);
  }

  async function unarchiveRfq() {
    await rfqs.unarchive(rfqid);
    rfq = await fetchRfq(rfqid);
    rfqs.fetchOne(rfqid);
  }

  async function downloadJSON() {
    const json = exportJson($group, rfq.job);
    downloadString(json, `${rfq.job.name}.json`);
  }

  onMount(async () => {
    if (!rfqid) return;

    try {
      rfq = await fetchRfq(rfqid);

      if (rfq.group_id) {
        const gd = await fetchGroup(rfq.group_id);
        const g = createGroup(gd);
        group = g;
      } else {
        const gd = await fetchGroup(rfq.job.current_group_id);
        const g = createGroup(gd);
        group = g;
      }

      if ($suppliers.length) {
        let supplier = $suppliers.find((s) => s.id === rfq?.data.saved_recipients?.[0]?.contact_id);
        if (!supplier) supplier = $suppliers[0];
        $currentSupplierid = supplier.id;

        if (!rfq?.data.saved_recipients?.length) {
          const recipient = {
            type: "org",
            contact_id: supplier.id,
            primary_contact_id: null,
            company_name: supplier.name,
            name: null,
            email: supplier.primary_contact_email,
          };

          if (!isEqual([recipient], rfq?.data.saved_recipients)) {
            setRecipient({ detail: { recipient } });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  });
</script>

{#if rfq}
  <div class="w-full space-y-4">
    <div class="flex justify-between items-start border-b pb-4 mx-4">
      <div class="grow flex items-start gap-2 flex-wrap">
        <div>
          <div class="font-bold uppercase w-48" class:italic={status == "draft"}>
            {status}
          </div>
          <div class="text-xs">
            {#if rfq.sent_at && rfq.status !== "canceled"}
              {formatDateTime(rfq.sent_at)}
            {/if}
            &nbsp;
          </div>
        </div>
        <GroupSummary {group} {items} {types} />
      </div>
      <div class="flex gap-2">
        {#if $org?.org_type !== "product_rep"}
          <button class="btn btn-icon" on:click={() => eb.dispatch("open-download")}>
            <DownloadIcon />
          </button>
        {/if}
        <div class="btn-bare">
          <HamburgerMenu>
            <HamburgerMenuItem label="Copy Link" icon="link" on:click={copyLink} />
            <HamburgerMenuItem label="Clone" icon="duplicate" on:click={cloneRfq} />
            {#if !rfq.sent_at}
              <HamburgerMenuItem label="Delete Draft" icon="trash" on:click={deleteRequest} />
            {:else if rfq.status !== "canceled"}
              <HamburgerMenuItem label="Cancel Request" icon="x" on:click={() => cancelRfqModal.open()} />
            {/if}
            {#if archived}
              <HamburgerMenuItem label="Unarchive" icon="unarchive" on:click={unarchiveRfq} />
            {:else}
              <HamburgerMenuItem label="Archive" icon="archive" on:click={archiveRfq} />
            {/if}
            {#if $profile?.user_role === "developer"}
              <HamburgerMenuItem label="Download JSON" icon="download" on:click={downloadJSON} />
            {/if}
          </HamburgerMenu>
        </div>
      </div>
    </div>
    {#if rfq.notifications.length}
      <div class="px-4">
        <Notifications notifications={rfq.notifications} on:clear={update} />
      </div>
    {/if}

    <div class="content-columns px-4">
      <div class="left-column space-y-2">
        <SelectInput
          placeholder="Assigned to"
          labelWidth="1rem"
          value={rfq.assigned_to_id}
          options={assignToOptions}
          outerBorder={false}
          on:input={(e) => updateRfq({ assigned_to_id: e.detail.value })}>
          <div class="text-black" slot="label">
            <ProfileIcon />
          </div>
          <div slot="selected" let:option class="flex items-center gap-1">
            {#if option.initials}
              <div
                class="profile-circle relative"
                style="background-color: {option.color || '#000'}; border-color: {option.color || '#000'}">
                {option.initials}
              </div>
            {/if}
            <div class:text-gray-400={!option.value}>
              {option.label}
            </div>
          </div>
          <div slot="option" let:option class="flex items-center gap-1">
            {#if option.initials}
              <div
                class="profile-circle relative"
                style="background-color: {option.color || '#000'}; border-color: {option.color || '#000'}">
                {option.initials}
              </div>
            {:else}
              <div class="text-gray-400">
                <ProfileIcon />
              </div>
            {/if}
            <div class:text-gray-400={!option.value}>
              {option.label}
            </div>
          </div>
        </SelectInput>
        <MapboxInput
          labelWidth="1rem"
          value={rfq.job.location}
          coordinates={rfq.job.location_coordinates}
          outerBorder={false}
          placeholder="Location"
          disabled={rfq.sent_at}
          on:input={(e) =>
            updateJob({
              location: e.detail.value,
              location_coordinates: e.detail.coordinates,
              location_postal_code: e.detail.postal_code,
              location_country_code: e.detail.country_code,
            })}>
          <div class="text-black" slot="label">
            <LocationIcon />
          </div>
        </MapboxInput>
        <SelectInput
          labelWidth="1rem"
          outerBorder={false}
          value={rfq.request_for}
          disabled={rfq.sent_at}
          options={[
            { label: "For quote", value: "quote" },
            { label: "For order", value: "order" },
            { label: "For other (see notes)", value: "other" },
          ]}
          on:input={(e) => updateRfq({ request_for: e.detail.value })}>
          <div class="text-black" slot="label">
            <TagIcon />
          </div>
        </SelectInput>
        <TextInput
          labelWidth="1rem"
          value={rfq.job.buyer_reference}
          outerBorder={false}
          placeholder="PO Number"
          on:input={(e) => updateJob({ buyer_reference: e.detail.value })}>
          <div class="text-black" slot="label">
            <NumberIcon />
          </div>
        </TextInput>
        <ContenteditableInput
          placeholder="Notes"
          labelWidth="1rem"
          value={rfq.job.notes}
          outerBorder={false}
          on:input={(e) => updateJob({ notes: e.detail.value })}>
          <div class="text-black" slot="label">
            <NotesIcon />
          </div>
        </ContenteditableInput>
        <GroupAttachments
          {group}
          disabled={rfq.sent_at || rfq.status === "canceled"}
          attachments={allAttachments?.none} />
      </div>

      <div class="right-column text-xs space-y-2">
        {#if !rfq.sent_at && rfq.status !== "canceled"}
          <RecipientsInput
            labelWidth="5rem"
            org={$org}
            {recipients}
            editable
            showAttn
            on:remove-recipient={removeReipient}
            on:add-recipient={confirmAddRecipients}
            on:set-recipient={setRecipient} />
        {/if}
        {#if rfq.sent_at}
          <div class="space-y-2">
            {#if rfq.quotes.length === 0}
              <div class="italic border rounded p-4 b-2">No quotes defined</div>
            {/if}
            {#each rfq.quotes as quote (quote.id)}
              <Quote {rfq} {quote} on:cancel={cancelQuote} />
            {/each}
          </div>
          {#if $org.account_type !== "free"}
            <div class="flex justify-end">
              <button class="item-list-button" on:click={() => eb.dispatch("open-recipient-modal")}>
                <CirclePlusIcon />
                <div>Add Recipient</div>
              </button>
            </div>
          {/if}
        {/if}
      </div>

      <div class="center-column space-y-2">
        {#if $group && !items.length}
          <div class="flex gap-2 text-xs px-2 justify-center mb-36">
            <div class="italic py-1">This Shape List contains zero items.</div>
            <button class="text-blue-500" on:click={() => navigate(`/jobs/${rfq.job_id}/current`)}
              >Add Items</button>
          </div>
        {/if}

        {#if $group && items.length}
          <SummaryTable sent={rfq.sent_at} {group} {types} {items} {org} attachments={allAttachments} />
        {/if}
      </div>
    </div>
  </div>

  <QuoteRecipientModal
    bind:this={qrModal}
    sent={rfq.sent_at}
    {rfq}
    org={$org}
    on:add-recipients={addRecipients}
    on:send-to-recipients={sendRecipients} />

  {#if !rfq.sent_at}
    <SendModal on:confirm={sendRfq} {rfq} {recipients} {items} {types} org={$org} />
  {/if}

  <Modal
    bind:this={cancelRfqModal}
    closeable
    on:confirm={cancelRfq}
    buttons={[
      { label: "Cancel", type: "cancel" },
      { label: "Cancel RFQ", type: "confirm", style: "danger" },
    ]}>
    <div slot="title">Cancel RFQ</div>
    <div slot="content" class="space-y-4">
      <div>
        <div>Are you sure you want to cancel this RFQ?</div>
      </div>
      <div class="font-bold">
        {rfq.job.name}
      </div>
    </div>
  </Modal>

  <DownloadModal title="Download Contents" {group} {rfq} {types} name={rfq.job.name} />
{/if}

<style lang="scss">
  .content-columns {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    overflow: hidden;

    .left-column,
    .right-column {
      @apply text-xs;
      width: 100%;
    }

    @media (min-width: 768px) {
      .left-column,
      .right-column {
        width: calc(50% - 0.25rem);
      }
    }

    .center-column {
      @apply grow overflow-x-hidden;
    }
  }

  @media (min-width: 1636px) {
    .content-columns {
      flex-wrap: nowrap;

      .left-column,
      .right-column {
        width: 20rem;
      }

      .right-column {
        order: 1;
      }
    }
  }
</style>
