<script>
  import upperFirst from "lodash/upperFirst";
  import { Link } from "svelte-routing";
  import formatHumanTime from "@local/extensions/formatters/format-human-time.js";

  export let datum;

  $: status = getStatus(datum);
  $: link = datum.type === "job" ? `/jobs/${datum.id}` : `/quotes/${datum.id}`;

  function getStatus(datum) {
    if (datum.request_status === "canceled") return "rfq-canceled";
    if (datum.status === "canceled") return "canceled";
    if (!datum.sent_at) {
      return "draft";
    } else if (datum.price) {
      return "quoted";
    } else {
      return "received";
    }
  }

  function items(count) {
    if (!count) return "No items";
    if (count === 0) return "No items";
    if (count === 1) return "1 item";
    return `${count} items`;
  }
</script>

<td>
  {datum.sent_at ? formatHumanTime(datum.sent_at) : ""}
</td>

<td>
  <Link to={link}>
    <div class="flex items-center gap-2 text-blue-600 underline">
      <div class="truncate">
        {datum.name}
      </div>
      <!-- <div class="text-gray-500">
        #{datum.quote_request?.group?.version}
      </div> -->
    </div>
  </Link>
</td>

<td>
  {datum.recipient_organization_name || ""}
</td>

<td>
  {datum.location || ""}
</td>

<td>
  {datum.seller_reference || ""}
</td>

<td>
  {items(datum.items_count)}
</td>

<!-- <td>
  {datum.type === "job" ? "✓" : ""}
</td> -->

<td class="text-right">
  {datum.price ? (datum.price / 100).toFixed(2) : ""}
</td>

<td>
  <div class="italic">
    {#if status === "rfq-canceled"}
      RFQ Canceled
    {:else}
      {upperFirst(status)}
    {/if}
  </div>
</td>

<style lang="scss">
  td {
    @apply text-sm px-2 py-2.5 truncate;
  }
</style>
