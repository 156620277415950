<script>
  import PaperclipIcon from "src/assets/icons/paperclip-xs.svg";
  import CommentIcon from "src/assets/icons/comment-xs.svg";
  import ShowIcon from "src/assets/icons/show.svg";
  import HideIcon from "src/assets/icons/hide.svg";
  import {
    settingDisplayTnBadges,
    settingDisplayTnQty,
    settingDisplayTnDims,
    settingDisplayTnMark,
    settingDisplayEdgeOffsets,
  } from "src/stores/ui.js";
</script>

<div class="thumbnail">
  <div class="w-20 flex justify-end text-gray-600 py-1 relative border border-transparent">
    <div class:opacity-20={!$settingDisplayTnBadges}>
      <PaperclipIcon />
    </div>
    <div class:opacity-20={!$settingDisplayTnBadges}>
      <CommentIcon />
    </div>
    <div
      class="show-button top-0 right-0 -mr-8 -mt-1"
      class:active={$settingDisplayTnBadges}
      on:click={() => ($settingDisplayTnBadges = !$settingDisplayTnBadges)}>
      {#if $settingDisplayTnBadges}
        <ShowIcon />
      {:else}
        <HideIcon />
      {/if}
    </div>
  </div>
  <div class="item-shape w-20 h-32 border border-black p-2">
    <div
      class="edge-offsets border border-dashed border-gray-600 w-full h-full flex flex-col justify-end items-center relative"
      class:active={$settingDisplayEdgeOffsets}>
      <div
        class="show-button top-0 right-0 -mr-10"
        class:active={$settingDisplayEdgeOffsets}
        on:click={() => ($settingDisplayEdgeOffsets = !$settingDisplayEdgeOffsets)}>
        {#if $settingDisplayEdgeOffsets}
          <ShowIcon />
        {:else}
          <HideIcon />
        {/if}
      </div>
      <div class="py-1" class:opacity-20={!$settingDisplayTnQty}>(2)</div>
      <div
        class="show-button bottom-0 right-0 -mr-10"
        class:active={$settingDisplayTnQty}
        on:click={() => ($settingDisplayTnQty = !$settingDisplayTnQty)}>
        {#if $settingDisplayTnQty}
          <ShowIcon />
        {:else}
          <HideIcon />
        {/if}
      </div>
    </div>
  </div>
  <div class="w-20 relative border border-transparent">
    <div class="text-center" class:opacity-20={!$settingDisplayTnDims}>20x32</div>
    <div
      class="show-button bottom-0 right-0 -mr-8"
      class:active={$settingDisplayTnDims}
      on:click={() => ($settingDisplayTnDims = !$settingDisplayTnDims)}>
      {#if $settingDisplayTnDims}
        <ShowIcon />
      {:else}
        <HideIcon />
      {/if}
    </div>
  </div>
  <div class="w-20 relative border border-transparent flex justify-center">
    <svg
      viewBox="-1 -1 12 12"
      width="30"
      height="30"
      xmlns="http://www.w3.org/2000/svg"
      class:opacity-20={!$settingDisplayTnMark}>
      <polygon
        points="2.5,9.33013 7.5,9.33013 10,5 7.5,0.669873 2.5,0.669873 0,5"
        style="fill:white;stroke:black;stroke-width:0.5;" />
      <text x="5" y="7" style="font-size:5px;text-anchor:middle;">A</text>
    </svg>
    <div
      class="show-button bottom-0 right-0 -mr-8 mb-1"
      class:active={$settingDisplayTnMark}
      on:click={() => ($settingDisplayTnMark = !$settingDisplayTnMark)}>
      {#if $settingDisplayTnMark}
        <ShowIcon />
      {:else}
        <HideIcon />
      {/if}
    </div>
  </div>
</div>

<style lang="scss">
  @import "src/assets/styles/variables.scss";

  .thumbnail {
    font-family: $font-monospace;
    @apply flex flex-col items-center text-xs relative py-2;
  }

  .item-shape {
    background-color: #b4d8f5;
  }

  .show-button {
    @apply absolute p-1 rounded cursor-pointer text-gray-500;

    &:hover {
      @apply bg-gray-200;
    }

    &.active {
      @apply text-blue-500;
    }
  }

  .edge-offsets {
    border-color: #4b556333;

    &.active {
      border-color: #4b5563;
    }
  }
</style>
