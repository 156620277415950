<script>
  import { onMount, getContext } from "svelte";
  import uniqBy from "lodash/uniqBy";

  import SendIcon from "src/assets/icons/share.svg";
  import Datatable from "src/lib/datatable/Datatable.svelte";
  import { Modal } from "svelte-utilities";
  import TextInput from "src/lib/sidebar/TextInput.svelte";
  import ErrorMessage from "src/lib/standard-inputs/ErrorMessage.svelte";

  import isEmail from "@local/extensions/validators/is-email.js";
  import { api, addRep, resendRepInvitation } from "src/api";
  import { profile } from "src/stores/auth.js";

  const org = getContext("org");

  let reps = [];
  let error;
  let invitationToResend = null;
  let resendInvitationModal;
  let newRepModal;
  let removeRepModal;
  let repToRemove = null;

  let name = "";
  let address = "";
  let user_email = "";

  $: canAdmin = $profile && ["org_admin", "developer"].includes($profile.user_role);
  $: repProps = [
    { label: "Org Name", prop: "name", type: "text" },
    { label: "Email", prop: "primary_contact_email", type: "text" },
    { label: "Address", prop: "address", type: "text" },
    { label: "Confirmed", prop: "confirmed_at", type: "boolean" },
  ];

  async function fetchReps() {
    const c = await api
      .from("business_relations")
      .select("*,rep: rep_id(*)")
      .eq("seller_id", $org.id)
      .not("rep_id", "is", null);

    if (!c.error) {
      let r;
      r = c.data.map((c) => ({ ...c.rep, business_relation: c }));
      reps = uniqBy(r, "id");
    }
  }

  function clickRep() {}

  function confirmRemoveRep(e) {
    repToRemove = reps[e.detail.index];
    removeRepModal.open();
  }

  async function removeRep() {
    const r = await api
      .from("business_relations")
      .delete()
      .eq("seller_id", $org.id)
      .eq("rep_id", repToRemove.id);

    if (!r.error) {
      await fetchReps();
    }
    removeRepModal.close();
  }

  async function inviteRep() {
    const { error: cError } = await addRep({
      name,
      address,
      user_email,
      seller_id: $org.id,
    });

    if (cError) {
      error = cError.message;
    }

    await fetchReps();
    if (newRepModal) newRepModal.close();
  }

  function confirmResendInvite(id) {
    invitationToResend = id;
    resendInvitationModal.open();
  }

  async function resendInvite() {
    if (!invitationToResend) return;

    const { error: cError } = await resendRepInvitation({
      rep_id: invitationToResend,
      seller_id: $org.id,
    });

    if (cError) {
      error = cError.message;
    }
  }

  function beginAddingRep() {
    name = "";
    address = "";
    user_email = "";
    newRepModal.open();
  }

  onMount(() => {
    fetchReps();
  });
</script>

<div class="px-4 pb-4 border-b border-gray-300">
  <h2>Reps</h2>
</div>

{#if canAdmin}
  <div class="py-4 sm:px-4 flex justify-end items-center">
    <button class="btn btn-primary" on:click={beginAddingRep}>+ Add Rep</button>
  </div>
{/if}

<ErrorMessage message={error} on:close={() => (error = null)} />
<div class="bg-white border rounded-lg overflow-hidden">
  <Datatable
    data={reps}
    columns={repProps}
    deletable={canAdmin}
    visitable
    on:clickrow={clickRep}
    on:deleterow={confirmRemoveRep}>
    <div slot="rowactions" let:item>
      <button class="dropdown-button-item" on:click={() => confirmResendInvite(item.id)}>
        <SendIcon />
        <div>Resend</div>
      </button>
    </div>
  </Datatable>
</div>

<Modal
  bind:this={removeRepModal}
  width="36rem"
  closeable
  on:confirm={removeRep}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Remove", type: "confirm", style: "danger" },
  ]}>
  <div slot="title">Remove Customer</div>
  <div slot="content" class="space-y-4">
    <div>Are you sure you want to remove this customer?</div>
    <div class="px-4">
      <div>Name: <span class="font-bold">{repToRemove?.name}</span></div>
      <div>Email: <span class="font-bold">{repToRemove?.primary_contact_email}</span></div>
      <div>Address: <span class="font-bold">{repToRemove?.address || ""}</span></div>
    </div>
    <div>They will no longer be able to submit requests to your organization.</div>
  </div>
</Modal>

<Modal
  bind:this={newRepModal}
  width="36rem"
  closeable
  on:confirm={inviteRep}
  buttons={[
    { label: "Cancel", type: "cancel" },
    {
      label: "Invite",
      type: "confirm",
      style: "primary",
      disabled: !isEmail(user_email) || !name,
    },
  ]}>
  <div slot="title">Invite Customer</div>
  <div slot="content" class="space-y-4">
    <TextInput labelWidth="8rem" label="Organization Name" border inputOnKeydown bind:value={name} />
    <TextInput labelWidth="8rem" label="User Email" border inputOnKeydown bind:value={user_email} />
    <TextInput labelWidth="8rem" label="Address" border inputOnKeydown bind:value={address} />
  </div>
</Modal>

<Modal
  bind:this={resendInvitationModal}
  width="36rem"
  closeable
  on:confirm={resendInvite}
  buttons={[
    { label: "Cancel", type: "cancel" },
    {
      label: "Resend Invitation",
      type: "confirm",
      style: "primary",
    },
  ]}>
  <div slot="title">Resend Rep Invitation</div>
  <div slot="content" class="space-y-4">
    {@const rep = reps.find((c) => c.id === invitationToResend)}
    <div>Do you want to resend an invitation to this rep?</div>
    <div>
      {rep?.name} ({rep?.primary_contact_email})
    </div>
  </div>
</Modal>
