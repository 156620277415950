function countCategories(orderedCategory, groupedItems) {
  const lengths = orderedCategory.map((id) =>
    groupedItems[id] ? groupedItems[id].length : 0
  );

  return lengths.reduce((l, len, i) => {
    if (i === 0) {
      l.push(len);
    } else {
      l.push(l[i - 1] + len);
    }

    return l;
  }, []);
}

export default countCategories;
