<!-- <svelte:options strictprops={false} /> -->

<script>
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";

  import Navbar from "src/lib/Navbar.svelte";
  import OrgSettings from "src/lib/settings/OrgSettings.svelte";
  import Datatable from "src/lib/datatable/Datatable.svelte";

  import { orgs } from "src/stores/organizations.js";
  import { profile } from "src/stores/auth.js";
  import { createOrg } from "src/stores/org.js";

  import formatDateTime from "@local/extensions/formatters/format-date-time.js";

  export let orgid;

  let newUser = null;

  const org = createOrg();

  $: $orgs[orgid] && org.init($orgs[orgid]);
  $: userProps = [
    {
      label: "",
      prop: "user_color",
      type: "color",
      editable: false,
    },
    {
      label: "Name",
      prop: "username",
      type: "text",
    },
    {
      label: "Email",
      prop: "email",
      type: "text",
    },
    {
      label: "Role",
      prop: "user_role",
      type: "text",
      editable: false,
    },
    {
      label: "Confirmed",
      prop: "confirmed_at",
      formatter: (v) => (v ? "✓" : ""),
      editable: false,
    },
    ...($org?.org_type === "supplier"
      ? [
          {
            label: "Public",
            prop: "is_public",
            type: "boolean",
          },
        ]
      : []),
    {
      label: "Last sign in",
      prop: "last_sign_in_at",
      formatter: formatDateTime,
      editable: false,
    },
  ];

  function updateDevOrg() {
    if (!$org) return;
    $profile.organization_id = orgid;
    $profile.organization = $org;
    localStorage.setItem("currentOrg", orgid);
  }

  function clickUser(evt) {
    const { id } = evt.detail;
    navigate(`/profiles/${id}`);
  }

  function addNewUser(evt) {
    const { row } = evt.detail;
    org.addUser({
      ...row,
      send_magic_link: true,
    });
    newUser = null;
  }

  function stopAddingUser() {
    newUser = null;
  }

  function removeUser(evt) {
    const { id } = evt.detail;
    org.removeUser(id);
  }

  onMount(() => {
    orgs.fetchOne(orgid);
  });
</script>

<Navbar />
{#if $org}
  <div class="grow overflow-auto bg-gray-100 p-4">
    <OrgSettings {org} />
    {#if $profile?.user_role === "developer"}
      <div class="w-full sm:px-4">
        <div class="mb-4 w-full sm:w-96">
          <button class="btn btn-danger w-full" on:click={updateDevOrg}
            >Act as a member of this Organization</button>
        </div>
      </div>
    {/if}
    <h3 class="py-1 px-2">Organization members</h3>
    <div class="bg-white border rounded-lg overflow-hidden">
      <Datatable
        data={$org.profiles}
        columns={userProps}
        newRow={newUser}
        deletable
        clickable
        on:clickrow={clickUser}
        on:addrow={addNewUser}
        on:deleterow={removeUser}
        on:stopaddingrow={stopAddingUser} />
    </div>
  </div>
{/if}
