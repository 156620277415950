<script>
  import { Modal } from "svelte-utilities";
  import { profile } from "src/stores/auth.js";
  import { ws, api } from "src/api";

  let clientReloadModal;

  async function reloadClients() {
    const { data } = await api.auth.getSession();
    const { access_token } = data.session;
    ws.send(JSON.stringify({ action: "update-appid", token: access_token }));
  }
</script>

<div class="px-4 pb-4 border-b border-gray-300">
  <h2>Developer</h2>
</div>

{#if $profile?.user_role === "developer"}
  <div class="py-4 sm:px-4 text-sm flex">
    <div class="w-full sm:w-96 space-y-4">
      <button class="my-4 px-4 btn btn-danger w-full" on:click={() => clientReloadModal.open()}>
        Force Client Reload
      </button>
    </div>
  </div>
{/if}

<Modal
  bind:this={clientReloadModal}
  on:confirm={reloadClients}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Force Reload", type: "confirm", style: "danger" },
  ]}
  closeOnOutclick>
  <div slot="title">Reload Clients</div>
  <div slot="content">Are you sure you want to force all clients to reload?</div>
</Modal>
