<script>
  import { createEventDispatcher } from "svelte";

  export let text = "Delete";

  const dispatch = createEventDispatcher();
</script>

<div class="flex justify-start px-2">
  <button class="btn btn-danger" on:click={() => dispatch("delete")}>{text}</button>
</div>
