<script>
  import { onMount } from "svelte";

  import { rfqs } from "src/stores/rfqs.js";
  import { quotes } from "src/stores/quotes.js";
  import { user } from "src/stores/auth.js";

  onMount(async () => {
    if ($rfqs) {
      await rfqs.unsubscribe();
    }

    if ($quotes) {
      await quotes.unsubscribe();
    }

    if ($user) {
      await user.logout();
    }
  });
</script>
