export default function sortByCategory(types, categories) {
  const grouped = types.reduce(
    (g, type) => {
      const category = type.category_id ?? type.category;
      if (category) {
        if (g[category]) {
          g[category].push(type);
        } else {
          g[category] = [type];
        }
      } else {
        g._uncategorized.push(type);
      }
      return g;
    },
    { _uncategorized: [] },
  );

  const u = grouped._uncategorized;
  delete grouped._uncategorized;

  const cats = Object.keys(grouped).sort((a, b) => {
    const acat = categories[a];
    const bcat = categories[b];

    if (acat && bcat) {
      return new Date(acat.created_at) - new Date(bcat.created_at);
    }

    const aprop = categories[a] ? categories[a].name : a;
    const bprop = categories[b] ? categories[b].name : b;

    return aprop.localeCompare(bprop);
  });

  const result = [];

  cats.forEach((cat) => {
    result.push(...grouped[cat]);
  });
  result.push(...u);

  return result;
}
