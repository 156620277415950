<script>
  import { setContext, onMount } from "svelte";
  import { derived, writable } from "svelte/store";
  import { createOrg, getOrgData } from "src/stores/org.js";
  import { createEdgeTreatmentStore, createEdgeTreatmentOptions } from "src/data/edge-treatments.js";
  import { createFabricationStore } from "src/data/fabrications.js";
  import { currentSupplierid, currentSuppliers } from "src/stores/ui.js";
  import {
    getSuppliers,
    getSupplier,
    getPriceEntries,
    getProductLists,
  } from "src/extensions/supplier-product-data.js";

  import { sortableList } from "@local/extensions/collections/sortable-list.js";
  import { profile } from "src/stores/auth.js";
  import { orgs } from "src/stores/organizations.js";
  import { api } from "src/api";

  const org = createOrg();
  const edgeTreatments = createEdgeTreatmentStore();
  const edgeTreatmentOptions = createEdgeTreatmentOptions(edgeTreatments);
  const fabrications = createFabricationStore();
  const productUser = derived(profile, ($profile) => {
    if ($profile?.user_role === "product_user") return true;
    if ($profile?.organization?.org_type === "product_rep") return true;
    return false;
  });
  const priceEntries = writable({});
  const productLists = writable([]);
  const supplier = writable(null);
  const suppliers = writable([]);

  setContext("org", org);
  setContext("edgeTreatments", edgeTreatments);
  setContext("edgeTreatmentOptions", edgeTreatmentOptions);
  setContext("fabrications", fabrications);
  setContext("productUser", productUser);
  setContext("productLists", productLists);
  setContext("priceEntries", priceEntries);
  setContext("suppliers", suppliers);
  setContext("supplier", supplier);

  $: initOrg($profile);
  $: isSupplier = $profile?.organization?.org_type === "supplier";
  $: isRep = $profile?.organization?.org_type === "product_rep";
  $: updateSuppliers($org);
  $: updateSupplier($currentSupplierid);
  $: updatePriceEntries($currentSupplierid);
  $: updateProductLists($currentSupplierid);

  async function initOrg(profile) {
    if (profile && profile.organization_id) {
      await orgs.fetchOne(profile.organization_id);
      const { data } = await getOrgData(profile.organization_id);
      org.init(data);
    }
  }

  async function updateSuppliers(org) {
    if (!org) return;
    const s = await getSuppliers(org);
    suppliers.set(s);
    $currentSuppliers = sortableList(s);
    if (s.length) {
      $currentSupplierid = s[0].id;
    }
  }

  async function updateSupplier(supplierId) {
    const s = await getSupplier(supplierId);
    supplier.set(s);
  }

  async function updatePriceEntries(supplierId) {
    const pe = await getPriceEntries(supplierId);
    priceEntries.set(pe);
  }

  async function updateProductLists(supplierId) {
    const pl = await getProductLists(supplierId);
    productLists.set(pl);
  }

  onMount(() => {
    edgeTreatments.fetch();
    fabrications.fetch();
  });
</script>

{#if $org}
  <slot />
{/if}
