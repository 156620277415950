import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

function makeMultiProduct(selected, application) {
  if (selected.length < 2) return null;

  const prods = [...selected];
  const last = cloneDeep(prods.pop());
  last.id = [last.id];

  return prods.reduce((m, product) => {
    Object.entries(product).forEach(([key, value]) => {
      // Data/cache properties need to be merged to identify which properties are mixed
      if (key === "id") {
        m.id.push(value);
      } else if (key === "cache") {
        if (!m.cache) m.cache = {};
        const v = value || {};

        Object.entries(m.cache).forEach(([key, prop]) => {
          const vprop = v[key];
          if (!isEqual(prop, vprop)) {
            m.cache[key] = "Mixed";
          }
        });
      } else if (key === "data") {
        const val = m[key] || {};
        const v = value || {};

        if (application === "material") {
          if (
            val.thickness !== "Mixed" &&
            val.thickness?.toString() !== v.thickness?.toString()
          ) {
            val.thickness = "Mixed";
          }
        }
      } else if (typeof value === "object" && isEqual(m[key], value)) {
        m[key] = value;
      } else if (m[key] !== value) {
        m[key] = "Mixed";
      }
    });

    return m;
  }, last);
}

export default makeMultiProduct;
