<script>
  import { createEventDispatcher } from "svelte";
  import isEmpty from "lodash/isEmpty";
  import { Link } from "svelte-routing";
  import cloneDeep from "lodash/cloneDeep";
  import SidebarSection from "./SidebarSection.svelte";
  import TextInput from "./TextInput.svelte";
  import ContenteditableInput from "./ContenteditableInput.svelte";
  import DimtextInput from "./DimtextInput.svelte";
  import SelectInput from "./SelectInput.svelte";
  import UploadDrawing from "./UploadDrawing.svelte";
  import { fabParams } from "@local/extensions/fabrications";

  export let group;
  export let fabrication;
  export let disabled;
  export let assignments;

  const dispatch = createEventDispatcher();

  $: settings = $group.data.settings;
  $: functionParams = fabParams[fabrication.func];
  $: assignedItems = Object.keys(assignments);
  $: visibleAssigned = assignedItems.slice(0, 5);

  function updateFabrication(prop, value) {
    group.updateProp(`data.fabrications.${fabrication.id}.${prop}`, value);
  }

  function updateShape(shape) {
    const fab = { ...fabrication };
    fab.func = shape;
    fab.params = fabParams[shape].map((p) => p.default);

    group.updateProp(`data.fabrications.${fabrication.id}`, fab);
  }

  function updateParam(index, value) {
    const p = fabParams[fabrication.func][index];
    if (p.update) {
      const newParams = cloneDeep(fabrication.params);
      p.update(newParams, value);
      group.updateProp(`data.fabrications.${fabrication.id}.params`, newParams);
    } else {
      group.updateProp(`data.fabrications.${fabrication.id}.params.${index}`, value);
    }
  }

  function highlight(param) {
    dispatch("highlight", { param });
  }

  function focus(param) {
    dispatch("focus", { param });
  }

  function unhighlight(param) {
    dispatch("unhighlight", { param });
  }

  function blur(param) {
    dispatch("blur", { param });
  }
</script>

<SidebarSection>
  {#if !isEmpty(assignments)}
    <div class="p-2">
      <div>Used by the following items:</div>
      <div class="px-2">
        {#each visibleAssigned as itemid, index}
          <Link to={`items/${itemid}`}>{$group.items[itemid].mark}</Link
          >{#if index < visibleAssigned.length - 1},&nbsp;{/if}
        {/each}
      </div>
      {#if assignedItems.length > visibleAssigned.length}
        <div>...and {assignedItems.length - visibleAssigned.length} more.</div>
      {/if}
    </div>
  {/if}
  <TextInput
    label="Mark"
    {disabled}
    labelWidth="5.5rem"
    value={fabrication.mark}
    on:input={(e) => updateFabrication("mark", e.detail.value)} />
  <TextInput
    label="Name"
    {disabled}
    labelWidth="5.5rem"
    value={fabrication.name}
    on:input={(e) => updateFabrication("name", e.detail.value)} />
  <TextInput label="Position" {disabled} readonly labelWidth="5.5rem" value={fabrication.position} />
  <ContenteditableInput
    label="Notes"
    {disabled}
    labelWidth="5.5rem"
    value={fabrication.notes || ""}
    on:input={(e) => updateFabrication("notes", e.detail.value)} />
</SidebarSection>
{#if fabrication.type === "generic"}
  <UploadDrawing {group} path={`data.fabrications.${fabrication.id}.drawing`} recordName="fabrication" />
{/if}
{#if fabrication.type === "function"}
  <SidebarSection>
    <SelectInput
      label="Shape"
      {disabled}
      labelWidth="5.5rem"
      value={fabrication.func}
      on:input={(e) => updateShape(e.detail.value)}
      options={fabrication.position === "corner"
        ? [{ label: "Patch", value: "patch" }]
        : [
            { label: "Geneva", value: "geneva" },
            { label: "Cutout", value: "cutout" },
          ]} />
    {#each functionParams as param, index}
      <DimtextInput
        label={param.label}
        {disabled}
        labelWidth="5.5rem"
        value={fabrication.params[index]}
        on:mouseover={() => highlight(param)}
        on:focus={() => focus(param)}
        on:mouseout={() => unhighlight(param)}
        on:blur={() => blur(param)}
        {settings}
        on:input={(e) => updateParam(index, e.detail.value)} />
    {/each}
  </SidebarSection>
{/if}
