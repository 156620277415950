<script>
  import get from "lodash/get";
  import set from "lodash/set";
  import { createEventDispatcher, tick } from "svelte";
  import { Popover, clickOutside } from "svelte-utilities";
  import SearchIcon from "src/assets/icons/search.svg";
  import CancelIcon from "src/assets/icons/x.svg";
  import CheckIcon from "src/assets/icons/check.svg";
  import isEmail from "@local/extensions/validators/is-email";
  import ErrorMessage from "src/lib/standard-inputs/ErrorMessage.svelte";

  export let border = false;
  export let placeholder = "Placeholder";
  export let label = null;
  export let labelWidth = "";
  export let disabled = false;
  export let value = "";
  export let selection = null;
  export let results = [];
  export let pendingData = [];
  export let showSelectedDetails = false;
  export let noResultsMsg = "No matching results found.";
  export let validate = null;
  export let update = null;
  export let confirm = null;
  export let searchIcon = true;
  export let outerBorder = true;
  export let newResult = null;
  export let confirmPending = async (v) => ({ success: true, message: null });

  const dispatch = createEventDispatcher();

  let focused = false;
  let text = value;
  let input;
  let inputContainer;
  let arrowCounter = -1;
  let errorMessage = null;
  let adding = false;
  let newResultForm;
  let newResultData = [];
  let showPanel = false;

  export const focus = () => {
    input.focus();
  };

  $: lw = labelWidth ? `width:${labelWidth};` : "";
  $: findNewResults(text);
  $: updateText(selection);
  $: selected = selection && !selection.pending;
  $: pending = selection?.pending;
  $: setResultData(newResult);
  $: newResultValid = checkNewResultData(newResultData);

  function setResultData(newResult) {
    if (newResult) {
      newResultData = newResult.inputs.map((input) => "");
    }
  }

  function checkNewResultData(data) {
    return data.every((d, i) => {
      const nrInput = newResult.inputs[i];
      if (nrInput.type === "email") {
        if (nrInput.required) return isEmail(d);
        if (d) return isEmail(d);
      }
      return d && (!nrInput.required || d.length > 0);
    });
  }

  async function findNewResults(t) {
    value = text;
    dispatch("search", { value: text });
  }

  function updateText(selection) {
    if (selection) {
      text = selection.name;
    }
  }

  async function handleFocus() {
    dispatch("focus", { value: text });
    showPanel = true;
    focused = true;
  }

  function outclick() {
    adding = false;
    showPanel = false;
  }

  function handleBlur() {
    if (value) {
      const result = results.find((r) => r.id === value);
      if (result) text = result.name;
    }
    focused = false;
  }

  function handleKeydown(evt) {
    if (evt.key === "ArrowDown") handleDownArrow();
    if (evt.key === "ArrowUp") handleUpArrow();
    if (evt.key === "Enter") handleEnter();
    if (evt.key === "Escape") handleEsc();
  }

  function handleDownArrow() {
    const len = newResult ? results.length : results.length - 1;
    if (arrowCounter < len) {
      arrowCounter += 1;
    }
  }

  function handleUpArrow() {
    if (arrowCounter > 0) {
      arrowCounter -= 1;
    }
  }

  function handleEnter() {
    if (arrowCounter === results.length && !adding) {
      beginAddingNewResult();
      return;
    }

    const result = results[arrowCounter];
    if (result) {
      select(result);
      input.blur();
    }
  }

  function handleEsc() {
    input.blur();
  }

  async function beginAddingNewResult() {
    arrowCounter = -1;
    adding = true;
    await tick();
    const nrfInput = newResultForm.querySelector("input");
    if (nrfInput) {
      nrfInput.focus();
    }
  }

  function addNewResult() {
    const nrd = newResultData.reduce((a, d, i) => {
      a[newResult.inputs[i].prop] = d;
      return a;
    }, {});
    dispatch("add", { result: nrd });
    setResultData(newResult);
    adding = false;
    showPanel = false;
  }

  function select(result) {
    if (result.invalid) return;
    dispatch("select", { result });
    selection = result;
    text = result.name;
    if (input) input.blur();
    showPanel = false;
  }

  function cancelSelection() {
    selection = null;
    text = "";
    dispatch("deselect");
  }

  function updateSelectionProp(prop, val) {
    if (val === "null") val = null;
    set(selection?.values, prop, val);
    if (update) update(selection, prop, val);
    selection = selection;
  }

  async function confirmSelection() {
    errorMessage = null;
    if (confirm) confirm(selection);

    const { success, message } = await confirmPending(selection);

    if (success) {
      selection.pending = false;
      select(selection);
    } else {
      errorMessage = message;
    }
  }
</script>

<div
  class="prop-container"
  class:has-outer-border={outerBorder}
  class:has-inner-border={!outerBorder}
  class:focused
  class:selected
  class:readonly={disabled}
  class:pending={selection?.pending}
  class:visible-border={border}>
  <div class="flex items-center">
    {#if searchIcon}
      <div class="text-gray-400">
        <div class="py-1">
          <SearchIcon />
        </div>
      </div>
    {/if}
    {#if label}
      <div style={lw}>
        <div class="label">
          <slot name="label">
            {label}
          </slot>
        </div>
      </div>
    {/if}
    <div class="grow ml-2">
      <div class="relative flex items-center input-div" class:readonly={disabled} bind:this={inputContainer}>
        {#if !text}
          <div class="absolute pointer-events-none">
            <div class="placeholder">
              <slot name="placeholder">
                {placeholder}
              </slot>
            </div>
          </div>
        {/if}
        {#if selected && showSelectedDetails}
          <div class="grow">
            <div class="flex items-end gap-2">
              <div class="truncate">{selection.name}</div>
              {#if selection.description}
                <div class="text-xs text-gray-600 truncate">{selection.description}</div>
              {/if}
            </div>
            {#if selection.cta}
              <div class="text-xs text-gray-600 truncate">
                {selection.cta}
              </div>
            {/if}
          </div>
        {:else}
          <input
            type="text"
            class="input"
            bind:this={input}
            disabled={disabled || !!selection}
            bind:value={text}
            on:focus={handleFocus}
            on:blur={handleBlur}
            on:keydown={handleKeydown} />
        {/if}
        {#if selected && !disabled}
          <button class="cancel-button" on:click={cancelSelection}>
            <CancelIcon />
          </button>
        {:else if pending && !disabled}
          <button
            class="text-amber-600 p-1 rounded hover:bg-amber-200 cursor-pointer"
            on:click={cancelSelection}>
            <CancelIcon />
          </button>
        {/if}
        {#if showPanel}
          <Popover container={inputContainer} fit>
            <div use:clickOutside={[inputContainer]} on:outclick={outclick}>
              <ul class="w-full z-40 overflow-hidden text-sm">
                {#if results.length > 0}
                  {#each results as result, index}
                    <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <li
                      class="px-2 py-1"
                      class:keyed={index === arrowCounter}
                      on:click={() => select(result)}>
                      <div class="flex items-end gap-2">
                        <div class="truncate">{result.name}</div>
                        {#if result.description}
                          <div class="text-xs small-text truncate">{result.description}</div>
                        {/if}
                      </div>
                      {#if result.cta}
                        <div class="text-xs small-text truncate">
                          {result.cta}
                        </div>
                      {/if}
                    </li>
                  {/each}
                {:else}
                  <li class="px-2 py-1 text-gray-500">{noResultsMsg}</li>
                {/if}
                {#if newResult && !adding}
                  <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
                  <!-- svelte-ignore a11y-click-events-have-key-events -->
                  <li
                    class="px-2 py-1"
                    class:keyed={results.length === arrowCounter}
                    on:click={beginAddingNewResult}>
                    <div class="flex items-end gap-2">
                      <div class="truncate">{newResult.name}</div>
                      {#if newResult.description}
                        <div class="text-xs small-text truncate">{newResult.description}</div>
                      {/if}
                    </div>
                    {#if newResult.cta}
                      <div class="text-xs small-text truncate">
                        {newResult.cta}
                      </div>
                    {/if}
                  </li>
                {/if}
              </ul>
              {#if adding}
                <div class="px-2 py-1">
                  <div class="text-sm text-black">
                    {newResult.name}
                  </div>
                  <div class="space-y-2 mt-2" bind:this={newResultForm}>
                    {#each newResult.inputs as nrInput, inputIndex}
                      <div class="text-xs flex gap-2 items-center px-4">
                        <div class="w-24 flex-none text-gray-500">
                          {nrInput.label}
                        </div>
                        <input
                          type="text"
                          class="grow border rounded p-1"
                          bind:value={newResultData[inputIndex]} />
                      </div>
                    {/each}
                  </div>
                  <div class="px-4 flex justify-end">
                    <button
                      on:click={addNewResult}
                      class="my-2 p-1.5 rounded hover:bg-gray-200"
                      class:text-gray-500={!newResultValid}
                      class:cursor-not-allowed={!newResultValid}
                      disabled={!newResultValid}>
                      <CheckIcon />
                    </button>
                  </div>
                </div>
              {/if}
            </div>
          </Popover>
        {/if}
      </div>
    </div>
  </div>
  {#if pending}
    {#if errorMessage}
      <div class="text-xs mt-4">
        <ErrorMessage message={errorMessage} on:close={() => (errorMessage = null)} padding={false} />
      </div>
    {/if}
    <div class="flex py-2 pr-6">
      <div class="opacity-0 flex-none">
        <div class="py-1">
          <SearchIcon />
        </div>
      </div>
      <div class="grow ml-2 space-y-1 text-xs">
        {#each pendingData as input}
          <div class="flex gap-2 items-center">
            <div class="text-xs text-gray-500 w-24 flex-none">{input.label}</div>
            {#if input.type === "select"}
              <select
                class="grow min-w-0 max-w-full bg-amber-50 border border-amber-300 rounded p-1"
                value={get(selection?.values, input.prop)}
                on:change={(e) => updateSelectionProp(input.prop, e.target.value)}>
                {#each input.options as option}
                  <option value={option.value}>{option.label}</option>
                {/each}
              </select>
            {:else}
              <input
                type="text"
                class="grow bg-amber-50 border border-amber-300 rounded p-1"
                value={get(selection?.values, input.prop)}
                on:input={(e) => updateSelectionProp(input.prop, e.target.value)} />
            {/if}
          </div>
        {/each}
        <div class="text-right">
          <button
            class="btn btn-primary-alt btn-compact"
            on:click={confirmSelection}
            disabled={validate && !validate(selection)}>
            OK
          </button>
        </div>
      </div>
    </div>
  {/if}
</div>

<style lang="scss">
  .input {
    border: none;
    background-color: none;
    outline: none;
    min-width: 0;
    max-width: 100%;
  }

  .prop-container {
    @apply w-full rounded relative bg-white;
    outline: none;

    &.has-outer-border {
      @apply p-2;

      &:hover:not(.readonly):not(.selected):not(.pending):not(.focused),
      &.visible-border {
        @apply ring-1 ring-inset ring-gray-300;
      }
      &.focused,
      &:focus {
        @apply ring-2 ring-inset ring-blue-500;
      }

      &.selected {
        @apply ring-2 ring-inset ring-blue-500 bg-blue-100;
      }

      .cancel-button {
        @apply text-blue-600 rounded cursor-pointer p-1;

        &:hover {
          @apply bg-blue-200;
        }
      }
    }

    &.has-inner-border {
      .input-div {
        @apply p-1 rounded border border-transparent;

        &:hover:not(.readonly) {
          @apply border-gray-300;
        }
      }
    }

    &.pending {
      @apply ring-2 ring-inset ring-amber-500 bg-amber-100;
    }

    .placeholder {
      @apply truncate text-gray-400;
    }

    .input {
      @apply min-w-0 grow w-full bg-transparent shrink truncate;
    }
  }

  .small-text {
    @apply text-gray-400;
  }

  li:hover,
  .keyed {
    @apply bg-blue-500 text-white;

    .small-text {
      @apply text-gray-200;
    }
  }
</style>
