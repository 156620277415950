export default function maxima(arr) {
  let minX;
  let minY;
  let maxX;
  let maxY;

  for (let i = 0; i < arr.length; i++) {
    const v = arr[i];

    if (minX === undefined || v[0] < minX) {
      minX = v[0];
    }

    if (minY === undefined || v[1] < minY) {
      minY = v[1];
    }

    if (maxX === undefined || v[0] > maxX) {
      maxX = v[0];
    }

    if (maxY === undefined || v[1] > maxY) {
      maxY = v[1];
    }
  }

  return { minX, minY, maxX, maxY };
}
