<svelte:options strictprops={false} />

<script>
  import { getContext } from "svelte";
  import { Link } from "svelte-routing";
  import upperFirst from "lodash/upperFirst";
  import get from "lodash/get";
  import { LinearDisplayFormat } from "dimtext";
  import CaretRightIcon from "src/assets/icons/caret-right.svg";
  import CaretDownIcon from "src/assets/icons/caret-down.svg";
  import CaretDownRight from "src/assets/icons/caret-down-right.svg";
  import Viewport from "src/lib/drawing/ViewportFixed.svelte";
  import typeDrawing from "@local/extensions/drawing/type-drawing.js";
  import getTotalThickness from "@local/extensions/formatters/get-total-thickness.js";
  import { typeSurfaces } from "@local/lamina-core";
  import liteDrawing from "@local/extensions/drawing/lite-drawing-mini.js";
  import PaperclipIcon from "src/assets/icons/paperclip-xs.svg";
  import imageUrl from "src/extensions/image-url.js";

  export let group;
  export let type = null;
  export let items;
  export let columns;
  export let selected;
  export let typeIndex;
  export let index;
  export let attachments;
  export let linkPrefix;

  const typeColors = getContext("typeColors");

  // three-state boolean: 0 = collapsed, 1 = expanded, -1 = partially expanded
  let expanded = 1;

  $: du = $group.data.settings.display_unit;
  $: productGroup = $group.project_type === "product";
  $: typeSettings = {
    display_unit: $group.data.settings.display_unit,
    mm_precision: $group.data.settings.mm_precision,
    decimal_precision: 3,
    fractional_precision: 5,
    dimension_format: $group.data.settings.dimension_format,
  };
  $: thickness = type && getTotalThickness(type, typeSettings);
  $: surfaces = type && typeSurfaces(type);
  $: area = typeArea(items);
  $: areaFormatted = du === "millimeters" ? `${(area * 0.092903).toFixed(2)} m²` : `${area.toFixed(2)} sf`;
  $: undefinedItems = items.filter((i) => !i.width || !i.height).length;

  function toggleExpanded() {
    if (!expanded) {
      expanded = 1;
    } else if (expanded > 0) {
      expanded = -1;
    } else {
      expanded = 0;
    }
  }

  function formatThickness(val) {
    const sdf = "FRACTIONAL";

    if (!val) return "Thickness TBD";

    const v = val.format(LinearDisplayFormat[sdf], sdf === "DECIMAL" ? 3 : 5, {
      displayUnit: "inches",
    });

    return `${v}"`;
  }

  function typeArea(items) {
    return items
      .filter((i) => i.width && i.height)
      .reduce((acc, item) => {
        const a = (item.cache.width / 12) * (item.cache.height / 12);
        return acc + a * item.quantity;
      }, 0);
  }

  function undefinedItemText(u) {
    if (!u) return "";
    if (u === 1) return " (1 undefined item)";
    return ` (${u} undefined items)`;
  }

  function datum(item, column) {
    const val = get(item, column.prop);

    let result;

    if (column.type === "select") {
      result = column.optionMap[val];
    } else if (column.formatterHTML) {
      result = {
        html: column.formatterHTML(val),
      };
    } else if (column.formatter) {
      result = column.formatter ? column.formatter(val, item) : val;
    } else {
      result = val;
    }

    if (result === undefined || result === null) return "";

    return result;
  }
</script>

<tbody>
  <tr>
    <td class="align-top w-8">
      <button class="p-1" on:click={toggleExpanded}>
        {#if !expanded}
          <CaretRightIcon />
        {:else if expanded > 0}
          <CaretDownIcon />
        {:else}
          <CaretDownRight />
        {/if}
      </button>
    </td>
    <td colspan={columns.length + 2} class="align-top">
      <div>
        {#if type}
          {#if productGroup}
            <div class="font-bold text-sm">{type.name}</div>
          {:else}
            <div class="text-sm">
              <span class="font-bold">
                {type.mark} - {type.name}
              </span>
              <span class="text-gray-500 italic">
                ({areaFormatted})
              </span>
            </div>
          {/if}
        {:else}
          <div class="font-bold text-sm">{"No type"}</div>
        {/if}
        {#if expanded}
          <div class="flex gap-4 py-2">
            <div class="w-24 h-24 relative">
              {#if type}
                <Link to={`${linkPrefix}/types/${type.id}`}>
                  {#if !type.data.layers && type.image}
                    <img
                      src={imageUrl(type.image)}
                      alt={type.name}
                      class="text-xs w-full h-full object-cover" />
                  {:else}
                    <Viewport
                      padding={1}
                      drawing={typeDrawing(type)}
                      cacheKey={type.id}
                      width={96}
                      height={96} />
                  {/if}
                </Link>
              {:else}
                <div class="w-full h-full border-gray-400 border-dashed" />
              {/if}
              {#if attachments?.[type?.id]}
                <div class="absolute top-0 right-0 pointer-events-none">
                  <PaperclipIcon />
                </div>
              {/if}
            </div>
            <div class="type-info">
              <div class="label">
                {#if type?.data.layers}
                  <div>Thickness</div>
                  {#each type.data.layers as layer, i}
                    <div>
                      {#if i === 0}Makeup{:else}&nbsp;{/if}
                    </div>
                  {/each}
                  <div>Area</div>
                {/if}
                <div>Quantity</div>
              </div>
              <div>
                {#if type?.data.layers}
                  <div>{thickness || "TBD"}</div>
                  {#each type.data.layers as layer, i}
                    <div>
                      <span>
                        {upperFirst(layer.type)}:&nbsp;
                      </span>
                      <span>
                        {formatThickness(layer.material?.data?.thickness)}
                      </span>
                      {#if layer.material?.name}
                        <span>, {layer.material.name}</span>
                      {/if}
                      {#if layer.outboard_surface?.name}
                        <span>, #{surfaces[i]} {layer.outboard_surface.name}</span>
                      {/if}
                      {#if layer.inboard_surface?.name}
                        <span>, #{surfaces[i] + 1} {layer.inboard_surface.name}</span>
                      {/if}
                    </div>
                  {/each}
                  <div>{areaFormatted}{undefinedItemText(undefinedItems)}</div>
                {/if}
                <div>{items.reduce((q, i) => q + i.quantity, 0)}</div>
              </div>
            </div>
          </div>
        {/if}
      </div>
    </td>
  </tr>
</tbody>
{#if expanded > 0}
  <tbody>
    {#if items.length}
      <tr>
        <td />
        <td />
        <td class="border-b" />
        {#each columns as column, col}
          <th class="border-b px-1" data-column={col} data-type-index={typeIndex}>{column.label}</th>
        {/each}
        <th />
      </tr>
      {#each items as item, i}
        {@const row = index + i}
        {@const typeColor = $typeColors[item.type_id]}
        <tr>
          <td />
          <td class="w-7">
            <div class="w-4 h-4 relative">
              <Link to={`${linkPrefix}/items/${item.id}`}>
                <Viewport padding={1} drawing={liteDrawing(item, { typeColor })} width={16} height={16} />
              </Link>
            </div>
          </td>
          <th
            class="border-l border-r border-b text-center w-8 bg-gray-100 font-normal"
            data-type-index={typeIndex}
            data-row={row}
            data-type-row={i}>{i + 1}</th>
          {#each columns as column, col}
            {@const td = datum(item, column)}
            <td
              class="border-b border-r px-1 whitespace-nowrap"
              class:bg-blue-100={selected[`${row}.${col}`]}
              data-row={row}
              data-type-row={i}
              data-type-index={typeIndex}
              data-column={col}>
              {#if td.html}
                {@html td.html}
              {:else}
                {td}
              {/if}
            </td>
          {/each}
          <td>
            {#if attachments?.[item.id]}
              <Link to={`${linkPrefix}/items/${item.id}`}>
                <div>
                  <PaperclipIcon />
                </div>
              </Link>
            {/if}
          </td>
        </tr>
      {/each}
    {:else}
      <tr>
        <td />
        <td />
        <td colspan={columns.length + 1}> No items </td>
      </tr>
    {/if}
    <tr>
      <td>&nbsp;</td>
    </tr>
  </tbody>
{/if}

<style lang="scss">
  .type-info {
    @apply flex gap-2;

    .label {
      @apply text-gray-400 text-right;
    }
  }

  td {
    @apply border-gray-300;
  }
</style>
